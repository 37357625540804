import useLoadingText from 'common/hooks/useLoadingText';
import { Button, Error, Loading, useToast } from '@optimization/ssi-common';
import { useAddSolutionMutation } from 'app/services/solution';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  disabled?: boolean;
  variant: 'primary' | 'secondary';
}

const CreateSolution = ({ className, disabled, variant }: Props) => {
  const navigate = useNavigate();
  const showToast = useToast();

  const [addSolution, { isLoading, isError, isSuccess, data }] = useAddSolutionMutation();

  const onCreateSolution = useCallback(() => {
    addSolution({ Name: null });
  }, [addSolution]);

  useEffect(() => {
    if (isSuccess) {
      const id = data?.Content?.Id;

      if (id) {
        showToast({
          header: 'Solution successfully created',
          dataTestid: 'toast-solution-created',
        });

        navigate(`/solution/${id}/home`);
      }
    }
  }, [isSuccess, data, navigate, showToast]);

  const loadingText = useLoadingText({
    isCreatingSolution: isLoading,
  });

  return (
    <div className={className}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <div className="tds-u-flex items-center">
        <Button
          text="New solution"
          className="mr-spacing-4"
          size="md"
          dataTestid="button-create-new-solution"
          iconSize={20}
          variant={variant}
          disabled={disabled || isLoading}
          onClick={onCreateSolution}
        />
      </div>
      {isError && <Error className="tds-u-mt2" />}
    </div>
  );
};

export default CreateSolution;
