import { useCallback } from 'react';

interface Props {
  items?: { Id: string | number }[];
  setSelectedItems: (value: React.SetStateAction<string[]>) => void;
}

const useHandleSelectAll = ({ items, setSelectedItems }: Props) =>
  useCallback(
    (checked: boolean) => {
      if (checked && items) {
        setSelectedItems(items.map((item) => item.Id.toString()));
      } else {
        setSelectedItems([]);
      }
    },
    [items, setSelectedItems],
  );

export default useHandleSelectAll;
