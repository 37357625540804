import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  selections: SelectionExecutions;
}

const useBusDisabled = ({ selections }: Props) => {
  const busBatteryDisabled = useMemo(() => !selections.busPortfolio, [selections]);

  const busSocDisabled = useMemo(() => !selections.busPortfolio || !selections.busBattery, [selections]);

  const busChargingTypeDisabled = useMemo(
    () => !selections.busPortfolio || !selections.busBattery || !selections.busSocWindow,
    [selections],
  );

  const busEngineGearBoxDisabled = useMemo(
    () => !selections.busPortfolio || !selections.busBattery || !selections.busSocWindow || !selections.busChargingType,
    [selections],
  );

  const busWheelConfigurationDisabled = useMemo(
    () =>
      !selections.busPortfolio ||
      !selections.busBattery ||
      !selections.busSocWindow ||
      !selections.busChargingType ||
      !selections.busElectricMotor,
    [selections],
  );

  const busBodyLengthDisabled = useMemo(
    () =>
      !selections.busPortfolio ||
      !selections.busBattery ||
      !selections.busSocWindow ||
      !selections.busChargingType ||
      !selections.busElectricMotor ||
      !selections.busWheelConfiguration,
    [selections],
  );

  const busChassisHeightDisabled = useMemo(
    () =>
      !selections.busPortfolio ||
      !selections.busBattery ||
      !selections.busSocWindow ||
      !selections.busChargingType ||
      !selections.busElectricMotor ||
      !selections.busWheelConfiguration ||
      !selections.busBodyLength,
    [selections],
  );

  const busFloorHeightDisabled = useMemo(
    () =>
      !selections.busPortfolio ||
      !selections.busBattery ||
      !selections.busSocWindow ||
      !selections.busChargingType ||
      !selections.busElectricMotor ||
      !selections.busWheelConfiguration ||
      !selections.busBodyLength ||
      !selections.busChassisHeight,
    [selections],
  );

  return useMemo(
    () => ({
      busBatteryDisabled,
      busSocDisabled,
      busChassisHeightDisabled,
      busEngineGearBoxDisabled,
      busWheelConfigurationDisabled,
      busBodyLengthDisabled,
      busFloorHeightDisabled,
      busChargingTypeDisabled,
    }),
    [
      busBatteryDisabled,
      busSocDisabled,
      busChassisHeightDisabled,
      busEngineGearBoxDisabled,
      busWheelConfigurationDisabled,
      busBodyLengthDisabled,
      busFloorHeightDisabled,
      busChargingTypeDisabled,
    ],
  );
};

export default useBusDisabled;
