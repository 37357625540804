import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  selectedCandidates: string[];
  vehicles?: ListVehicleTransformed[];
}

const useHighestAvgGtwFromSelectedCandidates = ({ selectedCandidates, vehicles }: Props): number =>
  useMemo(() => {
    const selectedVehicles = vehicles?.filter((vehicle) => selectedCandidates.includes(vehicle.Id.toString()));

    if (selectedVehicles && selectedVehicles?.length > 0) {
      const avgGtws = selectedVehicles.map((vehicle) => Number(vehicle.AvgGtwKg)).filter((value) => value);

      const highestAvgGtw = Math.max(...avgGtws);

      return highestAvgGtw;
    }

    return 0;
  }, [selectedCandidates, vehicles]);

export default useHighestAvgGtwFromSelectedCandidates;
