import { INFRA_PERFORMANCE_STEPS_TAB, Mode, OCCUPANCY_TAB } from '..';
import { useMemo } from 'react';
import {
  defineChargingEventsInfo,
  mapVehiclesToDepotInfo,
  manageDepotInfrastructureInfoDepot,
  occupancyInfo,
} from 'app/config/infrastructure';

interface Props {
  mode?: Mode;
  tabIndex: number;
}

const useStepInfo = ({ mode, tabIndex }: Props) =>
  useMemo(() => {
    let stepInfo = defineChargingEventsInfo;

    if (mode === 'map-vehicles') {
      stepInfo = mapVehiclesToDepotInfo;
    } else if (tabIndex === INFRA_PERFORMANCE_STEPS_TAB) {
      stepInfo = manageDepotInfrastructureInfoDepot;
    } else if (tabIndex === OCCUPANCY_TAB) {
      stepInfo = occupancyInfo;
    }

    return stepInfo;
  }, [mode, tabIndex]);

export default useStepInfo;
