import { DepotEnhanced } from '@optimization/sa-common';
import { sortNumbers } from '@optimization/ssi-common';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

interface Props {
  depot?: DepotEnhanced;
  solutionId: string;
}

const useContentSwitcherItems = ({ depot, solutionId }: Props) => {
  const location = useLocation();

  return useMemo(() => {
    if (depot) {
      const search = location.search ? `?${location.search}` : '';

      const years = Object.keys(depot.DepotDataPerPerformanceStep).map(Number);
      years.sort(sortNumbers);

      const depotUrl = `/solution/${solutionId}/infrastructure/${depot.Id}`;

      return years.map((year) => ({
        name: year.toString(),
        path:
          depot.transformed.depotDefaultYear === year.toString()
            ? `${depotUrl}${search}`
            : `${depotUrl}/year/${year}${search}`,
        end: depot.transformed.depotDefaultYear === year.toString(),
      }));
    }

    return [];
  }, [depot, solutionId, location]);
};

export default useContentSwitcherItems;
