import { ListVehicleEnhanced } from '@optimization/sa-common';
import { sortNumbers } from '@optimization/ssi-common';
import { useMemo } from 'react';

const useAxleDistances = ({ vehicles }: { vehicles?: ListVehicleEnhanced[] }) =>
  useMemo(
    () =>
      vehicles
        ? [...new Set(vehicles.map((vehicle) => vehicle.AxleDistanceMm || 0).filter((value) => value))].sort(
            sortNumbers,
          )
        : [],
    [vehicles],
  );

export default useAxleDistances;
