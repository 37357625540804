import classes from './style.module.scss';
import { NavLink } from 'react-router-dom';
import { TdsIcon } from '@scania/tegel-react';
import { useCallback } from 'react';
import { useTour } from 'app/context/TourContext';

interface Props {
  showIconTitle: boolean;
}

const TourLink = ({ showIconTitle }: Props) => {
  const tour = useTour();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (tour) {
        tour.takeTheTour({ onlyModalSteps: true });
      }
    },
    [tour],
  );

  return tour ? (
    <NavLink className={classes['tour-link']} onClick={onClick} to="#">
      <TdsIcon name="support" size="24px" title={showIconTitle ? 'Take the tour' : ''} />
      Take the tour
    </NavLink>
  ) : null;
};

export default TourLink;
