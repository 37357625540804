import InfoList, { InfoListItem } from 'common/components/InfoList';
import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depot: ListDepotTransformed;
  className?: string;
}

const DepotInfoList = ({ depot, className }: Props) => {
  const items = useMemo(() => {
    const items: InfoListItem[] = [];

    const depotDataPerPerformanceStep = depot.DepotDataPerPerformanceStep[depot.transformed.depotDefaultYear];

    if (depotDataPerPerformanceStep) {
      items.push({
        label: 'Power demand',
        value: `${depotDataPerPerformanceStep.PowerDemand} kW`,
      });

      items.push({
        label: 'Charging power',
        value: `${depotDataPerPerformanceStep.ChargingPower} kW`,
      });

      items.push({
        label: 'Vehicles',
        value: depotDataPerPerformanceStep.PeakConnectedVehicles,
      });

      items.push({
        label: 'Connectors',
        value: depotDataPerPerformanceStep.NumberOfSatellites,
      });
    }

    return items;
  }, [depot]);

  return <InfoList items={items} className={className} />;
};

export default DepotInfoList;
