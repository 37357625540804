import { KeyedString } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  minimumPerformanceStepByVehicle: KeyedString;
}

const useElectrificationPlanVehicleCount = ({ minimumPerformanceStepByVehicle }: Props): number =>
  useMemo(() => Object.keys(minimumPerformanceStepByVehicle).length, [minimumPerformanceStepByVehicle]);

export default useElectrificationPlanVehicleCount;
