import { solutionApi } from 'app/services/solution';
import { useCallback, useMemo } from 'react';
import { useUpdateVehicleMutation } from 'app/services/solution';

interface Props {
  solutionId: string;
}

const useUpdateCandidateName = ({ solutionId }: Props) => {
  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const [getVehicle, getVehicleState] = solutionApi.endpoints.getVehicle.useLazyQuery();

  const updateCandidateName = useCallback(
    async (vehicleId: number, name: string) => {
      const vehicle = (await getVehicle({ solutionId, vehicleId })).data;

      if (vehicle) {
        updateVehicle({
          solutionId,
          vehicle: {
            ...vehicle,
            ExecutedVehicleProduct: {
              ...vehicle.ExecutedVehicleProduct,
              Name: name,
            },
          },
        });
      }
    },
    [solutionId, getVehicle, updateVehicle],
  );

  return useMemo(
    () => ({
      updateCandidateName,
      updateCandidateNameState: {
        isLoading: updateVehicleState.isLoading || getVehicleState.isLoading,
        isError: updateVehicleState.isError || getVehicleState.isError,
      },
    }),
    [getVehicleState, updateVehicleState, updateCandidateName],
  );
};

export default useUpdateCandidateName;
