import classes from './style.module.scss';
import classNames from 'classnames';
import truckSide from 'assets/truck-side.png';
import useLoadingText from 'common/hooks/useLoadingText';
import useLogout from '../utils/useLogout';
import { APP_TITLE } from 'app/config';
import { Button, Checkbox, Error, Spinner } from '@optimization/ssi-common';
import { Loading } from '@optimization/ssi-common';
import { ReactNode, useCallback, useState } from 'react';
import { useGetUserQuery, useUpdateTosMutation } from 'app/services/solution';

const conditionsTerm = [
  {
    heading: 'Exploring preliminary product roadmap details',
    body: 'Future product roadmap data that has not yet been publicly launched is preliminary and will be subject to change according to updates in the introduction plan.',
  },
  {
    heading: 'Commercial limitations on future roadmap data usage',
    body: 'Future product roadmap data should not be used beyond specific commercial purposes with intent to generate sales of electrified solutions.',
  },
  {
    heading: 'Continuous updates: simulated performance insights',
    body: 'Simulated performance in the application is according to Scania’s current best estimations and will be updated continuously in accordance with increased knowledge and data.',
  },
  {
    heading: `Responsible data usage in ${APP_TITLE} for sales purposes`,
    body: `The data available through ${APP_TITLE} should only be used for specific commercial purposes with intent to generate sales of electrified solutions. Customers personal data, including stop positions, is not permitted to share with other parties without explicit consent from the customer in question.`,
  },
];

const HasSignedTos = ({ children }: { children: ReactNode }) => {
  const user = useGetUserQuery();
  const [tosMutation, tosState] = useUpdateTosMutation();

  const [isChecked, setIsChecked] = useState(false);

  const handleLogout = useLogout();

  const handleAccept = useCallback(() => {
    tosMutation();
  }, [tosMutation]);

  const loadingText = useLoadingText({ isLoadingUser: user.isFetching });

  if (user.isFetching || user.isError) {
    return <Loading isLoading={user.isFetching} isError={user.isError} loadingText={loadingText} />;
  }

  return user.isSuccess && user.data.ApprovedToS ? (
    children
  ) : (
    <div className={classNames(classes['has-signed-tos-page'])} data-testid="terms-of-service-page">
      <div className={classes.left}>
        <div className={classes.top}>
          <div>
            <p className={classNames('tds-detail-06', classes['service-usage-guidelines'])}>SERVICE USAGE GUIDELINES</p>
            <h1 className="mt-spacing-8 tds-headline-02">Terms of service</h1>
            <p className="mt-spacing-32 tds-detail-05">When using {APP_TITLE} I accept the following conditions:</p>
            <div className="max-w-550">
              {conditionsTerm.map((condition, i) => (
                <div key={i} className={i ? 'mt-spacing-24' : 'mt-spacing-16'}>
                  <h3 className="tds-headline-05">{condition.heading}</h3>
                  <p className={classNames('mt-spacing-4', 'tds-detail-03', 'tds-text-grey-200')}>{condition.body}</p>
                </div>
              ))}
            </div>
            <Checkbox
              className="mt-spacing-32"
              label="I have read and accepted all the terms and conditions"
              dataTestid="checkbox-accept-terms-of-service"
              onChange={() => {
                setIsChecked((prev) => !prev);
              }}
              value={`${isChecked}`}
              checked={isChecked}
            />
            {tosState.isLoading ? (
              <Spinner />
            ) : (
              <div className="flex gap-16 mt-spacing-32">
                <Button
                  size="lg"
                  variant={isChecked ? 'primary' : 'secondary'}
                  text="Accept terms"
                  dataTestid="button-accept-terms-of-service"
                  onClick={handleAccept}
                  disabled={!isChecked}
                />
                <Button size="lg" variant="secondary" text="Decline" onClick={handleLogout} />
              </div>
            )}
            {tosState.isError && <Error className="mt-spacing-16" />}
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <img src={truckSide} alt="Truck from side" />
      </div>
    </div>
  );
};

export default HasSignedTos;
