import { FactBasicVehicle, launchPeriodToDate } from '@optimization/sa-common';

const sortByPortfoliosAsc = <Type extends FactBasicVehicle>(a: Type, b: Type) => {
  const aPortfolios = a?.Portfolios;
  const bPortfolios = b?.Portfolios;

  if (aPortfolios && bPortfolios && aPortfolios.length && bPortfolios.length) {
    const aPortfoliosCopy = [...aPortfolios];
    const bPortfoliosCopy = [...bPortfolios];

    aPortfoliosCopy.sort();
    bPortfoliosCopy.sort();

    const aEarliestDate = launchPeriodToDate(aPortfoliosCopy[0]);
    const bEarliestDate = launchPeriodToDate(bPortfoliosCopy[0]);

    if (aEarliestDate && bEarliestDate) {
      return aEarliestDate.getTime() - bEarliestDate.getTime();
    }
  }

  return 0;
};

export default sortByPortfoliosAsc;
