import { Checkbox } from '@optimization/ssi-common';
import { EcItem } from './types';
import { PtoDevices, PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  ecItems: EcItem[];
  ptoDevices: PtoDevices;
  disabled?: boolean;
  onCheckboxChange: (checked: boolean, value: string, type: PtoType) => void;
}

const PtoCraneField = ({ ecItems, ptoDevices, disabled, onCheckboxChange }: Props) => {
  const onChange = useCallback(
    (checked: boolean, value: string) => onCheckboxChange(checked, value, PtoType.Crane),
    [onCheckboxChange],
  );

  return (
    <div>
      <div className="tds-detail-05 tds-u-mb1">Crane load</div>
      {Object.keys(ptoDevices.Crane.PtoAlternatives).map((size) => (
        <Checkbox
          key={size}
          value={size}
          label={size}
          disabled={disabled}
          onChange={onChange}
          checked={ecItems.find((ecItem) => ecItem.Type === 'Crane')?.Value === size}
        />
      ))}
    </div>
  );
};

export default PtoCraneField;
