import { DepotBasePowerDemand } from './useDepotBasePowerDemands';
import { InfrastructureProductV2VM } from '@optimization/sa-common';
import { Mode } from '../';
import { useEffect, useMemo } from 'react';
import { useValidateDepotSetupMutation } from 'app/services/solution';

interface Props {
  depotBasePowerDemands: DepotBasePowerDemand[];
  depotFutureMaxPowerCapacity: string;
  depotMaxPowerCapacity: string;
  depotName: string;
  infrastructureProducts: InfrastructureProductV2VM[];
  mode: Mode;
  selectedCandidates: string[];
  solutionId: string;
}

const useValidateDepotSetupHandler = ({
  depotBasePowerDemands,
  depotFutureMaxPowerCapacity,
  depotMaxPowerCapacity,
  depotName,
  infrastructureProducts,
  mode,
  selectedCandidates,
  solutionId,
}: Props) => {
  const [validateDepotSetup, validateDepotSetupState] = useValidateDepotSetupMutation();

  useEffect(() => {
    if (['depot-infrastructure'].includes(mode)) {
      validateDepotSetup({
        solutionId,
        Name: depotName,
        MaxPowerCapacity: Number(depotMaxPowerCapacity),
        FutureMaxPowerCapacity: Number(depotFutureMaxPowerCapacity),
        PowerDemands: depotBasePowerDemands,
        MappedVehicles: selectedCandidates.map(Number),
        InfrastructureProducts: infrastructureProducts.map((item) => ({
          Name: '',
          ...item,
        })),
      });
    }
  }, [
    depotBasePowerDemands,
    depotFutureMaxPowerCapacity,
    depotMaxPowerCapacity,
    depotName,
    infrastructureProducts,
    mode,
    selectedCandidates,
    solutionId,
    validateDepotSetup,
  ]);

  return useMemo(
    () => ({
      isLoading: validateDepotSetupState.isLoading,
      isError: validateDepotSetupState.isError,
      isSuccess: validateDepotSetupState.isSuccess,
      data: validateDepotSetupState.data,
    }),
    [validateDepotSetupState],
  );
};

export default useValidateDepotSetupHandler;
