import { APP_TITLE } from 'app/config';
import { Helmet } from 'react-helmet';
import { useGetDepotQuery, useGetSolutionQuery } from 'app/services/solution';
import { useMemo } from 'react';

interface Props {
  items?: string[];
  depotId?: string;
  solutionId?: string;
}

const HelmetTitle = ({ items, depotId, solutionId }: Props) => {
  const solutionQuery = useGetSolutionQuery(solutionId || '', {
    skip: !Boolean(solutionId),
  });

  const depotQuery = useGetDepotQuery(
    {
      solutionId: solutionId || '',
      depotId: depotId || '',
    },
    { skip: !Boolean(solutionId) || !Boolean(depotId) },
  );

  const parts = useMemo(() => {
    const result: string[] = [];

    if (items) {
      for (const item of items) {
        result.push(item);
      }
    }

    if (depotQuery.data) {
      result.push(depotQuery.data.Name);
    }

    if (solutionQuery.data) {
      result.push(solutionQuery.data.transformed.solutionNamePresentation);
    }

    result.push(APP_TITLE);

    return result;
  }, [items, solutionQuery.data, depotQuery.data]);

  return (
    <Helmet>
      <title>{parts.join(' - ')}</title>
    </Helmet>
  );
};

export default HelmetTitle;
