import DepotForm from 'features/infrastructure/utils/DepotForm';
import { invariant } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const EditDepot = () => {
  const { solutionId, depotId } = useParams();

  invariant(solutionId);
  invariant(depotId);

  const navigate = useNavigate();

  const onCancelDepotForm = useCallback(
    () => navigate(`/solution/${solutionId}/infrastructure/${depotId}`),
    [navigate, solutionId, depotId],
  );

  return <DepotForm solutionId={solutionId} depotId={depotId} onCancelDepotForm={onCancelDepotForm} />;
};

export default EditDepot;
