import classes from './style.module.scss';
import classNames from 'classnames';
import { ExecutedVehicleProductPto, PtoDevices } from '@optimization/sa-common';
import { roundTo } from '@optimization/ssi-common';

interface Props {
  netEc: number;
  ptoDevices: PtoDevices;
  executedVehicleProductPTOs: ExecutedVehicleProductPto[];
  chassisType?: string;
}

const EnergyConsumption = ({ netEc, ptoDevices, executedVehicleProductPTOs, chassisType }: Props) => (
  <>
    <div className={classNames(classes.wrapper, classes.net)}>
      <div>
        <div className="energy-consumption-header tds-detail-06 font-bold">NET EC</div>
      </div>
      <div>
        <div className="tds-detail-05">{roundTo(netEc, 2).toFixed(2)} kWh/km</div>
      </div>
    </div>
    {chassisType === 'Truck' && (
      <div className={classNames(classes.wrapper, classes.pto)}>
        <div>
          <div className={classNames('tds-detail-06', 'font-bold', classes.header)}>PTO</div>
        </div>
        {executedVehicleProductPTOs.length > 0 ? (
          <>
            {executedVehicleProductPTOs.map((pto, index) => (
              <div className="tds-detail-05" data-testid={index ? undefined : 'ptos-with-ec'} key={pto.Type}>
                <div>{ptoDevices[pto.Type]?.Name}:</div>
                <div>{roundTo(pto.ElectricalConsumption, 4)}</div>
                <div>kWh/km</div>
              </div>
            ))}
          </>
        ) : (
          <div className="tds-detail-05">Not configured</div>
        )}
      </div>
    )}
  </>
);

export default EnergyConsumption;
