import { ChargingEventV2VM } from '@optimization/sa-common';

const getResultingSoc = (chargingEvent?: ChargingEventV2VM): number => {
  if (!chargingEvent) {
    return 0;
  }

  return chargingEvent.AffectedByOtherEvents
    ? chargingEvent.ActualBatteryLevelAfterCharging
    : chargingEvent.ExpectedBatteryLevelAfterCharging;
};

export default getResultingSoc;
