import { formatDateInYearMonthDay, isNumeric, isValidDate } from '@optimization/ssi-common';
import { useCallback, useMemo } from 'react';
import { useInitTripsMutation } from 'app/services/solution';
import { GetTripsParams } from '..';

interface Props {
    chassisNumbers: number[];
    getTripsParamsRef: React.MutableRefObject<GetTripsParams>;
    solutionId: string;
}

const useInitTripsCallback = ({ chassisNumbers, getTripsParamsRef, solutionId }: Props) => {
    const [initTrips, initTripsState] = useInitTripsMutation();

    const initTripsCallback = useCallback(() => {
        const startDate = new Date(getTripsParamsRef.current.startDate);
        const endDate = new Date(getTripsParamsRef.current.endDate);
        const minDuration = getTripsParamsRef.current.minDuration;
        const maxDuration = getTripsParamsRef.current.maxDuration;
        const resolution = getTripsParamsRef.current.resolution;

        if (isValidDate(startDate) && isValidDate(endDate) && isNumeric(minDuration) && isNumeric(maxDuration)) {
            initTrips({
                solutionId,
                ChassisNumbers: chassisNumbers.map(String),
                MinDuration: parseInt(minDuration) * 60,
                MaxDuration: parseInt(maxDuration) * 60,
                OldestDateTime: formatDateInYearMonthDay(startDate),
                NewestDateTime: formatDateInYearMonthDay(endDate),
                Resolution: resolution,
            });
        }
    }, [initTrips, getTripsParamsRef, chassisNumbers, solutionId]);

    return useMemo(() => ({ initTripsCallback: initTripsCallback, initTripsState }), [initTripsCallback, initTripsState]);
};

export default useInitTripsCallback;
