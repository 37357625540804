import CustomChargingEvents from './CustomChargingEvents';
import SlowChargingEvent from './SlowChargingEvent';
import { HOURS } from '..';
import { useEnhancedVehicle, VehicleTransformed } from '@optimization/sa-common';
import { useGetFactBasicDataQuery } from 'app/services/solution';
import { useMemo } from 'react';

interface Props {
  depotId: number;
  depotYear: string;
  tdIndex: number;
  pixelsPerMinute: number;
  vehicle: VehicleTransformed;
}

const Main = ({ depotId, depotYear, tdIndex, pixelsPerMinute, vehicle }: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const enhancedVehicle = useEnhancedVehicle({
    vehicle,
    depotYear,
    factBasicDataQueryData: factBasicDataQuery.data,
  });

  const slowChargingEvent = useMemo(
    () => enhancedVehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent,

    [enhancedVehicle],
  );

  const chargingEvents = useMemo(
    () => enhancedVehicle.enhanced.vehiclePerformanceStep?.ChargingEvents,
    [enhancedVehicle],
  );

  return slowChargingEvent && chargingEvents ? (
    <>
      {tdIndex === 0 && (
        <>
          <SlowChargingEvent
            pixelsPerMinute={pixelsPerMinute}
            slowChargingEvent={slowChargingEvent}
            vehicle={enhancedVehicle}
            cell="first"
          />
          <CustomChargingEvents
            depotId={depotId}
            chargingEvents={chargingEvents}
            pixelsPerMinute={pixelsPerMinute}
            vehicle={enhancedVehicle}
          />
        </>
      )}
      {tdIndex === HOURS.length - 1 && (
        <SlowChargingEvent
          pixelsPerMinute={pixelsPerMinute}
          slowChargingEvent={slowChargingEvent}
          vehicle={enhancedVehicle}
          cell="last"
        />
      )}
    </>
  ) : null;
};

export default Main;
