import classes from './style.module.scss';
import classNames from 'classnames';
import { KeyedString, TextField } from '@optimization/ssi-common';
import { ListDepotTransformed } from '@optimization/sa-common';
import { Messages } from '../utils/useMessages';
import { ReactNode, useMemo } from 'react';

interface Props {
  className?: string;
  depotYear?: string;
  selectedDepot?: ListDepotTransformed;
  infrastructureProductInstanceId: string;
  messages?: Messages;
  footer?: ReactNode;
}

const ChargingDetails = ({
  className,
  depotYear,
  selectedDepot,
  infrastructureProductInstanceId,
  messages,
  footer,
}: Props) => {
  const chargerInstanceNames = useMemo(() => {
    let result: KeyedString = {};

    if (depotYear && selectedDepot) {
      for (const product of selectedDepot.InfrastructureProducts) {
        result = { ...result, ...product.Instances };
      }
    }

    return result;
  }, [depotYear, selectedDepot]);

  const currentChargerInstanceName = useMemo(
    () =>
      infrastructureProductInstanceId && chargerInstanceNames[infrastructureProductInstanceId.toString()]
        ? chargerInstanceNames[infrastructureProductInstanceId.toString()]
        : '',

    [chargerInstanceNames, infrastructureProductInstanceId],
  );

  return (
    <div className={classNames(className, classes['charging-details'])}>
      <div className={classes.header}>
        <h3 className="tds-detail-04">Charging details</h3>
        <div className="tds-detail-05 mt-spacing-4 tds-text-grey-500">Advanced charging information</div>
      </div>
      <div className={classes.body}>
        <TextField
          value={currentChargerInstanceName}
          disabled
          label="Charger"
          labelPosition="outside"
          state={messages?.chargerErrorMessage ? 'error' : undefined}
          helper={messages?.chargerErrorMessage?.header}
        />
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </div>
  );
};

export default ChargingDetails;
