import classes from './style.module.scss';
import classNames from 'classnames';
import truckSide from 'assets/truck-side.png';
import useLogin from '../utils/useLogin';
import { APP_TITLE } from 'app/config';
import { Button } from '@optimization/ssi-common';
import { Fragment, useMemo } from 'react';

function Login() {
  const login = useLogin();

  const appTitleWords: string[] = useMemo(() => APP_TITLE.split(' '), []);

  return (
    <div className={classes['login-page']}>
      <div className={classes.left}>
        <div className={classes.top}>
          <div>
            <h1 className="tds-headline-01 tds-text-white">
              {appTitleWords.map((word, index) => (
                <Fragment key={index}>
                  {word}
                  {index < appTitleWords.length - 1 && <br />}
                </Fragment>
              ))}
            </h1>
            <h2 className="tds-headline-01 tds-text-grey-400">Define. Solve. Assure.</h2>
          </div>
          <Button
            onClick={login}
            dataTestid="button-login"
            variant="primary"
            size="lg"
            text="Electrify your customer"
            mode-variant="secondary"
            className="mt-spacing-32"
          />
        </div>
        <p className={classNames('tds-body-01', classes.bottom)}>
          <strong>{APP_TITLE}</strong>{' '}
          <span className="tds-text-grey-400">
            empowers you and your customer with personalized electrification plans, complete with year-by-year
            performance step milestones, to enable you as the trusted advisor and support in solution sales.
          </span>
        </p>
      </div>
      <div className={classes.right}>
        <img src={truckSide} alt="Truck from side" />
      </div>
    </div>
  );
}

export default Login;
