import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useWheelConfigurations = ({ vehicles }: { vehicles?: ListVehicleEnhanced[] }) =>
  useMemo(
    () =>
      vehicles
        ? [...new Set(vehicles.map((vehicle) => vehicle.WheelConfiguration || '').filter((value) => value))].sort()
        : [],
    [vehicles],
  );

export default useWheelConfigurations;
