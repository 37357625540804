import classes from './style.module.scss';
import classNames from 'classnames';
import { Tooltip } from '@optimization/ssi-common';
import { useRef } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';

export type EventType = 'from-midnight' | 'day' | 'before-midnight';

interface Props {
  vehicle: VehicleEnhanced;
  power: number;
  durationInMinutes: number;
  startMinute?: number;
  pixelsPerMinute: number;
  type: EventType;
}

const Event = ({ vehicle, power, durationInMinutes, startMinute, pixelsPerMinute, type }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={classes['occupancy-event']}>
      <div
        ref={ref}
        className={classNames(classes.bar, classes[`bar-${type}`])}
        style={{
          width: `${durationInMinutes * pixelsPerMinute}px`,
          ...(startMinute ? { left: `${startMinute * pixelsPerMinute}px` } : {}),
        }}
      />
      <Tooltip elRef={ref}>
        <ul className={classes['tooltip-list']}>
          <li>
            <div>Vehicle:</div>
            <div>{vehicle.ExecutedVehicleProduct.Name || vehicle.transformed.chassisIdPresentation}</div>
          </li>
          <li>
            <div>Power:</div>
            <div>{power} kW</div>
          </li>
        </ul>
      </Tooltip>
    </div>
  );
};

export default Event;
