import { CandidateTabs } from '../';
import { isUndefinedOrEmpty } from 'common/utils/helper';
import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
  showHistorialStops: boolean;
  chargingEventTabRef: React.RefObject<HTMLButtonElement>;
  historicalStopsTabRef: React.RefObject<HTMLButtonElement>;
  enviromnetalImpactTabRef: React.RefObject<HTMLButtonElement>;
}

const useTabs = ({
  vehicle,
  showHistorialStops,
  chargingEventTabRef,
  historicalStopsTabRef,
  enviromnetalImpactTabRef,
}: Props) =>
  useMemo(() => {
    const inlineTabs: TInlineTabs<CandidateTabs> = [];

    inlineTabs.push({
      name: 'Vehicle configuration',
    });

    const isVehicleExecuted = !!vehicle.ExecutedVehicleProduct.CompositeId;

    if (isVehicleExecuted) {
      inlineTabs.push({
        name: 'Charging events',
        tabRef: chargingEventTabRef,
        disabled: !vehicle.HomeDepotId,
      });
    }

    if (showHistorialStops) {
      inlineTabs.push({
        name: 'Historical stops',
        tabRef: historicalStopsTabRef,
      });
    }

    if (isVehicleExecuted) {
      inlineTabs.push({
        name: 'Environmental impact',
        tabRef: enviromnetalImpactTabRef,
        disabled: !!(vehicle.ScaniaChassis?.ChassisNumber && isUndefinedOrEmpty(vehicle.ScaniaChassis?.Consumption)),
        dataTestid: 'button-environmental-impact',
      });
    }

    if (isVehicleExecuted) {
      inlineTabs.push({
        name: 'Financial evaluation',
        dataTestid: 'button-financial-evaluation',
      });
    }

    return inlineTabs;
  }, [showHistorialStops, chargingEventTabRef, historicalStopsTabRef, enviromnetalImpactTabRef, vehicle]);

export default useTabs;
