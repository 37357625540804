import useInvalidateSolutionCache from 'common/hooks/useInvalidateSolutionCache';
import { promiseAllSettledWrapper } from 'common/utils/helper';
import { useCallback, useMemo, useState } from 'react';
import { useToast } from '@optimization/ssi-common';
import { solutionApi, useCreateManualVehicleMutation } from 'app/services/solution';

interface Props {
  solutionId: string;
  selectedCandidates: string[];
  setSelectedCandidates: React.Dispatch<React.SetStateAction<string[]>>;
  setRejected: React.Dispatch<React.SetStateAction<number>>;
  resetMode: () => void;
  closeModal: () => void;
}

interface Result {
  isLoading: boolean;
  duplicateCandidates: () => Promise<void>;
}

const useDuplicateCandidatesHandler = ({
  solutionId,
  selectedCandidates,
  setSelectedCandidates,
  setRejected,
  resetMode,
  closeModal,
}: Props): Result => {
  const showToast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [addVehicle] = useCreateManualVehicleMutation();

  const [getVehicle] = solutionApi.endpoints.getVehicle.useLazyQuery();

  const setInvalidateSolutionTags = useInvalidateSolutionCache();

  const duplicateCandidates = useCallback(async () => {
    setIsLoading(true);

    const { fulfilled } = await promiseAllSettledWrapper(
      selectedCandidates.map(async (vehicleId) => {
        const vehicle = (await getVehicle({ solutionId, vehicleId: Number(vehicleId) })).data;

        if (vehicle) {
          const response = await addVehicle({
            solutionId,
            noInvalidates: true,
            ScaniaChassis: vehicle.ScaniaChassis ? { ...vehicle.ScaniaChassis } : undefined,
            ExecutedVehicleProduct: {
              ...vehicle.ExecutedVehicleProduct,
            },
          });

          if ('error' in response) {
            throw new Error();
          }

          return vehicleId;
        }
      }),
    );

    setInvalidateSolutionTags(['Vehicles', { type: 'Solutions', id: solutionId }, { type: 'Depots' }]);

    if (fulfilled.length > 0) {
      showToast({
        header: `${fulfilled.length} vehicle candidates added`,
        dataTestid: 'toast-candidates-duplicated',
      });
    }

    const allFulfilled = fulfilled.length === selectedCandidates.length;

    const rejected = selectedCandidates.filter((vehicleId) => !fulfilled.includes(vehicleId));

    setRejected(rejected.length);

    setIsLoading(false);

    if (!allFulfilled) {
      setSelectedCandidates(rejected);
      return;
    }

    setSelectedCandidates([]);
    closeModal();
    resetMode();
  }, [
    solutionId,
    selectedCandidates,
    addVehicle,
    closeModal,
    getVehicle,
    setSelectedCandidates,
    setInvalidateSolutionTags,
    setRejected,
    resetMode,
    showToast,
  ]);

  return useMemo(() => ({ duplicateCandidates, isLoading }), [duplicateCandidates, isLoading]);
};

export default useDuplicateCandidatesHandler;
