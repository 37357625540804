import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { DebouncedTextField, Dropdown, Tooltip } from '@optimization/ssi-common';
import { financeOptions } from '../convertFormValuesToResponse';
import { FormValues, SetFormValue } from '../..';
import { TdsIcon } from '@scania/tegel-react';
import { Dispatch, SetStateAction, useRef } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  fromCostsHeader: string;
  setFormValue: SetFormValue;
  formValues: FormValues;
  setResidualChanged: Dispatch<SetStateAction<boolean>>;
}

const VehicleCostsFields = ({
  className,
  disabled,
  fromCostsHeader,
  setFormValue,
  formValues,
  setResidualChanged,
}: Props) => {
  const residualInfoRef = useRef<HTMLDivElement>(null);
  const invalidBevInterest = formValues.bevFinanceType === 'Leasing' && formValues.bevInterestRate === '0';
  const invalidComparisonInterest =
    formValues.comparisonFinanceType === 'Leasing' && formValues.comparisonInterestRate === '0';
  const onChangeBevFinanceType = useOnChangeFieldValue({
    attribute: 'bevFinanceType',
    setFormValue,
  });

  const onChangeDieselFinanceType = useOnChangeFieldValue({
    attribute: 'comparisonFinanceType',
    setFormValue,
  });

  const onChangeBevVehiclePrice = useOnChangeFieldValue({
    attribute: 'bevVehiclePrice',
    setFormValue,
  });

  const onChangeDieselVehiclePrice = useOnChangeFieldValue({
    attribute: 'comparisonVehiclePrice',
    setFormValue,
  });

  const onChangeBevInterestRate = useOnChangeFieldValue({
    attribute: 'bevInterestRate',
    setFormValue,
  });

  const onChangeDieselInterestRate = useOnChangeFieldValue({
    attribute: 'comparisonInterestRate',
    setFormValue,
  });

  const onChangeBevResidual = useOnChangeFieldValue({
    attribute: 'bevResidual',
    setFormValue,
  });

  const onChangeDieselResidual = useOnChangeFieldValue({
    attribute: 'comparisonResidual',
    setFormValue,
  });

  const onChangeBevGovernmentSubsidies = useOnChangeFieldValue({
    attribute: 'bevGovernmentSubsidies',
    setFormValue,
  });

  const onChangeDieselGovernmentSubsidies = useOnChangeFieldValue({
    attribute: 'comparisonGovernmentSubsidies',
    setFormValue,
  });

  return (
    <div className={classNames(classes['vehicle-costs-fields'], className)}>
      <div className={classNames(classes['header-1'], 'tds-detail-04')}>BEV</div>
      <div className={classNames(classes['header-2'], 'tds-detail-04')}>{fromCostsHeader}</div>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Type of financing</div>
      <Dropdown
        className={classes['field-1-1']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={financeOptions}
        value={formValues.bevFinanceType}
        onChange={onChangeBevFinanceType}
      />
      <Dropdown
        className={classes['field-1-2']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={financeOptions}
        value={formValues.comparisonFinanceType}
        onChange={onChangeDieselFinanceType}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Vehicle price</div>
      <DebouncedTextField
        className={classes['field-2-1']}
        type="number"
        value={formValues.bevVehiclePrice}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevVehiclePrice}
        label={''}
      />
      <DebouncedTextField
        className={classes['field-2-2']}
        type="number"
        value={formValues.comparisonVehiclePrice}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselVehiclePrice}
        label={''}
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Interest rate</div>
      <DebouncedTextField
        className={classes['field-3-1']}
        type="number"
        size="lg"
        suffixText={formValues.bevFinanceType === 'Cash' ? 'N/A' : '%'}
        disabled={disabled || formValues.bevFinanceType === 'Cash'}
        min={0}
        noMinWidth
        state={invalidBevInterest ? 'error' : 'default'}
        helper={invalidBevInterest ? 'Interest Rate cannot be 0' : undefined}
        onChange={onChangeBevInterestRate}
        value={formValues.bevFinanceType === 'Cash' ? '' : formValues.bevInterestRate}
        label={''}
      />
      <DebouncedTextField
        className={classes['field-3-2']}
        type="number"
        size="lg"
        suffixText={formValues.comparisonFinanceType === 'Cash' ? 'N/A' : '%'}
        disabled={disabled || formValues.comparisonFinanceType === 'Cash'}
        min={0}
        noMinWidth
        state={invalidComparisonInterest ? 'error' : 'default'}
        helper={invalidComparisonInterest ? 'Interest Rate cannot be 0' : undefined}
        onChange={onChangeDieselInterestRate}
        value={formValues.comparisonFinanceType === 'Cash' ? '' : formValues.comparisonInterestRate}
        label={''}
      />
      <div className={classNames('tds-detail-04', classes['label-4-with-info'])}>
        <div>Residual value</div>
        <div>
          <Tooltip elRef={residualInfoRef}>
            Enter the residual value for the comparison vehicle to automatically calculate the BEV value
          </Tooltip>
          <div className="flex items-center">
            <div ref={residualInfoRef}>
              <TdsIcon name="info" size="14px" className="mr-spacing-6" />
            </div>
          </div>
        </div>
      </div>
      <DebouncedTextField
        className={classes['field-4-1']}
        type="number"
        value={formValues.bevResidual}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevResidual}
        label={''}
      />
      <DebouncedTextField
        className={classes['field-4-2']}
        type="number"
        value={formValues.comparisonResidual}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={(value) => {
          onChangeDieselResidual(value);
          setResidualChanged(true);
        }}
        label={''}
      />
      <div className={classNames('tds-detail-04', classes['label-5'])}>Government subsidies</div>
      <DebouncedTextField
        className={classes['field-5-1']}
        type="number"
        value={formValues.bevGovernmentSubsidies}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevGovernmentSubsidies}
        label={''}
      />
      <DebouncedTextField
        className={classes['field-5-2']}
        type="number"
        value={formValues.comparisonGovernmentSubsidies}
        size="lg"
        suffixText={formValues.currency}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselGovernmentSubsidies}
        label={''}
      />
    </div>
  );
};

export default VehicleCostsFields;
