import PageHeader from 'common/components/PageHeader';
import { useCallback } from 'react';
import { useUpdateSolutionMutation } from 'app/services/solution';

interface Props {
  children: string;
  solutionId: string;
  solutionName: string | null;
}

const SolutionHomePageHeader = ({ children, solutionId, solutionName }: Props) => {
  const [updateSolution, updateSolutionState] = useUpdateSolutionMutation();

  const handleEdit = useCallback(
    (Name: string) => {
      updateSolution({ Id: Number(solutionId), Name });
    },
    [solutionId, updateSolution],
  );

  return (
    <PageHeader
      valueWhenEditing={solutionName ? solutionName : ''}
      editIsLoading={updateSolutionState.isLoading}
      editIsError={updateSolutionState.isError}
      intro={children}
      handleEdit={handleEdit}
    >
      Solution home
    </PageHeader>
  );
};

export default SolutionHomePageHeader;
