import { currentYear } from './useVehiclesByPerformanceStep';
import { KeyedString } from '@optimization/ssi-common';
import { useMemo } from 'react';
import { VehiclesByPerformanceStep } from '..';

interface Props {
  vehiclesByPerformanceStep?: VehiclesByPerformanceStep;
}

const useMinimumPerformanceStepByVehicle = ({ vehiclesByPerformanceStep }: Props): KeyedString =>
  useMemo(
    () =>
      vehiclesByPerformanceStep
        ? Object.keys(vehiclesByPerformanceStep).reduce(
            (acc, year) => ({
              ...acc,
              ...vehiclesByPerformanceStep[year].reduce(
                (accInner, vehicleId) => ({ ...accInner, [vehicleId]: currentYear.toString() }),
                {},
              ),
            }),
            {},
          )
        : {},

    [vehiclesByPerformanceStep],
  );

export default useMinimumPerformanceStepByVehicle;
