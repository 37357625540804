import { ListVehicleTransformed } from '@optimization/sa-common';
import { solutionApi } from 'app/services/solution';
import { useCallback, useMemo } from 'react';
import { useUpdateVehicleMutation } from 'app/services/solution';

interface Props {
  solutionId: string;
}

const useChangePerformanceStepYear = ({ solutionId }: Props) => {
  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const [getVehicle, vehicleQueryState] = solutionApi.endpoints.getVehicle.useLazyQuery();

  const changePerformanceStepYear = useCallback(
    async (performanceStep: string, vehicle: ListVehicleTransformed) => {
      const result = await getVehicle({
        solutionId,
        vehicleId: vehicle.Id,
      });

      if (result.isSuccess && result.data) {
        updateVehicle({
          solutionId,
          vehicle: {
            ...result.data,
            ExecutedVehicleProduct: {
              ...result.data.ExecutedVehicleProduct,
              PerformanceStep: performanceStep,
            },
          },
        });
      }
    },
    [solutionId, getVehicle, updateVehicle],
  );

  return useMemo(
    () => ({
      changePerformanceStepYear,
      changePerformanceStepYearState: {
        isLoading: updateVehicleState.isLoading || vehicleQueryState.isLoading,
        isError: updateVehicleState.isError || vehicleQueryState.isError,
      },
    }),
    [updateVehicleState, vehicleQueryState, changePerformanceStepYear],
  );
};

export default useChangePerformanceStepYear;
