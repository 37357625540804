import useChangeSortType from 'common/components/Filters/utils/useChangeSortType';
import useHideFilters from 'common/components/Filters/utils/useHideFilters';
import useToggleFiltersAction from 'common/components/Filters/utils/useToggleFiltersAction';
import useToggleSortDirection from 'common/components/Filters/utils/useToggleSortDirection';
import { KeyedString } from '@optimization/ssi-common';
import { useMemo, useState } from 'react';

interface Props {
  filterParams: KeyedString;
  sortDirectionDefault: 'asc' | 'desc';
}

const useFiltersHandler = ({ filterParams, sortDirectionDefault }: Props) => {
  const [activeFiltersAction, setActiveFiltersAction] = useState<'filter' | 'sort-by' | null>(null);

  const hideFilters = useHideFilters(setActiveFiltersAction);

  const onChangeSortType = useChangeSortType({
    filterParams,
  });

  const toggleSortDirection = useToggleSortDirection({
    filterParams,
    sortDirectionDefault,
  });

  const toggleSortBy = useToggleFiltersAction({
    setAction: setActiveFiltersAction,
    activeFiltersAction,
    wantedFiltersAction: 'sort-by',
  });

  const toggleFilters = useToggleFiltersAction({
    setAction: setActiveFiltersAction,
    activeFiltersAction,
    wantedFiltersAction: 'filter',
  });

  return useMemo(
    () => ({
      onChangeSortType,
      toggleSortBy,
      toggleSortDirection,
      toggleFilters,
      hideFilters,
      activeFiltersAction,
    }),
    [onChangeSortType, toggleSortBy, toggleSortDirection, toggleFilters, hideFilters, activeFiltersAction],
  );
};

export default useFiltersHandler;
