import classes from '../style.module.scss';
import { Dropdown, useNamesToDropdownOptions } from '@optimization/ssi-common';
import { NO_VALUE_SIGN } from '@optimization/sa-common';
import { useCallback, useMemo } from 'react';

interface Props<TVehicle> {
  vehicle: TVehicle;
  isLoading: boolean;
  performanceStepYearList: number[] | null;
  isReadonly: boolean;
  createdAt: string;
  launchPeriod?: string;
  performanceStep?: string;
  changePerformanceStepYear: (value: string, vehicle: TVehicle) => Promise<void>;
}

const PerformanceStepDropdown = <TVehicle,>({
  vehicle,
  isLoading,
  performanceStepYearList,
  isReadonly,
  createdAt,
  launchPeriod = '',
  performanceStep = '',
  changePerformanceStepYear,
}: Props<TVehicle>) => {
  const vehicleCreatedYear = useMemo(() => new Date(createdAt).getFullYear(), [createdAt]);

  const performanceStepYearListAboveLaunchPeriod = useMemo(
    () =>
      performanceStepYearList?.filter(
        (year) => year >= Number(`20${launchPeriod.split('.')[0]}`) && year >= vehicleCreatedYear,
      ),
    [performanceStepYearList, launchPeriod, vehicleCreatedYear],
  );

  const performanceStepYearOptions = useNamesToDropdownOptions({
    names: performanceStepYearListAboveLaunchPeriod,
  });

  const finalPerformanceStepYearOptions = useMemo(() => {
    const options = [...performanceStepYearOptions];

    if (performanceStepYearList) {
      options.push({
        name: 'Add new year',
        value: performanceStepYearList[performanceStepYearList.length - 1] + 1,
      });
    }

    return options;
  }, [performanceStepYearOptions, performanceStepYearList]);

  const onChange = useCallback(
    (value: string) => changePerformanceStepYear(value, vehicle),
    [changePerformanceStepYear, vehicle],
  );

  if (!performanceStepYearList || !performanceStepYearList.length) {
    return <div className={classes['next-gen']}>{NO_VALUE_SIGN}</div>;
  }

  if (isReadonly) {
    return <div className={classes['next-gen']}>{performanceStep}</div>;
  }

  return (
    performanceStepYearList && (
      <Dropdown
        size="md"
        className="w-full"
        disabled={isLoading}
        disabledIncreaseKey={false}
        options={finalPerformanceStepYearOptions}
        value={Number(performanceStep)}
        onChange={onChange}
      />
    )
  );
};

export default PerformanceStepDropdown;
