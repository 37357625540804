import { FactBasicBus, FactBasicTruck, ListVehicleEnhanced } from '@optimization/sa-common';
import { OnConfigureVehicleProduct } from 'features/solution/SolutionDetail/utils/useConfigureVehicleProductHandler';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useCallback } from 'react';

interface UseSubmitChangedCandidatesProps {
  selectedBasicTruck: FactBasicTruck | null;
  selectedBasicBus: FactBasicBus | null;
  selections: SelectionExecutions;
  filteredSelectedVehicles: ListVehicleEnhanced[];
  onConfigureVehicleProduct: OnConfigureVehicleProduct;
}

const useSubmitChangedCandidates = ({
  selectedBasicTruck,
  selectedBasicBus,
  selections,
  filteredSelectedVehicles,
  onConfigureVehicleProduct,
}: UseSubmitChangedCandidatesProps) =>
  useCallback(() => {
    onConfigureVehicleProduct({
      selections,
      selectedBasicTruck,
      selectedBasicBus,
      selectedVehicles: filteredSelectedVehicles,
    });
  }, [selectedBasicTruck, selectedBasicBus, selections, filteredSelectedVehicles, onConfigureVehicleProduct]);

export default useSubmitChangedCandidates;
