import { ExecutionName, SelectionExecutions } from '../useVehicleOptionsHandler';
import { useCallback } from 'react';

interface Props {
  selectionsDefault: SelectionExecutions;
  setSelections: React.Dispatch<React.SetStateAction<SelectionExecutions>>;
}

const useHandleSelection = ({ selectionsDefault, setSelections }: Props) =>
  useCallback(
    (name: ExecutionName, value: string) => {
      switch (name) {
        case 'chassisType':
          setSelections({
            ...selectionsDefault,
            chassisType: value,
            isChangedByUser: true,
          });
          break;
        // Truck cases
        case 'truckPortfolio':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckPortfolio: value,
            truckBattery: '',
            truckSocWindow: '',
            truckElectricMotor: '',
            truckChassisAdaptation: '',
            truckWheelConfiguration: '',
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckBattery':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckBattery: value,
            truckSocWindow: '',
            truckElectricMotor: '',
            truckChassisAdaptation: '',
            truckWheelConfiguration: '',
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckSocWindow':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckSocWindow: value,
            truckElectricMotor: '',
            truckChassisAdaptation: '',
            truckWheelConfiguration: '',
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckElectricMotor':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckElectricMotor: value,
            truckChassisAdaptation: '',
            truckWheelConfiguration: '',
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckChassisAdaptation':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckChassisAdaptation: value,
            truckWheelConfiguration: '',
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckWheelConfiguration':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckWheelConfiguration: value,
            truckAxleDistance: '',
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckAxleDistance':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckAxleDistance: value,
            truckChassisHeight: '',
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckChassisHeight':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckChassisHeight: value,
            truckCabType: '',
            isChangedByUser: true,
          }));
          break;
        case 'truckCabType':
          setSelections((prevSelections) => ({
            ...prevSelections,
            truckCabType: value,
            isChangedByUser: true,
          }));
          break;
        // Bus cases
        case 'busPortfolio':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busPortfolio: value,
            busBattery: '',
            busSocWindow: '',
            busChargingType: '',
            busElectricMotor: '',
            busWheelConfiguration: '',
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busBattery':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busBattery: value,
            busSocWindow: '',
            busElectricMotor: '',
            busWheelConfiguration: '',
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            busChargingType: '',
            isChangedByUser: true,
          }));
          break;
        case 'busSocWindow':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busSocWindow: value,
            busChargingType: '',
            busElectricMotor: '',
            busWheelConfiguration: '',
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busChargingType':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busChargingType: value,
            busElectricMotor: '',
            busWheelConfiguration: '',
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busElectricMotor':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busElectricMotor: value,
            busWheelConfiguration: '',
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busWheelConfiguration':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busWheelConfiguration: value,
            busBodyLength: '',
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busBodyLength':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busBodyLength: value,
            busChassisHeight: '',
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busChassisHeight':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busChassisHeight: value,
            busFloorHeight: '',
            isChangedByUser: true,
          }));
          break;
        case 'busFloorHeight':
          setSelections((prevSelections) => ({
            ...prevSelections,
            busFloorHeight: value,
            isChangedByUser: true,
          }));
          break;
        default:
          setSelections({
            ...selectionsDefault,
            isChangedByUser: true,
          });
          break;
      }
    },
    [selectionsDefault, setSelections],
  );

export default useHandleSelection;
