import { useCallback } from 'react';

interface Props {
  setSelectedItems: (value: React.SetStateAction<string[]>) => void;
}

const useHandleItemSelected = ({ setSelectedItems }: Props) =>
  useCallback(
    (checked: boolean, itemId: string) => {
      if (checked) {
        setSelectedItems((prev) => [...prev.filter((selectedItemId) => selectedItemId !== itemId), itemId]);
      } else {
        setSelectedItems((prev) => prev.filter((selectedItemId) => selectedItemId !== itemId));
      }
    },
    [setSelectedItems],
  );

export default useHandleItemSelected;
