import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useRemoveFilters = () => {
  const [, setSearchParams] = useSearchParams();

  return useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);
};

export default useRemoveFilters;
