import { ChargingEventV2VM } from '@optimization/sa-common';
import { Datum } from 'react-charts';
import { useMemo } from 'react';

const useKmAtEvent = ({
  currentChargingEvent,
  currentDatum,
}: {
  currentChargingEvent?: ChargingEventV2VM;
  currentDatum: Datum<unknown> | null;
}) =>
  useMemo(() => {
    let kmAtEvent = 0;

    if (currentDatum) {
      kmAtEvent = currentDatum.primaryValue;
    } else if (currentChargingEvent) {
      kmAtEvent = currentChargingEvent.KmAtEvent;
    }

    return kmAtEvent;
  }, [currentChargingEvent, currentDatum]);

export default useKmAtEvent;
