import classes from './style.module.scss';
import classNames from 'classnames';
import { ButtonAlt, RadioButtonOption, RadioButtons, useOutsideClick } from '@optimization/ssi-common';
import { ReactNode, useMemo } from 'react';

const getValue = (sortOption: Option) => `${sortOption.type}`;

export interface Option {
  type: string;
  name: string;
}

export interface Filter {
  key: string;
  name: string;
  value: string;
}

interface Props {
  className?: string;
  children: ReactNode;
  activeAction: 'filter' | 'sort-by' | null;
  sortType: string;
  sortOptions: Option[];
  onChangeSortType: (value: string) => void;
  hideFilters: () => void;
}

const FiltersSelector = ({
  className,
  children,
  activeAction,
  sortType,
  sortOptions,
  onChangeSortType,
  hideFilters,
}: Props) => {
  const ref = useOutsideClick(hideFilters);

  const radioOptions: RadioButtonOption[] = useMemo(
    () =>
      sortOptions.map((sortOption) => ({
        value: getValue(sortOption),
        name: `${sortOption.name}`,
        checked: getValue(sortOption) === `${sortType}`,
      })),
    [sortOptions, sortType],
  );

  return activeAction ? (
    <div ref={ref} className={classNames(classes['filters-selector'], className)}>
      <div className={classes.inner}>
        <ButtonAlt
          className={classes.close}
          tdsIcon="cross"
          tdsIconSize={24}
          onClick={hideFilters}
          style={{ zIndex: 1 }}
        />
        <div
          className={classNames(classes.filters, 'px-spacing-24', 'py-spacing-24', {
            [classes['multiple-items']]: activeAction === 'filter' && Array.isArray(children),
          })}
        >
          {activeAction === 'filter' ? (
            children
          ) : (
            <div className={classes.sort}>
              <RadioButtons onChange={onChangeSortType} options={radioOptions} />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default FiltersSelector;
