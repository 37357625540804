import { CandidateChartFormValues } from './types';
import { useEffect, useMemo } from 'react';
import { useUpdateVehicleChargingEventsMutation } from 'app/services/solution';
import { VehicleEnhanced } from '@optimization/sa-common';

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  candidateChartFormValues: CandidateChartFormValues;
  setCandidateChartFormValues: (value: React.SetStateAction<CandidateChartFormValues>) => void;
}

const useUpdateChargingEventsHandler = ({
  solutionId,
  vehicle,
  candidateChartFormValues,
  setCandidateChartFormValues,
}: Props) => {
  const [updateVehicleChargingEvents, updateVehicleChargingEventsState] = useUpdateVehicleChargingEventsMutation();

  useEffect(() => {
    if (
      vehicle &&
      candidateChartFormValues.departureTime &&
      candidateChartFormValues.slowChargeStartTime &&
      candidateChartFormValues.runSaveSlowChargingStartTimeAndDepartureTime
    ) {
      const timer = setTimeout(() => {
        const vehiclePerformanceStep = vehicle.enhanced.vehiclePerformanceStep;

        if (vehiclePerformanceStep) {
          const slowChargingEvent = vehiclePerformanceStep.SlowChargingEvent;

          if (slowChargingEvent) {
            updateVehicleChargingEvents({
              solutionId,
              vehicleId: vehicle.Id,
              IsManualSlowChargeOverride: true,
              PerformanceStep: vehicle.enhanced.vehicleDepotYear,
              DepartureTime: candidateChartFormValues.departureTime,
              ChargingEvents: vehiclePerformanceStep.ChargingEvents,
              SlowChargingEvent: {
                ...slowChargingEvent,
                TimeAtEvent: candidateChartFormValues.slowChargeStartTime,
              },
            });

            setCandidateChartFormValues((prev) => ({
              ...prev,
              runSaveSlowChargingStartTimeAndDepartureTime: false,
            }));
          }
        }
      }, 1500);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [
    solutionId,
    vehicle,
    candidateChartFormValues.departureTime,
    candidateChartFormValues.slowChargeStartTime,
    candidateChartFormValues.runSaveSlowChargingStartTimeAndDepartureTime,
    setCandidateChartFormValues,
    updateVehicleChargingEvents,
  ]);

  return useMemo(
    () => ({
      isLoading: updateVehicleChargingEventsState.isLoading,
      isError: updateVehicleChargingEventsState.isError,
    }),
    [updateVehicleChargingEventsState],
  );
};

export default useUpdateChargingEventsHandler;
