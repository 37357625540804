import { ChargingEventV2VM, VehicleEnhanced } from '@optimization/sa-common';
import { Datum } from 'react-charts';
import { isSavedChargingEvent, SlowChargingEventV2VM } from '@optimization/sa-common';
import { useCallback, useMemo } from 'react';
import { useToast } from '@optimization/ssi-common';
import { useUpdateVehicleChargingEventsMutation } from 'app/services/solution';

export interface SetupChargingEventHandler {
  currentDatum: Datum<unknown> | null;
  isLoading: boolean;
  isError: boolean;
  vehicle: VehicleEnhanced;
  onCancel: () => void;
  onDelete: () => Promise<void>;
  onSave: () => Promise<void>;
}

interface Props {
  currentDatum: Datum<unknown> | null;
  currentChargingEvent?: ChargingEventV2VM;
  vehicle: VehicleEnhanced;
  solutionId: string;
  slowChargingEvent?: SlowChargingEventV2VM;
  chargingEvents?: ChargingEventV2VM[];
  resetCurrentDatum: () => void;
}

const useSetupChargingEventHandler = ({
  currentDatum,
  currentChargingEvent,
  vehicle,
  solutionId,
  slowChargingEvent,
  chargingEvents,
  resetCurrentDatum,
}: Props): SetupChargingEventHandler => {
  const showToast = useToast();

  const [updateVehicleChargingEvents, updateVehicleChargingEventsState] = useUpdateVehicleChargingEventsMutation();

  const onDelete = useCallback(async () => {
    if (chargingEvents && slowChargingEvent && currentChargingEvent) {
      const chargingEventsBefore = chargingEvents.filter(
        (chargingEvent) => chargingEvent.KmAtEvent < currentChargingEvent.KmAtEvent,
      );

      const response = await updateVehicleChargingEvents({
        solutionId,
        vehicleId: vehicle.Id,
        PerformanceStep: vehicle.enhanced.vehicleDepotYear,
        IsManualSlowChargeOverride: false,
        SlowChargingEvent: slowChargingEvent,
        ChargingEvents: chargingEventsBefore,
      });

      if (response.data?.Success) {
        showToast({ header: 'Charging event removed' });
        resetCurrentDatum();
      }
    }
  }, [
    currentChargingEvent,
    solutionId,
    vehicle,
    chargingEvents,
    slowChargingEvent,
    resetCurrentDatum,
    showToast,
    updateVehicleChargingEvents,
  ]);

  const onSave = useCallback(async () => {
    if (slowChargingEvent && chargingEvents) {
      const response = await updateVehicleChargingEvents({
        PerformanceStep: vehicle.enhanced.vehicleDepotYear,
        solutionId,
        vehicleId: vehicle.Id,
        IsManualSlowChargeOverride: false,
        SlowChargingEvent: slowChargingEvent,
        ChargingEvents: chargingEvents.map((chargingEvent) => ({
          ...chargingEvent,
          Id: chargingEvent.Id === -1 ? 0 : chargingEvent.Id,
        })),
      });

      if (response.data?.Success) {
        showToast({
          header: `Charging event ${isSavedChargingEvent(currentChargingEvent?.Id) ? 'updated' : 'added'}`,
        });
        resetCurrentDatum();
      } else {
        showToast({
          header: `Something went wrong`,
          variant: 'error',
        });
      }
    }
  }, [
    chargingEvents,
    currentChargingEvent,
    solutionId,
    vehicle,
    slowChargingEvent,
    resetCurrentDatum,
    showToast,
    updateVehicleChargingEvents,
  ]);

  const onCancel = useCallback(() => {
    resetCurrentDatum();
  }, [resetCurrentDatum]);

  return useMemo(
    () => ({
      currentDatum,
      isLoading: updateVehicleChargingEventsState.isLoading,
      isError: updateVehicleChargingEventsState.isError,
      vehicle,
      onCancel,
      onDelete,
      onSave,
    }),
    [currentDatum, updateVehicleChargingEventsState, vehicle, onCancel, onDelete, onSave],
  );
};

export default useSetupChargingEventHandler;
