import { authConfig } from 'app/config/auth';
import { setUser } from 'features/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';

// Attaches a given access token to a MS Graph API call.
// Returns information about the user
const callMsGraph = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(authConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const useLoadUser = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const [userState, setUserState] = useState({
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    if (accessToken) {
      setUserState({ isLoading: true, isError: false });

      callMsGraph(accessToken)
        .then((response) => {
          const payload = {
            displayName: response.displayName,
          };
          dispatch(setUser(payload));
          setUserState({ isLoading: false, isError: false });
        })
        .catch(() => {
          setUserState({ isLoading: false, isError: true });
        });
    }
  }, [accessToken, dispatch]);

  return userState;
};

export default useLoadUser;
