const getMaxKey = ({
  countByName,
}: {
  countByName: {
    [key: string]: number;
  };
}) => {
  let maxKey = '';

  if (Object.keys(countByName)) {
    let count = 0;

    for (const countByNameKey of Object.keys(countByName)) {
      if (countByName[countByNameKey] > count) {
        maxKey = countByNameKey;
        count = countByName[countByNameKey];
      }
    }
  }

  return maxKey;
};

export default getMaxKey;
