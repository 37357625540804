import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedBattery = ({
  vehicles,
  batteryList,
}: {
  vehicles: ListVehicleTransformed[];
  batteryList: number[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const batteryCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const battery = vehicle.InstalledEnergyKWh;

        return increaseValue({ acc, key: battery, list: batteryList });
      }, {});

      result = getMaxKey({ countByName: batteryCount });
    }

    return result;
  }, [vehicles, batteryList]);

export default useSuggestedBattery;
