import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedAxleDistance = ({
  vehicles,
  truckAxleDistanceList,
}: {
  vehicles: ListVehicleTransformed[];
  truckAxleDistanceList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const truckAxleDistanceCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const truckAxleDistance = vehicle.AxleDistanceMm;

        return increaseValue({
          acc,
          key: truckAxleDistance?.toString(),
          list: truckAxleDistanceList,
        });
      }, {});

      result = getMaxKey({ countByName: truckAxleDistanceCount });
    }

    return result;
  }, [vehicles, truckAxleDistanceList]);

export default useSuggestedAxleDistance;
