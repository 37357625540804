import { ListDepotResponseV2VM, ListVehicleEnhanced, ListVehicleTransformed } from '@optimization/sa-common';
import { Message } from './';
import { useMemo } from 'react';

interface Props {
  depots?: ListDepotResponseV2VM[];
  depotsIsReady: boolean;
  depotsWithWarning?: ListDepotResponseV2VM[];
  notFeasibleVehicles?: ListVehicleEnhanced[];
  vehicles?: ListVehicleTransformed[];
  vehiclesIsReady: boolean;
}

const useMessages = ({
  depots,
  depotsIsReady,
  depotsWithWarning,
  notFeasibleVehicles,
  vehicles,
  vehiclesIsReady,
}: Props) =>
  useMemo(() => {
    let depotMessages: null | Message[] = null;
    let vehicleMessages: null | Message[] = null;

    const hasNoVehicles = vehiclesIsReady && vehicles?.length === 0;
    const hasNoDepots = depotsIsReady && depots?.length === 0;

    const hasNotFeasibleVehicles = vehiclesIsReady && notFeasibleVehicles;

    if (hasNoVehicles || hasNotFeasibleVehicles) {
      if (!vehicleMessages) {
        vehicleMessages = [];
      }

      if (hasNotFeasibleVehicles) {
        vehicleMessages.push({
          header: 'Solve vehicles',
          body: 'Update required to solve selected vehicles',
          variant: 'information',
        });
      } else if (hasNoVehicles) {
        vehicleMessages.push({
          header: 'Create your first vehicle',
          variant: 'information',
        });
      }
    }

    if (depotsWithWarning || hasNoDepots) {
      if (!depotMessages) {
        depotMessages = [];
      }

      if (depotsWithWarning) {
        depotMessages.push({
          header: 'Solve depots',
          body: 'Update required to solve charging demand at selected depots.',
          variant: 'information',
        });
      } else if (hasNoDepots) {
        depotMessages.push({
          header: 'Setup your first depot',
          variant: 'information',
        });
      }
    }

    return { depotMessages, vehicleMessages };
  }, [depots, depotsIsReady, depotsWithWarning, notFeasibleVehicles, vehicles, vehiclesIsReady]);

export default useMessages;
