import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { TdsIcon } from '@scania/tegel-react';

export type MenuItemTitle = 'Evaluate Scania chassis' | 'Define manually';

const items: { title: MenuItemTitle; icon: ReactNode; dataTestid?: string }[] = [
  {
    title: 'Evaluate Scania chassis',
    icon: <TdsIcon name="truck" size="20px" />,
    dataTestid: 'scania-chassis-menu-item',
  },
  {
    title: 'Define manually',
    icon: <TdsIcon name="document_tool" size="20px" />,
    dataTestid: 'define-manually-menu-item',
  },
];

interface Props {
  activeMenuItemTitle?: MenuItemTitle;
  setActiveMenuItemTitle: React.Dispatch<React.SetStateAction<MenuItemTitle | undefined>>;
}

const Menu = ({ activeMenuItemTitle, setActiveMenuItemTitle }: Props) => (
  <ul className={classes.menu}>
    {items.map((item) => (
      <li key={item.title}>
        <button
          type="button"
          data-testid={item.dataTestid}
          className={classNames('reset-button-styles', { [classes.selected]: activeMenuItemTitle === item.title })}
          onClick={() => setActiveMenuItemTitle(item.title)}
        >
          {item.icon}
          <div>{item.title}</div>
          <TdsIcon name="arrow_right" size="16px" />
        </button>
      </li>
    ))}
  </ul>
);

export default Menu;
