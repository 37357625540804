import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import ProductManualBlock from './ProductManualBlock';
import productManualCategories from 'app/config/productManualCategories';
import ProductManualHeader from '../utils/ProductManualHeader';
import { InlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const tabs = [{ name: 'Information' }];

const ProductManualSubCategory = () => {
  const { categorySlug, subCategorySlug } = useParams();

  const category = useMemo(
    () => productManualCategories.find((productManualCategory) => productManualCategory.slug === categorySlug),
    [categorySlug],
  );

  const subCategory = useMemo(() => {
    if (!category) {
      return;
    }

    return category.subCategories.find((sc) => sc.slug === subCategorySlug);
  }, [category, subCategorySlug]);

  if (!category || !subCategory) {
    return <PageNotFound />;
  }

  return (
    <>
      <HelmetTitle items={[subCategory.name, category.name]} />
      <ProductManualHeader categoryName={category.name} subCategoryName={subCategory.name} />
      <InlineTabs tabs={tabs} />
      <div className="px-spacing-32 pt-spacing-24">
        <h3 className="tds-headline-05">{subCategory.name}</h3>
        {subCategory.body.map((bodyItem, index) => (
          <ProductManualBlock body={bodyItem} key={index} />
        ))}
      </div>
    </>
  );
};

export default ProductManualSubCategory;
