import useInvalidateSolutionCache from 'common/hooks/useInvalidateSolutionCache';
import { useCallback, useMemo, useState } from 'react';
import { useDeleteSolutionMutation } from 'app/services/solution';
import { usePlural, useToast } from '@optimization/ssi-common';

interface Props {
  solutionIds: string[];
  onSuccess: () => void;
  onFailure: () => void;
}

const useDeleteSolutions = ({ solutionIds, onSuccess, onFailure }: Props) => {
  const showToast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [deleteSolutionMutation] = useDeleteSolutionMutation();
  const invalidateSolutionTags = useInvalidateSolutionCache();

  const solutionsPlural = usePlural({ count: solutionIds.length });

  const deleteSolutions = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      await Promise.all(
        solutionIds.map(async (solutionId) => {
          const response = await deleteSolutionMutation({ solutionId });

          if ('error' in response) {
            throw new Error();
          }
        }),
      );

      showToast({
        header: `${solutionIds.length} solution${solutionsPlural} deleted`,
        subheader: 'Create an issue report to restore it.',
        dataTestid: 'toast-solution-deleted',
      });

      onSuccess();
    } catch (e) {
      showToast({
        header: `Couldn't delete solution${solutionsPlural}`,
        variant: 'error',
      });

      setIsError(true);
      onFailure();
    }
    invalidateSolutionTags(['Solutions']);
    setIsLoading(false);
  }, [invalidateSolutionTags, solutionIds, showToast, solutionsPlural, onSuccess, deleteSolutionMutation, onFailure]);

  return useMemo(
    () => ({ deleteSolutions, deleteSolutionsState: { isLoading, isError } }),
    [deleteSolutions, isLoading, isError],
  );
};

export default useDeleteSolutions;
