import classes from './style.module.scss';
import classNames from 'classnames';
import PtoDevicesSelector from 'features/candidate/Pto/DevicesSelector';
import { Button, ButtonAlt } from '@optimization/ssi-common';
import { PtoHandler } from 'features/solution/SolutionDetail/utils/useSetupPtoHandler';

interface Props {
  className?: string;
  setupPtoHandler: PtoHandler;
}

const SetupPto = ({ className, setupPtoHandler }: Props) => (
  <div className={classNames(classes['setup-pto'], className)}>
    <div className={classes.header}>
      <h5 className="tds-headline-05">Setup PTO</h5>
      <p className="tds-body-02 mt-spacing-4">Select the settings that you want to add to the selected vehicles</p>
      <div className={classes.close}>
        <ButtonAlt tdsIcon="cross" disabled={setupPtoHandler.isLoading} onClick={setupPtoHandler.onClose} />
      </div>
    </div>
    <div className={classNames('mt-spacing-24', classes.body)}>
      <div className={classes.fields}>
        <PtoDevicesSelector
          dataTestid="dropdown-setup-pto-devices"
          disabled={setupPtoHandler.isLoading}
          ecItems={setupPtoHandler.ecItems}
          ptoDevices={setupPtoHandler.ptoDevices}
          onChangePtoDevices={setupPtoHandler.onChangePtoDevices}
        />
        {setupPtoHandler.dropdownEcItems.map((ecItem) => (
          <div key={ecItem.Type}>{setupPtoHandler.getPtoField(ecItem.Type)}</div>
        ))}
        {setupPtoHandler.checkboxEcItems.length > 0 && <div className={classes.break} />}
        {setupPtoHandler.checkboxEcItems.map((ecItem) => (
          <div key={ecItem.Type}>{setupPtoHandler.getPtoField(ecItem.Type)}</div>
        ))}
      </div>
      <div className="flex">
        <Button
          className="tds-u-ml-auto"
          dataTestid="button-setup-pto-add-pto"
          disabled={setupPtoHandler.isLoading}
          modeVariant="secondary"
          text="Add PTO"
          size="sm"
          onClick={setupPtoHandler.setupPto}
        />
      </div>
    </div>
  </div>
);

export default SetupPto;
