import useResetApiState from 'common/hooks/useResetApiCache';
import { promiseAllSettledWrapper } from 'common/utils/helper';
import { useCallback, useMemo, useState } from 'react';
import { useDeleteVehiclesMutation } from 'app/services/solution';
import { useToast } from '@optimization/ssi-common';

const chunkArray = (array: number[], chunkSize: number) => {
  const chunks = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
};

interface Props {
  solutionId: string;
  selectedCandidates: string[];
  closeModal: () => void;
  setSelectedCandidates: React.Dispatch<React.SetStateAction<string[]>>;
  setRejected: React.Dispatch<React.SetStateAction<number>>;
  resetMode: () => void;
}

interface Result {
  isLoading: boolean;
  deleteCandidates: () => Promise<void>;
}

const useDeleteCandidatesHandler = ({
  solutionId,
  selectedCandidates,
  closeModal,
  setSelectedCandidates,
  setRejected,
  resetMode,
}: Props): Result => {
  const showToast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [deleteCandidatesMutation] = useDeleteVehiclesMutation();

  const resetApiState = useResetApiState();

  const deleteCandidates = useCallback(async () => {
    setIsLoading(true);

    const ids = selectedCandidates.map(Number);

    const chunkedIds = chunkArray(ids, 100);

    const { fulfilled } = await promiseAllSettledWrapper(
      chunkedIds.map(async (idsList) => {
        const response = await deleteCandidatesMutation({
          solutionId,
          ids: idsList,
          noInvalidates: true,
        });

        if ('error' in response) {
          throw new Error();
        }

        return idsList;
      }),
    );

    // Todo: Only invalidate required tags instead?
    // But then how to avoid error on GET for existing vehicles after duplicate?
    resetApiState();

    const flatFulfilled = fulfilled.flat();

    const rejected = selectedCandidates.filter((c) => !flatFulfilled.map(String).includes(c));

    if (flatFulfilled.length > 0) {
      showToast({
        header: `${flatFulfilled.length} vehicle candidate${flatFulfilled.length === 1 ? '' : 's'} deleted`,
        dataTestid: 'toast-candidates-deleted',
      });
    }

    const allFulfilled = flatFulfilled.length === selectedCandidates.length;

    setRejected(rejected.length);

    setIsLoading(false);

    if (!allFulfilled) {
      setSelectedCandidates(rejected);
      return;
    }

    resetMode();
    setSelectedCandidates([]);
    closeModal();
  }, [
    solutionId,
    selectedCandidates,
    resetMode,
    resetApiState,
    setSelectedCandidates,
    deleteCandidatesMutation,
    showToast,
    setRejected,
    closeModal,
  ]);

  return useMemo(() => ({ deleteCandidates, isLoading }), [deleteCandidates, isLoading]);
};

export default useDeleteCandidatesHandler;
