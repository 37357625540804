import { TdsMessage } from '@scania/tegel-react';

export interface Message {
  header: string;
  body?: string;
  variant?: 'error' | 'information' | 'success' | 'warning';
}

interface Props {
  header: string;
  messages: Message[] | null;
}

const Messages = ({ header, messages }: Props) =>
  messages ? (
    <div>
      <h4 className="tds-detail-04 mb-spacing-8">{header}</h4>
      {messages.map((message, index) => (
        <TdsMessage key={index} header={message.header} variant={message.variant}>
          {message.body}
        </TdsMessage>
      ))}
    </div>
  ) : null;

export default Messages;
