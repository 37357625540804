import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  left?: ReactNode;
  right?: ReactNode;
  below?: ReactNode;
  actionLayoutRef?: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
  style?: React.CSSProperties;
}

const ActionLayout = ({ left, right, below, actionLayoutRef, className, style }: Props) => (
  <div style={style} ref={actionLayoutRef} className={classNames(classes['action-layout'], className)}>
    <div className={classes.body}>
      <div className={classes.left}>
        <div>{left}</div>
      </div>
      <div className={classes.right}>{right}</div>
    </div>
    {below && <div className={classes.below}>{below}</div>}
  </div>
);

export default ActionLayout;
