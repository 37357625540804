import { Filter } from 'common/components/Filters/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedSolutionNames: string[];
}

const useSelectedFilters = ({ selectedSolutionNames }: Props) =>
  useMemo(() => {
    let filters: Filter[] | null = null;

    if (selectedSolutionNames.length) {
      if (!filters) {
        filters = [];
      }

      filters.push({
        key: 'solutionNames',
        name: 'SOLUTION NAMES',
        value: '',
      });
    }

    return filters;
  }, [selectedSolutionNames]);

export default useSelectedFilters;
