import { KeyedNumber } from '@optimization/ssi-common';
import { useMemo } from 'react';

export interface DepotBasePowerDemand {
  TimeOfDay: string;
  Value: number;
}

interface Props {
  depotBasePowerDemand: KeyedNumber;
}

const useDepotBasePowerDemands = ({ depotBasePowerDemand }: Props): DepotBasePowerDemand[] =>
  useMemo(() => {
    const result = [];

    for (let i = 0; i < 24; i++) {
      const TimeOfDay = `${i.toString().padStart(2, '0')}:00`;
      const basePowerDemand =
        Object.entries(depotBasePowerDemand).find(([interval]) => {
          const [start, end] = interval.split('-');
          return i >= Number(start) && i < Number(end);
        })?.[1] ?? 0;

      result.push({
        TimeOfDay,
        Value: basePowerDemand,
      });
    }

    return result;
  }, [depotBasePowerDemand]);

export default useDepotBasePowerDemands;
