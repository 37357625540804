import chassisAdaptationCheck from './chassisAdaptationCheck';
import { ChargingSpecification, FactBasicBus } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

const CHARGING_TYPE_SEPARATOR = '##';

export const parseChargingTypeValue = (chargingSpecificationValue: string) =>
  chargingSpecificationValue.split(CHARGING_TYPE_SEPARATOR);

export const getChargingTypeValue = ({
  chargingType,
  chargingPowerKW,
}: {
  chargingType?: string;
  chargingPowerKW?: number;
}) => `${chargingType || ''}${CHARGING_TYPE_SEPARATOR}${chargingPowerKW || 0}`;

export const getChargingTypePresentation = (chargingSpecification: ChargingSpecification) =>
  `${chargingSpecification.ChargingType} ${chargingSpecification.ChargingPowerKW} kW`;

interface Props {
  basicBusses: FactBasicBus[];
  selections: SelectionExecutions;
}

const useBusChargingTypeOptions = ({ basicBusses, selections }: Props): { name: string; value: string }[] =>
  useMemo(() => {
    const uniqueChargingTypes = new Map();

    basicBusses
      .filter((basicBus) =>
        chassisAdaptationCheck({
          basicVehicle: basicBus,
          selectedBattery: selections.busBattery,
          selectedSocWindow: selections.busSocWindow,
          selectedPortfolio: selections.busPortfolio,
        }),
      )
      .forEach((basicBus) =>
        basicBus.ChargingSpecifications.forEach((chargingSpecification) => {
          const value = getChargingTypeValue({
            chargingType: chargingSpecification.ChargingType,
            chargingPowerKW: chargingSpecification.ChargingPowerKW,
          });

          if (!uniqueChargingTypes.has(value)) {
            uniqueChargingTypes.set(value, {
              name: getChargingTypePresentation(chargingSpecification),
              value,
            });
          }
        }),
      );

    return Array.from(uniqueChargingTypes.values()).sort();
  }, [basicBusses, selections]);

export default useBusChargingTypeOptions;
