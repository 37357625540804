import SetupEvaluation from './SetupEvaluation';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useExpandVehicleAndTab } from '../ExpandVehicleAndTabContext';
import { useParams } from 'react-router-dom';
import { VehicleEnhanced } from '@optimization/sa-common';

interface FinancialEvaluation {
  startSetupEvaluation: (vehicle: VehicleEnhanced) => void;
}

type EvaluationStatus = {
  [key: string]: { isDone: boolean; enableInPresentation: boolean };
};

const FinancialEvaluationContext = createContext<FinancialEvaluation | null>(null);

export const FinancialEvaluationProvider = ({ children }: { children: ReactNode }) => {
  const { solutionId } = useParams();
  const [vehicle, setVehicle] = useState<VehicleEnhanced | null>(null);
  const [evaluations, setEvaluations] = useState<EvaluationStatus>({});

  const expandVehicleAndTab = useExpandVehicleAndTab();

  const expandVehicleAndTabCallback = useCallback(() => {
    if (expandVehicleAndTab) {
      expandVehicleAndTab.setExpandVehicleAndTab({ vehicleId: vehicle?.Id, candidateTab: 'Financial evaluation' });
    }
  }, [expandVehicleAndTab, vehicle?.Id]);

  const cancelSetupEvaluation = useCallback(() => {
    setVehicle(null);
    expandVehicleAndTabCallback();
  }, [expandVehicleAndTabCallback]);

  const startSetupEvaluation = useCallback((vehicleParam: VehicleEnhanced) => {
    setVehicle(vehicleParam);
  }, []);

  const setEvaluationIsDone = useCallback(
    ({ vehicleId, isDone }: { vehicleId: number; isDone: boolean }) => {
      setEvaluations((prev) => ({
        ...prev,
        [vehicleId.toString()]: {
          ...(prev[vehicleId.toString()] ? prev[vehicleId.toString()] : { enableInPresentation: false }),
          isDone,
        },
      }));
      setVehicle(null);
      expandVehicleAndTabCallback();
    },
    [expandVehicleAndTabCallback],
  );

  const value = useMemo(
    () => ({
      evaluations,
      startSetupEvaluation,
    }),
    [evaluations, startSetupEvaluation],
  );

  return (
    <FinancialEvaluationContext.Provider value={value}>
      {vehicle && solutionId ? (
        <SetupEvaluation
          solutionId={solutionId}
          vehicle={vehicle}
          cancelSetupEvaluation={cancelSetupEvaluation}
          setEvaluationIsDone={setEvaluationIsDone}
        />
      ) : (
        children
      )}
    </FinancialEvaluationContext.Provider>
  );
};

export const useFinancialEvaluation = () => useContext(FinancialEvaluationContext);
