import depotFeasibilityImageUrl26 from 'features/productManual/assets/depot-feasibility/26.png';
import depotFeasibilityImageUrl27 from 'features/productManual/assets/depot-feasibility/27.png';
import depotManagementImageUrl19 from 'features/productManual/assets/depot-mgmt/19.png';
import depotManagementImageUrl20 from 'features/productManual/assets/depot-mgmt/20.png';
import depotManagementImageUrl21 from 'features/productManual/assets/depot-mgmt/21.png';
import depotManagementImageUrl22 from 'features/productManual/assets/depot-mgmt/22.png';
import depotManagementImageUrl23 from 'features/productManual/assets/depot-mgmt/23.png';
import depotManagementImageUrl24 from 'features/productManual/assets/depot-mgmt/24.png';
import depotManagementImageUrl25 from 'features/productManual/assets/depot-mgmt/25.png';
import solutionManagementImageUrl1 from 'features/productManual/assets/solution-mgmt/1.png';
import solutionManagementImageUrl2 from 'features/productManual/assets/solution-mgmt/2.png';
import solutionPresentationImageUrl28 from 'features/productManual/assets/solution-presentation/28.png';
import solutionPresentationImageUrl29 from 'features/productManual/assets/solution-presentation/29.png';
import solutionPresentationImageUrl30 from 'features/productManual/assets/solution-presentation/30.png';
import solutionPresentationImageUrl31 from 'features/productManual/assets/solution-presentation/31.png';
import solutionPresentationImageUrl32 from 'features/productManual/assets/solution-presentation/32.png';
import solutionPresentationImageUrl33 from 'features/productManual/assets/solution-presentation/33.png';
import vehicleFeasibilityImageUrl14 from 'features/productManual/assets/vehicle-feasibility/14.png';
import vehicleFeasibilityImageUrl15 from 'features/productManual/assets/vehicle-feasibility/15.png';
import vehicleFeasibilityImageUrl16 from 'features/productManual/assets/vehicle-feasibility/16.png';
import vehicleFeasibilityImageUrl17 from 'features/productManual/assets/vehicle-feasibility/17.png';
import vehicleFeasibilityImageUrl18 from 'features/productManual/assets/vehicle-feasibility/18.png';
import vehicleManagementImageUrl10 from 'features/productManual/assets/vehicle-mgmt/10.png';
import vehicleManagementImageUrl11 from 'features/productManual/assets/vehicle-mgmt/11.png';
import vehicleManagementImageUrl12 from 'features/productManual/assets/vehicle-mgmt/12.png';
import vehicleManagementImageUrl13 from 'features/productManual/assets/vehicle-mgmt/13.png';
import vehicleManagementImageUrl3 from 'features/productManual/assets/vehicle-mgmt/3.png';
import vehicleManagementImageUrl4 from 'features/productManual/assets/vehicle-mgmt/4.png';
import vehicleManagementImageUrl5 from 'features/productManual/assets/vehicle-mgmt/5.png';
import vehicleManagementImageUrl6 from 'features/productManual/assets/vehicle-mgmt/6.png';
import vehicleManagementImageUrl7 from 'features/productManual/assets/vehicle-mgmt/7.png';
import vehicleManagementImageUrl8 from 'features/productManual/assets/vehicle-mgmt/8.png';
import vehicleManagementImageUrl9 from 'features/productManual/assets/vehicle-mgmt/9.png';

interface ProductManualCategory {
  name: string;
  slug: string;
  subCategories: {
    name: string;
    slug: string;
    body: { type: 'image' | 'description'; text?: string; url?: string }[];
  }[];
}

const productManualCategories: ProductManualCategory[] = [
  {
    name: 'Solution management',
    slug: 'solution-management',
    subCategories: [
      {
        name: 'Create new solution',
        slug: 'create-new-solution',
        body: [
          {
            type: 'description',
            text: "In the solution overview, you start off by creating a new solution. A solution is thought to be grouped around a customer, and will hold the definition of the customer's future vehicles and depot infrastructure.",
          },
          {
            type: 'image',
            url: solutionManagementImageUrl1,
          },
        ],
      },
      {
        name: 'Load solution',
        slug: 'load-solution',
        body: [
          {
            type: 'description',
            text: 'If you have already created a solution for a customer, you can easily revisit it and continue working on the analysis by loading it.',
          },
          {
            type: 'image',
            url: solutionManagementImageUrl2,
          },
        ],
      },
    ],
  },
  {
    name: 'Vehicle management',
    slug: 'vehicle-management',
    subCategories: [
      {
        name: 'Add vehicle by chassis number',
        slug: 'add-vehicle-by-chassis-number',
        body: [
          {
            type: 'description',
            text: "You can add vehicle candidates to your solution by evaluating the customer's current vehicles' basic execution and operation. Use the customer's chassis numbers as input.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl3,
          },
        ],
      },
      {
        name: 'Add vehicle manually',
        slug: 'add-vehicle-manually',
        body: [
          {
            type: 'description',
            text: "For a more agnostic flow, you can add vehicle candidates to your solution by manually defining a vehicle candidate's basic execution and operation. For example, use the basic execution and operational factors from the customer's non-Scania vehicles as input.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl4,
          },
        ],
      },
      {
        name: 'Duplicate vehicle candidates',
        slug: 'duplicate-vehicle-candidates',
        body: [
          {
            type: 'description',
            text: "When interacting with the 'Action Menu' in the Vehicles page, you can duplicate one or multiple vehicle candidates.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl5,
          },
        ],
      },
      {
        name: 'Rename vehicle candidates',
        slug: 'rename-vehicle-candidates',
        body: [
          {
            type: 'description',
            text: 'You can adjust the name of vehicle candidates by clicking in the editable Name field.',
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl6,
          },
        ],
      },
      {
        name: 'Change vehicle candidate performance step',
        slug: 'change-vehicle-candidate-performance-step',
        body: [
          {
            type: 'description',
            text: 'You can adjust the corresponding performance step year for vehicle candidates by using the performance step dropdown. The performance step year corresponds to the year you will be able to find the mapped vehicle candidate in the Depot page. It also corresponds to the year in the customer electrification plan.',
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl7,
          },
        ],
      },
      {
        name: 'Delete vehicle candidates',
        slug: 'delete-vehicle-candidates',
        body: [
          {
            type: 'description',
            text: "When interacting with the 'Action Menu' in the Vehicles page, you can delete one or multiple vehicle candidates.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl8,
          },
        ],
      },
      {
        name: 'Configure vehicle product',
        slug: 'configure-vehicle-product',
        body: [
          {
            type: 'description',
            text: "When interacting with the 'Action Menu' in the Vehicles page, you can change vehicle product for one or multiple vehicle candidates. This is if you want to override evaluation and define the vehicle candidate's vehicle product with the supported configurations for earlier or later launch periods. Start off by selecting the vehicle candidates you want to change.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl9,
          },
          {
            type: 'description',
            text: ' In the next step, you can define the basic execution of the vehicle product, and effectively change it for the vehicle candidates you selected in the previous step.',
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl10,
          },
        ],
      },
      {
        name: 'Adjust vehicle candidate definition',
        slug: 'adjust-vehicle-candidate',
        body: [
          {
            type: 'image',
            url: vehicleManagementImageUrl11,
          },
          {
            type: 'description',
            text: 'When expanding a vehicle candidate, you can adjust its basic execution, operational factors and user factors on the fly.',
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl12,
          },
        ],
      },
      {
        name: 'Configure PTO devices',
        slug: 'configure-pto-devices',
        body: [
          {
            type: 'description',
            text: "When interacting with 'Configure PTO', you can configure one or multiple PTO devices, adding their consumption to the vehicle candidate's total energy consumption.",
          },
          {
            type: 'image',
            url: vehicleManagementImageUrl13,
          },
        ],
      },
    ],
  },
  {
    name: 'Vehicle feasibility',
    slug: 'vehicle-feasibility',
    subCategories: [
      {
        name: 'Estimated Energy Consumption',
        slug: 'estimated-energy-consumption',
        body: [
          {
            type: 'description',
            text: 'When expanding a vehicle candidate, you can understand and assess its estimated net and total energy consumption per kilometre (kWh/km). You can toggle between a summer/winter day to understand the difference in energy consumption for those scenarios.',
          },
          {
            type: 'image',
            url: vehicleFeasibilityImageUrl14,
          },
        ],
      },
      {
        name: 'Daily range',
        slug: 'daily-range',
        body: [
          {
            type: 'description',
            text: "When expanding a vehicle candidate, you can understand and assess its estimated daily range, and contrast it to the vehicle candidate's median and longest daily distances. You can toggle between a summer/winter day to understand the difference in daily range for those scenarios.",
          },
          {
            type: 'image',
            url: vehicleFeasibilityImageUrl15,
          },
        ],
      },
      {
        name: 'Cycled energy',
        slug: 'cycled-energy',
        body: [
          {
            type: 'description',
            text: 'When expanding a vehicle candidate, you can understand and assess the years until the battery system hits 80% State of Health (SoH), and the cycled energy per km. This is based on the energy consumption, median daily distance and both summer and winter temperatures of the vehicle candidate.',
          },
          {
            type: 'image',
            url: vehicleFeasibilityImageUrl16,
          },
        ],
      },
      {
        name: 'Historical stop pattern and place image',
        slug: 'historical-stop-pattern-and-place-image',
        body: [
          {
            type: 'description',
            text: 'When expanding a vehicle candidate, you can understand and assess the historical stop pattern for the Scania chassis the vehicle candidate is evaluated from. You can enable and disable the stop locations and trips layer, change resolution, and filter the stops based on time window and stop duration.',
          },
          {
            type: 'image',
            url: vehicleFeasibilityImageUrl17,
          },
          {
            type: 'description',
            text: "Once you are happy with the resulting stop visualization, you can choose to 'Place Image'. This will insert the image in the solution presentation, and can be consumed when expanding the specific vehicle candidate.",
          },
          {
            type: 'image',
            url: vehicleFeasibilityImageUrl18,
          },
        ],
      },
    ],
  },
  {
    name: 'Depot management',
    slug: 'depot-management',
    subCategories: [
      {
        name: 'Create new depot',
        slug: 'create-new-depot',
        body: [
          {
            type: 'description',
            text: 'You can create depots, and add them to your solution. Name your depot, define its max. grid capacity, future max. grid capacity, and the daily base power demand. This is information you may have to ask the customer for.',
          },
          {
            type: 'image',
            url: depotManagementImageUrl19,
          },
        ],
      },
      {
        name: 'Map vehicle candidates',
        slug: 'map-vehicle-candidates',
        body: [
          {
            type: 'description',
            text: 'Go ahead and map the vehicle candidates that belong to the depot. In order for it to nightcharge, and also in order for you to define daily charging events, a vehicle candidate must be mapped to some kind of depot.',
          },
          {
            type: 'image',
            url: depotManagementImageUrl20,
          },
        ],
      },
      {
        name: 'Define daily charging events',
        slug: 'define-daily-charging-events',
        body: [
          {
            type: 'description',
            text: 'Click on the SoC graph line to define when (time since departure) or where (km count) you would like to place the charging event. In the next step, you can choose to create the charging event in a few different ways. You can define the event by specifying either duration, power or both.',
          },
          {
            type: 'image',
            url: depotManagementImageUrl21,
          },
          {
            type: 'description',
            text: 'You can also define the event by not specifying anything, and just hit the confirm button, and the system will attempt to create a charging event that will charge enough to complete the daily distance. The system will also negotiate between overlapping charging events from multiple vehicles at the depot, and optimize the affected events.',
          },
          {
            type: 'image',
            url: depotManagementImageUrl22,
          },
        ],
      },
      {
        name: 'Update depot',
        slug: 'update-depot',
        body: [
          {
            type: 'description',
            text: "When interacting with the 'Action Menu' in the Depot page, you can update the depot to adjust its name, grid power capacity, and base power demand.",
          },
          {
            type: 'image',
            url: depotManagementImageUrl23,
          },
        ],
      },
      {
        name: 'Delete depot',
        slug: 'delete-depot',
        body: [
          {
            type: 'description',
            text: "When interacting with the 'Action Menu' in the Depot page, you can delete the depot. Any mapped vehicle candidates will be unmapped from the depot when doing so.",
          },
          {
            type: 'image',
            url: depotManagementImageUrl24,
          },
        ],
      },
      {
        name: 'Define charging infrastructure',
        slug: 'define-charging-infrastructure',
        body: [
          {
            type: 'description',
            text: "When you are happy with the mapped vehicle candidates' daily charging events, the resulting daily power demand graph and the occupancy chart, you can dimension and define the required charging products (chargers) in performance steps (yearly) for the depot.",
          },
          {
            type: 'image',
            url: depotManagementImageUrl25,
          },
        ],
      },
    ],
  },
  {
    name: 'Depot feasibility',
    slug: 'depot-feasibility',
    subCategories: [
      {
        name: 'Daily power demand',
        slug: 'daily-power-demand',
        body: [
          {
            type: 'description',
            text: 'You can understand the feasibility of the depot by assessing its daily power demand graph.',
          },
          {
            type: 'image',
            url: depotFeasibilityImageUrl26,
          },
        ],
      },
      {
        name: 'Daily depot occupancy',
        slug: 'daily-depot-occupancy',
        body: [
          {
            type: 'description',
            text: 'You can understand the feasibility of the depot by assessing its daily depot occupancy chart.',
          },
          {
            type: 'image',
            url: depotFeasibilityImageUrl27,
          },
        ],
      },
    ],
  },
  {
    name: 'Solution presentation',
    slug: 'solution-presentation',
    subCategories: [
      {
        name: 'Filter solution content to create presentation link',
        slug: 'filter-solution-content-to-create-presentation-link',
        body: [
          {
            type: 'description',
            text: "In the presentation configurator, you can filter the vehicle candidates that you want to include in the customer view of the solution presentation. When you are happy with your filters and sorting, you can go ahead and copy the link via the 'Share' interaction, or you can go directly to the presentation, which will open in a new tab.",
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl28,
          },
        ],
      },
      {
        name: 'Solution historical stop pattern and place image',
        slug: 'solution-historical-stop-pattern-and-place-image',
        body: [
          {
            type: 'description',
            text: "In the 'Historical stops' tab in the presentation configurator, you can understand and assess the historical stop pattern for all the Scania chassis the vehicle candidatesares evaluated from. You can enable and disable the stop locations and trips layer, change resolution, and filter the stops based on time window and stop duration.",
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl29,
          },
          {
            type: 'description',
            text: "Once you are happy with the resulting stop visualization, you can choose to 'Place Image'. This will insert the image in the solution presentation, and can be consumed when expanding the specific vehicle candidate.",
          },
        ],
      },
      {
        name: 'Solution performance steps',
        slug: 'solution-performance-steps',
        body: [
          {
            type: 'description',
            text: "When you access the solution presentation, you can navigate to the solution performance steps section. In the next step, you can expand a performance step to present that year's vehicle candidates and depots.",
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl30,
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl31,
          },
        ],
      },
      {
        name: 'Showcase solution vehicle products',
        slug: 'showcase-solution-vehicle-products',
        body: [
          {
            type: 'description',
            text: 'Showcase all possible configurations for vehicle products contained in the solution presentation.',
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl32,
          },
        ],
      },
      {
        name: 'Export solution presentation as Excel',
        slug: 'export-solution-presentation-as-excel',
        body: [
          {
            type: 'description',
            text: 'In the top right corner of the cover page in the solution presentation, you can choose to export the raw data of the solution presentation as xlsx.',
          },
          {
            type: 'image',
            url: solutionPresentationImageUrl33,
          },
        ],
      },
    ],
  },
];

export default productManualCategories;
