import AddNewDepotPopoverMenu from './utils/AddNewDepotPopoverMenu';
import classes from './style.module.scss';
import classNames from 'classnames';
import DeckGL from '@deck.gl/react/typed';
import ExistingDepotPopover from './utils/ExistingDepotPopover';
import SuggestedDepotPopover from './utils/SuggestedDepotPopover';
import useLayers from './utils/useLayers';
import useLocations from './utils/useLocations';
import useLookAtLocations from './utils/useLookAtLocations';
import usePickingInfoDelayed from './utils/usePickingInfoDelayed';
import { FlyToInterpolator, PickingInfo } from 'deck.gl/typed';
import { Map, MapRef } from 'react-map-gl';
import { MAPBOX_API_KEY } from 'app/config';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ListDepotTransformed,
  ToggleFullScreenMap,
  ToggleSatelliteMap,
  MAPBOX_STYLE_DARK,
  MAPBOX_STYLE_SATELLITE,
  HomeBase,
} from '@optimization/sa-common';
import {
  ExistingDepotMarker,
  OnSuggestedDepotMarkerClick,
  OnExistingDepotMarkerClick,
  SuggestedDepotMarker,
} from './utils/types';

export const DEPOT_MARKER_ICON_SIZE = 54;

interface Props {
  className?: string;
  depots?: ListDepotTransformed[];
  homeBases?: HomeBase[];
  solutionId: string;
  onShowDepotForm: () => void;
  onSelectHomeBase: (marker: HomeBase) => void;
}

const DepotsMap = ({
  className,
  depots = [],
  homeBases = [],
  solutionId,
  onShowDepotForm,
  onSelectHomeBase: onSuggestDepot,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef | null>(null);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isSatellite, setIsSatellite] = useState(false);
  const [suggestedDepotMarker, setSuggestedDepotMarker] = useState<SuggestedDepotMarker>();
  const [existingDepotMarker, setExistingDepotMarker] = useState<ExistingDepotMarker>();
  const [pickingInfo, setPickingInfo] = useState<PickingInfo>();

  const [initialViewState, setInitialViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 1,
    bearing: 0,
    pitch: 0,
  });

  const clickToFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev);
  }, []);

  const clickToSatellite = useCallback(() => {
    setIsSatellite((prev) => !prev);
  }, []);

  const onMapClick = useCallback((pickingInfo: PickingInfo) => {
    setPickingInfo(pickingInfo);
  }, []);

  const onSuggestedDepotMarkerClick: OnSuggestedDepotMarkerClick = useCallback(
    (suggestedDepotMarker: SuggestedDepotMarker) => {
      setSuggestedDepotMarker(suggestedDepotMarker);
      setExistingDepotMarker(undefined);
      setPickingInfo(undefined);
      onSuggestDepot(suggestedDepotMarker.homeBase);
    },
    [onSuggestDepot],
  );

  const onExistingDepotMarkerClick: OnExistingDepotMarkerClick = useCallback(
    (existingDepotMarker: ExistingDepotMarker) => {
      setExistingDepotMarker(existingDepotMarker);
      setSuggestedDepotMarker(undefined);
      setPickingInfo(undefined);
    },
    [],
  );

  const onHideMarkerPopover = useCallback(() => {
    setSuggestedDepotMarker(undefined);
    setExistingDepotMarker(undefined);
  }, []);

  const onViewStateChange = useCallback(() => {
    setSuggestedDepotMarker(undefined);
    setExistingDepotMarker(undefined);
    setPickingInfo(undefined);
  }, []);

  const onOpenSuggestedDepotPopover = useCallback(
    (suggestedDepotMarker: SuggestedDepotMarker) => {
      // Set timeout to avoid clickOutsideEvent taking over and closing the popup
      setTimeout(() => {
        onSuggestedDepotMarkerClick(suggestedDepotMarker);
      }, 50);
    },
    [onSuggestedDepotMarkerClick],
  );

  const onOpenExistingDepotPopover = useCallback(
    (existingDepotMarker: ExistingDepotMarker) => {
      // Set timeout to avoid clickOutsideEvent taking over and closing the popup
      setTimeout(() => {
        onExistingDepotMarkerClick(existingDepotMarker);
      }, 50);
    },
    [onExistingDepotMarkerClick],
  );

  const layers = useLayers({ depots, homeBases, onOpenExistingDepotPopover, onOpenSuggestedDepotPopover });

  const locations = useLocations(homeBases, depots);

  useLookAtLocations({ locations, wrapperRef, isFullScreen, setInitialViewState });

  const pickingInfoDelayed = usePickingInfoDelayed({ pickingInfo });

  const mapStyle = useMemo(() => (isSatellite ? MAPBOX_STYLE_SATELLITE : MAPBOX_STYLE_DARK), [isSatellite]);

  const activePopover = useMemo(() => {
    let result;

    if (existingDepotMarker) {
      result = (
        <ExistingDepotPopover
          solutionId={solutionId}
          existingDepotMarker={existingDepotMarker}
          onHideMarkerPopover={onHideMarkerPopover}
        />
      );
    } else if (suggestedDepotMarker) {
      result = (
        <SuggestedDepotPopover
          suggestedDepotMarker={suggestedDepotMarker}
          onShowDepotForm={onShowDepotForm}
          onSelectHomeBase={onSuggestDepot}
          onHideMarkerPopover={onHideMarkerPopover}
        />
      );
    } else if (pickingInfoDelayed) {
      result = (
        <AddNewDepotPopoverMenu
          pickingInfo={pickingInfoDelayed}
          onShowDepotForm={onShowDepotForm}
          onSelectHomeBase={onSuggestDepot}
          onHideMarkerPopover={onHideMarkerPopover}
        />
      );
    }

    return result;
  }, [
    existingDepotMarker,
    suggestedDepotMarker,
    pickingInfoDelayed,
    solutionId,
    onHideMarkerPopover,
    onShowDepotForm,
    onSuggestDepot,
  ]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(classes['depots-map'], className, {
        [classes['full-screen']]: isFullScreen,
      })}
    >
      <div className={classes['top-right-actions']}>
        <ToggleSatelliteMap isSatellite={isSatellite} clickToSatellite={clickToSatellite} />
        <ToggleFullScreenMap
          isSatellite={isSatellite}
          isFullScreen={isFullScreen}
          clickToFullScreen={clickToFullScreen}
        />
      </div>
      {activePopover}
      <DeckGL
        layers={layers}
        onClick={onMapClick}
        onViewStateChange={onViewStateChange}
        controller={{
          dragPan: true,
          doubleClickZoom: false,
          scrollZoom: true,
          keyboard: false,
        }}
        initialViewState={{
          ...initialViewState,
          transitionDuration: 200,
          transitionInterpolator: new FlyToInterpolator(),
        }}
      >
        <Map
          ref={mapRef}
          mapboxAccessToken={MAPBOX_API_KEY}
          mapStyle={mapStyle}
          // @ts-expect-error mercator projection is missing in type
          projection="mercator"
        />
      </DeckGL>
    </div>
  );
};

export default DepotsMap;
