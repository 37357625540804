import useDatadogLogInfo from 'features/datadog/useDatadogLogInfo';
import { MULTIPLE_VALUES_SEPARATOR } from 'app/config';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  filterParams: any;
  filterKey: string;
  selectedValues: string[];
}

const useCheckboxChange = ({ filterParams, filterKey, selectedValues }: Props) => {
  const [, setSearchParams] = useSearchParams();

  const onChangeLogInfo = useDatadogLogInfo();

  return useCallback(
    (checked: boolean, value: string) => {
      const newFilterParams = {
        ...filterParams,
        [filterKey]: checked
          ? [...new Set(selectedValues).add(value)].join(MULTIPLE_VALUES_SEPARATOR)
          : selectedValues
              .filter((selectedCandidateType: string) => selectedCandidateType !== value)
              .join(MULTIPLE_VALUES_SEPARATOR),
      };
      setSearchParams(newFilterParams);
      onChangeLogInfo({
        logText: 'Filter updated',
        logObject: newFilterParams,
      });
    },
    [setSearchParams, filterKey, filterParams, selectedValues, onChangeLogInfo],
  );
};

export default useCheckboxChange;
