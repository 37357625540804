import { CandidateTabs } from '..';
import { TInlineTabs } from '@optimization/ssi-common';
import { useEffect } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';

interface Props {
  tabs: TInlineTabs<CandidateTabs>;
  tabIndex: number;
  vehicle: VehicleEnhanced;
  enableYearSelectorForChargingEvents: boolean;
  setDepotYear: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useResetDepotYear = ({ tabs, tabIndex, vehicle, enableYearSelectorForChargingEvents, setDepotYear }: Props) => {
  useEffect(() => {
    if (tabs[tabIndex].name !== 'Charging events' && enableYearSelectorForChargingEvents) {
      setDepotYear(vehicle.ExecutedVehicleProduct.PerformanceStep);
    }
  }, [tabs, tabIndex, vehicle, enableYearSelectorForChargingEvents, setDepotYear]);

  useEffect(() => {
    setDepotYear(vehicle.enhanced.vehicleDepotYear);
  }, [vehicle.enhanced.vehicleDepotYear, setDepotYear]);
};

export default useResetDepotYear;
