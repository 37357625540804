import classes from './style.module.scss';
import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  style?: CSSProperties;
  isHighlighted?: boolean;
}

const Card = ({ children, className, innerClassName = 'px-spacing-16 py-spacing-16', style, isHighlighted }: Props) => (
  <article className={classNames(classes.card, { [classes.highlighted]: isHighlighted }, className)} style={style}>
    <div className={innerClassName}>{children}</div>
  </article>
);

export default Card;
