import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  selectedCandidates: string[];
  vehicles?: ListVehicleTransformed[];
}

const useHighestDailyDistanceFromSelectedCandidates = ({ selectedCandidates, vehicles }: Props): number =>
  useMemo(() => {
    const selectedVehicles = vehicles?.filter((vehicle) => selectedCandidates.includes(vehicle.Id.toString()));

    if (selectedVehicles && selectedVehicles?.length > 0) {
      const dailyDistances = selectedVehicles
        .map((vehicle) => Number(vehicle.DailyOdometerMedianKm))
        .filter((value) => value);

      const highestDailyDistance = Math.max(...dailyDistances);

      return highestDailyDistance;
    }

    return 0;
  }, [selectedCandidates, vehicles]);

export default useHighestDailyDistanceFromSelectedCandidates;
