import { EcItem, SetEcItem } from './types';
import { PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  ecItems: EcItem[];
  calculatePtoInstant?: () => void;
  calculatePtoDelayed?: () => void;
  setEcItems: (value: React.SetStateAction<EcItem[]>) => void;
}

const useSetEcItem = ({ ecItems, calculatePtoInstant, calculatePtoDelayed, setEcItems }: Props): SetEcItem =>
  useCallback(
    ({ type, value, intensity }: { type: PtoType; value: string; intensity: 'delayed' | 'instant' }) => {
      const existingEcItem = ecItems.find((ecItem) => ecItem.Type === type);

      if (existingEcItem) {
        setEcItems(
          ecItems.map((ecItem) =>
            ecItem === existingEcItem
              ? {
                  ...ecItem,
                  Value: value,
                }
              : ecItem,
          ),
        );
      } else {
        setEcItems([
          ...ecItems,
          {
            Type: type,
            Value: value,
            ElectricalConsumption: 0,
          },
        ]);
      }

      if (intensity === 'instant') {
        if (calculatePtoInstant) {
          calculatePtoInstant();
        }
      } else {
        if (calculatePtoDelayed) {
          calculatePtoDelayed();
        }
      }
    },
    [ecItems, calculatePtoInstant, calculatePtoDelayed, setEcItems],
  );

export default useSetEcItem;
