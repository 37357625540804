import classes from './style.module.scss';
import classNames from 'classnames';
import GetStops from './GetStops';
import GetStopsStatus from './GetStopsStatus';
import PlaceLocation from './PlaceLocation';
import Settings from './Settings';
import useChassisNumbers from 'features/candidate/Candidates/utils/useChassisNumbers';
import useDeleteLocation from './utils/useDeleteLocation';
import useInitStopsCallback from './utils/useInitStopsCallback';
import useLoadingText from 'common/hooks/useLoadingText';
import useSaveLocation from './utils/useSaveLocation';
import useStops from './utils/useStops';
import useTrips from './utils/useTrips';
import useVehicleNamesByChassisNumbers from './utils/useVehicleNamesByChassisNumbersV2';
import { formatDateInYearMonthDay, Loading } from '@optimization/ssi-common';
import { MAPBOX_API_KEY } from 'app/config';
import { MapRef } from 'react-map-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetStopsMetadataMutation } from 'app/services/solution';
import {
  MapLocationV2VM,
  MapLocationName,
  MapLocationType,
  ListVehicleEnhanced,
  OnMarkerClick,
  SelectedMarker,
  ToggleFullScreenMap,
  MapMedianStopTime,
  Mapbox,
  ToggleSatelliteMap,
  SelectedMarkerPopover,
  Stops,
  Trips,
} from '@optimization/sa-common';
import useInitTripsCallback from './utils/useInitTripsCallback';
import GetTripsStatus from './GetTripsStatus';
import GetTrips from './GetTrips';

export interface GetStopsParams {
  startDate: string;
  endDate: string;
  minDuration: string;
  maxDuration: string;
  resolution: number;
}
export interface GetTripsParams extends GetStopsParams {}

export type TopRightAction = 'median-stop-time' | 'place-location';

export interface StopStatistics {
  numberOfStops: number;
  medianStopDuration: {
    min: number;
    max: number;
  };
}

interface Props {
  className?: string;
  solutionId: string;
  mapLocation?: MapLocationV2VM;
  enhancedVehicles?: ListVehicleEnhanced[];
  mapHeight?: string;
  mapLocationName?: MapLocationName;
  mapLocationType?: MapLocationType;
  setStopStatistics?: (stopStatistics: StopStatistics) => void;
  setStopsIsLoading?: (isLoading: boolean) => void;
}

const HistoricalStops = ({
  className,
  solutionId,
  mapLocation,
  enhancedVehicles = [],
  mapHeight,
  mapLocationName = 'vehicle-candidate',
  mapLocationType = MapLocationType.Vehicle,
  setStopStatistics,
  setStopsIsLoading,
}: Props) => {
  const mapRef = useRef<MapRef | null>(null);
  const stopsQueryIdRef = useRef({ queryId: '' });
  const tripsQueryIdRef = useRef({ queryId: '' });

  const [getStopsMetadata, getStopsMetadataState] = useGetStopsMetadataMutation();

  const [stopsStatusState, setStopsStatusState] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  const [readyToGetStops, setReadyToGetStops] = useState(false);
  const [stopsData, setStopsData] = useState<Stops>();
  const [stopsState, setStopsState] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  const [tripsStatusState, setTripsStatusState] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  const [readyToGetTrips, setReadyToGetTrips] = useState(false);
  const [tripsData, setTripsData] = useState<Trips>();
  const [tripsState, setTripsState] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  // useRef for params to make debouncing work correctly
  const getStopsParamsRef = useRef<GetStopsParams>({
    startDate: '',
    endDate: '',
    minDuration: '',
    maxDuration: '',
    resolution: 0,
  });

  useEffect(() => {
    if (mapLocation) {
      getStopsParamsRef.current.startDate = formatDateInYearMonthDay(new Date(mapLocation.StartDate));
      getStopsParamsRef.current.endDate = formatDateInYearMonthDay(new Date(mapLocation.EndDate));
      getStopsParamsRef.current.minDuration = parseInt(`${mapLocation.MinDuration / 60}`).toString();
      getStopsParamsRef.current.maxDuration = parseInt(`${mapLocation.MaxDuration / 60}`).toString();
      getStopsParamsRef.current.resolution = mapLocation.Resolution;

      setGetStopsCount((prev) => prev + 1);
    } else if (getStopsMetadataState.isSuccess) {
      getStopsParamsRef.current.startDate = formatDateInYearMonthDay(
        new Date(getStopsMetadataState.data.OldestDateTime),
      );
      getStopsParamsRef.current.endDate = formatDateInYearMonthDay(new Date(getStopsMetadataState.data.NewestDateTime));
      getStopsParamsRef.current.minDuration = parseInt(`${getStopsMetadataState.data.MinDuration / 60}`).toString();
      getStopsParamsRef.current.maxDuration = parseInt(`${getStopsMetadataState.data.MaxDuration / 60}`).toString();
      getStopsParamsRef.current.resolution = getStopsMetadataState.data.DefaultResolution;

      setGetStopsCount((prev) => prev + 1);
    }
  }, [getStopsMetadataState, mapLocation]);

  const vehicleIds = useMemo(() => enhancedVehicles.map((enhancedVehicle) => enhancedVehicle.Id), [enhancedVehicles]);

  const chassisNumbers = useChassisNumbers(enhancedVehicles);
  const vehicleNames = useVehicleNamesByChassisNumbers(enhancedVehicles);

  const [getStopsCount, setGetStopsCount] = useState(0);
  const [getStopsDelay, setGetStopsDelay] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isSatellite, setIsSatellite] = useState(false);

  const [showTrips, setShowTrips] = useState(mapLocation ? mapLocation.ShowTrips : true);

  const [showStops, setShowStops] = useState(mapLocation ? mapLocation.ShowStops : true);

  const [activeTopRightAction, setActiveTopRightAction] = useState<TopRightAction>();
  const [showSettings, setShowSettings] = useState(false);

  const onChangeTrips = useCallback((checked: boolean) => {
    setShowTrips(checked);
  }, []);

  const onChangeStops = useCallback((checked: boolean) => {
    setShowStops(checked);
  }, []);

  const onShowSettings = useCallback(() => {
    setShowSettings(true);
  }, []);

  const onHideSettings = useCallback(() => {
    setShowSettings(false);
  }, []);

  const togglePlaceLocation = useCallback(() => {
    if (activeTopRightAction === 'place-location') {
      setActiveTopRightAction(undefined);
    } else {
      setActiveTopRightAction('place-location');
    }
  }, [activeTopRightAction]);

  const toggleMedianStopTime = useCallback(() => {
    if (activeTopRightAction === 'median-stop-time') {
      setActiveTopRightAction(undefined);
    } else {
      setActiveTopRightAction('median-stop-time');
    }
  }, [activeTopRightAction]);

  const onMarkerClick: OnMarkerClick = useCallback((selectedMarker) => {
    setSelectedMarker(selectedMarker);
  }, []);

  const onHideMarkerInfo = useCallback(() => {
    setSelectedMarker(undefined);
  }, []);

  const { saveLocation, saveLocationState } = useSaveLocation({
    mapRef,
    solutionId,
    vehicleIds,
    getStopsParamsRef,
    showTrips,
    showStops,
    mapLocation,
    mapLocationName,
    mapLocationType,
    mapProvider: 'MapBox',
  });

  const { deleteLocation, deleteLocationState } = useDeleteLocation({
    solutionId,
    vehicleIds,
  });

  const getStopsDelayed = useCallback((getStopsParams: Object = {}) => {
    setGetStopsDelay(1500);
    setGetStopsCount((prev) => prev + 1);
    getStopsParamsRef.current = {
      ...getStopsParamsRef.current,
      ...getStopsParams,
    };
  }, []);

  const { initTripsCallback, initTripsState } = useInitTripsCallback({
    chassisNumbers: chassisNumbers,
    getTripsParamsRef: getStopsParamsRef,
    solutionId: solutionId,
  });

  useEffect(() => {
    if (initTripsState.data?.QueryId) {
      setReadyToGetTrips(false);
      tripsQueryIdRef.current.queryId = initTripsState.data?.QueryId;
    }
  }, [initTripsState.data?.QueryId]);

  const { initStopsCallback, initStopsState } = useInitStopsCallback({
    chassisNumbers,
    getStopsParamsRef,
    solutionId,
  });

  useEffect(() => {
    if (initStopsState.data?.QueryId) {
      setReadyToGetStops(false);
      stopsQueryIdRef.current.queryId = initStopsState.data?.QueryId;
    }
  }, [initStopsState.data?.QueryId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (getStopsCount) {
        initStopsCallback();
        initTripsCallback();
      }
    }, getStopsDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [initStopsCallback, initTripsCallback, getStopsCount, getStopsDelay]);

  const stops = useStops({
    stopAggregates: stopsData?.StopAggregates,
    run: stopsState.isSuccess && showStops,
  });

  const trips = useTrips({
    tripAggregates: tripsData?.TripAggregates,
    run: tripsState.isSuccess && showTrips,
  });

  useEffect(() => {
    if (setStopsIsLoading) {
      setStopsIsLoading(stopsState.isLoading);
    }
  }, [setStopsIsLoading, stopsState.isLoading]);

  useEffect(() => {
    if (setStopStatistics && stopsState.isSuccess) {
      const medianStopDurations = stops.map((stop) => stop.medianStopDuration);

      setStopStatistics({
        numberOfStops: stops.reduce((acc, stop) => acc + stop.stopCount, 0),
        medianStopDuration: {
          min: Math.min(...medianStopDurations),
          max: Math.max(...medianStopDurations),
        },
      });
    }
  }, [stops, setStopStatistics, stopsState]);

  useEffect(() => {
    if (chassisNumbers.length) {
      getStopsMetadata({ solutionId, ChassisNumbers: chassisNumbers });
    }
  }, [solutionId, chassisNumbers, getStopsMetadata]);

  const placeLocationEnabled = useMemo(() => vehicleIds.length > 0, [vehicleIds]);

  const clickToFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev);
  }, []);

  const clickToSatellite = useCallback(() => {
    setIsSatellite((prev) => !prev);
  }, []);

  const isLoading =
    deleteLocationState.isLoading ||
    getStopsMetadataState.isLoading ||
    initStopsState.isLoading ||
    saveLocationState.isLoading ||
    stopsStatusState.isLoading ||
    stopsState.isLoading ||
    tripsStatusState.isLoading ||
    tripsState.isLoading;

  const isError =
    deleteLocationState.isError ||
    getStopsMetadataState.isError ||
    initStopsState.isError ||
    saveLocationState.isError ||
    stopsStatusState.isError ||
    tripsStatusState.isError;

  const loadingText = useLoadingText({
    isDeletingMapLocation: deleteLocationState.isLoading,
    isSavingMapLocation: saveLocationState.isLoading,
    isLoadingStops:
      getStopsMetadataState.isLoading || initStopsState.isLoading || stopsStatusState.isLoading || stopsState.isLoading,
    isLoadingTrips: initTripsState.isLoading || tripsStatusState.isLoading || tripsState.isLoading,
  });

  return (
    <div className={className} style={{ height: mapHeight }}>
      {initStopsState.data?.QueryId && (
        <GetStopsStatus
          solutionId={solutionId}
          key={initStopsState.data.QueryId}
          queryId={initStopsState.data.QueryId}
          queryIdRef={stopsQueryIdRef}
          setReadyToGetStops={setReadyToGetStops}
          setStopsStatusState={setStopsStatusState}
        />
      )}
      {initStopsState.data?.QueryId && readyToGetStops && (
        <GetStops
          solutionId={solutionId}
          queryId={initStopsState.data.QueryId}
          setStopsData={setStopsData}
          setStopsState={setStopsState}
        />
      )}
      {initTripsState.data?.QueryId && (
        <GetTripsStatus
          solutionId={solutionId}
          key={initTripsState.data.QueryId}
          queryId={initTripsState.data.QueryId}
          queryIdRef={tripsQueryIdRef}
          setReadyToGetTrips={setReadyToGetTrips}
          setTripsStatusState={setTripsStatusState}
        />
      )}
      {initTripsState.data?.QueryId && readyToGetTrips && (
        <GetTrips
          solutionId={solutionId}
          queryId={initTripsState.data.QueryId}
          setTripsData={setTripsData}
          setTripsState={setTripsState}
        />
      )}
      <div
        className={classNames(classes['historical-stops'], className, {
          [classes['full-screen']]: isFullScreen,
        })}
      >
        <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
        {selectedMarker && (
          <SelectedMarkerPopover
            selectedMarker={selectedMarker}
            onHideMarkerInfo={onHideMarkerInfo}
            vehicleNames={vehicleNames}
          />
        )}
        {getStopsMetadataState.data && (
          <Settings
            isFullScreen={isFullScreen}
            showSettings={showSettings}
            showTrips={showTrips}
            showStops={showStops}
            disableStops={stopsState.isError}
            disableTrips={tripsState.isError}
            getStopsParams={getStopsParamsRef.current}
            stopsMetadata={getStopsMetadataState.data}
            getStopsCount={getStopsCount}
            onShowSettings={onShowSettings}
            onHideSettings={onHideSettings}
            onChangeTrips={onChangeTrips}
            onChangeStops={onChangeStops}
            getStopsDelayed={getStopsDelayed}
          />
        )}
        <div className={classes['top-right-actions']}>
          <MapMedianStopTime
            isSatellite={isSatellite}
            activeTopRightAction={activeTopRightAction}
            toggleMedianStopTime={toggleMedianStopTime}
          />
          {placeLocationEnabled && (
            <PlaceLocation
              mapLocation={mapLocation}
              mapLocationName={mapLocationName}
              activeTopRightAction={activeTopRightAction}
              togglePlaceLocation={togglePlaceLocation}
              saveLocation={saveLocation}
              deleteLocation={deleteLocation}
              isLoading={saveLocationState.isLoading || deleteLocationState.isLoading}
            />
          )}
          <ToggleSatelliteMap isSatellite={isSatellite} clickToSatellite={clickToSatellite} />
          <ToggleFullScreenMap
            isSatellite={isSatellite}
            isFullScreen={isFullScreen}
            clickToFullScreen={clickToFullScreen}
          />
        </div>
        <Mapbox
          isSatellite={isSatellite}
          mapLocation={mapLocation}
          mapRef={mapRef}
          stops={stops}
          trips={trips}
          mapboxAccessToken={MAPBOX_API_KEY}
          onMarkerClick={onMarkerClick}
        />
      </div>
    </div>
  );
};

export default HistoricalStops;
