import ActionLayout from 'common/components/ActionLayout';
import Candidates from 'features/candidate/Candidates';
import CandidatesFilterSelector from 'features/candidate/Candidates/Filter/Selector';
import CandidatesFilterToggle from 'features/candidate/Candidates/Filter/Toggle';
import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import SolutionPageHeader from 'features/solution/utils/SolutionPageHeader';
import TotalCount from 'common/components/TotalCount';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useSolutionHasVehicles from '../utils/useSolutionHasVehicles';
import { BackLink, useVehicleSettingsHandler } from '@optimization/sa-common';
import { Button, ButtonLink, invariant, Loading, useToast } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { PRESENTATION_APP_URL } from 'app/config';
import { useCallback, useMemo, useRef } from 'react';
import { useGetSolutionQuery } from 'app/services/solution';
import { useLocation, useParams } from 'react-router-dom';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';

const SolutionPresentationConfigurator = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const showToast = useToast();

  const actionLayoutRef = useRef<HTMLDivElement | null>(null);

  const solutionQuery = useGetSolutionQuery(solutionId);
  const solutionHasVehicles = useSolutionHasVehicles({ solutionId });

  const location = useLocation();

  const url = useMemo(
    () => `${PRESENTATION_APP_URL}/solution/${solutionQuery.data?.Id}/presentation/home${location.search}`,
    [solutionQuery.data, location],
  );

  const onShareLink = useCallback(() => {
    navigator.clipboard.writeText(url);
    showToast({ header: 'Solution presentation link was copied' });
  }, [showToast, url]);

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    includeCandidateWithNonExecutedVehicleProduct: true,
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
  });

  useMakeElementsSticky({
    actionLayoutRef,
  });

  const isLoading = solutionQuery.isFetching || candidatesHandler.isLoading;
  const isError = solutionQuery.isError || candidatesHandler.isError;
  const isSuccess = solutionQuery.isSuccess && candidatesHandler.isSuccess;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates: candidatesHandler.isLoading,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className="tds-container">
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <HelmetTitle solutionId={solutionId} items={['Solution presentation configurator']} />
      {isSuccess && (
        <>
          <BackLink url="/" className="mb-spacing-64">
            All solutions
          </BackLink>
          <SolutionPageHeader
            solutionName={solutionQuery.data.Name}
            intro="Presentation configurator"
            description={[
              'Filter your presentation by selecting the content and performance steps you wish to showcase in the customer view. For now, kindly utilize your global account for authentication when accessing the presentation.',
            ]}
            cta={
              <div className="flex">
                <ButtonLink
                  text="Go to presentation"
                  url={url}
                  variant="primary"
                  size="md"
                  icon="arrow_right"
                  dataTestid="button-go-to-presentation"
                  iconSize={20}
                  target="_blank"
                />
                <Button
                  text="Copy"
                  variant="secondary"
                  className="ml-spacing-16"
                  icon="link"
                  iconSize={20}
                  onClick={onShareLink}
                />
              </div>
            }
          >
            {solutionQuery.data.transformed.solutionNamePresentation}
          </SolutionPageHeader>
          {solutionHasVehicles && (
            <ActionLayout
              actionLayoutRef={actionLayoutRef}
              className="mt-spacing-40"
              left={
                <TotalCount
                  count={candidatesHandler.filteredCandidates?.length}
                  entityName="VEHICLE CANDIDATE"
                  extraText="INCLUDED IN PRESENTATION"
                />
              }
              right={<CandidatesFilterToggle candidatesHandler={candidatesHandler} />}
              below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
            />
          )}
          <Candidates
            solutionId={solutionId}
            candidatesHandler={candidatesHandler}
            vehicleSettingsHandler={vehicleSettingsHandler}
            itemsPerPage={100}
          />
        </>
      )}
    </div>
  );
};

export default SolutionPresentationConfigurator;
