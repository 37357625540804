import { useGetDepotsQuery } from 'app/services/solution';
import { useMemo } from 'react';

interface Props {
  solutionId: string;
}

const useSolutionHasDepots = ({ solutionId }: Props): boolean => {
  const depotsQuery = useGetDepotsQuery(solutionId);

  return useMemo(() => Boolean(depotsQuery.data && depotsQuery.data.length > 0), [depotsQuery]);
};

export default useSolutionHasDepots;
