import { InfrastructureProductV2VM, PowerDeliveryMode } from '@optimization/sa-common';

export const infrastructureProductDefault: Omit<InfrastructureProductV2VM, 'Id' | 'RequiredAt'> = {
  Brand: '20300A',
  Solution: '20301A',
  Power: '20305L',
  Outlets: '20308H',
  Quantity: 1,
  PowerDeliveryMode: PowerDeliveryMode.Democratic,
  InfrastructureProductInstances: [],
  TotalPowerBlocks: 0,
};

export const defineChargingEventsInfo = {
  header: 'Define daily charging events',
  description: 'Expand vehicle candidates in order to create charging events for the selected year',
};

export const mapVehiclesToDepotInfo = {
  header: 'Map vehicles to depot',
  description: 'Select the vehicle candidates you would like to map',
};

export const manageDepotInfrastructureInfoDepot = {
  header: 'Manage depot infrastructure',
  description: 'Define chargers to meet the daily power and connectivity demand for all vehicles during the year.',
};

export const manageDepotInfrastructureInfoWizard = {
  header: 'Manage depot infrastructure',
  description: 'Define chargers to meet the daily power and connectivity demand for all vehicles and years.',
};

export const occupancyInfo = {
  header: 'Occupancy',
  description: 'Visual overview of charging vehicles',
};
