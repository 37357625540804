import { EventType } from '@azure/msal-browser';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const useNavigateToRootOnLoginSuccess = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        navigate('/');
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, navigate]);
};

export default useNavigateToRootOnLoginSuccess;
