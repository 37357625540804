import { Dropdown } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils//vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  disabled?: boolean;
  vehicleOptionsHandler: VehicleOptionsHandler;
}

const TruckFields = ({ disabled, vehicleOptionsHandler }: Props) => {
  const {
    truckPortfolioOptions,
    truckBatteryOptions,
    truckSocOptions,
    truckEngineGearBoxOptions,
    truckChassisAdaptationOptions,
    truckWheelConfigurationOptions,
    truckAxleDistanceOptions,
    truckChassisHeightOptions,
    truckCabTypeOptions,
    selections,
    truckBatteryDisabled,
    truckSocDisabled,
    truckEngineGearBoxDisabled,
    truckChassisAdaptationDisabled,
    truckWheelConfigurationDisabled,
    truckAxleDistanceDisabled,
    truckChassisHeightDisabled,
    truckCabTypeDisabled,
    handleSelection,
  } = vehicleOptionsHandler;

  const handleTruckPortfolioChange = useCallback(
    (value: string) => handleSelection('truckPortfolio', value),
    [handleSelection],
  );

  const handleTruckBatteryChange = useCallback(
    (value: string) => handleSelection('truckBattery', value),
    [handleSelection],
  );

  const handleTruckSocChange = useCallback(
    (value: string) => handleSelection('truckSocWindow', value),
    [handleSelection],
  );

  const handleTruckElectricMotorChange = useCallback(
    (value: string) => handleSelection('truckElectricMotor', value),
    [handleSelection],
  );

  const handleTruckChassisAdaptationChange = useCallback(
    (value: string) => handleSelection('truckChassisAdaptation', value),
    [handleSelection],
  );

  const handleTruckWheelConfigurationChange = useCallback(
    (value: string) => handleSelection('truckWheelConfiguration', value),
    [handleSelection],
  );

  const handleTruckAxleDistanceChange = useCallback(
    (value: string) => handleSelection('truckAxleDistance', value),
    [handleSelection],
  );

  const handleTruckChassisHeightChange = useCallback(
    (value: string) => handleSelection('truckChassisHeight', value),
    [handleSelection],
  );

  const handleTruckCabTypeChange = useCallback(
    (value: string) => handleSelection('truckCabType', value),
    [handleSelection],
  );

  return (
    <>
      <div>
        <Dropdown
          value={selections.truckPortfolio}
          label="Product portfolio"
          labelPosition="outside"
          placeholder="Product portfolio"
          modeVariant="secondary"
          disabled={disabled}
          options={truckPortfolioOptions}
          dataTestid="dropdown-configure-vehicle-product-portfolio"
          onChange={handleTruckPortfolioChange}
        />
        <Dropdown
          value={selections.truckBattery}
          label="Battery"
          placeholder="Battery"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-battery"
          modeVariant="secondary"
          options={truckBatteryOptions}
          disabled={truckBatteryDisabled || disabled}
          onChange={handleTruckBatteryChange}
        />
        <Dropdown
          value={selections.truckSocWindow}
          label="SoC window"
          labelPosition="outside"
          placeholder="SoC window"
          dataTestid="dropdown-configure-vehicle-product-soc-window"
          modeVariant="secondary"
          options={truckSocOptions}
          disabled={truckSocDisabled || disabled}
          onChange={handleTruckSocChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.truckElectricMotor}
          label="Electric motor"
          labelPosition="outside"
          placeholder="Electric motor"
          dataTestid="dropdown-configure-vehicle-product-electric-motor"
          modeVariant="secondary"
          options={truckEngineGearBoxOptions}
          disabled={truckEngineGearBoxDisabled || disabled}
          onChange={handleTruckElectricMotorChange}
        />
        <Dropdown
          value={selections.truckChassisAdaptation}
          label="Chassis adaptation"
          placeholder="Chassis adaptation"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-chassis-adaptation"
          modeVariant="secondary"
          options={truckChassisAdaptationOptions}
          disabled={truckChassisAdaptationDisabled || disabled}
          onChange={handleTruckChassisAdaptationChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.truckWheelConfiguration}
          label="Wheel configuration"
          placeholder="Wheel configuration"
          labelPosition="outside"
          disabled={truckWheelConfigurationDisabled || disabled}
          options={truckWheelConfigurationOptions}
          dataTestid="dropdown-configure-vehicle-product-wheel-configuration"
          modeVariant="secondary"
          onChange={handleTruckWheelConfigurationChange}
        />
        <Dropdown
          value={selections.truckAxleDistance}
          label="Axle distance"
          placeholder="Axle distance"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-axle-distance"
          modeVariant="secondary"
          options={truckAxleDistanceOptions}
          disabled={truckAxleDistanceDisabled || disabled}
          onChange={handleTruckAxleDistanceChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.truckChassisHeight}
          label="Chassis height"
          placeholder="Chassis height"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-chassis-height"
          modeVariant="secondary"
          options={truckChassisHeightOptions}
          disabled={truckChassisHeightDisabled || disabled}
          onChange={handleTruckChassisHeightChange}
        />
        <Dropdown
          value={selections.truckCabType}
          label="Cab type"
          placeholder="Cab type"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-cab-type"
          modeVariant="secondary"
          options={truckCabTypeOptions}
          disabled={truckCabTypeDisabled || disabled}
          onChange={handleTruckCabTypeChange}
        />
      </div>
    </>
  );
};

export default TruckFields;
