import { ModeType } from '..';
import { useMemo } from 'react';

const manageSolutionOptions = [
  {
    dataTestid: 'button-delete-solutions-action-menu-item',
    name: 'Delete solutions',
    value: 'delete',
  },
];

interface Props {
  setSelectedMode: React.Dispatch<React.SetStateAction<ModeType | undefined>>;
  setShowActionMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const useManageSolutionPopoverOptions = ({
  setSelectedMode,
  setShowActionMenu,
}: Props): {
  name: string;
  dataTestid: string;
  onClick: () => void;
}[] =>
  useMemo(
    () =>
      manageSolutionOptions.map((option) => ({
        name: option.name,
        dataTestid: option.dataTestid,
        onClick: () => {
          setSelectedMode(option.value as ModeType);
          setShowActionMenu(false);
        },
      })),
    [setSelectedMode, setShowActionMenu],
  );

export default useManageSolutionPopoverOptions;
