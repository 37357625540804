import classes from './style.module.scss';
import classNames from 'classnames';
import { Dropdown, useObjectToDropdownOptions } from '@optimization/ssi-common';
import { temperatures, temperaturesToValue } from 'common/utils/translate';
import { topographyTranslate, trafficTranslate } from '@optimization/sa-common';
import { useCallback, useMemo } from 'react';

interface Props {
  className?: string;
  disabled: boolean;
  setUserFactors: React.Dispatch<
    React.SetStateAction<{
      climate: string;
      topography: string;
      traffic: string;
    }>
  >;
}

const UserFactors = ({ className, disabled, setUserFactors }: Props) => {
  const temperatureOptions = useMemo(
    () =>
      temperatures.map((temperature) => ({
        name: `${temperature.name} (${temperature.winter},${' '}${temperature.summer})`,
        value: temperaturesToValue(temperature.winter, temperature.summer),
      })),
    [],
  );

  const topographyOptions = useObjectToDropdownOptions({
    object: topographyTranslate,
  });

  const trafficOptions = useObjectToDropdownOptions({
    object: trafficTranslate,
  });

  const handleClimateChange = useCallback(
    (value: string) => {
      setUserFactors((prev) => ({ ...prev, climate: value }));
    },
    [setUserFactors],
  );

  const handleTopographyChange = useCallback(
    (value: string) => {
      setUserFactors((prev) => ({ ...prev, topography: value }));
    },
    [setUserFactors],
  );

  const handleTrafficChange = useCallback(
    (value: string) => {
      setUserFactors((prev) => ({ ...prev, traffic: value }));
    },
    [setUserFactors],
  );

  return (
    <div className={classNames(className, classes.fields)}>
      <div>Climate</div>
      <Dropdown
        value=""
        placeholder="Select an option..."
        dataTestid="dropdown-define-manually-climate"
        disabled={disabled}
        options={temperatureOptions}
        onChange={handleClimateChange}
      />
      <div>Topography</div>
      <Dropdown
        value=""
        placeholder="Select an option..."
        dataTestid="dropdown-define-manually-topography"
        disabled={disabled}
        options={topographyOptions}
        onChange={handleTopographyChange}
      />
      <div>Traffic</div>
      <Dropdown
        value=""
        placeholder="Select an option..."
        dataTestid="dropdown-define-manually-traffic"
        disabled={disabled}
        options={trafficOptions}
        onChange={handleTrafficChange}
      />
    </div>
  );
};

export default UserFactors;
