import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { Dropdown, TextField, roundTo } from '@optimization/ssi-common';
import { FormValues, SetFormValue } from '../..';
import { useMemo } from 'react';
import { VehicleEnhanced, financialEvaluationVehicleTypeOptions } from '@optimization/sa-common';
import useCurrecyOptions from './useCurrencyOptions';

interface Props {
  disabled?: boolean;
  className?: string;
  vehicle: VehicleEnhanced;
  formValues: FormValues;
  setFormValue: SetFormValue;
}

const GeneralInfoFields = ({ className, vehicle, disabled, setFormValue, formValues }: Props) => {
  const currencyOptions = useCurrecyOptions();

  const onChangeBevCompare = useOnChangeFieldValue({
    attribute: 'bevEngineType',
    setFormValue,
  });

  const onChangeDieselCompare = useOnChangeFieldValue({
    attribute: 'comparisonEngineType',
    setFormValue,
  });

  const onChangeCurrency = useOnChangeFieldValue({
    attribute: 'currency',
    setFormValue,
  });

  const yearsOfOwnership = useMemo(
    () => vehicle.ExecutedVehicleProduct?.EightyPercentSoH ?? 0,

    [vehicle],
  );

  const bevOptions = useMemo(() => financialEvaluationVehicleTypeOptions.filter((x) => x.value === 'Bev'), []);
  const nonBevOptions = useMemo(() => financialEvaluationVehicleTypeOptions.filter((x) => x.value !== 'Bev'), []);
  const nonBevValue = useMemo(
    () => nonBevOptions.find((x) => x.value === formValues.comparisonEngineType),
    [formValues.comparisonEngineType, nonBevOptions],
  );

  return (
    <div className={classNames(classes['general-info-fields'], className)}>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Currency</div>
      <Dropdown
        className={classes['field-1-1']}
        placeholder="Select currency"
        size="lg"
        filter
        disabled={disabled}
        options={currencyOptions}
        value={formValues.currency}
        onChange={onChangeCurrency}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Daily distance</div>
      <TextField
        className={classes['field-2-1']}
        type="number"
        value={vehicle.ExecutedVehicleProduct.DailyOdometerMedianKm.toString()}
        size="lg"
        suffixText="KM"
        disabled
        noMinWidth
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Years of ownership</div>
      <TextField
        className={classes['field-3-1']}
        type="number"
        value={roundTo(yearsOfOwnership, 2).toString()}
        size="lg"
        suffixText="Years"
        disabled
        noMinWidth
        label={''}
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Compare vehicles</div>
      <Dropdown
        className={classes['field-4-1']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={bevOptions}
        value="Bev"
        onChange={onChangeBevCompare}
      />
      <Dropdown
        className={classes['field-4-2']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={nonBevOptions}
        value={nonBevValue?.value ?? ''}
        onChange={onChangeDieselCompare}
      />
    </div>
  );
};

export default GeneralInfoFields;
