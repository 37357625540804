import { PickingInfo } from 'deck.gl/typed';
import { PopoverMenu } from '@optimization/ssi-common';
import { useCallback, useMemo } from 'react';
import { HomeBase } from '@optimization/sa-common';
import { geoToH3 } from 'h3-js';

interface Props {
  pickingInfo: PickingInfo;
  onHideMarkerPopover: () => void;
  onShowDepotForm: () => void;
  onSelectHomeBase: (marker: HomeBase) => void;
}

const AddNewDepotPopoverMenu = ({
  pickingInfo,
  onHideMarkerPopover,
  onShowDepotForm,
  onSelectHomeBase: onSuggestDepot,
}: Props) => {
  const handleAddDepotClick = useCallback(() => {
    const coordinates = pickingInfo.coordinate;
    if (coordinates) {
      const h3Index = geoToH3(coordinates[1], coordinates[0], 10);
      onSuggestDepot({ H3CellIndex: h3Index, VehicleIds: [] });
    } else {
      console.error('Coordinates are undefined.');
    }
    onShowDepotForm();
  }, [onShowDepotForm, onSuggestDepot, pickingInfo.coordinate]);

  const options = useMemo(
    () => [
      {
        name: 'Add new depot',
        onClick: handleAddDepotClick,
      },
    ],
    [handleAddDepotClick],
  );

  console.log('pickingInfo: ');
  console.log(pickingInfo);
  return (
    <PopoverMenu
      show
      options={options}
      hidePopoverMenu={onHideMarkerPopover}
      style={{
        top: `${pickingInfo.pixel?.[1]}px`,
        left: `${pickingInfo.pixel?.[0]}px`,
      }}
    />
  );
};

export default AddNewDepotPopoverMenu;
