import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';

type DefineManuallyTabs = 'Vehicle configuration' | 'Operation';

const useTabs = () =>
  useMemo(() => {
    const inlineTabs: TInlineTabs<DefineManuallyTabs> = [];

    inlineTabs.push({
      name: 'Vehicle configuration',
    });

    inlineTabs.push({
      name: 'Operation',
    });

    return inlineTabs;
  }, []);

export default useTabs;
