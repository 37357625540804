import { Button, PopoverCanvas, Spinner } from '@optimization/ssi-common';
import { h3ToGeo } from 'h3-js';
import { SuggestedDepotMarker } from '../types';
import { useMemo } from 'react';
import { useReverseGeocodingQuery } from 'app/services/mapbox';
import { HomeBase } from '@optimization/sa-common';

interface Props {
  suggestedDepotMarker: SuggestedDepotMarker;
  onHideMarkerPopover: () => void;
  onShowDepotForm: () => void;
  onSelectHomeBase: (marker: HomeBase) => void;
}

const SuggestedDepotPopover = ({
  suggestedDepotMarker,
  onHideMarkerPopover,
  onShowDepotForm,
  onSelectHomeBase: onSuggestDepot,
}: Props) => {
  const [latitude, longitude] = useMemo(
    () => h3ToGeo(suggestedDepotMarker.homeBase.H3CellIndex),
    [suggestedDepotMarker],
  );

  const reverseGeocodingQuery = useReverseGeocodingQuery({ latitude, longitude });

  const place = useMemo(
    () => reverseGeocodingQuery.data?.features?.[0].properties.full_address,
    [reverseGeocodingQuery],
  );

  const handleAddDepotClick = () => {
    onSuggestDepot(suggestedDepotMarker.homeBase);
    onShowDepotForm();
  };

  return (
    <PopoverCanvas
      show
      hidePopoverCanvas={onHideMarkerPopover}
      style={{
        top: `${suggestedDepotMarker.position.y}px`,
        left: `${suggestedDepotMarker.position.x}px`,
        width: '275px',
      }}
    >
      <div className="flex justify-between">
        <div className="tds-detail-06">Identified depot</div>
        <Button variant="secondary" size="xs" text="Add depot" onClick={handleAddDepotClick} />
      </div>
      <div className="tds-headline-07 mt-spacing-16">
        {reverseGeocodingQuery.isLoading && <Spinner size="xs" />}
        {place && <div className="tds-headline-07">{place}</div>}
      </div>
      <div className="tds-detail-07 flex w-full justify-betweens mt-spacing-16">
        <div className="w-50-percent">Vehicles</div>
        <div className="w-50-percent">{suggestedDepotMarker.homeBase.VehicleIds.length}</div>
      </div>
    </PopoverCanvas>
  );
};

export default SuggestedDepotPopover;
