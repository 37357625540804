import { SolutionMetadataTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  splicedSolutions?: SolutionMetadataTransformed[];
  selectedSolutions: string[];
}

const useSelectedSolutionsById = ({
  splicedSolutions,
  selectedSolutions,
}: Props): { [key: string]: SolutionMetadataTransformed } =>
  useMemo(
    () =>
      splicedSolutions?.reduce(
        (acc, solution) => ({
          ...acc,
          [solution.Id.toString()]: selectedSolutions.includes(solution.Id.toString()),
        }),
        {},
      ) || {},
    [splicedSolutions, selectedSolutions],
  );

export default useSelectedSolutionsById;
