import classes from './style.module.scss';
import classNames from 'classnames';
import { KeyItem } from '.';
import { TdsIcon } from '@scania/tegel-react';
import { Tooltip } from '@optimization/ssi-common';
import { useRef } from 'react';

interface Props {
  isFull: boolean;
  item: KeyItem;
}

const DepotKeyValue = ({ isFull, item }: Props) => {
  const iconRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(classes['grid-item'], {
        [classes['is-full']]: isFull,
      })}
      key={item.header}
    >
      <div className={classes['depot-info']}>
        <div>
          <div className={classes['header-icon-marker']}>
            <div className={isFull ? 'tds-body-02' : 'tds-detail-02'}>{item.header}</div>
            {item.tooltip && (
              <>
                <div className={classes.icon} ref={iconRef}>
                  <TdsIcon name="info" size="14px" className="mr-spacing-6" />
                </div>
                <Tooltip elRef={iconRef}>{item.tooltip}</Tooltip>
              </>
            )}
            {item.hasMarker && <div className={classes.marker} />}
          </div>
          <div className="tds-detail-05 tds-text-grey-400">{item.unit}</div>
        </div>
        <div>
          <span className={isFull ? 'tds-headline-01' : 'tds-headline-02'}>{item.value.toLocaleString()}</span>
          {typeof item.total === 'number' && <span className="tds-paragraph-02"> / {item.total.toLocaleString()}</span>}
        </div>
      </div>
    </div>
  );
};

export default DepotKeyValue;
