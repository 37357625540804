import portfolioCheck from './portfolioCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';

const socsCheck = ({
  basicVehicle,
  selectedBattery,
  selectedPortfolio,
}: {
  basicVehicle: FactBasicTruck | FactBasicBus;
  selectedBattery: string;
  selectedPortfolio?: string;
}) => {
  const batteryIsMatching = basicVehicle.InstalledEnergyKWh.toString() === selectedBattery;

  let portfolioIsMatching = true;

  if (selectedPortfolio) {
    portfolioIsMatching = portfolioCheck({ basicVehicle, selectedPortfolio });
  }

  return batteryIsMatching && portfolioIsMatching;
};

export default socsCheck;
