import Card from 'common/components/Card';
import classes from './style.module.scss';
import classNames from 'classnames';
import InfoList, { InfoListItem } from 'common/components/InfoList';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { Checkbox, EntitiesIcon, ConditionalWrapper } from '@optimization/ssi-common';

interface Props {
  solutionId: number;
  solutionName: string;
  vehiclesCount?: number;
  depotsCount?: number;
  modifiedAt: string;
  checked?: boolean;
  onChangeCheckbox?: (checked: boolean, value: string) => void;
}

const SolutionTeaser = ({
  solutionId,
  solutionName,
  vehiclesCount,
  depotsCount,
  modifiedAt,
  checked,
  onChangeCheckbox,
}: Props) => {
  const date = useMemo(() => new Date(modifiedAt), [modifiedAt]);

  const items = useMemo(() => {
    const items: InfoListItem[] = [];

    if (typeof vehiclesCount === 'number') {
      items.push({ label: 'Vehicle candidates', value: vehiclesCount });
    }

    if (typeof depotsCount === 'number') {
      items.push({ label: 'Depots', value: depotsCount });
    }

    items.push({ label: 'Solution created', value: date.toLocaleDateString() });

    return items;
  }, [vehiclesCount, depotsCount, date]);

  return (
    <ConditionalWrapper
      condition={Boolean(onChangeCheckbox)}
      wrapperWhenFalse={(innerChildren) => (
        <Link
          className={classes['solution-teaser-link']}
          to={`/solution/${solutionId}/home`}
          data-testid={`link-solution-${solutionId}`}
        >
          {innerChildren}
        </Link>
      )}
    >
      <Card isHighlighted={checked} innerClassName="px-spacing-16 pt-spacing-16 pb-spacing-42">
        {onChangeCheckbox ? (
          <Checkbox value={solutionId.toString()} checked={checked} onChange={onChangeCheckbox} />
        ) : (
          <EntitiesIcon />
        )}
        <p className={classNames('tds-u-mt3', 'tds-detail-05', 'tds-text-grey-300')}>Solution</p>
        <h3
          className={classNames(
            'tds-paragraph-02',
            'mt-spacing-4',
            'mb-spacing-16',
            'overflow-hidden',
            'text-ellipsis',
            'whitespace-nowrap',
          )}
        >
          {solutionName}
        </h3>
        <InfoList items={items} />
      </Card>
    </ConditionalWrapper>
  );
};

export default SolutionTeaser;
