import { Filter } from 'common/components/Filters/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedDepotNames: string[];
}

const useSelectedFilters = ({ selectedDepotNames }: Props) =>
  useMemo(() => {
    let filters: Filter[] | null = null;

    if (selectedDepotNames.length) {
      if (!filters) {
        filters = [];
      }

      filters.push({
        key: 'depotNames',
        name: 'DEPOT NAMES',
        value: '',
      });
    }

    return filters;
  }, [selectedDepotNames]);

export default useSelectedFilters;
