import { useEffect, useState } from 'react';
import { useGetTripsStatusQuery } from 'app/services/solution';

interface Props {
  solutionId: string;
  queryId: string;
  queryIdRef: React.MutableRefObject<{
    queryId: string;
  }>;
  setReadyToGetTrips: (readyToGetTrips: boolean) => void;
  setTripsStatusState: React.Dispatch<
    React.SetStateAction<{
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    }>
  >;
}

const GetTripsStatus = ({ solutionId, queryId, queryIdRef, setReadyToGetTrips, setTripsStatusState }: Props) => {
  const [skip, setSkip] = useState(false);

  const getTripsStatusQuery = useGetTripsStatusQuery(
    {
      solutionId,
      queryId,
    },
    { skip, pollingInterval: 2000 },
  );

  useEffect(() => {
    if (getTripsStatusQuery.data) {
      const executionStatus = getTripsStatusQuery.data.ExecutionStatus;

      if (executionStatus === 'SUCCESS' && queryId === queryIdRef.current.queryId) {
        setReadyToGetTrips(true);
      }

      if (
        ['ABORTED', 'DISCONNECTED', 'FAILED_WITH_ERROR', 'FAILED_WITH_INCIDENT', 'NO_DATA', 'SUCCESS'].includes(
          executionStatus,
        )
      ) {
        setSkip(true);
      }
    }
  }, [getTripsStatusQuery, queryId, queryIdRef, setReadyToGetTrips]);

  useEffect(() => {
    setTripsStatusState({
      isError: getTripsStatusQuery.isError,
      isLoading: getTripsStatusQuery.isLoading,
      isSuccess: getTripsStatusQuery.isSuccess,
    });

    return () => {
      setTripsStatusState({
        isError: false,
        isLoading: false,
        isSuccess: false,
      });
    };
  }, [setTripsStatusState, getTripsStatusQuery.isError, getTripsStatusQuery.isLoading, getTripsStatusQuery.isSuccess]);

  return null;
};

export default GetTripsStatus;
