import { useGetVehiclesQuery } from 'app/services/solution';
import { useMemo } from 'react';

interface Props {
  solutionId: string;
}

const useSolutionHasVehicles = ({ solutionId }: Props): boolean => {
  const vehiclesQuery = useGetVehiclesQuery(solutionId);

  return useMemo(() => Boolean(vehiclesQuery.data && vehiclesQuery.data.length > 0), [vehiclesQuery]);
};

export default useSolutionHasVehicles;
