import classes from './style.module.scss';
import classNames from 'classnames';
import EnergyCostsFields from './utils/EnergyCostsFields';
import GeneralInfoFields from './utils/GeneralInfoFields';
import OperationalCostsFields from './utils/OperationalCostsFields';
import ScrollProgressBar from './utils/ScrollProgressBar';
import StepInfo from './utils/StepInfo';
import useLoadingText from 'common/hooks/useLoadingText';
import useScrollToTop from './utils/useScrollToTop';
import VehicleCostsFields from './utils/VehicleCostsFields';
import { Button, Modal } from '@optimization/ssi-common';
import { convertFormValuesToResponse } from './utils/convertFormValuesToResponse';
import { convertResponseToFormValues } from './utils/convertResponseToFormValues';
import { energyCostsInfo, generalInfo, operationalCostsInfo, vehicleCostsInfo } from 'app/config/tco';
import { Loading, useToast } from '@optimization/ssi-common';
import { TcoFinancingMethod, TcoVehicleType } from '@optimization/sa-common/dist/types/backendModels';
import { useCalculateVehicleTcoFiguresQuery, useUpdateVehicleTcoFiguresMutation } from 'app/services/solution';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import {
  ChartTooltip,
  FinancialEvaluationToCosts,
  FinancialEvaluationFromCosts,
  VehicleEnhanced,
  financialEvaluationVehicleTypeOptions,
  TcoCalculationsV2VM,
} from '@optimization/sa-common';

export interface FormValues {
  currency: string;
  bevInsuranceCost: string;
  bevTaxesCost: string;
  bevRoadToll: string;
  bevMaintenanceCost: string;
  bevEngineType: string;
  bevEnergyCost: string;
  bevFinanceType: string;
  bevVehiclePrice: string;
  bevInterestRate: string;
  bevResidual: string;
  bevGovernmentSubsidies: string;
  comparisonInsuranceCost: string;
  comparisonTaxesCost: string;
  comparisonRoadtoll: string;
  comparisonMaintenanceCost: string;
  comparisonAdBlueCost: string;
  comparisonAdBlueConsumption: string;
  comparisonEngineType: string;
  comparisonEnergyCost: string;
  comparisonEnergyConsumption: string;
  comparisonFinanceType: string;
  comparisonVehiclePrice: string;
  comparisonInterestRate: string;
  comparisonResidual: string;
  comparisonGovernmentSubsidies: string;
}

export type Mode = 'setup-evaluation' | 'edit-evaluation' | 'copy-settings';

export type SetFormValue = (attribute: string, value: string) => void;

type ModalType = 'save-and-exit' | 'clear-all-fields';

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  depotId?: string;
  cancelSetupEvaluation: () => void;
  setEvaluationIsDone: ({ vehicleId, isDone }: { vehicleId: number; isDone: boolean }) => void;
}

const SetupEvaluation = ({ solutionId, vehicle, cancelSetupEvaluation, setEvaluationIsDone }: Props) => {
  useScrollToTop();

  const showToast = useToast();

  const elementController = useElementController();
  const tooltipId = useId();

  const scrollProgressBarRef = useRef<HTMLDivElement | null>(null);
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const createDepotRef = useRef<HTMLDivElement | null>(null);

  const initialApiValues: TcoCalculationsV2VM = useMemo(() => {
    let comparisonConsumption;
    switch (true) {
      case vehicle.ExecutedVehicleProduct?.ComparisonConsumptionLPer100Km !== undefined:
        comparisonConsumption = vehicle.ExecutedVehicleProduct.ComparisonConsumptionLPer100Km!;
        break;
      case vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm !== undefined:
        comparisonConsumption = 100 * vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm!;
        break;
      default:
        comparisonConsumption = 25;
    }

    return {
      Currency: 'EUR',
      ShowInPresentationApp: true,
      BevVehicle: {
        EngineType: TcoVehicleType[TcoVehicleType.Bev],
        FinancingType: TcoFinancingMethod[TcoFinancingMethod.Cash],
        VehiclePrice: 0,
        InterestRate: 0,
        ResidualValue: 0,
        GovernmentSubsidies: 0,
        EnergyCost: 0,
        EnergyConsumption: 0,
        InsurancePerMonth: 0,
        TaxPerMonth: 0,
        RoadTollPerMonth: 0,
        MonthlyMaintenance: 0,
      },
      ComparisonVehicle: {
        EngineType: vehicle.ScaniaChassis?.Consumption?.FuelType ?? TcoVehicleType[TcoVehicleType.Diesel],
        FinancingType: TcoFinancingMethod[TcoFinancingMethod.Cash],
        VehiclePrice: 0,
        InterestRate: 0,
        ResidualValue: 0,
        GovernmentSubsidies: 0,
        EnergyCost: 0,
        EnergyConsumption: comparisonConsumption,
        AdBlueConsumption: 0.1,
        InsurancePerMonth: 0,
        TaxPerMonth: 0,
        RoadTollPerMonth: 0,
        MonthlyMaintenance: 0,
      },
    };
  }, [
    vehicle.ExecutedVehicleProduct.ComparisonConsumptionLPer100Km,
    vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm,
    vehicle.ScaniaChassis?.Consumption?.FuelType,
  ]);

  const initialformValues = useCallback(() => {
    return convertResponseToFormValues(vehicle);
  }, [vehicle]);

  const [formValues, setFormValues] = useState<FormValues>(initialformValues);
  const [updateVehicle, updateVehicleState] = useUpdateVehicleTcoFiguresMutation();

  const [residualChanged, setResidualChanged] = useState<boolean>(false);

  const [apiValues, setApiValues] = useState<TcoCalculationsV2VM>(
    vehicle.TcoCalculations ? { ...vehicle.TcoCalculations } : { ...initialApiValues },
  );

  const setFormValue = useCallback(
    (attribute: string, value: string) => {
      setFormValues((prev) => ({ ...prev, [attribute]: value }));
    },
    [setFormValues],
  );

  const vehicleCalculation = useCalculateVehicleTcoFiguresQuery(
    {
      solutionId: solutionId,
      vehicleId: vehicle.Id,
      ReturnResidualValue: residualChanged,
      Currency: apiValues.Currency,
      ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
      BevTcoFigures: apiValues.BevVehicle!,
      ComparisonTcoFigures: apiValues.ComparisonVehicle,
    },
    {
      skip:
        !apiValues.BevVehicle ||
        (apiValues.BevVehicle.FinancingType === 'Leasing' && apiValues.BevVehicle.InterestRate === 0) ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle.FinancingType === 'Leasing' &&
          apiValues.ComparisonVehicle.InterestRate === 0) ||
        apiValues.BevVehicle?.VehiclePrice === 0 ||
        apiValues.BevVehicle?.VehiclePrice <= apiValues.BevVehicle?.ResidualValue ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle?.VehiclePrice <= apiValues.ComparisonVehicle?.ResidualValue),
    },
  );

  const { refetch, isUninitialized } = vehicleCalculation;

  useEffect(() => {
    if (residualChanged && formValues.comparisonResidual) {
      const bevResidual = vehicleCalculation.data?.Content.VehicleTcoResponseInstances.find(
        (e) => e.EngineType === 'Bev',
      )?.ResidualValue;

      if (bevResidual) {
        setFormValues((prev) => ({ ...prev, bevResidual: bevResidual.toFixed(0) }));

        if (
          formValues.bevResidual === bevResidual.toFixed(0) &&
          (apiValues.ComparisonVehicle?.ResidualValue.toString() ?? '0' === formValues.comparisonResidual)
        )
          setResidualChanged(false);
      }
    }
  }, [
    apiValues.ComparisonVehicle?.ResidualValue,
    formValues.bevResidual,
    formValues.comparisonResidual,
    residualChanged,
    vehicleCalculation.data?.Content.VehicleTcoResponseInstances,
  ]);

  useEffect(() => {
    if (
      (formValues.bevFinanceType === 'Leasing' && formValues.bevInterestRate === '0') ||
      (formValues.comparisonFinanceType === 'Leasing' && formValues.comparisonInterestRate === '0') ||
      formValues.bevVehiclePrice === '0'
    )
      return;

    setApiValues(convertFormValuesToResponse(vehicle, formValues));

    if (!isUninitialized) refetch();
  }, [formValues, vehicle, refetch, vehicleCalculation.data, isUninitialized]);

  const [modal, setModal] = useState<ModalType | null>(null);

  useEffect(() => {
    if (elementController) {
      elementController.setEnableSidebar(false);
    }

    return () => {
      if (elementController) {
        elementController.setEnableSidebar(true);
      }
    };
  }, [elementController]);

  const showSaveAndExitModal = useCallback(() => {
    setModal('save-and-exit');
  }, []);

  const saveAndExit = useCallback(() => {
    if (
      (formValues.bevFinanceType === 'Leasing' && formValues.bevInterestRate === '0') ||
      (formValues.comparisonFinanceType === 'Leasing' && formValues.comparisonInterestRate === '0')
    ) {
      showToast({
        header: 'Interest Rate cannot be set to 0',
        subheader: 'Please ensure a valid interest rate is entered before saving',
        variant: 'error',
        dataTestid: 'toast-validate-financial-evaluation',
      });
      return;
    }
    const newRequestValues = convertFormValuesToResponse(vehicle, formValues);
    if (newRequestValues.BevVehicle)
      updateVehicle({
        solutionId,
        vehicleId: vehicle.Id,
        ReturnResidualValue: false,
        Currency: newRequestValues.Currency,
        ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
        BevTcoFigures: newRequestValues.BevVehicle,
        ComparisonTcoFigures: newRequestValues.ComparisonVehicle,
      });
  }, [formValues, vehicle, updateVehicle, solutionId, showToast]);

  useEffect(() => {
    if (updateVehicleState.isSuccess) {
      showToast({
        header: 'Financial evaluation saved successfully',
        dataTestid: 'toast-saved-financial-evaluation',
      });
      setEvaluationIsDone({ vehicleId: vehicle.Id, isDone: true });
    }
  }, [updateVehicleState, vehicle.Id, showToast, setEvaluationIsDone]);

  useEffect(() => {
    if (
      apiValues.BevVehicle?.ResidualValue !== 0 &&
      apiValues.BevVehicle?.VehiclePrice !== 0 &&
      apiValues.ComparisonVehicle?.ResidualValue !== 0 &&
      apiValues.ComparisonVehicle?.VehiclePrice !== 0 &&
      ((apiValues.BevVehicle && apiValues.BevVehicle?.ResidualValue >= apiValues.BevVehicle?.VehiclePrice) ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle?.ResidualValue >= apiValues.ComparisonVehicle?.VehiclePrice))
    )
      showToast({
        header: 'Unable to calculate TCO',
        subheader: 'Please enter a Residual Value that is lower than the vehicle cost',
        variant: 'error',
      });
  }, [apiValues, showToast]);

  const showClearAllFieldsModal = useCallback(() => {
    setModal('clear-all-fields');
  }, []);

  const hideModal = useCallback(() => {
    setModal(null);
  }, []);

  const clearAllFields = useCallback(() => {
    hideModal();

    setApiValues({ ...initialApiValues });

    showToast({
      header: 'All settings cleared',
    });
  }, [hideModal, initialApiValues, showToast]);

  useMakeElementsSticky({
    scrollProgressBarRef,
    summaryRef,
  });

  const fromCostsHeader = useMemo(() => {
    const queryData = vehicleCalculation.data?.Content;
    let ComparisonVehicle = queryData?.VehicleTcoResponseInstances.find((x) => x.EngineType !== 'Bev');
    if (vehicleCalculation.isSuccess && ComparisonVehicle) {
      const fromOption = financialEvaluationVehicleTypeOptions.find((x) => x.value === ComparisonVehicle!.EngineType);

      if (fromOption) {
        return fromOption.name;
      }
    }

    return financialEvaluationVehicleTypeOptions.find((x) => x.value === formValues.comparisonEngineType)!.name;
  }, [formValues.comparisonEngineType, vehicleCalculation.data?.Content, vehicleCalculation.isSuccess]);

  const toTotalCosts = useMemo(() => {
    if (vehicleCalculation.isSuccess && vehicleCalculation.data.Content) {
      const calculations = vehicleCalculation.data.Content;
      return calculations.VehicleTcoResponseInstances.find((e) => e.EngineType === 'Bev');
    }
  }, [vehicleCalculation]);

  const fromTotalCosts = useMemo(() => {
    if (vehicleCalculation.isSuccess && vehicleCalculation.data.Content) {
      const calculations = vehicleCalculation.data.Content;
      return calculations.VehicleTcoResponseInstances.find((e) => e.EngineType !== 'Bev');
    }
  }, [vehicleCalculation]);

  const isLoading = updateVehicleState.isLoading || vehicleCalculation.isLoading;
  const isError = updateVehicleState.isError || vehicleCalculation.isError;

  const loadingText = useLoadingText({
    isSavingEvaluation: updateVehicleState.isLoading,
    isCalculatingEvaluation: vehicleCalculation.isLoading,
  });

  return (
    <div className={classNames(classes['setup-evaluation'], 'tds-container')}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {modal === 'save-and-exit' && (
        <Modal
          size="xs"
          header="Save and exit"
          variant="primary"
          disabled={isLoading}
          ctaConfirmSubmitDataTestid="button-save-financial-evaluation"
          ctaConfirmText="Save and exit"
          ctaConfirmSubmit={saveAndExit}
          onClose={hideModal}
        >
          Are you sure you want to exit? All current settings will be saved.
        </Modal>
      )}
      {modal === 'clear-all-fields' && (
        <Modal
          size="xs"
          header="Clear all fields"
          variant="danger"
          ctaConfirmText="Remove values"
          ctaConfirmSubmit={clearAllFields}
          onClose={hideModal}
        >
          Are you sure you want to remove all added values from the fields?
        </Modal>
      )}
      <ScrollProgressBar scrollProgressBarRef={scrollProgressBarRef} />
      <ChartTooltip tooltipId={tooltipId} hidden absolute />
      <div className={classes.summary} ref={summaryRef}>
        <div className={classes.inner}>
          <div className={classes.navigation}>
            <Button
              ref={createDepotRef}
              text="Save and exit"
              disabled={isLoading}
              dataTestid="button-save-and-exit-financial-evaluation"
              onClick={showSaveAndExitModal}
            />
            <Button text="Cancel" variant="secondary" disabled={isLoading} onClick={cancelSetupEvaluation} />
          </div>
          <div className={classes.costs}>
            <FinancialEvaluationToCosts
              vehicleCosts={toTotalCosts?.VehicleCostsPercent ?? 0}
              energyCosts={toTotalCosts?.EnergyCostsPercent ?? 0}
              operationalCosts={toTotalCosts?.OperationalCostsPercent ?? 0}
              totalCost={Math.round(toTotalCosts?.TotalCostOfOwnership ?? 0)}
              isEmpty={false}
              tooltipId={tooltipId}
              currency={formValues?.currency || 'EUR'}
            />
            <FinancialEvaluationFromCosts
              vehicleCosts={fromTotalCosts?.VehicleCostsPercent ?? 0}
              energyCosts={fromTotalCosts?.EnergyCostsPercent ?? 0}
              operationalCosts={fromTotalCosts?.OperationalCostsPercent ?? 0}
              totalCost={Math.round(fromTotalCosts?.TotalCostOfOwnership ?? 0)}
              isEmpty={!Boolean(apiValues.ComparisonVehicle?.EngineType)}
              header={fromCostsHeader}
              tooltipId={tooltipId}
              currency={formValues?.currency || 'EUR'}
            />
          </div>
          <button
            onClick={showClearAllFieldsModal}
            className={classNames('reset-button-styles', classes['clear-all-fields'])}
          >
            Clear all fields
          </button>
        </div>
      </div>
      <div className={classNames('mt-spacing-48', classes.steps)}>
        <div className={classes.step}>
          <StepInfo header={generalInfo.header} description={generalInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <GeneralInfoFields
                disabled={isLoading}
                vehicle={vehicle}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={vehicleCostsInfo.header} description={vehicleCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <VehicleCostsFields
                setResidualChanged={setResidualChanged}
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={energyCostsInfo.header} description={energyCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <EnergyCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                vehicle={vehicle}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={operationalCostsInfo.header} description={operationalCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <OperationalCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupEvaluation;
