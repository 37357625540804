import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';

const portfolioCheck = ({
  basicVehicle,
  selectedPortfolio,
}: {
  basicVehicle: FactBasicTruck | FactBasicBus;
  selectedPortfolio: string;
}): boolean => Boolean(basicVehicle.Portfolios?.includes(selectedPortfolio));

export default portfolioCheck;
