import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const usePerformanceSteps = (vehicles?: ListVehicleEnhanced[]): string[] =>
  useMemo(() => {
    if (!vehicles) {
      return [];
    }

    return [
      ...new Set(
        vehicles
          .map((vehicle) => vehicle.transformed.performanceStepPresentation)
          .filter((performanceStep) => performanceStep),
      ),
    ].sort();
  }, [vehicles]);

export default usePerformanceSteps;
