import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depots?: ListDepotTransformed[];
  selectedDepotNames: string[];
}

const useFilteredDepots = ({ depots, selectedDepotNames }: Props) =>
  useMemo(() => {
    if (depots?.length) {
      return [...depots].filter((depot) => {
        if (!selectedDepotNames.length) {
          return true;
        }

        return selectedDepotNames.includes(depot.DepotName);
      });
    }
  }, [depots, selectedDepotNames]);

export default useFilteredDepots;
