import useInfraPerformanceSteps from 'features/infrastructure/utils/InfraPerformanceStep/utils/useInfraPerformanceSteps';
import useValidateDepotSetupHandler from './useValidateDepotSetupHandler';
import { CHARGER_VALUES_SEPARATOR } from 'features/infrastructure/utils/InfraPerformanceStep';
import { DepotBasePowerDemand } from './useDepotBasePowerDemands';
import { FactBasicChargingSolution, InfrastructureProductV2VM } from '@optimization/sa-common';
import { InfraPerformanceStepHelper } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';
import { InfraProductChangeAttribute } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';
import { infrastructureProductDefault } from 'app/config/infrastructure';
import { Mode } from '../';
import { mutateInfrastructureProduct } from 'features/infrastructure/utils/InfraPerformanceStep/utils/helper';
import { useCallback, useMemo, useState } from 'react';

interface Props {
  depotYear: string;
  chargingSolutions?: FactBasicChargingSolution[];
  depotBasePowerDemands: DepotBasePowerDemand[];
  depotFutureMaxPowerCapacity: string;
  depotMaxPowerCapacity: string;
  depotName: string;
  mode: Mode;
  selectedCandidates: string[];
  solutionId: string;
}

const useInfraPerformanceStepWizardHandler = ({
  depotYear,
  chargingSolutions,
  depotBasePowerDemands,
  depotFutureMaxPowerCapacity,
  depotMaxPowerCapacity,
  depotName,
  mode,
  selectedCandidates,
  solutionId,
}: Props): InfraPerformanceStepHelper => {
  const [infrastructureProductId, setInfrastructureProductId] = useState(1);
  const [infrastructureProducts, setInfrastructureProducts] = useState<InfrastructureProductV2VM[]>([]);

  const validateDepotSetupHandler = useValidateDepotSetupHandler({
    depotName,
    depotMaxPowerCapacity,
    depotFutureMaxPowerCapacity,
    depotBasePowerDemands,
    mode,
    selectedCandidates,
    solutionId,
    infrastructureProducts,
  });

  const depotDataPerPerformanceStep = validateDepotSetupHandler.data?.DepotDataPerPerformanceStep;

  const years: string[] = useMemo(
    () => (depotDataPerPerformanceStep ? Object.keys(depotDataPerPerformanceStep) : []),
    [depotDataPerPerformanceStep],
  );

  const infraPerformanceSteps = useInfraPerformanceSteps({
    years,
    chargingSolutions,
    infrastructureProducts,
    depotDataPerPerformanceStep: validateDepotSetupHandler.data?.DepotDataPerPerformanceStep,
  });

  const onChangeChargerValue = useCallback(
    (value: string, attribute: InfraProductChangeAttribute, chargerIndex: number) => {
      const [chargingSolutionIndex, selectedValue]: string[] = value.split(CHARGER_VALUES_SEPARATOR);

      const chargingSolution = chargingSolutions?.[chargingSolutionIndex as any];

      if (chargingSolution) {
        const product = { ...infrastructureProducts[chargerIndex] };

        mutateInfrastructureProduct({
          product,
          chargingSolution,
          depotYear,
          attribute,
          selectedValue,
        });

        setInfrastructureProducts((prev) =>
          prev.map((infrastructureProduct) =>
            infrastructureProduct.Id === product.Id ? product : infrastructureProduct,
          ),
        );
      }
    },
    [depotYear, chargingSolutions, infrastructureProducts],
  );

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteIndex(null);
  }, []);

  const addCharger = useCallback(() => {
    setInfrastructureProducts((prev) => [
      ...prev,
      {
        ...infrastructureProductDefault,
        Id: infrastructureProductId,
        RequiredAt: depotYear,
      },
    ]);

    setInfrastructureProductId((prev) => prev + 1);
  }, [depotYear, infrastructureProductId]);

  const deleteCharger = useCallback(() => {
    if (typeof deleteIndex === 'number') {
      const productToDelete = infrastructureProducts[deleteIndex];

      setInfrastructureProducts((prev) => prev.filter((product) => product !== productToDelete));

      setDeleteIndex(null);
    }
  }, [deleteIndex, infrastructureProducts]);

  const errorsStates = useMemo(
    () => validateDepotSetupHandler.data?.DepotDataPerPerformanceStep?.[depotYear]?.ErrorStates,
    [validateDepotSetupHandler, depotYear],
  );

  return useMemo(
    () => ({
      depotDataPerPerformanceStep,
      errorsStates,
      infraPerformanceSteps,
      infrastructureProducts,
      deleteIndex,
      isLoading: validateDepotSetupHandler.isLoading,
      isError: validateDepotSetupHandler.isError,
      validateDepotSetupSuccess: validateDepotSetupHandler.isSuccess,
      years,
      updateDepotIsLoading: false,
      updateDepotIsError: false,
      deleteCharger,
      addCharger,
      onCloseDeleteModal,
      onChangeChargerValue,
      setDeleteIndex,
      infraPerformanceStep: infraPerformanceSteps.find(
        (infraPerformanceStep) => infraPerformanceStep.year.toString() === depotYear,
      ),
    }),
    [
      depotYear,
      errorsStates,
      depotDataPerPerformanceStep,
      infrastructureProducts,
      infraPerformanceSteps,
      deleteIndex,
      years,
      validateDepotSetupHandler,
      deleteCharger,
      addCharger,
      onCloseDeleteModal,
      onChangeChargerValue,
      setDeleteIndex,
    ],
  );
};

export default useInfraPerformanceStepWizardHandler;
