import classes from './style.module.scss';
import classNames from 'classnames';
import productManualCategories from 'app/config/productManualCategories';
import { NavLink, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import {
  TdsSideMenu,
  TdsSideMenuDropdown,
  TdsSideMenuDropdownList,
  TdsSideMenuDropdownListItem,
} from '@scania/tegel-react';

const SideNavbarSolutionProductManual = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const elementController = useElementController();

  const isLinkSelected = useCallback((path: string) => location.pathname === path, [location]);

  return (
    <div className="side-menu-wrapper">
      {elementController?.enableSidebar && (
        <TdsSideMenu id="side-menu" aria-label="Side menu" ref={elementController.sideMenuRef} persistent>
          {productManualCategories.map((category, i) => (
            <TdsSideMenuDropdown key={category.slug} default-open={i < 2}>
              <span slot="label">{category.name}</span>
              <TdsSideMenuDropdownList>
                <TdsSideMenuDropdownListItem selected={isLinkSelected(`/product-manual/${category.slug}`)}>
                  <NavLink
                    to={{
                      pathname: `/product-manual/${category.slug}`,
                    }}
                  >
                    Overview
                  </NavLink>
                </TdsSideMenuDropdownListItem>
                {category.subCategories.map((subCategory) => (
                  <TdsSideMenuDropdownListItem
                    key={subCategory.slug}
                    selected={isLinkSelected(`/product-manual/${category.slug}/${subCategory.slug}`)}
                  >
                    <NavLink
                      to={{
                        pathname: `/product-manual/${category.slug}/${subCategory.slug}`,
                      }}
                    >
                      {subCategory.name}
                    </NavLink>
                  </TdsSideMenuDropdownListItem>
                ))}
              </TdsSideMenuDropdownList>
            </TdsSideMenuDropdown>
          ))}
        </TdsSideMenu>
      )}
      <div className={classNames(classes['container-margin'], classes['product-manual'])}>{children}</div>
    </div>
  );
};

export default SideNavbarSolutionProductManual;
