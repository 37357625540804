import classes from './style.module.scss';
import classNames from 'classnames';
import DepotKeyValues from '../DepotKeyValues';
import DepotMessages from 'features/infrastructure/utils/DepotMessages';
import QuantityTextField from './utils/QuantityTextField';
import useLoadingText from 'common/hooks/useLoadingText';
import { Charger, InfraPerformanceStepHelper } from './utils/types';
import { FactBasicChargingSolution } from '@optimization/sa-common';
import { outletIsValidForPower, powerOutputIsAvailable } from './utils/helper';
import { useCallback, useMemo } from 'react';
import {
  Button,
  ButtonAlt,
  Modal,
  IColumn,
  RowItemBase,
  ResponsiveTable,
  Dropdown,
  Loading,
} from '@optimization/ssi-common';

export const CHARGER_VALUES_SEPARATOR = '##';

export interface RowItem extends RowItemBase {
  charger: Charger;
}

interface Props {
  className?: string;
  depotYear: string;
  chargingSolutions?: FactBasicChargingSolution[];
  infraPerformanceStepHandler: InfraPerformanceStepHelper;
  hasRightColumnTopMargin?: boolean;
  onNextYear?: () => void;
}

const InfraPerformanceStep = ({
  className,
  depotYear,
  chargingSolutions,
  infraPerformanceStepHandler,
  hasRightColumnTopMargin,
  onNextYear,
}: Props) => {
  const brandOptions = useMemo(
    () =>
      chargingSolutions?.map((chargingSolution, chargingSolutionIndex) => ({
        value: `${chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}0`,
        name: `${
          chargingSolution.Brand.VariantDescription
        } (${chargingSolution.Solution.VariantDescription.toLowerCase()})`,
      })) || [],
    [chargingSolutions],
  );

  const columns: IColumn<RowItem>[] = useMemo(
    () => [
      {
        columnKey: 'year',
        columnTitle: 'Year',
        render: (row: RowItem) => {
          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          return (
            <div
              className={classNames('infra-performance-step-body-col-2-year', {
                'infra-performance-step-body-col-2-year-disabled': disabled,
              })}
            >
              {row.charger.year}
            </div>
          );
        },
      },
      {
        columnKey: 'brand',
        columnTitle: 'Charging model',
        disablePadding: true,
        render: (row: RowItem) => {
          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          return (
            <Dropdown
              size="md"
              className="w-full"
              onChange={(value: string) =>
                infraPerformanceStepHandler.onChangeChargerValue(value, 'Brand', row.charger.chargerIndex)
              }
              value={`${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}0`}
              disabled={disabled}
              options={brandOptions}
            />
          );
        },
      },
      {
        columnKey: 'power',
        columnTitle: 'Power',
        disablePadding: true,
        render: (row: RowItem) => {
          const selectedValue = `${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}${row.charger.power.code}`;

          const powerBlockSize = chargingSolutions?.[row.charger.chargingSolutionIndex as any].PowerBlockSize;

          const powerOptions =
            chargingSolutions?.[row.charger.chargingSolutionIndex as any]?.PowerOutputs.filter((powerOutput) =>
              powerOutputIsAvailable(powerOutput, depotYear),
            ).map((powerOutput) => {
              const optionValue = `${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}${powerOutput.VariantCode}`;

              return {
                value: optionValue,
                name: `${powerOutput.VariantDescription}${` (${
                  (powerOutput.VariantValue || 0) / (powerBlockSize || 1)
                } channels)`}`,
              };
            }) || [];

          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          return (
            <Dropdown
              size="md"
              className="w-full"
              value={selectedValue}
              disabled={disabled}
              options={powerOptions}
              onChange={(value: string) =>
                infraPerformanceStepHandler.onChangeChargerValue(value, 'Power', row.charger.chargerIndex)
              }
            />
          );
        },
      },
      {
        columnKey: 'satellites',
        columnTitle: 'Connectors',
        disablePadding: true,
        render: (row: RowItem) => {
          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          const satelliteOptions =
            chargingSolutions?.[row.charger.chargingSolutionIndex as any]?.NumberOfOutlets.filter((outlet) =>
              outletIsValidForPower(outlet, row.charger.power.code),
            ).map((outlet) => ({
              value: `${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}${outlet.VariantCode}`,
              name: outlet.VariantDescription,
            })) || [];

          return (
            <Dropdown
              size="md"
              className="w-full"
              value={`${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}${row.charger.satellites.code}`}
              disabled={disabled}
              options={satelliteOptions}
              onChange={(value: string) =>
                infraPerformanceStepHandler.onChangeChargerValue(value, 'Outlets', row.charger.chargerIndex)
              }
            />
          );
        },
      },
      {
        columnKey: 'quantity',
        columnTitle: 'Quantity',
        disablePadding: true,
        render: (row: RowItem) => {
          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          return (
            <QuantityTextField
              row={row}
              disabled={disabled}
              infraPerformanceStepHandler={infraPerformanceStepHandler}
            />
          );
        },
      },
      {
        columnKey: 'trash',
        columnTitle: '',
        disablePadding: true,
        render: (row: RowItem) => {
          const disabled = row.charger.disabled || infraPerformanceStepHandler.isLoading;

          return (
            <div className="tds-u-flex">
              <ButtonAlt
                className="tds-u-ml-auto"
                dataTestid="button-delete-charger"
                disabled={disabled}
                tdsIcon={disabled ? 'trash_inactive' : 'trash'}
                onClick={() => infraPerformanceStepHandler.setDeleteIndex(row.charger.chargerIndex)}
              />
            </div>
          );
        },
      },
    ],
    [brandOptions, infraPerformanceStepHandler, chargingSolutions, depotYear],
  );

  const rows: RowItem[] = useMemo(
    () =>
      infraPerformanceStepHandler.infraPerformanceStep?.chargers.map((charger) => ({
        charger,
      })) || [],
    [infraPerformanceStepHandler],
  );

  const getRowKey = useCallback((row: RowItem) => row.charger.chargerIndex, []);

  const hasChargers = useMemo(
    () => (infraPerformanceStepHandler.infraPerformanceStep?.chargers?.length || 0) > 0,
    [infraPerformanceStepHandler],
  );

  const loadingText = useLoadingText({
    isSavingInfrastructure: infraPerformanceStepHandler.updateDepotIsLoading,
  });

  return (
    <article className={classNames(classes['infra-performance-step'], className)}>
      <Loading
        isLoading={infraPerformanceStepHandler.updateDepotIsLoading}
        isError={infraPerformanceStepHandler.updateDepotIsError}
        loadingText={loadingText}
      />
      {typeof infraPerformanceStepHandler.deleteIndex === 'number' && (
        <Modal
          size="xs"
          header="Delete charger"
          ctaConfirmText="Delete"
          ctaConfirmSubmit={infraPerformanceStepHandler.deleteCharger}
          ctaConfirmSubmitDataTestid="button-confirm-delete-charger"
          onClose={infraPerformanceStepHandler.onCloseDeleteModal}
        >
          Are you sure you want to delete this charger?
        </Modal>
      )}
      {infraPerformanceStepHandler.infraPerformanceStep && (
        <div className={classes.body}>
          <div className={classes.left}>
            <DepotKeyValues
              header="Infrastructure demand"
              notEnoughPowerBlocks={infraPerformanceStepHandler.infraPerformanceStep.notEnoughPowerBlocks}
              notEnoughSatellites={infraPerformanceStepHandler.infraPerformanceStep.notEnoughSatellites}
              notEnoughPower={infraPerformanceStepHandler.infraPerformanceStep.notEnoughPower}
              powerDemand={infraPerformanceStepHandler.infraPerformanceStep.peakChargingPowerDemand}
              chargingPower={infraPerformanceStepHandler.infraPerformanceStep.totalMaxChargingPower}
              peakConnectedVehicles={infraPerformanceStepHandler.infraPerformanceStep.peakConnectedVehicles}
              maxUsedPowerBlocks={infraPerformanceStepHandler.infraPerformanceStep.maxUsedPowerBlocks}
              totalNumberOfPowerBlocks={infraPerformanceStepHandler.infraPerformanceStep.totalNumberOfPowerBlocks}
              numberOfSatellites={infraPerformanceStepHandler.infraPerformanceStep.satellitesCount}
            />
            {infraPerformanceStepHandler.errorsStates && (
              <div className="mt-spacing-16">
                <DepotMessages
                  errorsStates={infraPerformanceStepHandler.errorsStates}
                  peakConnectedVehicles={infraPerformanceStepHandler.infraPerformanceStep.peakConnectedVehicles}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(classes.right, {
              [classes['has-top-margin']]: hasRightColumnTopMargin,
            })}
          >
            {hasChargers && (
              <ResponsiveTable<RowItem>
                dataTestid="table-chargers"
                modeVariant="secondary"
                columns={columns}
                rows={rows}
                getRowKey={getRowKey}
              />
            )}
            <div
              className={classNames('flex', 'justify-between', {
                'tds-u-mt2': hasChargers,
              })}
            >
              <Button
                text="Add charger"
                dataTestid="button-add-charger"
                disabled={infraPerformanceStepHandler.isLoading}
                onClick={infraPerformanceStepHandler.addCharger}
                variant={infraPerformanceStepHandler.infraPerformanceStep.chargers.length ? 'secondary' : 'primary'}
              />
              {onNextYear && <Button icon="arrow_right" variant="primary" text="Next" onClick={onNextYear} />}
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

export default InfraPerformanceStep;
