import { solutionApi } from 'app/services/solution';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useResetApiState = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(solutionApi.util.resetApiState());
  }, [dispatch]);
};

export default useResetApiState;
