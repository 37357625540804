import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedBodyLength = ({
  vehicles,
  bodyLengthList,
}: {
  vehicles: ListVehicleTransformed[];
  bodyLengthList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const bodyLengthCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const bodyLength = vehicle.BodyLengthM || '';

        return increaseValue({
          acc,
          key: bodyLength,
          list: bodyLengthList,
        });
      }, {});

      result = getMaxKey({ countByName: bodyLengthCount });
    }

    return result;
  }, [vehicles, bodyLengthList]);

export default useSuggestedBodyLength;
