import { useCallback, useMemo, useState } from 'react';

export interface ExpandedHandler {
  isExpanded: { [key: string]: boolean };
  setVehicleIsExpanded: ({ isExpanded, vehicleId }: { isExpanded: boolean; vehicleId: number }) => void;
}

const useExpandedHandler = (): ExpandedHandler => {
  const [isExpanded, setIsExpanded] = useState({});

  const setVehicleIsExpanded = useCallback(
    ({ isExpanded, vehicleId }: { isExpanded: boolean; vehicleId: number }) => {
      setIsExpanded((prev) => ({ ...prev, [vehicleId]: isExpanded }));
    },
    [setIsExpanded],
  );

  return useMemo(() => ({ isExpanded, setVehicleIsExpanded }), [isExpanded, setVehicleIsExpanded]);
};

export default useExpandedHandler;
