import { Dropdown } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  disabled: boolean;
  vehicleOptionsHandler: VehicleOptionsHandler;
}

const VehicleConfigurationFields = ({ disabled, vehicleOptionsHandler }: Props) => {
  const {
    truckBatteryDisabled,
    truckBatteryOptions,
    truckPortfolioOptions,
    truckSocOptions,
    truckSocDisabled,
    truckEngineGearBoxOptions,
    truckEngineGearBoxDisabled,
    selections,
    handleSelection,
  } = vehicleOptionsHandler;

  const handleTruckPortfolioChange = useCallback(
    (value: string) => handleSelection('truckPortfolio', value),
    [handleSelection],
  );

  const handleTruckBatteryChange = useCallback(
    (value: string) => handleSelection('truckBattery', value),
    [handleSelection],
  );

  const handleTruckSocWindowChange = useCallback(
    (value: string) => handleSelection('truckSocWindow', value),
    [handleSelection],
  );

  const handleTruckElectricMotorChange = useCallback(
    (value: string) => handleSelection('truckElectricMotor', value),
    [handleSelection],
  );

  return (
    <>
      <Dropdown
        value={selections.truckPortfolio}
        label="Product portfolio"
        labelPosition="outside"
        placeholder="Product portfolio"
        disabled={disabled}
        options={truckPortfolioOptions}
        dataTestid="dropdown-non-executed-portfolio"
        onChange={handleTruckPortfolioChange}
      />
      <Dropdown
        value={selections.truckBattery}
        label="Battery"
        labelPosition="outside"
        placeholder="Battery"
        dataTestid="dropdown-non-executed-battery"
        options={truckBatteryOptions}
        disabled={truckBatteryDisabled || disabled}
        onChange={handleTruckBatteryChange}
      />
      <Dropdown
        value={selections.truckSocWindow}
        label="SoC window"
        labelPosition="outside"
        placeholder="SoC window"
        dataTestid="dropdown-non-executed-soc-window"
        options={truckSocOptions}
        disabled={truckSocDisabled || disabled}
        onChange={handleTruckSocWindowChange}
      />
      <Dropdown
        value={selections.truckElectricMotor}
        label="Electric motor"
        labelPosition="outside"
        placeholder="Electric motor"
        dataTestid="dropdown-non-executed-electric-motor"
        options={truckEngineGearBoxOptions}
        disabled={truckEngineGearBoxDisabled || disabled}
        onChange={handleTruckElectricMotorChange}
      />
    </>
  );
};

export default VehicleConfigurationFields;
