import ChargingPowerInputs from './ChargingPowerInputs';
import classes from './style.module.scss';
import classNames from 'classnames';
import { CandidateChartFormValues } from '../utils/types';
import { ChargingEventV2VM, ChartChargingEvent, ListDepotResponseV2VM } from '@optimization/sa-common';
import { Messages } from '../utils/useMessages';
import { SetupChargingEventHandler } from '../utils/useSetupChargingEventHandler';
import { TdsIcon } from '@scania/tegel-react';
import { ValidateChargingEventsHandler } from '../utils/useValidateChargingEventsHandler';

interface Props {
  depots?: ListDepotResponseV2VM[];
  resultingSoc: number;
  messages?: Messages;
  setupChargingEventHandler: SetupChargingEventHandler;
  validateChargingEventsHandler: ValidateChargingEventsHandler;
  candidateChartFormValues: CandidateChartFormValues;
  currentChargingEvent?: ChargingEventV2VM;
  currentChartChargingEvent?: ChartChargingEvent;
  medianTickIsOver: boolean;
  setCandidateChartFormValues: React.Dispatch<React.SetStateAction<CandidateChartFormValues>>;
}

const SetupChargingEvent = ({
  depots,
  resultingSoc,
  messages,
  setupChargingEventHandler,
  validateChargingEventsHandler,
  candidateChartFormValues,
  currentChargingEvent,
  currentChartChargingEvent,
  medianTickIsOver,
  setCandidateChartFormValues,
}: Props) => (
  <div
    className={classNames(classes['setup-charging-event'], {
      [classes['median-tick-is-over']]: medianTickIsOver,
      [classes['median-tick-not-over']]: !medianTickIsOver,
    })}
  >
    {setupChargingEventHandler.currentDatum ? (
      <ChargingPowerInputs
        depots={depots}
        resultingSoc={resultingSoc}
        homeDepotId={setupChargingEventHandler.vehicle.HomeDepotId}
        messages={messages}
        updateVehicleChargingEventsLoading={setupChargingEventHandler.isLoading}
        validateChargingEventsHandler={validateChargingEventsHandler}
        onSave={setupChargingEventHandler.onSave}
        onCancel={setupChargingEventHandler.onCancel}
        onDelete={setupChargingEventHandler.onDelete}
        setCandidateChartFormValues={setCandidateChartFormValues}
        eventStartTime={candidateChartFormValues.eventStartTime}
        chargingDuration={candidateChartFormValues.chargingDuration}
        currentChargingEvent={currentChargingEvent}
        currentChartChargingEvent={currentChartChargingEvent}
        depotIdOrExternal={candidateChartFormValues.depotIdOrExternal}
      />
    ) : (
      <div className="tds-body-02 flex items-center">
        <span className="mr-spacing-8">
          <TdsIcon name="info" size="16px" />
        </span>
        <p>Click on a specific point on the SoC graph to add or edit a charging event.</p>
      </div>
    )}
  </div>
);

export default SetupChargingEvent;
