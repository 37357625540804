import { ListVehicleRowItem } from '@optimization/sa-common';
import { OnSelectRow } from '@optimization/ssi-common';
import { useCallback } from 'react';

interface Props {
  setSelectedCandidates: React.Dispatch<React.SetStateAction<string[]>>;
  vehiclesMappedToOtherDepot?: { [key: string]: boolean };
}

const useSelectCandidateRow = ({ setSelectedCandidates, vehiclesMappedToOtherDepot }: Props) =>
  useCallback<OnSelectRow<ListVehicleRowItem>>(
    ({ row, checked }) => {
      setSelectedCandidates((prev: string[]) => {
        if (checked) {
          if (vehiclesMappedToOtherDepot?.[row.vehicle.Id]) {
            return [...prev];
          } else {
            return [...prev, row.vehicle.Id.toString()];
          }
        }

        return [...prev].filter((item) => item !== row.vehicle.Id.toString());
      });
    },
    [setSelectedCandidates, vehiclesMappedToOtherDepot],
  );

export default useSelectCandidateRow;
