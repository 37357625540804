import Event from '../../../utils/Event';
import timeToDayMinute from '../../../utils/timeToDayMinute';
import { ChargingEventV2VM, VehicleEnhanced } from '@optimization/sa-common';
import { MINUTES_ONE_DAY } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  vehicle: VehicleEnhanced;
  pixelsPerMinute: number;
  depotChargingEvent: ChargingEventV2VM;
}

const CustomChargingEvent = ({ vehicle, pixelsPerMinute, depotChargingEvent }: Props) => {
  const startMinute = useMemo(() => timeToDayMinute(depotChargingEvent.TimeAtEvent), [depotChargingEvent.TimeAtEvent]);

  const power = useMemo(() => depotChargingEvent.Power, [depotChargingEvent]);

  const durationAfterMidnight = useMemo(
    () => Math.max(startMinute + depotChargingEvent.ChargingDuration - MINUTES_ONE_DAY, 0),
    [startMinute, depotChargingEvent],
  );

  const durationInMinutes = useMemo(
    () => depotChargingEvent.ChargingDuration - durationAfterMidnight,
    [durationAfterMidnight, depotChargingEvent],
  );

  return (
    <>
      {durationAfterMidnight > 0 && (
        <Event
          vehicle={vehicle}
          power={power}
          pixelsPerMinute={pixelsPerMinute}
          startMinute={0}
          type="from-midnight"
          durationInMinutes={durationAfterMidnight}
        />
      )}
      <Event
        vehicle={vehicle}
        power={power}
        pixelsPerMinute={pixelsPerMinute}
        startMinute={startMinute}
        type={durationAfterMidnight ? 'before-midnight' : 'day'}
        durationInMinutes={durationInMinutes}
      />
    </>
  );
};

export default CustomChargingEvent;
