import { ListVehicleEnhanced } from '@optimization/sa-common';
import { sortNumbers } from '@optimization/ssi-common';
import { useMemo } from 'react';

const useInstalledEnergies = (vehicles?: ListVehicleEnhanced[]) =>
  useMemo(
    () =>
      [
        ...new Set(
          vehicles?.map((vehicle) => vehicle.enhanced.basicTruck?.InstalledEnergyKWh || 0).filter((value) => value),
        ),
      ].sort(sortNumbers),
    [vehicles],
  );

export default useInstalledEnergies;
