import useInfraPerformanceStep from 'features/infrastructure/utils/InfraPerformanceStep/utils/useInfraPerformanceStep';
import { CHARGER_VALUES_SEPARATOR } from 'features/infrastructure/utils/InfraPerformanceStep';
import { FactBasicChargingSolution, GetDepotResponseV2VM } from '@optimization/sa-common';
import { InfraPerformanceStepHelper } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';
import { InfraProductChangeAttribute } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';
import { infrastructureProductDefault } from 'app/config/infrastructure';
import { mutateInfrastructureProduct } from 'features/infrastructure/utils/InfraPerformanceStep/utils/helper';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateDepotMutation } from 'app/services/solution';

interface Props {
  solutionId: string;
  depot?: GetDepotResponseV2VM;
  depotYear: string;
  chargingSolutions?: FactBasicChargingSolution[];
  isLoading: boolean;
  isError: boolean;
}

const useInfraPerformanceStepUpdateHandler = ({
  solutionId,
  depot,
  depotYear,
  chargingSolutions,
  isLoading,
  isError,
}: Props): InfraPerformanceStepHelper => {
  const infraPerformanceStep = useInfraPerformanceStep({
    depot,
    depotYear,
    chargingSolutions,
    infrastructureProducts: depot?.InfrastructureProducts,
  });

  const [updateDepot, updateDepotQuery] = useUpdateDepotMutation();

  const onChangeChargerValue = useCallback(
    (value: string, attribute: InfraProductChangeAttribute, chargerIndex: number) => {
      if (!depot) {
        return;
      }

      const [chargingSolutionIndex, selectedValue]: string[] = value.split(CHARGER_VALUES_SEPARATOR);

      const chargingSolution = chargingSolutions?.[chargingSolutionIndex as any];

      if (chargingSolution) {
        const InfrastructureProducts = depot.InfrastructureProducts.map((infrastructureProduct) => ({
          ...infrastructureProduct,
          Name: '',
        }));

        const product = InfrastructureProducts[chargerIndex];

        if (!product) {
          return;
        }

        mutateInfrastructureProduct({
          product,
          chargingSolution,
          depotYear,
          attribute,
          selectedValue,
        });

        updateDepot({
          ...depot,
          solutionId,
          depotId: depot.Id,
          InfrastructureProducts,
        });
      }
    },
    [chargingSolutions, depot, depotYear, solutionId, updateDepot],
  );

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteIndex(null);
  }, []);

  const addCharger = useCallback(() => {
    if (!depot) {
      return;
    }

    updateDepot({
      ...depot,
      solutionId,
      depotId: depot.Id,
      InfrastructureProducts: [
        ...depot.InfrastructureProducts.map((product) => ({
          ...product,
          Name: '',
        })),
        {
          ...infrastructureProductDefault,
          RequiredAt: infraPerformanceStep.year.toString(),
          Name: '',
        },
      ],
    });
  }, [depot, solutionId, infraPerformanceStep.year, updateDepot]);

  const deleteCharger = useCallback(() => {
    if (!depot) {
      return;
    }

    updateDepot({
      ...depot,
      solutionId,
      depotId: depot.Id,
      InfrastructureProducts: [
        ...depot.InfrastructureProducts.filter((_, index) => index !== deleteIndex).map((product) => ({
          ...product,
          Name: '',
        })),
      ],
    });

    setDeleteIndex(null);
  }, [depot, solutionId, deleteIndex, updateDepot]);

  const errorsStates = useMemo(() => depot?.DepotDataPerPerformanceStep?.[depotYear]?.ErrorStates, [depot, depotYear]);

  return useMemo(
    () => ({
      deleteIndex,
      errorsStates,
      infraPerformanceStep,
      isError: isError || updateDepotQuery.isError,
      isLoading: isLoading || updateDepotQuery.isLoading,
      updateDepotIsLoading: updateDepotQuery.isLoading,
      updateDepotIsError: updateDepotQuery.isError,
      deleteCharger,
      addCharger,
      onCloseDeleteModal,
      onChangeChargerValue,
      setDeleteIndex,
    }),
    [
      deleteIndex,
      errorsStates,
      infraPerformanceStep,
      isError,
      isLoading,
      updateDepotQuery,
      deleteCharger,
      addCharger,
      onCloseDeleteModal,
      onChangeChargerValue,
      setDeleteIndex,
    ],
  );
};

export default useInfraPerformanceStepUpdateHandler;
