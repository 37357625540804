import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';

type DefineManuallyTabs = 'Actions' | 'Evaluated chassis' | 'Historical stops';

const useTabs = () =>
  useMemo(() => {
    const inlineTabs: TInlineTabs<DefineManuallyTabs> = [
      {
        name: 'Actions',
      },
      {
        name: 'Evaluated chassis',
      },
      {
        name: 'Historical stops',
      },
    ];

    return inlineTabs;
  }, []);

export default useTabs;
