import { CandidateTabs } from '..';
import { useLayoutEffect, useRef, useState } from 'react';

interface Props {
  tabs: {
    name: CandidateTabs;
    dataTestid?: string | undefined;
  }[];
  vehicleId: number;
  candidateTabDefault: CandidateTabs;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const useDefaultTabInitially = ({ tabs, vehicleId, candidateTabDefault, setTabIndex }: Props) => {
  const tabsRef = useRef(tabs);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useLayoutEffect(() => {
    if (tabsRef.current && vehicleId) {
      const index = tabsRef.current.findIndex((tab) => tab.name === candidateTabDefault);

      if (index > -1 && isFirstTime) {
        setTabIndex(index);
        setIsFirstTime(false);
      }
    }
  }, [candidateTabDefault, vehicleId, isFirstTime, setTabIndex]);
};

export default useDefaultTabInitially;
