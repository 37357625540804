import { Dropdown, useObjectToDropdownOptions, DebouncedTextField } from '@optimization/ssi-common';
import { FormState } from '../../Main';
import { topographyTranslate, trafficTranslate } from '@optimization/sa-common';
import { useCallback } from 'react';

interface DropdownProps {
  disabled: boolean;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const UserFactors = ({ disabled, formState, setFormState }: DropdownProps) => {
  const onChangeTopography = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        topography: value,
      }));
    },
    [setFormState],
  );

  const onChangeTraffic = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        traffic: value,
      }));
    },
    [setFormState],
  );

  const onChangeWinterMinTemp = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: Number.isInteger(parseInt(value)),
        temperatureWinterC: value,
      }));
    },
    [setFormState],
  );

  const onChangeSummerMaxTemp = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: Number.isInteger(parseInt(value)),
        temperatureSummerC: value,
      }));
    },
    [setFormState],
  );

  const topographyOptions = useObjectToDropdownOptions({
    object: topographyTranslate,
  });

  const trafficOptions = useObjectToDropdownOptions({
    object: trafficTranslate,
  });

  return (
    <>
      <Dropdown
        value={formState.topography}
        label="Topography"
        placeholder="Topography"
        labelPosition="outside"
        className="tds-u-mt2s"
        dataTestid="dropdown-candidate-detail-topography"
        options={topographyOptions}
        disabled={disabled}
        onChange={onChangeTopography}
      />
      <Dropdown
        value={formState.traffic}
        label="Traffic"
        placeholder="Traffic"
        labelPosition="outside"
        className="tds-u-mt2s"
        options={trafficOptions}
        disabled={disabled}
        onChange={onChangeTraffic}
      />
      <DebouncedTextField
        label="Min. temperature"
        type="number"
        value={formState.temperatureWinterC}
        disabled={disabled}
        onChange={onChangeWinterMinTemp}
        min={-100}
        max={100}
      />
      <DebouncedTextField
        label="Max. temperature"
        type="number"
        value={formState.temperatureSummerC}
        disabled={disabled}
        onChange={onChangeSummerMaxTemp}
        min={-100}
        max={100}
      />
    </>
  );
};

export default UserFactors;
