import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedChassisAdaptation = ({
  vehicles,
  truckChassisAdaptationList,
}: {
  vehicles: ListVehicleTransformed[];
  truckChassisAdaptationList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const engineGearboxCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const truckChassisAdaptation = vehicle.ChassisAdaptation;

        return increaseValue({
          acc,
          key: truckChassisAdaptation,
          list: truckChassisAdaptationList,
        });
      }, {});

      result = getMaxKey({ countByName: engineGearboxCount });
    }

    return result;
  }, [vehicles, truckChassisAdaptationList]);

export default useSuggestedChassisAdaptation;
