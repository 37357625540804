import { ListVehicleEnhanced, SALES_READY_TEXT } from '@optimization/sa-common';
import { useMemo } from 'react';

const moveItemToFront = (arr: string[], item: string) => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
    arr.unshift(item);
  }

  return arr;
};

const useLaunchPeriods = (vehicles?: ListVehicleEnhanced[]): string[] =>
  useMemo(() => {
    if (!vehicles) {
      return [];
    }

    const launchPeriods = [
      ...new Set(
        vehicles
          .map((vehicle) => vehicle.transformed.launchPeriodPresentation)
          .filter((launchPeriodPresentation) => launchPeriodPresentation),
      ),
    ].sort();

    const launchPeriodsSorted = moveItemToFront(launchPeriods, SALES_READY_TEXT);
    return launchPeriodsSorted;
  }, [vehicles]);

export default useLaunchPeriods;
