import CardTegel from 'common/components/CardTegel';
import classes from './style.module.scss';
import Grid from '../utils/Grid';
import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import productManualCategories from 'app/config/productManualCategories';
import ProductManualHeader from '../utils/ProductManualHeader';
import { InlineTabs } from '@optimization/ssi-common';
import { Link, useParams } from 'react-router-dom';
import { TdsIcon } from '@scania/tegel-react';
import { useMemo } from 'react';

const tabs = [{ name: 'Use cases' }];

const ProductManualOverview = () => {
  const { categorySlug } = useParams();

  const category = useMemo(
    () => productManualCategories.find((productManualCategory) => productManualCategory.slug === categorySlug),
    [categorySlug],
  );

  if (!category) {
    return <PageNotFound />;
  }

  return (
    <div data-testid="product-manual-overview">
      <HelmetTitle items={[category.name, 'Overview']} />
      <ProductManualHeader categoryName={category.name} subCategoryName="Overview" />
      <InlineTabs tabs={tabs} />
      <Grid className={classes.grid}>
        {category.subCategories.map((subCategory) => (
          <Link
            key={subCategory.slug}
            className="no-underline"
            to={`/product-manual/${category.slug}/${subCategory.slug}`}
          >
            <CardTegel
              header={subCategory.name}
              bodyImage={
                <div slot="body-image" className="tds-background-grey-200 tds-display-flex tds-u-flex-center h-200">
                  <img
                    height="140px"
                    src={subCategory.body.find((b) => b.type === 'image')?.url || ''}
                    alt={subCategory.name}
                  />
                </div>
              }
              actions={<TdsIcon style={{ color: 'rgba(13, 15, 19, 1)' }} size="20px" name="arrow_right" />}
            />
          </Link>
        ))}
      </Grid>
    </div>
  );
};

export default ProductManualOverview;
