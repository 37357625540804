import { Dropdown } from '@optimization/ssi-common';
import { EcItem } from './types';
import { PtoDevices } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  dataTestid?: string;
  ecItems: EcItem[];
  ptoDevices: PtoDevices;
  disabled?: boolean;
  openDirection?: 'auto' | 'up' | 'down';
  onChangePtoDevices: (value: string) => void;
}

const PtoDevicesSelector = ({
  dataTestid,
  ecItems,
  ptoDevices,
  disabled,
  openDirection,
  onChangePtoDevices,
}: Props) => {
  const value = useMemo(() => ecItems.map((item) => item.Type).join(','), [ecItems]);

  const options = useMemo(
    () =>
      Object.keys(ptoDevices).map((type) => ({
        name: ptoDevices[type].Name,
        value: type,
      })),
    [ptoDevices],
  );

  return (
    <Dropdown
      label="PTO devices"
      labelPosition="outside"
      placeholder="Choose an option..."
      dataTestid={dataTestid}
      value={value}
      disabled={disabled}
      multiselect
      options={options}
      openDirection={openDirection}
      onChange={onChangePtoDevices}
    />
  );
};

export default PtoDevicesSelector;
