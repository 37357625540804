import { formatDateInYearMonthDay, isNumeric, isValidDate } from '@optimization/ssi-common';
import { GetStopsParams } from '..';
import { useCallback, useMemo } from 'react';
import { useInitStopsMutation } from 'app/services/solution';

interface Props {
  chassisNumbers: number[];
  getStopsParamsRef: React.MutableRefObject<GetStopsParams>;
  solutionId: string;
}

const useInitStopsCallback = ({ chassisNumbers, getStopsParamsRef, solutionId }: Props) => {
  const [initStops, initStopsState] = useInitStopsMutation();

  const initStopsCallback = useCallback(() => {
    const startDate = new Date(getStopsParamsRef.current.startDate);
    const endDate = new Date(getStopsParamsRef.current.endDate);
    const minDuration = getStopsParamsRef.current.minDuration;
    const maxDuration = getStopsParamsRef.current.maxDuration;
    const resolution = getStopsParamsRef.current.resolution;

    if (isValidDate(startDate) && isValidDate(endDate) && isNumeric(minDuration) && isNumeric(maxDuration)) {
      initStops({
        solutionId,
        ChassisNumbers: chassisNumbers.map(String),
        MinDuration: parseInt(minDuration) * 60,
        MaxDuration: parseInt(maxDuration) * 60,
        OldestDateTime: formatDateInYearMonthDay(startDate),
        NewestDateTime: formatDateInYearMonthDay(endDate),
        Resolution: resolution,
      });
    }
  }, [initStops, getStopsParamsRef, chassisNumbers, solutionId]);

  return useMemo(() => ({ initStopsCallback, initStopsState }), [initStopsCallback, initStopsState]);
};

export default useInitStopsCallback;
