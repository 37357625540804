import ActionLayout from 'common/components/ActionLayout';
import CreateSolution from './utils/CreateSolution';
import FiltersSelector from 'common/components/Filters/Selector';
import FiltersToggle from 'common/components/Filters/Toggle';
import Grid from 'common/components/Grid';
import LoadMore from 'common/components/LoadMore';
import PageHeader from 'common/components/PageHeader';
import SolutionTeaser from './utils/SolutionTeaser';
import TotalCount from 'common/components/TotalCount';
import useDeleteSolutions from './utils/useDeleteSolutions';
import useFilteredSolutions from './utils/useFilteredSolutions';
import useFiltersHandler from 'common/components/Filters/utils/useFiltersHandler';
import useHandleItemSelected from 'common/hooks/useHandleItemSelected';
import useHandleSelectAll from 'common/hooks/useHandleSelectAll';
import useLoadingText from 'common/hooks/useLoadingText';
import useManageSolutionPopoverOptions from './utils/useManageSolutionPopoverOptions';
import useMultiselectChange from 'common/components/Filters/utils/useMultiselectChange';
import useParseMultipleValues from 'common/components/Filters/utils/useParseMultipleValues';
import useRemoveFilter from 'common/components/Filters/utils/useRemoveFilter';
import useSelectedFilters from './utils/useSelectedFilters';
import useSelectedSolutionsById from './utils/useSelectedSolutionsById';
import useSolutionNames from 'common/components/Filters/utils/useSolutionNames';
import { APP_TITLE } from 'app/config';
import { MULTIPLE_VALUES_SEPARATOR } from 'app/config';
import { Option } from 'common/components/Filters/utils/types';
import { SolutionMetadataTransformed } from '@optimization/sa-common';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useGetSolutionsQuery } from 'app/services/solution';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useSearchParams } from 'react-router-dom';
import {
  useSortItems,
  useSplicedItems,
  SortFunctions,
  Button,
  ButtonAlt,
  Checkbox,
  Dropdown,
  Loading,
  Modal,
  PopoverMenu,
  useNamesToDropdownOptions,
  usePlural,
} from '@optimization/ssi-common';
import {
  sortByNameAsc,
  sortByNameDesc,
  sortByVehiclesCountAsc,
  sortByVehiclesCountDesc,
  sortModifiedAsc,
  sortModifiedDesc,
} from '@optimization/sa-common';

const CLEAR_ALL_AT_ONCE_KEYS = ['solutionNames'];
const SORT_DIRECTION_DEFAULT = 'desc';

const sortOptions: Option[] = [
  { type: 'date', name: 'Date' },
  { type: 'name', name: 'Name' },
  { type: 'size', name: 'Size' },
];

const sortFunctions: SortFunctions = {
  date: {
    asc: sortModifiedAsc,
    desc: sortModifiedDesc,
  },
  name: {
    asc: sortByNameAsc,
    desc: sortByNameDesc,
  },
  size: {
    asc: sortByVehiclesCountAsc,
    desc: sortByVehiclesCountDesc,
  },
};

export type ModeType = 'delete';

const SolutionManager = () => {
  const solutionsQuery = useGetSolutionsQuery();

  const actionLayoutRef = useRef<HTMLDivElement | null>(null);
  const [selectedMode, setSelectedMode] = useState<ModeType>();
  const [selectedSolutions, setSelectedSolutions] = useState<string[]>([]);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [solutionsCount, setSolutionsCount] = useState<number>();

  const [searchParams] = useSearchParams();

  const filterParams = useMemo(
    () => ({
      solutionNames: searchParams.get('solutionNames') || '',
      sort: searchParams.get('sort') || 'date',
      sortDirection: searchParams.get('sortDirection') || SORT_DIRECTION_DEFAULT,
    }),
    [searchParams],
  );

  const { sort: sortType, sortDirection } = filterParams;

  const selectedSolutionNames = useParseMultipleValues(filterParams.solutionNames);

  const filtersHandler = useFiltersHandler({
    filterParams,
    sortDirectionDefault: SORT_DIRECTION_DEFAULT,
  });

  const selectedFilters = useSelectedFilters({ selectedSolutionNames });

  const onRemoveFilter = useRemoveFilter({
    filterParams,
    clearAllAtOnceKeys: CLEAR_ALL_AT_ONCE_KEYS,
  });

  const onChangeSolutionNames = useMultiselectChange({
    filterParams,
    filterKey: 'solutionNames',
  });

  const filteredSolutions = useFilteredSolutions({
    solutions: solutionsQuery.data,
    selectedSolutionNames,
  });

  const sortedSolutions = useSortItems<SolutionMetadataTransformed>({
    items: filteredSolutions,
    sortFunctions,
    sortType,
    sortDirection,
  });

  const splicedSolutions = useSplicedItems<SolutionMetadataTransformed>({
    items: sortedSolutions,
    count: solutionsCount,
  });

  const onShowDeleteModal = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onDeleteSuccess = useCallback(() => {
    onCloseDeleteModal();
    setSelectedMode(undefined);
    setSelectedSolutions([]);
  }, [onCloseDeleteModal]);

  const onDeleteFailure = useCallback(() => {
    onCloseDeleteModal();
  }, [onCloseDeleteModal]);

  const { deleteSolutions, deleteSolutionsState } = useDeleteSolutions({
    solutionIds: selectedSolutions,
    onSuccess: onDeleteSuccess,
    onFailure: onDeleteFailure,
  });

  const handleItemSelected = useHandleItemSelected({
    setSelectedItems: setSelectedSolutions,
  });

  const handleSelectAllExisting = useHandleSelectAll({
    items: filteredSolutions,
    setSelectedItems: setSelectedSolutions,
  });

  const cancelAction = useCallback(() => {
    setSelectedMode(undefined);
    setSelectedSolutions([]);
  }, []);

  const hasSolutions = useMemo(() => Boolean(solutionsQuery.data && solutionsQuery.data.length > 0), [solutionsQuery]);

  useMakeElementsSticky({
    actionLayoutRef: hasSolutions ? actionLayoutRef : undefined,
  });

  const manageSolutionPopoverOptions = useManageSolutionPopoverOptions({
    setSelectedMode,
    setShowActionMenu,
  });

  const solutionNames = useSolutionNames({ solutions: solutionsQuery.data });

  const solutionNamesOptions = useNamesToDropdownOptions({
    names: solutionNames,
  });

  const solutionsPluralLowercase = usePlural({
    count: selectedSolutions.length,
    uppercase: false,
  });

  const solutionsPluralUppercase = usePlural({
    count: selectedSolutions.length,
    uppercase: true,
  });

  const selectedSolutionsById = useSelectedSolutionsById({
    splicedSolutions,
    selectedSolutions,
  });

  const allSolutionsIsSelected = useMemo(
    () => selectedSolutions.length === filteredSolutions?.length,
    [selectedSolutions, filteredSolutions],
  );

  const loadingText = useLoadingText({
    isLoadingSolutions: solutionsQuery.isLoading,
    isDeletingSolutions: deleteSolutionsState.isLoading,
  });

  const isLoading = solutionsQuery.isLoading || deleteSolutionsState.isLoading;
  const isError = solutionsQuery.isError || deleteSolutionsState.isError;

  return (
    <div className="tds-container" data-testid="solution-manager-page">
      <Loading isError={isError} isLoading={isLoading} loadingText={loadingText} />
      {showDeleteModal && (
        <Modal
          size="xs"
          header={`Delete solution${solutionsPluralLowercase}`}
          disabled={isLoading}
          ctaConfirmText="Delete"
          ctaConfirmSubmitDataTestid="button-confirm-delete-solutions"
          ctaConfirmSubmit={deleteSolutions}
          onClose={onCloseDeleteModal}
        >
          Are you sure you want to delete {selectedSolutions.length} solution
          {solutionsPluralLowercase} from this profile?
        </Modal>
      )}
      <PageHeader
        headerClassName="max-w-700"
        description={[
          `${APP_TITLE} enables solution-level analysis. A solution is
            grouped around a customer, and is meant to hold definitions of the
            customer's future, electrified vehicles and depot charging
            infrastructure. The increasingly detailed and analyzed solution
            results in a presentable electrification plan with yearly
            performance steps.`,
        ]}
        cta={
          solutionsQuery.isSuccess && (
            <CreateSolution
              disabled={Boolean(selectedMode)}
              variant={solutionsQuery.data?.length ? 'secondary' : 'primary'}
            />
          )
        }
      >
        Your solutions
      </PageHeader>
      {hasSolutions && (
        <ActionLayout
          actionLayoutRef={actionLayoutRef}
          className="mt-spacing-72"
          left={
            <>
              {selectedMode === 'delete' ? (
                <>
                  <div className="flex">
                    <Button
                      text="Delete"
                      size="sm"
                      dataTestid="button-delete-solutions-action"
                      disabled={!selectedSolutions.length || isLoading}
                      onClick={onShowDeleteModal}
                      variant={selectedSolutions.length ? 'danger' : 'secondary'}
                    />
                    <Button
                      className="ml-spacing-16"
                      variant="secondary"
                      text="Close"
                      size="sm"
                      onClick={cancelAction}
                    />
                  </div>
                  <div className="flex items-center tds-u-mt2">
                    <TotalCount
                      count={selectedSolutions.length}
                      entityName={`SOLUTION${solutionsPluralUppercase} SELECTED`}
                      autoPlural={false}
                    />
                    <Checkbox
                      label="Select All"
                      value="select-all"
                      className="ml-spacing-16"
                      dataTestid="checkbox-select-all-solutions"
                      onChange={handleSelectAllExisting}
                      disabled={isLoading}
                      checked={allSolutionsIsSelected}
                    />
                  </div>
                </>
              ) : (
                <TotalCount className="tds-u-mt2" count={filteredSolutions?.length} entityName="SOLUTION" />
              )}
            </>
          }
          right={
            <div className="flex items-center">
              <FiltersToggle
                sortType={sortType}
                sortDirection={sortDirection === 'asc' ? 'asc' : 'desc'}
                sortOptions={sortOptions}
                activeAction={filtersHandler.activeFiltersAction}
                toggleFilters={filtersHandler.toggleFilters}
                toggleSortBy={filtersHandler.toggleSortBy}
                toggleSortDirection={filtersHandler.toggleSortDirection}
                selectedFilters={selectedFilters}
                onRemoveFilter={onRemoveFilter}
              />
              <PopoverMenu
                show={showActionMenu}
                options={manageSolutionPopoverOptions}
                hidePopoverMenu={() => setShowActionMenu(false)}
                toggle={
                  <ButtonAlt
                    tdsIcon="kebab"
                    dataTestid="button-toggle-solutions-action-menu"
                    tdsIconSize={30}
                    style={{ height: '40px' }}
                    onClick={() => setShowActionMenu((prev) => !prev)}
                  />
                }
              />
            </div>
          }
          below={
            <FiltersSelector
              sortType={sortType}
              sortOptions={sortOptions}
              onChangeSortType={filtersHandler.onChangeSortType}
              activeAction={filtersHandler.activeFiltersAction}
              hideFilters={filtersHandler.hideFilters}
            >
              <Dropdown
                label="Filter by solution name"
                labelPosition="outside"
                placeholder="Select solution name"
                className="w-250"
                multiselect
                filter
                options={solutionNamesOptions}
                value={selectedSolutionNames.join(MULTIPLE_VALUES_SEPARATOR)}
                onChange={onChangeSolutionNames}
              />
            </FiltersSelector>
          }
        />
      )}
      {splicedSolutions && (
        <>
          <Grid dataTestid="solutions-grid">
            {splicedSolutions.map((solution) => (
              <SolutionTeaser
                key={solution.Id}
                solutionId={solution.Id}
                solutionName={solution.transformed.solutionNamePresentation}
                vehiclesCount={solution.VehiclesCount}
                depotsCount={solution.DepotsCount}
                modifiedAt={solution.ModifiedAt.toString()}
                checked={Boolean(selectedSolutionsById[solution.Id])}
                onChangeCheckbox={selectedMode === 'delete' ? handleItemSelected : undefined}
              />
            ))}
          </Grid>
          <LoadMore
            contentName="Solutions"
            itemsCount={splicedSolutions.length}
            totalCount={filteredSolutions?.length}
            setItemsCount={setSolutionsCount}
          />
        </>
      )}
    </div>
  );
};

export default SolutionManager;
