import { Season } from '@optimization/sa-common';

export const temperatures: { winter: number; summer: number; name: string }[] = [
  { winter: -15, summer: 5, name: 'Cold Climate' },
  { winter: -5, summer: 20, name: 'Standard European Climate' },
  { winter: 10, summer: 40, name: 'Warm Climate' },
];

export const valueToTemperature = (value: string, type: Season): number => {
  let index = type === 'winter' ? 0 : 1;
  let tempStr = value.split('|')[index];

  if (tempStr) {
    return parseInt(tempStr);
  }

  return 0;
};

export const temperaturesToValue = (winterTemperature?: number, summerTemperature?: number): string => {
  if (winterTemperature && summerTemperature) {
    return `${winterTemperature}|${summerTemperature}`;
  }

  return '0|0';
};
