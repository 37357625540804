import classes from './style.module.scss';
import classNames from 'classnames';
import getNavLinkClassName from './utils/getNavLinkClassName';
import ProductManualLink from './utils/ProductManualLink';
import TourLink from './utils/TourLink';
import usePersistentCollapsedHandler from './utils/usePersistentCollapsedHandler';
import { invariant, LightningAltIcon, ShowcaseIcon } from '@optimization/ssi-common';
import { NavLink, useParams } from 'react-router-dom';
import { TdsIcon, TdsSideMenu, TdsSideMenuItem } from '@scania/tegel-react';
import { useElementController } from 'app/context/ElementControllerContext';

const SideNavbarDefault = ({ children }: { children: React.ReactNode }) => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const elementController = useElementController();

  const { persistent, collapsed, sideMenuKey, showIconTitle } = usePersistentCollapsedHandler();

  return (
    <div className="side-menu-wrapper">
      {elementController?.enableSidebar ? (
        <TdsSideMenu
          id="side-menu"
          aria-label="Side menu"
          ref={elementController.sideMenuRef}
          persistent={persistent}
          collapsed={collapsed}
          key={sideMenuKey}
        >
          <TdsSideMenuItem>
            <NavLink to={`/solution/${solutionId}/home`} className={getNavLinkClassName}>
              <TdsIcon name="home" size="24" title={showIconTitle ? 'Solution home' : ''} />
              Solution home
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem data-testid="button-vehicle-candidates-menu-item">
            <NavLink to={`/solution/${solutionId}/candidate`} className={getNavLinkClassName}>
              <TdsIcon name="truck" size="24" title={showIconTitle ? 'Vehicle candidates' : ''} />
              Vehicle candidates
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem data-testid="button-depots-menu-item">
            <NavLink to={`/solution/${solutionId}/infrastructure`} className={getNavLinkClassName}>
              <LightningAltIcon
                title={showIconTitle ? 'Depot management' : ''}
                className={classNames({
                  [classes['custom-icon-collapsed']]: collapsed,
                })}
              />
              Depot management
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem data-testid="button-solution-showcase-menu-item">
            <NavLink to={`/solution/${solutionId}/presentation-configurator`} className={getNavLinkClassName}>
              <ShowcaseIcon
                sizeClassName="w-26 h-26"
                title={showIconTitle ? 'Presentation configurator' : ''}
                className={classNames({
                  [classes['custom-icon-collapsed']]: collapsed,
                })}
              />
              Presentation configurator
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem slot="sticky-end">
            <TourLink showIconTitle={showIconTitle} />
          </TdsSideMenuItem>
          <TdsSideMenuItem slot="sticky-end">
            <ProductManualLink showIconTitle={showIconTitle} />
          </TdsSideMenuItem>
        </TdsSideMenu>
      ) : null}
      <div className={classes['container-margin']}>{children}</div>
    </div>
  );
};

export default SideNavbarDefault;
