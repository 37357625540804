import { DebouncedTimeField } from '@optimization/ssi-common';
import { CandidateChartFormValues } from '../utils/types';
import { Datum } from 'react-charts';
import { Messages } from '../utils/useMessages';
import { useCallback } from 'react';

interface Props {
  currentDatum: Datum<unknown> | null;
  departureTime: string;
  slowChargeStartTime: string;
  messages?: Messages;
  setCandidateChartFormValues: React.Dispatch<React.SetStateAction<CandidateChartFormValues>>;
}

const DepartureAndSlowChargeFields = ({
  currentDatum,
  departureTime,
  slowChargeStartTime,
  messages,
  setCandidateChartFormValues,
}: Props) => {
  const onChangeDepartureTime = useCallback(
    (value: string) => {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        departureTime: value,
        runSaveSlowChargingStartTimeAndDepartureTime: true,
      }));
    },
    [setCandidateChartFormValues],
  );

  const onChangeSlowChargeStartTime = useCallback(
    (value: string) => {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        slowChargeStartTime: value,
        runSaveSlowChargingStartTimeAndDepartureTime: true,
      }));
    },
    [setCandidateChartFormValues],
  );

  return (
    <>
      <DebouncedTimeField
        label="Departure time"
        onChange={onChangeDepartureTime}
        disabled={Boolean(currentDatum)}
        value={departureTime}
        helper={messages?.departureTimeErrorMessage?.header}
        state={messages?.departureTimeErrorMessage ? 'error' : 'none'}
      />
      <DebouncedTimeField
        label="Slow-charge start time"
        onChange={onChangeSlowChargeStartTime}
        disabled={Boolean(currentDatum)}
        value={slowChargeStartTime}
        helper={messages?.slowChargeTimeErrorMessage?.header}
        state={messages?.slowChargeTimeErrorMessage ? 'error' : 'none'}
      />
    </>
  );
};

export default DepartureAndSlowChargeFields;
