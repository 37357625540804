import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depots?: ListDepotTransformed[];
}

const useDepotsWithWarning = ({ depots }: Props) =>
  useMemo(() => {
    if (depots) {
      const depotsWithWarning = depots
        .filter((depot) => {
          const errorStates = depot.DepotDataPerPerformanceStep?.[depot.transformed.depotDefaultYear]?.ErrorStates;

          return errorStates
            ? errorStates.NotEnoughPower || errorStates.NotEnoughPowerBlocks || errorStates.NotEnoughSatellites
            : false;
        })
        .splice(0, 6);

      if (depotsWithWarning.length) {
        return depotsWithWarning;
      }
    }

    return undefined;
  }, [depots]);

export default useDepotsWithWarning;
