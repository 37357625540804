import useSuggestedAxleDistance from './useSuggestedAxleDistance';
import useSuggestedBattery from './useSuggestedBattery';
import useSuggestedBodyLength from './useSuggestedBodyLength';
import useSuggestedBusWheelConfiguration from './useSuggestedBusWheelConfiguration';
import useSuggestedCabType from './useSuggestedCabType';
import useSuggestedCharginType from './useSuggestedCharginType';
import useSuggestedChassisAdaptation from './useSuggestedChassisAdaptation';
import useSuggestedChassisHeight from './useSuggestedChassisHeight';
import useSuggestedEngineGearBox from './useSuggestedEngineGearBox';
import useSuggestedFloorHeight from './useSuggestedFloorHeight';
import useSuggestedPortfolio from './useSuggestedPortfolio';
import useSuggestedSoc from './useSuggestedSoc';
import useSuggestedTruckWheelConfiguration from './useSuggestedTruckWheelConfiguration';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useEffect } from 'react';

interface Props {
  chassisType: string;
  vehicles?: ListVehicleTransformed[];
  truckPortfolioList: string[];
  truckBatteryList: number[];
  truckSocList: number[];
  truckEngineGearBoxList: string[];
  truckChassisAdaptationList: string[];
  truckWheelConfigurationList: string[];
  truckAxleDistanceList: string[];
  truckChassisHeightList: string[];
  truckCabTypeList: string[];
  busPortfolioList: string[];
  busBatteryList: number[];
  busSocList: number[];
  busChargingTypeList: string[];
  busEngineGearBoxList: string[];
  busWheelConfigurationList: string[];
  busBodyLengthList: string[];
  busChassisHeightList: string[];
  busFloorHeightList: string[];
  preSelectOptionsInitially?: boolean;
  enableSuggestions: boolean;
  setSelections: React.Dispatch<React.SetStateAction<SelectionExecutions>>;
}

const useLoadSelectionBySuggestion = ({
  chassisType,
  vehicles = [],
  truckPortfolioList,
  truckBatteryList,
  truckSocList,
  truckEngineGearBoxList,
  truckChassisAdaptationList,
  truckWheelConfigurationList,
  truckAxleDistanceList,
  truckChassisHeightList,
  truckCabTypeList,
  busPortfolioList,
  busBatteryList,
  busSocList,
  busChargingTypeList,
  busEngineGearBoxList,
  busWheelConfigurationList,
  busBodyLengthList,
  busChassisHeightList,
  busFloorHeightList,
  preSelectOptionsInitially,
  enableSuggestions,
  setSelections,
}: Props) => {
  // Truck suggestions
  const suggestedTruckPortfolio = useSuggestedPortfolio({
    vehicles,
    portfolioList: truckPortfolioList,
  });

  const suggestedTruckBattery = useSuggestedBattery({ vehicles, batteryList: truckBatteryList });

  const suggestedTruckSoc = useSuggestedSoc({ vehicles, socList: truckSocList });

  const suggestedTruckEngineGearBox = useSuggestedEngineGearBox({
    vehicles,
    engineGearBoxList: truckEngineGearBoxList,
  });

  const suggestedTruckChassisAdaptation = useSuggestedChassisAdaptation({
    vehicles,
    truckChassisAdaptationList,
  });

  const suggestedTruckWheelConfiguration = useSuggestedTruckWheelConfiguration({
    vehicles,
    wheelConfigurationList: truckWheelConfigurationList,
  });

  const suggestedTruckAxleDistance = useSuggestedAxleDistance({
    vehicles,
    truckAxleDistanceList,
  });

  const suggestedTruckChassisHeight = useSuggestedChassisHeight({
    vehicles,
    chassisHeightList: truckChassisHeightList,
  });

  const suggestedTruckCabType = useSuggestedCabType({
    vehicles,
    truckCabTypeList,
  });

  // Bus suggestions
  const suggestedBusPortfolio = useSuggestedPortfolio({
    vehicles,
    portfolioList: busPortfolioList,
  });

  const suggestedBusBattery = useSuggestedBattery({ vehicles, batteryList: busBatteryList });

  const suggestedBusSoc = useSuggestedSoc({ vehicles, socList: busSocList });

  const suggestedBusChargingType = useSuggestedCharginType({
    vehicles,
    chargingTypeList: busChargingTypeList,
  });

  const suggestedBusEngineGearBox = useSuggestedEngineGearBox({
    vehicles,
    engineGearBoxList: busEngineGearBoxList,
  });

  const suggestedBusWheelConfiguration = useSuggestedBusWheelConfiguration({
    vehicles,
    wheelConfigurationList: busWheelConfigurationList,
  });

  const suggestedBusBodyLength = useSuggestedBodyLength({
    vehicles,
    bodyLengthList: busBodyLengthList,
  });

  const suggestedBusChassisHeight = useSuggestedChassisHeight({
    vehicles,
    chassisHeightList: busChassisHeightList,
  });

  const suggestedBusFloorHeight = useSuggestedFloorHeight({
    vehicles,
    floorHeightList: busFloorHeightList,
  });

  useEffect(() => {
    if (chassisType === 'Truck' && suggestedTruckPortfolio && preSelectOptionsInitially) {
      setSelections((prev) => ({
        ...prev,
        isChangedByUser: true,
        truckPortfolio: !prev.truckPortfolio ? suggestedTruckPortfolio : prev.truckPortfolio,
      }));
    }
  }, [chassisType, suggestedTruckPortfolio, preSelectOptionsInitially, setSelections]);

  useEffect(() => {
    if (chassisType === 'Bus' && suggestedBusPortfolio && preSelectOptionsInitially) {
      setSelections((prev) => ({
        ...prev,
        isChangedByUser: true,
        busPortfolio: !prev.busPortfolio ? suggestedBusPortfolio : prev.busPortfolio,
      }));
    }
  }, [chassisType, suggestedBusPortfolio, preSelectOptionsInitially, setSelections]);

  useEffect(() => {
    setSelections((prev) =>
      prev.isChangedByUser
        ? {
            ...prev,
            truckPortfolio:
              !prev.truckPortfolio && suggestedTruckPortfolio && enableSuggestions
                ? suggestedTruckPortfolio
                : prev.truckPortfolio,
            truckBattery:
              !prev.truckBattery && suggestedTruckBattery && enableSuggestions
                ? suggestedTruckBattery
                : prev.truckBattery,
            truckSocWindow:
              !prev.truckSocWindow && suggestedTruckSoc && enableSuggestions ? suggestedTruckSoc : prev.truckSocWindow,
            truckElectricMotor:
              !prev.truckElectricMotor && suggestedTruckEngineGearBox && enableSuggestions
                ? suggestedTruckEngineGearBox
                : prev.truckElectricMotor,
            truckChassisAdaptation:
              !prev.truckChassisAdaptation && suggestedTruckChassisAdaptation && enableSuggestions
                ? suggestedTruckChassisAdaptation
                : prev.truckChassisAdaptation,
            truckWheelConfiguration:
              !prev.truckWheelConfiguration && suggestedTruckWheelConfiguration && enableSuggestions
                ? suggestedTruckWheelConfiguration
                : prev.truckWheelConfiguration,
            truckAxleDistance:
              !prev.truckAxleDistance && suggestedTruckAxleDistance && enableSuggestions
                ? suggestedTruckAxleDistance
                : prev.truckAxleDistance,
            truckChassisHeight:
              !prev.truckChassisHeight && suggestedTruckChassisHeight && enableSuggestions
                ? suggestedTruckChassisHeight
                : prev.truckChassisHeight,
            truckCabType: suggestedTruckCabType && enableSuggestions ? suggestedTruckCabType : prev.truckCabType,
            busPortfolio:
              !prev.busPortfolio && suggestedBusPortfolio && enableSuggestions
                ? suggestedBusPortfolio
                : prev.busPortfolio,
            busBattery:
              !prev.busBattery && suggestedBusBattery && enableSuggestions ? suggestedBusBattery : prev.busBattery,
            busSocWindow:
              !prev.busSocWindow && suggestedBusSoc && enableSuggestions ? suggestedBusSoc : prev.busSocWindow,
            busChargingType:
              !prev.busChargingType && suggestedBusChargingType && enableSuggestions
                ? suggestedBusChargingType
                : prev.busChargingType,
            busElectricMotor:
              !prev.busElectricMotor && suggestedBusEngineGearBox && enableSuggestions
                ? suggestedBusEngineGearBox
                : prev.busElectricMotor,
            busWheelConfiguration:
              !prev.busWheelConfiguration && suggestedBusWheelConfiguration && enableSuggestions
                ? suggestedBusWheelConfiguration
                : prev.busWheelConfiguration,
            busBodyLength:
              !prev.busBodyLength && suggestedBusBodyLength && enableSuggestions
                ? suggestedBusBodyLength
                : prev.busBodyLength,
            busChassisHeight:
              !prev.busChassisHeight && suggestedBusChassisHeight && enableSuggestions
                ? suggestedBusChassisHeight
                : prev.busChassisHeight,
            busFloorHeight:
              !prev.busFloorHeight && suggestedBusFloorHeight && enableSuggestions
                ? suggestedBusFloorHeight
                : prev.busFloorHeight,
          }
        : prev,
    );
  }, [
    suggestedTruckPortfolio,
    suggestedTruckBattery,
    suggestedTruckSoc,
    suggestedTruckEngineGearBox,
    suggestedTruckChassisAdaptation,
    suggestedTruckWheelConfiguration,
    suggestedTruckAxleDistance,
    suggestedTruckChassisHeight,
    suggestedTruckCabType,
    suggestedBusPortfolio,
    suggestedBusBattery,
    suggestedBusSoc,
    suggestedBusChargingType,
    suggestedBusEngineGearBox,
    suggestedBusWheelConfiguration,
    suggestedBusBodyLength,
    suggestedBusChassisHeight,
    suggestedBusFloorHeight,
    enableSuggestions,
    setSelections,
  ]);
};

export default useLoadSelectionBySuggestion;
