import classes from './style.module.scss';
import useLoadingText from 'common/hooks/useLoadingText';
import { Button, Checkbox, Loading } from '@optimization/ssi-common';
import { useCalculateVehicleTcoFiguresQuery, useUpdateVehicleTcoFiguresMutation } from 'src/app/services/solution';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFinancialEvaluation } from 'app/context/FinancialEvaluationContext';
import { useParams } from 'react-router-dom';
import {
  FinancialEvaluationVisual,
  useFinancialEvaluationChartData,
  VehicleEnhanced,
  financialEvaluationVehicleTypeOptions,
} from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
}

const FinancialEvaluation = ({ vehicle }: Props) => {
  const { solutionId } = useParams();
  const financialEvaluation = useFinancialEvaluation();

  const evaluationExists = useMemo(() => vehicle.TcoCalculations !== null, [vehicle]);

  const enableInPresentation = useMemo(() => Boolean(vehicle.TcoCalculations?.ShowInPresentationApp), [vehicle]);

  const [enableInPresentationChecked, setEnableInPresentationChecked] = useState(enableInPresentation);

  const [updateVehicle, updateVehicleState] = useUpdateVehicleTcoFiguresMutation();

  const vehicleCalculation = useCalculateVehicleTcoFiguresQuery(
    {
      solutionId: solutionId!,
      vehicleId: vehicle.Id,
      ReturnResidualValue: false,
      Currency: vehicle.TcoCalculations?.Currency ?? 'EUR',
      ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
      BevTcoFigures: vehicle.TcoCalculations?.BevVehicle!,
      ComparisonTcoFigures: vehicle.TcoCalculations?.ComparisonVehicle,
    },
    {
      skip:
        !evaluationExists ||
        !vehicle.TcoCalculations?.BevVehicle ||
        (vehicle.TcoCalculations?.BevVehicle.FinancingType === 'Leasing' &&
          vehicle.TcoCalculations?.BevVehicle.InterestRate === 0) ||
        (vehicle.TcoCalculations?.ComparisonVehicle?.FinancingType === 'Leasing' &&
          vehicle.TcoCalculations?.ComparisonVehicle.InterestRate === 0) ||
        vehicle.TcoCalculations?.BevVehicle?.VehiclePrice === 0 ||
        vehicle.TcoCalculations?.BevVehicle?.VehiclePrice <= vehicle.TcoCalculations?.BevVehicle?.ResidualValue ||
        (vehicle.TcoCalculations?.ComparisonVehicle &&
          vehicle.TcoCalculations?.ComparisonVehicle?.VehiclePrice <=
            vehicle.TcoCalculations?.ComparisonVehicle?.ResidualValue),
    },
  );

  const toggleEnableTcoInPresentation = useCallback(() => {
    if (vehicle.TcoCalculations) {
      const showInPresentationApp = !enableInPresentation;

      setEnableInPresentationChecked(showInPresentationApp);

      updateVehicle({
        solutionId: solutionId!,
        vehicleId: vehicle.Id,
        ReturnResidualValue: false,
        Currency: vehicle.TcoCalculations!.Currency,
        ShowInPresentationApp: showInPresentationApp,
        BevTcoFigures: vehicle.TcoCalculations!.BevVehicle!,
        ComparisonTcoFigures: vehicle.TcoCalculations!.ComparisonVehicle!,
      });
    }
  }, [updateVehicle, vehicle, solutionId, enableInPresentation]);

  useEffect(() => {
    setEnableInPresentationChecked(enableInPresentation);
  }, [enableInPresentation]);

  const fromHeader = useMemo(
    () =>
      evaluationExists && vehicle.TcoCalculations?.ComparisonVehicle
        ? financialEvaluationVehicleTypeOptions.find(
            (x) => x.value === vehicle.TcoCalculations!.ComparisonVehicle!.EngineType,
          )!.name
        : '...',
    [evaluationExists, vehicle],
  );

  const loadingText = useLoadingText({ isSaving: updateVehicleState.isLoading });

  const chartData = useFinancialEvaluationChartData(vehicle, vehicleCalculation.data?.Content);

  return (
    <div className={classes['financial-evaluation']}>
      <Loading
        isLoading={updateVehicleState.isLoading}
        isError={updateVehicleState.isError}
        loadingText={loadingText}
      />
      <FinancialEvaluationVisual
        fromHeader={fromHeader}
        data={chartData}
        currency={evaluationExists ? vehicle.TcoCalculations?.Currency : 'EUR'}
        vehicleCalculation={vehicleCalculation.data?.Content}
      />
      <div className={classes.actions}>
        <Button
          variant={evaluationExists ? 'secondary' : 'primary'}
          size="sm"
          text={evaluationExists ? 'Edit evaluation' : 'Setup evaluation'}
          dataTestid="button-setup-financial-evaluation"
          onClick={financialEvaluation ? () => financialEvaluation.startSetupEvaluation(vehicle) : undefined}
        />
        <Checkbox
          onChange={toggleEnableTcoInPresentation}
          label="Enable TCO in presentation"
          value={enableInPresentationChecked ? 'true' : 'false'}
          checked={enableInPresentationChecked}
          disabled={!evaluationExists || updateVehicleState.isLoading || vehicleCalculation.isLoading}
        />
      </div>
    </div>
  );
};

export default FinancialEvaluation;
