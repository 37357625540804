import { useCallback } from 'react';

interface Props {
  activeFiltersAction: 'filter' | 'sort-by' | null;
  wantedFiltersAction: 'filter' | 'sort-by' | null;
  setAction: React.Dispatch<React.SetStateAction<'filter' | 'sort-by' | null>>;
}

const useToggleFiltersAction = ({ setAction, activeFiltersAction, wantedFiltersAction }: Props) =>
  useCallback(() => {
    if (activeFiltersAction !== wantedFiltersAction) {
      setAction(wantedFiltersAction);
    } else if (activeFiltersAction === wantedFiltersAction) {
      setAction(null);
    }
  }, [setAction, activeFiltersAction, wantedFiltersAction]);

export default useToggleFiltersAction;
