import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  vehicles?: ListVehicleEnhanced[];
}

const useChassisNames = ({ vehicles }: Props) =>
  useMemo(() => {
    if (vehicles) {
      return [...new Set(vehicles.map((vehicle) => vehicle.transformed.chassisIdPresentation))].splice(0, 100).sort();
    }

    return [];
  }, [vehicles]);

export default useChassisNames;
