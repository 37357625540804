import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles: (FactBasicTruck | FactBasicBus)[];
}

const usePortfolios = ({ basicVehicles }: Props): string[] =>
  useMemo(
    () => [
      ...new Set(
        basicVehicles
          .map((basicVehicle) => basicVehicle.Portfolios || [])
          .flat()
          .sort(),
      ),
    ],
    [basicVehicles],
  );

export default usePortfolios;
