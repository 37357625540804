import PtoCraneField from 'features/candidate/Pto/CraneField';
import PtoRefuseCollectorField from 'features/candidate/Pto/RefuseCollectorField';
import PtoTextField from 'features/candidate/Pto/TextField';
import { EcItem, SetEcItem, SubmitTextFieldByKeyboardEvent } from './types';
import { PtoDevices, PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  ecItems: EcItem[];
  ptoDevices: PtoDevices;
  disabled?: boolean;
  onCheckboxChange: (checked: boolean, value: string, type: PtoType) => void;
  setEcItem: SetEcItem;
  submitOnEnterKey: SubmitTextFieldByKeyboardEvent;
}

const useGetPtoField = ({ ecItems, ptoDevices, disabled, onCheckboxChange, setEcItem, submitOnEnterKey }: Props) =>
  useCallback(
    (type: PtoType) => {
      switch (type) {
        case 'Crane':
          return (
            <PtoCraneField
              ecItems={ecItems}
              ptoDevices={ptoDevices}
              disabled={disabled}
              onCheckboxChange={onCheckboxChange}
            />
          );
        case 'RefuseCollector':
          return (
            <PtoRefuseCollectorField
              ecItems={ecItems}
              ptoDevices={ptoDevices}
              disabled={disabled}
              onCheckboxChange={onCheckboxChange}
            />
          );
        case 'Tipper':
        case 'HookliftSkipholder':
        case 'TailLift':
        case 'CustomDevice':
          return (
            <PtoTextField
              ecItems={ecItems}
              ptoDevices={ptoDevices}
              type={type}
              setEcItem={setEcItem}
              disabled={disabled}
              submitOnEnterKey={submitOnEnterKey}
            />
          );
        default:
          return null;
      }
    },

    [ecItems, ptoDevices, disabled, onCheckboxChange, setEcItem, submitOnEnterKey],
  );

export default useGetPtoField;
