import { FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

const useTruckExceedingAvgGtw = ({
  selectedBasicTruck,
  highestAvgGtwFromSelectedCandidates,
}: {
  selectedBasicTruck: FactBasicTruck | null;
  highestAvgGtwFromSelectedCandidates: number;
}) => {
  const truckExceedingAvgGtw = useMemo(() => {
    if (!selectedBasicTruck) {
      return 0;
    }

    const selectedBasicTruckMaxGtw = selectedBasicTruck.TechnicalMaxGtwKg;

    return selectedBasicTruckMaxGtw && highestAvgGtwFromSelectedCandidates > selectedBasicTruckMaxGtw
      ? selectedBasicTruckMaxGtw
      : 0;
  }, [highestAvgGtwFromSelectedCandidates, selectedBasicTruck]);

  return truckExceedingAvgGtw;
};

export default useTruckExceedingAvgGtw;
