import engineGearboxCheck from './engineGearboxCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

const useTruckEngineGearBox = ({
  basicVehicles,
  selectedBattery,
  selectedSocWindow,
  selectedPortfolio,
}: {
  basicVehicles: (FactBasicTruck | FactBasicBus)[];
  selectedBattery: string;
  selectedSocWindow: string;
  selectedPortfolio: string;
}) =>
  useMemo(
    () => [
      ...new Set(
        basicVehicles
          .filter((basicVehicle) =>
            engineGearboxCheck({
              basicVehicle,
              selectedBattery,
              selectedSocWindow,
              selectedPortfolio,
            }),
          )
          .map((basicVehicle) => basicVehicle.EngineGearboxes.map((engineGearbox) => engineGearbox.Name))
          .flat(),
      ),
    ],
    [basicVehicles, selectedBattery, selectedSocWindow, selectedPortfolio],
  );

export default useTruckEngineGearBox;
