import getInitialElectrificationPlan from './getInitialElectrificationPlan';
import { KeyedString } from '@optimization/ssi-common';
import { TElectrificationPlan, VehiclesByPerformanceStep } from '..';
import { useEffect } from 'react';

interface Props {
  minimumPerformanceStepByVehicle: KeyedString;
  vehiclesByPerformanceStep?: VehiclesByPerformanceStep;
  setElectrificationPlan: React.Dispatch<React.SetStateAction<TElectrificationPlan>>;
}

const useSetElectrificationPlan = ({
  minimumPerformanceStepByVehicle,
  vehiclesByPerformanceStep,
  setElectrificationPlan,
}: Props) => {
  useEffect(() => {
    if (vehiclesByPerformanceStep) {
      const electrificationPlan = getInitialElectrificationPlan({
        vehiclesByPerformanceStep,
        minimumPerformanceStepByVehicle,
      });

      setElectrificationPlan(electrificationPlan);
    }
  }, [minimumPerformanceStepByVehicle, vehiclesByPerformanceStep, setElectrificationPlan]);
};

export default useSetElectrificationPlan;
