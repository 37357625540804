import { AVG_SPEED_DEFAULT, CRUISE_SPEED_DEFAULT, VehicleTransformed } from '@optimization/sa-common';
import { FormState } from '../Main';
import { SUMMER_MAX_TEMP_DEFEAULT, WINTER_MIN_TEMP_DEFAULT } from 'app/config';
import { useEffect, useMemo } from 'react';
import { useUpdateVehicleMutation } from 'app/services/solution';

interface Props {
  formState: FormState;
  solutionId: string;
  vehicle: VehicleTransformed;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const useHandleOperationalFactors = ({ solutionId, formState, vehicle, setFormState }: Props) => {
  const [updateVehicle, updateOperationFactorsState] = useUpdateVehicleMutation();

  useEffect(() => {
    if (formState.runUpdateVehicle) {
      updateVehicle({
        vehicle: {
          ...vehicle,
          ExecutedVehicleProduct: {
            ...vehicle.ExecutedVehicleProduct,
            AvgGtwKg: Number(formState.avgGtw) || vehicle.ExecutedVehicleProduct.AvgGtwKg,
            AvgSpeedKmh: Number(formState.avgSpeed) || AVG_SPEED_DEFAULT,
            CruiseSpeedKmh: Number(formState.cruisingSpeed) || CRUISE_SPEED_DEFAULT,
            DailyOdometerMedianKm: Number(formState.dailyOdometerMedian) || 0,
            DailyOdometerLongestKm: Number(formState.dailyOdometerLongest) || 0,
            Traffic: formState.traffic,
            Topography: formState.topography,
            TemperatureWinterC: formState.temperatureWinterC
              ? parseInt(formState.temperatureWinterC)
              : WINTER_MIN_TEMP_DEFAULT,
            TemperatureSummerC: formState.temperatureSummerC
              ? parseInt(formState.temperatureSummerC)
              : SUMMER_MAX_TEMP_DEFEAULT,
          },
        },
        solutionId,
      });

      setFormState((prev) => ({ ...prev, runUpdateVehicle: false }));
    }
  }, [vehicle, formState, solutionId, updateVehicle, setFormState]);

  return useMemo(
    () => ({
      isLoading: updateOperationFactorsState.isLoading,
      isError: updateOperationFactorsState.isError,
    }),
    [updateOperationFactorsState],
  );
};

export default useHandleOperationalFactors;
