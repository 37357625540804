import { h3ToGeo } from 'h3-js';
import { DEPOT_MARKER_ICON_SIZE } from '../';
import { ExistingDepotMarker, SuggestedDepotMarker } from './types';
import { IconLayer, PickingInfo } from 'deck.gl/typed';
import { HomeBase, ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depots: ListDepotTransformed[];
  homeBases: HomeBase[];
  onOpenExistingDepotPopover: (existingDepotMarker: ExistingDepotMarker) => void;
  onOpenSuggestedDepotPopover: (suggestedDepotMarker: SuggestedDepotMarker) => void;
}

const useLayers = ({ depots, homeBases, onOpenExistingDepotPopover, onOpenSuggestedDepotPopover }: Props) =>
  useMemo(
    () => [
      new IconLayer({
        id: 'existing-depot-layer',
        data: depots.filter((depot: ListDepotTransformed) => depot.H3CellIndex),
        pickable: true,
        getSize: DEPOT_MARKER_ICON_SIZE,
        // @ts-expect-error
        getPosition: (depot: ListDepotTransformed) =>
          h3ToGeo(depot.H3CellIndex!).reverse(),
        getIcon: () => ({
          url: "data:image/svg+xml,%3Csvg width='63' height='63' viewBox='0 0 63 63' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5564 15.5563C24.148 6.96482 38.0776 6.96482 46.6691 15.5563C55.2607 24.1479 55.2607 38.0775 46.6691 46.669L31.1128 62.2254L15.5564 46.669C6.96491 38.0775 6.96491 24.1479 15.5564 15.5563Z' fill='%236191B4'/%3E%3Cg clip-path='url(%23clip0_1737_171653)'%3E%3Cg clip-path='url(%23clip1_1737_171653)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30.25 22.201C30.7142 21.933 31.286 21.933 31.7502 22.201L39.2502 26.5314C39.7142 26.7993 40.0001 27.2945 40.0001 27.8303V38.5001C40.0001 39.3285 39.3285 40 38.5001 40H34.0001C33.1717 40 32.5001 39.3284 32.5001 38.5001V33.2503H29.5001V38.5001C29.5001 39.3284 28.8286 40 28.0001 40H23.5001C22.6717 40 22.0001 39.3285 22.0001 38.5001V27.8303C22.0001 27.2945 22.286 26.7993 22.7501 26.5314L30.25 22.201ZM28.0001 38.5001V32.8754C28.0001 32.2541 28.5038 31.7504 29.1251 31.7504H32.8751C33.4964 31.7504 34.0001 32.2541 34.0001 32.8754V38.5001H38.5001V27.8303L31.0001 23.4999L23.5001 27.8303V38.5001H28.0001Z' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1737_171653'%3E%3Crect width='24' height='24' fill='white' transform='translate(19.0001 19)'/%3E%3C/clipPath%3E%3CclipPath id='clip1_1737_171653'%3E%3Crect width='24' height='24' fill='white' transform='translate(19.0001 19)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A",
          width: DEPOT_MARKER_ICON_SIZE,
          height: DEPOT_MARKER_ICON_SIZE,
          anchorY: DEPOT_MARKER_ICON_SIZE,
        }),
        onClick: (value: PickingInfo) => {
          onOpenExistingDepotPopover({
            existingDepot: { ...value.object },
            position: { x: value.x, y: value.y },
          });
        },
      }),
      new IconLayer({
        id: 'suggested-depot-layer',
        data: homeBases,
        pickable: true,
        getSize: DEPOT_MARKER_ICON_SIZE,
        // @ts-expect-error
        getPosition: (homeBase: HomeBase) =>
          h3ToGeo(homeBase.H3CellIndex).reverse(),
        getIcon: () => ({
          url: "data:image/svg+xml,%3Csvg width='63' height='63' viewBox='0 0 63 63' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5563 15.5563C24.1479 6.96482 38.0775 6.96482 46.669 15.5563C55.2606 24.1479 55.2606 38.0775 46.669 46.669L31.1127 62.2254L15.5563 46.669C6.96479 38.0775 6.96479 24.1479 15.5563 15.5563Z' fill='%236191B4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.7065 20.4885C24.168 20.4885 20.4887 24.1678 20.4887 28.7063C20.4887 33.2449 24.168 36.9242 28.7065 36.9242C30.7055 36.9242 32.5377 36.2104 33.9624 35.0239L40.2114 41.2729C40.5043 41.5658 40.9791 41.5658 41.272 41.2729C41.5649 40.98 41.5649 40.5052 41.272 40.2123L35.0231 33.9634C36.2102 32.5386 36.9244 30.7059 36.9244 28.7063C36.9244 24.1678 33.2451 20.4885 28.7065 20.4885ZM21.9887 28.7063C21.9887 24.9962 24.9964 21.9885 28.7065 21.9885C32.4167 21.9885 35.4244 24.9962 35.4244 28.7063C35.4244 32.4165 32.4167 35.4242 28.7065 35.4242C24.9964 35.4242 21.9887 32.4165 21.9887 28.7063Z' fill='%23F9FAFB'/%3E%3C/svg%3E%0A",
          width: DEPOT_MARKER_ICON_SIZE,
          height: DEPOT_MARKER_ICON_SIZE,
          anchorY: DEPOT_MARKER_ICON_SIZE,
        }),
        onClick: (value: PickingInfo) => {
          onOpenSuggestedDepotPopover({
            homeBase: { ...value.object },
            position: { x: value.x, y: value.y },
          });
        },
      }),
    ],
    [depots, homeBases, onOpenExistingDepotPopover, onOpenSuggestedDepotPopover],
  );

export default useLayers;
