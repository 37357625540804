import { h3ToGeo } from 'h3-js';
import { Location } from './types';
import { useMemo } from 'react';
import { HomeBase, ListDepotTransformed } from '@optimization/sa-common';

const useLocations = (homeBases: HomeBase[], depots: ListDepotTransformed[]): Location[] =>
  useMemo(() => {
    return [
      ...homeBases
        .filter((homeBase) => homeBase.H3CellIndex !== undefined) // Filter out undefined H3CellIndex in homeBases
        .map((homeBase) => {
          const [lat, lng] = h3ToGeo(homeBase.H3CellIndex as string);

          return {
            h3Cell: homeBase.H3CellIndex as string,
            lat,
            lng,
          };
        }),
      ...depots
        .filter((depot) => depot.H3CellIndex !== undefined) // Filter out undefined H3CellIndex in depots
        .map((depot) => {
          const [lat, lng] = h3ToGeo(depot.H3CellIndex as string);

          return {
            h3Cell: depot.H3CellIndex as string,
            lat,
            lng,
          };
        }),
    ];
  }, [homeBases, depots]);

export default useLocations;
