import useDatadogLogInfo from 'features/datadog/useDatadogLogInfo';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  filterParams: any;
  sortDirectionDefault: 'asc' | 'desc';
}

const useToggleSortDirection = ({ filterParams, sortDirectionDefault }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeLogInfo = useDatadogLogInfo();

  return useCallback(() => {
    const sortDirection = searchParams.get('sortDirection');
    const sortDirectionFinal = sortDirection || sortDirectionDefault;

    const newFilterParams = {
      ...filterParams,
      sortDirection: sortDirectionFinal === 'asc' ? 'desc' : 'asc',
    };

    setSearchParams(newFilterParams);

    onChangeLogInfo({
      logText: 'Filter updated',
      logObject: newFilterParams,
    });
  }, [filterParams, searchParams, sortDirectionDefault, onChangeLogInfo, setSearchParams]);
};

export default useToggleSortDirection;
