import { FactBasicTruck } from '@optimization/sa-common';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useMemo } from 'react';

const useTruckExceedingDailyDistance = ({
  selectedBasicTruck,
  selections,
  highestDailyDistanceFromSelectedCandidates,
}: {
  selectedBasicTruck: FactBasicTruck | null;
  selections: SelectionExecutions;
  highestDailyDistanceFromSelectedCandidates: number;
}): number => {
  const truckExceedingDailyDistance = useMemo(() => {
    if (!selectedBasicTruck) {
      return 0;
    }

    const selectedBasicTruckMaxDailyRange = selectedBasicTruck.SocSpecifications.find(
      (soc) => soc.SocWindow.toString() === selections.truckSocWindow,
    )?.MaxDailyRangeKm;

    return selectedBasicTruckMaxDailyRange &&
      highestDailyDistanceFromSelectedCandidates > selectedBasicTruckMaxDailyRange
      ? selectedBasicTruckMaxDailyRange
      : 0;
  }, [highestDailyDistanceFromSelectedCandidates, selectedBasicTruck, selections.truckSocWindow]);

  return truckExceedingDailyDistance;
};

export default useTruckExceedingDailyDistance;
