import { EcItem, SetEcItem } from './types';
import { PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  calculatePtoInstant?: () => void;
  setEcItem: SetEcItem;
  setEcItems: (value: React.SetStateAction<EcItem[]>) => void;
}

const useOnCheckboxChange = ({ calculatePtoInstant, setEcItem, setEcItems }: Props) =>
  useCallback(
    (checked: boolean, value: string, type: PtoType) => {
      if (checked) {
        setEcItem({ type, value, intensity: 'instant' });
      } else {
        setEcItems((prev) => prev.filter((item) => item.Type !== type));

        if (calculatePtoInstant) {
          calculatePtoInstant();
        }
      }
    },
    [setEcItems, setEcItem, calculatePtoInstant],
  );

export default useOnCheckboxChange;
