interface Props {
  chargingEventKmAtEvent: number;
  findChargingEventKmOffset: number;
  distance: number;
}

const isChargingEventNearDistance = ({ chargingEventKmAtEvent, findChargingEventKmOffset, distance }: Props) =>
  chargingEventKmAtEvent >= distance - findChargingEventKmOffset &&
  chargingEventKmAtEvent <= distance + findChargingEventKmOffset;

export default isChargingEventNearDistance;
