import CustomChargingEvent from './CustomChargingEvent';
import { ChargingEventV2VM, VehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  chargingEvents: ChargingEventV2VM[];
  depotId: number;
  pixelsPerMinute: number;
  vehicle: VehicleEnhanced;
}

const CustomChargingEvents = ({ chargingEvents, depotId, pixelsPerMinute, vehicle }: Props) => {
  const depotChargingEvents = useMemo(
    () => chargingEvents.filter((chargingEvent) => chargingEvent?.DepotId === depotId),
    [chargingEvents, depotId],
  );

  return depotChargingEvents.map((depotChargingEvent) => (
    <CustomChargingEvent
      key={depotChargingEvent.Id}
      vehicle={vehicle}
      depotChargingEvent={depotChargingEvent}
      pixelsPerMinute={pixelsPerMinute}
    />
  ));
};

export default CustomChargingEvents;
