import { ReactNode } from 'react';

interface Props {
  className?: string;
  header: string;
  children: ReactNode;
  dataTestid?: string;
}

const Checkboxes = ({ className, children, header, dataTestid }: Props) => (
  <div className={className} data-testid={dataTestid}>
    <div className="tds-detail-04 tds-u-mb1">{header}</div>
    {children}
  </div>
);

export default Checkboxes;
