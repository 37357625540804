import { SelectionExecutions } from '../useVehicleOptionsHandler';

const allTruckSelectionsCompleted = (selections: SelectionExecutions) =>
  selections.truckPortfolio &&
  selections.truckBattery &&
  selections.truckSocWindow &&
  selections.truckElectricMotor &&
  selections.truckChassisAdaptation &&
  selections.truckWheelConfiguration &&
  selections.truckAxleDistance &&
  selections.truckChassisHeight &&
  selections.truckCabType;

export default allTruckSelectionsCompleted;
