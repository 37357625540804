import chassisAdaptationCheck from './chassisAdaptationCheck';
import { FactBasicTruck } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicTrucks: FactBasicTruck[];
  selections: SelectionExecutions;
}

const useTruckCabType = ({ basicTrucks, selections }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicTrucks
          .filter(
            (basicTruck) =>
              chassisAdaptationCheck({
                basicVehicle: basicTruck,
                selectedBattery: selections.truckBattery,
                selectedSocWindow: selections.truckSocWindow,
                selectedElectricMotor: selections.truckElectricMotor,
                selectedPortfolio: selections.truckPortfolio,
              }) &&
              basicTruck.ChassisAdaptation === selections.truckChassisAdaptation &&
              basicTruck.WheelConfiguration === selections.truckWheelConfiguration.toLocaleLowerCase() &&
              basicTruck.AxleDistancesMm.map(String).includes(selections.truckAxleDistance.toString()) &&
              basicTruck.ChassisHeights.includes(selections.truckChassisHeight),
          )
          .map((basicTruck) => basicTruck.Cabs)
          .flat(),
      ),
    ],
    [basicTrucks, selections],
  );

export default useTruckCabType;
