import BusFields from './BusFields';
import classes from './style.module.scss';
import classNames from 'classnames';
import TruckFields from './TruckFields';
import useSelectedBasicBusEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicBusEarliest';
import useSelectedBasicTruckEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicTruckEarliest';
import useSubmitChangedCandidates from './utils/useSubmitChangedCandidates';
import useTruckExceedingAvgGtw from './utils/useTruckExceedingAvgGtw';
import useTruckExceedingDailyDistance from './utils/useTruckExceedingDailyDistance';
import useVehicleOptionsHandler from 'features/candidate/utils//vehicleOptionsHandler/useVehicleOptionsHandler';
import useWarningExceedingMessage from './utils/useWarningExceedingMessage';
import { Button, ButtonAlt } from '@optimization/ssi-common';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import { OnConfigureVehicleProduct } from 'features/solution/SolutionDetail/utils/useConfigureVehicleProductHandler';
import { TdsMessage } from '@scania/tegel-react';
import { useEnsureArray } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery } from 'app/services/solution';
import { useMemo } from 'react';

interface Props {
  className?: string;
  changeVehicleLoading?: boolean;
  highestDailyDistanceFromSelectedCandidates: number;
  highestAvgGtwFromSelectedCandidates: number;
  selectedVehicles: ListVehicleEnhanced[];
  onClose: () => void;
  onConfigureVehicleProduct: OnConfigureVehicleProduct;
}

const ConfigureVehicleProduct = ({
  className,
  changeVehicleLoading,
  highestDailyDistanceFromSelectedCandidates,
  highestAvgGtwFromSelectedCandidates,
  selectedVehicles,
  onClose,
  onConfigureVehicleProduct,
}: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const basicBusses = useEnsureArray(factBasicDataQuery.data?.FactBasicBusses);

  const chassisTypeFromVehicles = useMemo(
    () => (selectedVehicles.some((vehicle) => vehicle.ChassisType === 'Truck') ? 'Truck' : 'Bus'),
    [selectedVehicles],
  );

  const filteredSelectedVehicles = useMemo(
    () => selectedVehicles.filter((selectedVehicle) => selectedVehicle.ChassisType === chassisTypeFromVehicles),
    [selectedVehicles, chassisTypeFromVehicles],
  );

  const vehicleOptionsHandler = useVehicleOptionsHandler({
    vehicles: filteredSelectedVehicles,
    preSelectOptionsInitially: true,
    chassisTypeFromVehicles,
  });

  const { selections } = vehicleOptionsHandler;
  const { chassisType } = selections;

  const selectedBasicTruck = useSelectedBasicTruckEarliest({ basicTrucks, selections });

  const selectedBasicBus = useSelectedBasicBusEarliest({
    basicBusses,
    selections: vehicleOptionsHandler.selections,
  });

  const truckExceedingDailyDistance = useTruckExceedingDailyDistance({
    highestDailyDistanceFromSelectedCandidates,
    selectedBasicTruck,
    selections,
  });

  const truckExceedingAvgGtw = useTruckExceedingAvgGtw({ highestAvgGtwFromSelectedCandidates, selectedBasicTruck });

  const warningExceedingMessage = useWarningExceedingMessage({ truckExceedingAvgGtw, truckExceedingDailyDistance });

  const onSubmitChangedCandidates = useSubmitChangedCandidates({
    selectedBasicTruck,
    selectedBasicBus,
    selections,
    filteredSelectedVehicles,
    onConfigureVehicleProduct,
  });

  const allInputsFilled = useMemo(
    () =>
      Boolean(
        (chassisTypeFromVehicles === 'Truck' && selections.truckCabType) ||
          (chassisTypeFromVehicles === 'Bus' && selections.busFloorHeight),
      ),
    [chassisTypeFromVehicles, selections.truckCabType, selections.busFloorHeight],
  );

  const disabled = changeVehicleLoading;

  return (
    <div className={classNames(classes['configure-vehicle-product'], className)}>
      <div className={classes.header}>
        <div>
          <h5 className="tds-headline-05">Configure vehicle product</h5>
          <p className="tds-body-02 mt-spacing-4">
            Select the vehicle product you would like to configure for the vehicle candidates
          </p>
        </div>
        <ButtonAlt tdsIcon="cross" onClick={onClose} />
      </div>
      <div className={classNames(classes.body, 'mt-spacing-24')}>
        <div className={classNames(classes.fields)}>
          {chassisType === 'Truck' && <TruckFields disabled={disabled} vehicleOptionsHandler={vehicleOptionsHandler} />}
          {chassisType === 'Bus' && <BusFields disabled={disabled} vehicleOptionsHandler={vehicleOptionsHandler} />}
        </div>
      </div>
      <div className={classes.footer}>
        {(!!truckExceedingDailyDistance || !!truckExceedingAvgGtw) && (
          <div className={classes.message}>
            <TdsMessage variant="warning" modeVariant="secondary" header={warningExceedingMessage.header}>
              {warningExceedingMessage.body}
            </TdsMessage>
          </div>
        )}
        <div className={classes.actions}>
          <Button
            dataTestid="button-configure-vehicle-product-confirm"
            disabled={!allInputsFilled || disabled}
            modeVariant="secondary"
            text="Confirm changes"
            size="sm"
            onClick={onSubmitChangedCandidates}
          />
          <Button disabled={disabled} text="Cancel" size="sm" variant="secondary" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ConfigureVehicleProduct;
