import ActionLayout from 'common/components/ActionLayout';
import Candidates from 'features/candidate/Candidates';
import CandidatesFilterSelector from 'features/candidate/Candidates/Filter/Selector';
import CandidatesFilterToggle from 'features/candidate/Candidates/Filter/Toggle';
import classes from './style.module.scss';
import classNames from 'classnames';
import DepotKeyValues from '../utils/DepotKeyValues';
import DepotMessages from 'features/infrastructure/utils/DepotMessages';
import ErrorBoundary from 'common/components/ErrorBoundary';
import HelmetTitle from 'common/components/HelmetTitle';
import InfraPerformanceStep from '../utils/InfraPerformanceStep';
import InfraPerformanceStepInfo from 'features/infrastructure/utils/InfraPerformanceStepInfo';
import Occupancy from './Occupancy';
import PageNotFound from 'common/components/PageNotFound';
import TotalCount from 'common/components/TotalCount';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useContentSwitcherItems from './utils/useContentSwitcherItems';
import useDepotYear from '../utils/useDepotYear';
import useHandleSelectAll from 'common/hooks/useHandleSelectAll';
import useInfraPerformanceStepUpdateHandler from './utils/useInfraPerformanceStepUpdateHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useNavigateToDefaultWhenMissingYear from './utils/useNavigateToDefaultWhenMissingYear';
import useSelectCandidateRow from 'features/candidate/utils/useSelectCandidateRow';
import useStepInfo from './utils/useStepInfo';
import useVehiclesMappedToOtherDepot from './utils/useVehiclesMappedToOtherDepot';
import { isError404 } from 'common/utils/helper';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
  } from 'react';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BackLink,
  DepotChart,
  useEnhancedDepot,
  useDepotChargingEventsChartData,
  useDepotPowerDemandChartData,
  useDepotMaxSecondaryAxes,
  useVehicleSettingsHandler,
} from '@optimization/sa-common';
import {
  Button,
  ButtonAlt,
  Checkbox,
  ContentSwitcherLinks,
  InlineTabs,
  Loading,
  Modal,
  PopoverMenu,
  useToast,
  usePlural,
  invariant,
} from '@optimization/ssi-common';
import {
  useDeleteDepotsMutation,
  useGetFactBasicDataQuery,
  useGetDepotQuery,
  useGetDepotsQuery,
  useGetVehiclesQuery,
  useUpdateDepotMutation,
} from 'app/services/solution';

const tabs = [
  {
    name: 'Vehicles',
  },
  {
    name: 'Depot Infrastructure',
    dataTestid: 'depot-infrastructure-tab',
  },
  {
    name: 'Occupancy',
    dataTestid: 'depot-occupancy-tab',
  },
];

export const VEHICLES_TAB = 0;
export const INFRA_PERFORMANCE_STEPS_TAB = 1;
export const OCCUPANCY_TAB = 2;

export type Mode = 'map-vehicles' | 'delete' | 'update';

type ModalType = 'map-vehicles' | 'delete-depot' | 'missing-vehicles-to-map';

export interface NightCharging {
  timeAtEvent: number;
  power: number;
  eventDuration: number;
  vehicleId: number;
}

const DepotDetail = () => {
  const navigate = useNavigate();

  const { solutionId, depotId, yearParam } = useParams<{
    solutionId: string;
    depotId: string;
    yearParam?: string;
  }>();

  invariant(solutionId);
  invariant(depotId);

  const actionLayoutWhenModeRef = useRef<HTMLDivElement | null>(null);
  const actionLayoutRef = useRef<HTMLDivElement | null>(null);
  const depotYearSwitcherRef = useRef<HTMLDivElement | null>(null);

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);
  const depotQuery = useGetDepotQuery({
    solutionId,
    depotId,
  });

  const depotYear = useDepotYear({
    depotDefaultYear: depotQuery.data?.transformed.depotDefaultYear,
    yearParam,
  });

  const [deleteDepots, deleteDepotsState] = useDeleteDepotsMutation();
  const [updateDepot, updateDepotState] = useUpdateDepotMutation();
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState(VEHICLES_TAB);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [modal, setModal] = useState<ModalType | null>(null);
  const [selectedMode, setSelectedMode] = useState<Mode | undefined>();

  const vehiclesInSolutionQuery = useGetVehiclesQuery(solutionId);

  const depot = useEnhancedDepot({
    depot: depotQuery.data,
    depotYear,
    vehiclesInSolution: vehiclesInSolutionQuery.data || [],
  });

  const depotDataPerPerformanceStep = useMemo(
    () => depot?.DepotDataPerPerformanceStep?.[depotYear],
    [depot, depotYear],
  );

  const showToast = useToast();

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const closeDeleteDepotModal = useCallback(() => {
    setModal(null);
    setSelectedMode(undefined);
  }, []);

  const cancelMapVehiclesToDepot = useCallback(() => {
    setSelectedMode(undefined);
    setSelectedCandidates([]);
  }, []);

  useEffect(() => {
    if (depotQuery.data && depotQuery.data.MappedVehicles.length > 0) {
      setSelectedMode(undefined);
    }
  }, [depotQuery.data]);

  useEffect(() => {
    if (depotQuery.data) {
      setSelectedCandidates(depotQuery.data.MappedVehicles.map(String));
    }
  }, [depotQuery.data]);

  const vehiclesMappedToOtherDepot = useVehiclesMappedToOtherDepot({
    depots: depotsQuery.data,
    depotId,
    vehicles: vehiclesQuery.data,
  });

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    selectedMode,
    selectedCandidates,
    vehiclesMappedToOtherDepot,
    depotId: selectedMode === 'map-vehicles' ? undefined : depotId,
    depotYear: selectedMode === 'map-vehicles' ? undefined : depotYear,
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
  });

  const manageDepotOptions = useMemo(() => {
    const options = [{ value: 'map-vehicles', name: 'Map vehicles to depot' }];

    if (depotQuery.data?.MappedVehicles?.length) {
      options.push({ value: 'update', name: 'Update depot' });
    }

    options.push({ value: 'delete', name: 'Delete depot' });

    return options;
  }, [depotQuery.data]);

  const vehiclesUntilCurrentYear = useMemo(() => depot?.enhanced.vehiclesUntilCurrentYear || [], [depot]);

  const infraPerformanceStepHandler = useInfraPerformanceStepUpdateHandler({
    solutionId,
    depot: depotQuery.data,
    depotYear,
    chargingSolutions: factBasicDataQuery.data?.ChargingSolutions,
    isLoading: depotQuery.isLoading,
    isError: depotQuery.isError,
  });

  const chargingEventsChartData = useDepotChargingEventsChartData({
    depot,
    depotYear,
  });

  const powerDemandChartData = useDepotPowerDemandChartData({
    depot: depotQuery.data,
    chargingEventsChartData,
  });

  const maxSecondaryAxes = useDepotMaxSecondaryAxes({
    depot: depotQuery.data,
    depotYear,
  });

  const handleSelectRow = useSelectCandidateRow({
    setSelectedCandidates,
    vehiclesMappedToOtherDepot,
  });

  const candidatesPluralLowercase = usePlural({
    count: selectedCandidates.length,
    uppercase: false,
  });

  const candidatesPluralUppercase = usePlural({
    count: selectedCandidates.length,
    uppercase: true,
  });

  const handleMapVehiclesToDepot = useCallback(() => {
    if (depotQuery.data) {
      updateDepot({
        ...depotQuery.data,
        solutionId,
        depotId: Number(depotId),
        MappedVehicles: selectedCandidates.map(Number),
        InfrastructureProducts: depotQuery.data.InfrastructureProducts.map((product) => ({ ...product, Name: '' })),
      });

      showToast({
        header: `${selectedCandidates.length} vehicle${candidatesPluralLowercase} mapped to depot`,
      });

      setSelectedCandidates([]);
      setSelectedMode(undefined);
      setModal(null);
    }
  }, [candidatesPluralLowercase, depotId, depotQuery.data, selectedCandidates, solutionId, showToast, updateDepot]);

  const handleDeleteDepot = useCallback(async () => {
    const response = await deleteDepots({ solutionId, ids: [Number(depotId)] });

    if ('data' in response) {
      showToast({ header: 'Depot has been deleted' });
      navigate(`/solution/${solutionId}/infrastructure`);
    } else {
      showToast({
        header: 'Error deleting depot. Please try again.',
        variant: 'error',
      });
    }
  }, [depotId, solutionId, deleteDepots, navigate, showToast]);

  const navigateToUpdateDepotPage = useCallback(() => {
    navigate(`/solution/${solutionId}/infrastructure/${depotId}/edit`);
  }, [depotId, navigate, solutionId]);

  const navigateToCandidatePage = useCallback(() => {
    navigate(`/solution/${solutionId}/candidate`);
  }, [navigate, solutionId]);

  const handleManageAction = useCallback(() => {
    if (selectedMode === 'map-vehicles') {
      setModal('map-vehicles');
    }
  }, [selectedMode]);

  useEffect(() => {
    if (selectedMode === 'delete') {
      setModal('delete-depot');
    } else if (selectedMode === 'update') {
      navigateToUpdateDepotPage();
    }
  }, [navigateToUpdateDepotPage, selectedMode]);

  useNavigateToDefaultWhenMissingYear({
    yearParam,
    depot: depotQuery.data,
    solutionId,
  });

  const solutionIsMissingVehicles = useMemo(
    () => vehiclesQuery.isSuccess && vehiclesQuery.data?.length === 0,
    [vehiclesQuery],
  );

  const handlemanageDepotPopover = useCallback(
    (value: string) => {
      if (depotQuery.data && depotQuery.data.MappedVehicles) {
        setSelectedCandidates(depotQuery.data.MappedVehicles.map(String));
      }

      if (value === 'map-vehicles' && solutionIsMissingVehicles) {
        setModal('missing-vehicles-to-map');
      }

      setSelectedMode(value as Mode);
    },
    [depotQuery, solutionIsMissingVehicles],
  );

  const handleSelectAllShown = useHandleSelectAll({
    items: candidatesHandler.splicedCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  const handleSelectAllExisting = useHandleSelectAll({
    items: candidatesHandler.filteredCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  const contentSwitcherItems = useContentSwitcherItems({
    depot,
    solutionId,
  });

  const stepInfo = useStepInfo({ mode: selectedMode, tabIndex });

  const mainDataIsLoaded = useMemo(() => depotQuery.data && vehiclesQuery.data, [depotQuery.data, vehiclesQuery.data]);

  const showDepotYearSwitcher = useMemo(() => contentSwitcherItems.length > 1, [contentSwitcherItems]);

  const allCandidatesIsSelected = useMemo(
    () => selectedCandidates.length === candidatesHandler.filteredCandidates?.length,
    [selectedCandidates, candidatesHandler],
  );

  const expandVehicleId = useMemo(() => candidatesHandler.splicedCandidates?.[0]?.Id, [candidatesHandler]);

  useMakeElementsSticky({
    depotYearSwitcherRef: mainDataIsLoaded && showDepotYearSwitcher ? depotYearSwitcherRef : undefined,
    actionLayoutRef:
      mainDataIsLoaded && selectedMode
        ? actionLayoutWhenModeRef
        : mainDataIsLoaded && !selectedMode
          ? actionLayoutRef
          : undefined,
  });

  const loadingText = useLoadingText({
    isLoadingCandidates: vehiclesQuery.isFetching || candidatesHandler.isLoading || factBasicDataQuery.isLoading,
    isLoadingDepot: depotsQuery.isFetching || depotQuery.isFetching,
    isMappingVehicles: updateDepotState.isLoading,
    isDeletingDepot: deleteDepotsState.isLoading,
  });

  const isLoading =
    vehiclesQuery.isFetching ||
    depotsQuery.isFetching ||
    depotQuery.isFetching ||
    updateDepotState.isLoading ||
    factBasicDataQuery.isLoading ||
    candidatesHandler.isLoading ||
    deleteDepotsState.isLoading;

  const isError =
    vehiclesQuery.isError ||
    depotsQuery.isError ||
    depotQuery.isError ||
    updateDepotState.isError ||
    factBasicDataQuery.isError ||
    candidatesHandler.isError ||
    deleteDepotsState.isError;

  const manageDepotPopoverOptions = useMemo(
    () =>
      manageDepotOptions.map((option) => ({
        name: option.name,
        onClick: () => {
          handlemanageDepotPopover(option.value);
          setShowActionMenu(false);
        },
      })),
    [manageDepotOptions, handlemanageDepotPopover],
  );

  const manageDepotPopover = (
    <PopoverMenu
      show={showActionMenu}
      options={manageDepotPopoverOptions}
      hidePopoverMenu={() => setShowActionMenu(false)}
      toggle={
        <ButtonAlt
          tdsIcon="kebab"
          tdsIconSize={30}
          style={{ height: '40px' }}
          onClick={() => setShowActionMenu((prev) => !prev)}
        />
      }
    />
  );

  if (isError404(depotQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className="tds-container">
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {modal === 'map-vehicles' && (
        <Modal
          size="xs"
          header="Map vehicles to depot"
          variant="primary"
          ctaConfirmText="Map to depot"
          ctaConfirmSubmit={handleMapVehiclesToDepot}
          ctaConfirmSubmitDataTestid="button-confirm-add-vehicles-to-depot"
          onClose={closeModal}
        >
          Are you sure you want to map the selected vehicles to this depot?
        </Modal>
      )}
      {modal === 'missing-vehicles-to-map' && (
        <Modal
          size="xs"
          header="Map vehicles"
          variant="primary"
          ctaConfirmText="Go to page"
          ctaConfirmSubmit={navigateToCandidatePage}
          ctaConfirmSubmitIcon="arrow_right"
          onClose={closeModal}
        >
          Please add vehicle candidates on the Vehicle Candidate page to map them to a depot.
        </Modal>
      )}
      {modal === 'delete-depot' && (
        <Modal
          size="xs"
          header="Delete depot"
          ctaConfirmText="Delete depot"
          ctaConfirmSubmit={handleDeleteDepot}
          disabled={isLoading}
          onClose={closeDeleteDepotModal}
        >
          Are you sure you want to delete this depot?
        </Modal>
      )}
      {depotQuery.data && vehiclesQuery.data && (
        <>
          <HelmetTitle solutionId={solutionId} depotId={depotId} />
          <BackLink url={`/solution/${solutionId}/infrastructure`}>Back to depot overview</BackLink>
          <div ref={depotYearSwitcherRef} className={classNames('mt-spacing-48', classes['depot-info-and-years'])}>
            <div>
              <div className="tds-detail-04">Depot</div>
              <h4 className="tds-headline-04 mt-spacing-2">{depotQuery.data?.Name}</h4>
            </div>
            {showDepotYearSwitcher && (
              <div>
                <ContentSwitcherLinks dataTestid="depot-detail-years" items={contentSwitcherItems} />
              </div>
            )}
          </div>
          <div className={classes['depot-overview-and-chart']}>
            <DepotKeyValues
              header="Depot demand"
              powerDemand={depotDataPerPerformanceStep?.PowerDemand || 0}
              chargingPower={depotDataPerPerformanceStep?.ChargingPower || 0}
              notEnoughPowerBlocks={depotDataPerPerformanceStep?.ErrorStates.NotEnoughPowerBlocks || false}
              notEnoughSatellites={depotDataPerPerformanceStep?.ErrorStates.NotEnoughSatellites || false}
              notEnoughPower={depotDataPerPerformanceStep?.ErrorStates.NotEnoughPower || false}
              energyConsumptionPerDayKwh={depotDataPerPerformanceStep?.EnergyConsumptionPerDayKwh || 0}
              peakConnectedVehicles={depotDataPerPerformanceStep?.PeakConnectedVehicles || 0}
              maxUsedPowerBlocks={depotDataPerPerformanceStep?.MaxUsedPowerBlocks || 0}
              totalNumberOfPowerBlocks={depotDataPerPerformanceStep?.TotalNumberOfPowerBlocks || 0}
              numberOfSatellites={depotDataPerPerformanceStep?.NumberOfSatellites || 0}
            />
            <DepotChart
              depotMaxPowerCapacity={depotQuery.data.MaxPowerCapacity}
              dataPlot={powerDemandChartData}
              maxSecondaryAxes={maxSecondaryAxes}
              depotFutureMaxPowerCapacity={depotQuery.data.FutureMaxPowerCapacity}
            />
            <DepotMessages
              errorsStates={depotDataPerPerformanceStep?.ErrorStates}
              peakConnectedVehicles={depotDataPerPerformanceStep?.PeakConnectedVehicles || 0}
            />
          </div>
          {(!selectedMode || selectedMode === 'delete') && (
            <InlineTabs
              modeVariant="primary"
              extraClassName="inline-tabs-popover"
              className="mt-spacing-24"
              tabs={tabs}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          )}
          <InfraPerformanceStepInfo
            className="mt-spacing-24"
            header={stepInfo.header}
            description={stepInfo.description}
          />
          {selectedMode === 'map-vehicles' && (
            <ActionLayout
              actionLayoutRef={actionLayoutWhenModeRef}
              className="mt-spacing-16"
              left={
                <>
                  <div className="flex">
                    <Button
                      variant="primary"
                      text="Confirm"
                      size="sm"
                      dataTestid="button-confirm-depot-vehicles-action"
                      onClick={handleManageAction}
                      disabled={selectedCandidates.length === 0 && depotQuery.data?.MappedVehicles.length === 0}
                    />
                    <Button
                      className="ml-spacing-16"
                      variant="secondary"
                      text="Close"
                      size="sm"
                      onClick={cancelMapVehiclesToDepot}
                    />
                  </div>
                  <div className="flex items-center tds-u-mt2">
                    <TotalCount
                      count={selectedCandidates.length}
                      entityName={`VEHICLE${candidatesPluralUppercase} SELECTED`}
                      autoPlural={false}
                    />
                    <Checkbox
                      label="Select All"
                      value="select-all"
                      className="ml-spacing-16"
                      onChange={handleSelectAllExisting}
                      checked={allCandidatesIsSelected}
                    />
                  </div>
                </>
              }
              right={
                <div className="flex items-center">
                  <CandidatesFilterToggle candidatesHandler={candidatesHandler} />
                  <div className="tds-u-ml-auto">{manageDepotPopover}</div>
                </div>
              }
              below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
            />
          )}
          {(!selectedMode || selectedMode === 'delete') && (
            <>
              <ActionLayout
                actionLayoutRef={actionLayoutRef}
                left={
                  <TotalCount
                    count={candidatesHandler.filteredCandidates?.length}
                    entityName={`VEHICLE${candidatesPluralUppercase} IN DEPOT`}
                    autoPlural={false}
                  />
                }
                right={
                  <div className="flex items-center">
                    {tabIndex === VEHICLES_TAB && <CandidatesFilterToggle candidatesHandler={candidatesHandler} />}
                    <div className="tds-u-ml-auto">{manageDepotPopover}</div>
                  </div>
                }
                below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
              />
              {tabIndex === INFRA_PERFORMANCE_STEPS_TAB && (
                <ErrorBoundary>
                  <InfraPerformanceStep
                    className="mt-spacing-22"
                    depotYear={depotYear}
                    infraPerformanceStepHandler={infraPerformanceStepHandler}
                    chargingSolutions={factBasicDataQuery.data?.ChargingSolutions}
                  />
                </ErrorBoundary>
              )}
              {tabIndex === OCCUPANCY_TAB && depot && (
                <ErrorBoundary>
                  <Occupancy
                    depotId={depot.Id}
                    depotYear={depotYear}
                    vehicles={vehiclesUntilCurrentYear}
                    solutionId={solutionId}
                  />
                </ErrorBoundary>
              )}
            </>
          )}
          {(selectedMode === 'map-vehicles' || tabIndex === VEHICLES_TAB) && (
            <Candidates
              depotYear={depotYear}
              solutionId={solutionId}
              candidatesHandler={candidatesHandler}
              vehicleSettingsHandler={vehicleSettingsHandler}
              enableYearSelectorForChargingEvents={false}
              performanceStepDropdownReadonly
              expandVehicleId={expandVehicleId}
              candidateTabDefault="Charging events"
              handleSelectAll={handleSelectAllShown}
              handleSelectRow={handleSelectRow}
              actionMode={selectedMode === 'map-vehicles' ? 'multiselect' : 'expandable-rows'}
              dataTestid={selectedMode === 'map-vehicles' ? 'map-vehicles-to-depot-table' : 'depot-candidates-table'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DepotDetail;
