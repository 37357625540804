import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedFloorHeight = ({
  vehicles,
  floorHeightList,
}: {
  vehicles: ListVehicleTransformed[];
  floorHeightList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const floorHeightCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const floorHeight = vehicle.BusFloorHeight || '';

        return increaseValue({
          acc,
          key: floorHeight,
          list: floorHeightList,
        });
      }, {});

      result = getMaxKey({ countByName: floorHeightCount });
    }

    return result;
  }, [vehicles, floorHeightList]);

export default useSuggestedFloorHeight;
