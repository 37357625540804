import Event, { EventType } from '../../utils/Event';
import usePreparedSlowChargingEvent from './usePreparedSlowChargingEvent';
import { ChargingEventV2VM, VehicleEnhanced } from '@optimization/sa-common';
import { MINUTES_ONE_DAY } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  cell: 'first' | 'last';
  pixelsPerMinute: number;
  slowChargingEvent: ChargingEventV2VM;
  vehicle: VehicleEnhanced;
}

const SlowChargingEvent = ({ cell, pixelsPerMinute, slowChargingEvent, vehicle }: Props) => {
  const preparedSlowChargingEvent = usePreparedSlowChargingEvent({
    slowChargingEvent,
  });

  const event:
    | {
        startMinute?: number;
        durationInMinutes: number;
        type: EventType;
      }
    | undefined = useMemo(() => {
    if (preparedSlowChargingEvent) {
      if (preparedSlowChargingEvent.timeAtEvent + preparedSlowChargingEvent.eventDuration <= MINUTES_ONE_DAY) {
        if (cell === 'first') {
          return {
            startMinute: preparedSlowChargingEvent.timeAtEvent,
            durationInMinutes: preparedSlowChargingEvent.eventDuration,
            type: preparedSlowChargingEvent.timeAtEvent ? 'day' : 'from-midnight',
          };
        }
      } else {
        if (cell === 'first') {
          return {
            type: 'from-midnight',
            durationInMinutes:
              preparedSlowChargingEvent.timeAtEvent + preparedSlowChargingEvent.eventDuration - MINUTES_ONE_DAY,
          };
        } else {
          return {
            durationInMinutes: MINUTES_ONE_DAY - preparedSlowChargingEvent.timeAtEvent,
            type: 'before-midnight',
          };
        }
      }
    }
  }, [cell, preparedSlowChargingEvent]);

  if (preparedSlowChargingEvent && event) {
    return (
      <Event
        vehicle={vehicle}
        power={preparedSlowChargingEvent.power}
        durationInMinutes={event.durationInMinutes}
        pixelsPerMinute={pixelsPerMinute}
        startMinute={event.startMinute}
        type={event.type}
      />
    );
  }

  return null;
};

export default SlowChargingEvent;
