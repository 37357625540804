import { ListVehicleTransformed } from '@optimization/sa-common';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  vehicles: ListVehicleTransformed[];
}

// The prevent the tooltip from stop working on re-render
const useTooltipKey = ({ vehicles }: Props) => {
  const location = useLocation();

  const [tooltipKey, setTooltipKey] = useState(0);

  useLayoutEffect(() => {
    setTooltipKey((prev) => prev + 1);
  }, [vehicles, location]);

  return tooltipKey;
};

export default useTooltipKey;
