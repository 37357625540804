import classes from './style.module.scss';
import classNames from 'classnames';
import { Chip, ChipIcon } from '@optimization/ssi-common';
import { useMemo } from 'react';

const getValue = (sortOption: Option) => `${sortOption.type}`;

export interface Option {
  type: string;
  name: string;
}

export interface Filter {
  key: string;
  name: string;
  value: string;
}

interface Props {
  className?: string;
  dataTestid?: string;
  enabledSorting?: boolean;
  activeAction: 'filter' | 'sort-by' | null;
  sortType: string;
  sortOptions: Option[];
  sortDirection: 'asc' | 'desc';
  selectedFilters: Filter[] | null;
  toggleFilters: () => void;
  toggleSortDirection: () => void;
  toggleSortBy?: () => void;
  onRemoveFilter: (selectedFilter: Filter) => void;
  onRemoveFilters?: () => void;
}

const FiltersToggle = ({
  className,
  dataTestid,
  enabledSorting = true,
  activeAction,
  sortType,
  sortOptions,
  sortDirection,
  selectedFilters,
  toggleFilters,
  toggleSortBy,
  toggleSortDirection,
  onRemoveFilter,
  onRemoveFilters,
}: Props) => {
  const selectedOption = useMemo(
    () => sortOptions.find((sortOption) => getValue(sortOption) === `${sortType}`),
    [sortOptions, sortType],
  );

  return (
    <div className={classNames(classes['filters-toggle'], className)}>
      <ul>
        {selectedFilters && onRemoveFilters && (
          <li>
            <Chip text="Clear all" onClick={onRemoveFilters} />
          </li>
        )}
        {selectedFilters?.map((selectedFilter) => (
          <li key={`${selectedFilter.key}-${selectedFilter.value}`}>
            <Chip icon="cross" checked text={selectedFilter.name} onClick={() => onRemoveFilter(selectedFilter)} />
          </li>
        ))}
        <li>
          <Chip text="Filter" checked={activeAction === 'filter'} onClick={toggleFilters} dataTestid={dataTestid} />
        </li>
        {enabledSorting && (
          <>
            <li>
              <Chip
                text={`Sort by${selectedOption ? `: ${selectedOption.name}` : ''}`}
                checked={activeAction === 'sort-by'}
                onClick={toggleSortBy}
              />
            </li>
            <li>
              <ChipIcon icon={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} onClick={toggleSortDirection} />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default FiltersToggle;
