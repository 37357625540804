import { Button } from '@optimization/ssi-common';
import { useCallback, useEffect, useMemo } from 'react';

interface Props {
  contentName: string;
  itemsCount?: number;
  totalCount?: number;
  itemsPerPage?: number;
  setItemsCountInitially?: boolean;
  setItemsCount: (value: React.SetStateAction<number | undefined>) => void;
}

const LoadMore = ({
  contentName,
  itemsCount,
  totalCount,
  itemsPerPage = 10,
  setItemsCountInitially = true,
  setItemsCount,
}: Props) => {
  const showLoadMoreButton = useMemo(
    () => itemsCount !== undefined && totalCount !== undefined && itemsCount < totalCount,
    [itemsCount, totalCount],
  );

  const onLoadMore = useCallback(() => {
    if (totalCount !== undefined && itemsCount !== undefined) {
      setItemsCount(Math.min(itemsCount + itemsPerPage, totalCount));
    }
  }, [totalCount, itemsCount, itemsPerPage, setItemsCount]);

  useEffect(() => {
    if (setItemsCountInitially && totalCount !== undefined) {
      setItemsCount(Math.min(totalCount, itemsPerPage));
    }
  }, [totalCount, itemsPerPage, setItemsCountInitially, setItemsCount]);

  return showLoadMoreButton ? (
    <div className="flex mt-spacing-40">
      <Button
        text={`Load more ${contentName.toLowerCase()}`}
        variant="secondary"
        className="tds-u-ml-auto tds-u-mr-auto tds-u-mt3"
        onClick={onLoadMore}
        dataTestid="button-load-more"
      />
    </div>
  ) : null;
};

export default LoadMore;
