import useBattery from './defaultOptions/useBattery';
import useBusBodyLenghts from './defaultOptions/useBusBodyLenghts';
import useBusChargingTypeOptions from './defaultOptions/useBusChargingTypeOptions';
import useBusChassisHeights from './defaultOptions/useBusChassisHeights';
import useBusDisabled from './utils/useBusDisabled';
import useBusEngineGearBox from './defaultOptions/useBusEngineGearBox';
import useBusFloorHeights from './defaultOptions/useBusFloorHeights';
import useBusWheelConfiguration from './defaultOptions/useBusWheelConfiguration';
import useChassisTypeOptions from './defaultOptions/useChassisTypeOptions';
import useHandleSelection from './utils/useHandleSelection';
import useLoadSelectionBySuggestion from './suggestedOptions/useLoadSelectionBySuggestion';
import usePortfolios from './defaultOptions/usePortfolios';
import useSoc from './defaultOptions/useSoc';
import useTruckAxleDistance from './defaultOptions/useTruckAxleDistance';
import useTruckCabType from './defaultOptions/useTruckCabType';
import useTruckChassisAdaptation from './defaultOptions/useTruckChassisAdaptation';
import useTruckChassisHeight from './defaultOptions/useTruckChassisHeight';
import useTruckDisabled from './utils/useTruckDisabled';
import useTruckEngineGearBox from './defaultOptions/useTruckEngineGearBox';
import useTruckWheelConfiguration from './defaultOptions/useTruckWheelConfiguration';
import { chassisAdaptationTranslate, chassisHeightTranslate, ListVehicleTransformed } from '@optimization/sa-common';
import { DropdownOption, useNamesToDropdownOptions } from '@optimization/ssi-common';
import { RadioButtonOption } from '@optimization/ssi-common';
import { useEnsureArray } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery } from 'app/services/solution';
import { useMemo, useState } from 'react';

export interface SelectionExecutions {
  chassisType: string;
  truckPortfolio: string;
  truckBattery: string;
  truckSocWindow: string;
  truckElectricMotor: string;
  truckWheelConfiguration: string;
  truckChassisHeight: string;
  truckChassisAdaptation: string;
  truckAxleDistance: string;
  truckCabType: string;
  busPortfolio: string;
  busBattery: string;
  busSocWindow: string;
  busChargingType: string;
  busElectricMotor: string;
  busWheelConfiguration: string;
  busBodyLength: string;
  busChassisHeight: string;
  busFloorHeight: string;
  isChangedByUser: boolean;
}

export type ExecutionName =
  | 'chassisType'
  | 'truckPortfolio'
  | 'truckBattery'
  | 'truckSocWindow'
  | 'truckElectricMotor'
  | 'truckWheelConfiguration'
  | 'truckAxleDistance'
  | 'truckChassisHeight'
  | 'truckChassisAdaptation'
  | 'truckCabType'
  | 'busPortfolio'
  | 'busBattery'
  | 'busSocWindow'
  | 'busChargingType'
  | 'busElectricMotor'
  | 'busWheelConfiguration'
  | 'busBodyLength'
  | 'busChassisHeight'
  | 'busFloorHeight';

export interface VehicleOptionsHandler {
  chassisTypeOptions: RadioButtonOption[];
  truckPortfolioOptions: DropdownOption[];
  truckBatteryOptions: DropdownOption[];
  truckSocOptions: DropdownOption[];
  truckEngineGearBoxOptions: DropdownOption[];
  truckWheelConfigurationOptions: DropdownOption[];
  truckChassisHeightOptions: DropdownOption[];
  truckBatteryDisabled: boolean;
  truckSocDisabled: boolean;
  truckEngineGearBoxDisabled: boolean;
  truckWheelConfigurationDisabled: boolean;
  truckChassisHeightDisabled: boolean;
  truckAxleDistanceDisabled: boolean;
  truckAxleDistanceOptions: DropdownOption[];
  truckChassisAdaptationOptions: DropdownOption[];
  truckChassisAdaptationDisabled: boolean;
  truckCabTypeOptions: DropdownOption[];
  truckCabTypeDisabled: boolean;
  busPortfolioOptions: DropdownOption[];
  busBatteryOptions: DropdownOption[];
  busBatteryDisabled: boolean;
  busSocOptions: DropdownOption[];
  busSocDisabled: boolean;
  busChargingTypeOptions: DropdownOption[];
  busChargingTypeDisabled: boolean;
  busEngineGearBoxOptions: DropdownOption[];
  busEngineGearBoxDisabled: boolean;
  busWheelConfigurationOptions: DropdownOption[];
  busWheelConfigurationDisabled: boolean;
  busBodyLengthOptions: DropdownOption[];
  busBodyLengthDisabled: boolean;
  busChassisHeightOptions: DropdownOption[];
  busChassisHeightDisabled: boolean;
  busFloorHeightOptions: DropdownOption[];
  busFloorHeightDisabled: boolean;
  selections: SelectionExecutions;
  setSelections: React.Dispatch<React.SetStateAction<SelectionExecutions>>;
  handleSelection: (name: ExecutionName, value: string) => void;
}

interface Props {
  vehicles?: ListVehicleTransformed[];
  preSelectOptionsInitially?: boolean;
  chassisTypeFromVehicles?: string;
  enableSuggestions?: boolean;
}

const useVehicleOptionsHandler = ({
  vehicles = [],
  preSelectOptionsInitially,
  chassisTypeFromVehicles,
  enableSuggestions = true,
}: Props): VehicleOptionsHandler => {
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const basicBusses = useEnsureArray(factBasicDataQuery.data?.FactBasicBusses);

  const selectionsDefault: SelectionExecutions = useMemo(
    () => ({
      chassisType: chassisTypeFromVehicles || 'Truck',
      truckPortfolio: '',
      truckBattery: '',
      truckSocWindow: '',
      truckElectricMotor: '',
      truckChassisAdaptation: '',
      truckWheelConfiguration: '',
      truckAxleDistance: '',
      truckChassisHeight: '',
      truckCabType: '',
      busPortfolio: '',
      busBattery: '',
      busSocWindow: '',
      busElectricMotor: '',
      busWheelConfiguration: '',
      busBodyLength: '',
      busChassisHeight: '',
      busFloorHeight: '',
      busChargingType: '',
      isChangedByUser: false,
    }),
    [chassisTypeFromVehicles],
  );

  const [selections, setSelections] = useState<SelectionExecutions>({
    ...selectionsDefault,
  });

  // Truck and bus
  const chassisTypeOptions = useChassisTypeOptions({ selections });

  // Truck
  const truckPortfolioList = usePortfolios({ basicVehicles: basicTrucks });
  const truckBatteryList = useBattery({
    basicVehicles: basicTrucks,
    selectedPortfolio: selections.truckPortfolio,
  });
  const truckSocList = useSoc({
    basicVehicles: basicTrucks,
    selectedBattery: selections.truckBattery,
    selectedPortfolio: selections.truckPortfolio,
  });
  const truckEngineGearBoxList = useTruckEngineGearBox({
    basicVehicles: basicTrucks,
    selectedBattery: selections.truckBattery,
    selectedSocWindow: selections.truckSocWindow,
    selectedPortfolio: selections.truckPortfolio,
  });
  const truckChassisAdaptationList = useTruckChassisAdaptation({
    basicTrucks,
    selections,
  });
  const truckWheelConfigurationList = useTruckWheelConfiguration({
    basicTrucks,
    selections,
  });
  const truckAxleDistanceList = useTruckAxleDistance({
    basicTrucks,
    selections,
  });
  const truckChassisHeightList = useTruckChassisHeight({ basicTrucks, selections });
  const truckCabTypeList = useTruckCabType({ basicTrucks, selections });

  // Bus
  const busPortfolioList = usePortfolios({ basicVehicles: basicBusses });
  const busBatteryList = useBattery({
    basicVehicles: basicBusses,
    selectedPortfolio: selections.busPortfolio,
  });
  const busSocList = useSoc({
    basicVehicles: basicBusses,
    selectedBattery: selections.busBattery,
    selectedPortfolio: selections.busPortfolio,
  });
  const busEngineGearBoxList = useBusEngineGearBox({
    basicVehicles: basicBusses,
    selectedBattery: selections.busBattery,
    selectedSocWindow: selections.busSocWindow,
    selectedPortfolio: selections.busPortfolio,
    selectedChargingType: selections.busChargingType,
  });
  const busWheelConfigurationList = useBusWheelConfiguration({
    basicBusses,
    selections,
  });
  const busBodyLengthList = useBusBodyLenghts({ basicBusses, selections });
  const busChassisHeightList = useBusChassisHeights({ basicBusses, selections });
  const busFloorHeightList = useBusFloorHeights({ basicBusses, selections });

  // Truck options
  const truckPortfolioOptions = useNamesToDropdownOptions({
    names: truckPortfolioList,
  });

  const truckBatteryOptions = useNamesToDropdownOptions({
    names: truckBatteryList,
    suffix: 'kWh',
  });

  const truckSocOptions = useNamesToDropdownOptions({
    names: truckSocList,
  });

  const truckEngineGearBoxOptions = useNamesToDropdownOptions({
    names: truckEngineGearBoxList,
  });

  const truckChassisAdaptationOptions = useNamesToDropdownOptions({
    names: truckChassisAdaptationList,
    translate: chassisAdaptationTranslate,
  });

  const truckWheelConfigurationOptions = useNamesToDropdownOptions({
    names: truckWheelConfigurationList,
  });

  const truckAxleDistanceOptions = useNamesToDropdownOptions({
    names: truckAxleDistanceList,
    suffix: 'mm',
  });

  const truckChassisHeightOptions = useNamesToDropdownOptions({
    names: truckChassisHeightList,
    translate: chassisHeightTranslate,
  });

  const truckCabTypeOptions = useNamesToDropdownOptions({
    names: truckCabTypeList,
  });

  // Bus options
  const busPortfolioOptions = useNamesToDropdownOptions({
    names: busPortfolioList,
  });

  const busBatteryOptions = useNamesToDropdownOptions({
    names: busBatteryList,
    suffix: 'kWh',
  });

  const busSocOptions = useNamesToDropdownOptions({
    names: busSocList,
  });

  const busChargingTypeOptions = useBusChargingTypeOptions({ basicBusses, selections });

  const busEngineGearBoxOptions = useNamesToDropdownOptions({
    names: busEngineGearBoxList,
  });

  const busWheelConfigurationOptions = useNamesToDropdownOptions({
    names: busWheelConfigurationList,
  });

  const busBodyLengthOptions = useNamesToDropdownOptions({
    names: busBodyLengthList,
  });

  const busChassisHeightOptions = useNamesToDropdownOptions({
    names: busChassisHeightList,
    translate: chassisHeightTranslate,
  });

  const busFloorHeightOptions = useNamesToDropdownOptions({
    names: busFloorHeightList,
  });

  const busChargingTypeList = useMemo(
    () => busChargingTypeOptions.map((busChargingTypeOption) => busChargingTypeOption.value),
    [busChargingTypeOptions],
  );

  const truckDisabled = useTruckDisabled({ selections });
  const busDisabled = useBusDisabled({ selections });

  const handleSelection = useHandleSelection({ selectionsDefault, setSelections });

  useLoadSelectionBySuggestion({
    chassisType: selections.chassisType,
    vehicles,
    truckPortfolioList,
    truckBatteryList,
    truckSocList,
    truckEngineGearBoxList,
    truckChassisAdaptationList,
    truckWheelConfigurationList,
    truckAxleDistanceList,
    truckChassisHeightList,
    truckCabTypeList,
    busPortfolioList,
    busBatteryList,
    busChargingTypeList,
    busSocList,
    busEngineGearBoxList,
    busWheelConfigurationList,
    busBodyLengthList,
    busChassisHeightList,
    busFloorHeightList,
    preSelectOptionsInitially,
    enableSuggestions,
    setSelections,
  });

  return useMemo(
    () => ({
      chassisTypeOptions,
      truckPortfolioOptions,
      truckBatteryOptions,
      truckSocOptions,
      truckEngineGearBoxOptions,
      truckChassisHeightOptions,
      truckWheelConfigurationOptions,
      truckChassisAdaptationOptions,
      truckAxleDistanceOptions,
      truckCabTypeOptions,
      busPortfolioOptions,
      busBatteryOptions,
      busSocOptions,
      busChassisHeightOptions,
      busEngineGearBoxOptions,
      busWheelConfigurationOptions,
      busBodyLengthOptions,
      busFloorHeightOptions,
      busChargingTypeOptions,
      selections,
      ...busDisabled,
      ...truckDisabled,
      handleSelection,
      setSelections,
    }),
    [
      chassisTypeOptions,
      truckPortfolioOptions,
      truckBatteryOptions,
      truckSocOptions,
      truckEngineGearBoxOptions,
      truckChassisHeightOptions,
      truckWheelConfigurationOptions,
      truckChassisAdaptationOptions,
      truckAxleDistanceOptions,
      truckCabTypeOptions,
      truckDisabled,
      busPortfolioOptions,
      busBatteryOptions,
      busSocOptions,
      busChassisHeightOptions,
      busEngineGearBoxOptions,
      busWheelConfigurationOptions,
      busBodyLengthOptions,
      busFloorHeightOptions,
      busChargingTypeOptions,
      busDisabled,
      selections,
      handleSelection,
      setSelections,
    ],
  );
};

export default useVehicleOptionsHandler;
