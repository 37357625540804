import classes from './style.module.scss';
import classNames from 'classnames';
import { DebouncedTextField, DebouncedTimeField } from '@optimization/ssi-common';
import { Button, ButtonAlt, Dropdown, DropdownOption, Modal, Slider } from '@optimization/ssi-common';
import { CandidateChartFormValues } from '../../utils/types';
import { Messages } from '../../utils/useMessages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ValidateChargingEventsHandler } from '../../utils/useValidateChargingEventsHandler';
import {
  isSavedChargingEvent,
  ChargingEventV2VM,
  ChartChargingEvent,
  ListDepotResponseV2VM,
} from '@optimization/sa-common';

export const EXTERNAL_DEPOT_VALUE = 'external';
export const CHARGING_DURATION_MIN = 15;

type ModalType = 'delete-charging-event';

interface Props {
  depotIdOrExternal: string;
  resultingSoc: number;
  eventStartTime: string;
  chargingDuration: number;
  currentChargingEvent?: ChargingEventV2VM;
  currentChartChargingEvent?: ChartChargingEvent;
  updateVehicleChargingEventsLoading: boolean;
  depots?: ListDepotResponseV2VM[];
  homeDepotId?: number;
  messages?: Messages;
  validateChargingEventsHandler: ValidateChargingEventsHandler;
  onCancel: () => void;
  onDelete?: () => void;
  onSave: () => void;
  setCandidateChartFormValues: React.Dispatch<React.SetStateAction<CandidateChartFormValues>>;
}

const ChargingPowerInputs = ({
  depotIdOrExternal,
  resultingSoc,
  eventStartTime,
  chargingDuration,
  currentChargingEvent,
  currentChartChargingEvent,
  updateVehicleChargingEventsLoading,
  depots,
  homeDepotId,
  messages,
  validateChargingEventsHandler,
  onSave,
  onCancel,
  onDelete,
  setCandidateChartFormValues,
}: Props) => {
  const [modal, setModal] = useState<ModalType | null>(null);
  const [hasTouchedInput, setHasTouchedInput] = useState(false);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const showDeleteChargingEventModal = useCallback(() => {
    setModal('delete-charging-event');
  }, []);

  const setInputIsTouched = useCallback(() => {
    setHasTouchedInput(true);
  }, []);

  const setInputNotTouched = useCallback(() => {
    setHasTouchedInput(false);
  }, []);

  const onChangeDuration = useCallback(
    (value: string) => {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        chargingDuration: Boolean(value) ? Number(value) : 0,
        includeChargingDurationInValidate: true,
        runValidateVehicleChargingEvents: true,
      }));
    },
    [setCandidateChartFormValues],
  );

  const onChangeEventStartTime = useCallback(
    (value: string) => {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        eventStartTime: value,
        includeChargingDurationInValidate: true,
        runValidateVehicleChargingEvents: true,
      }));
    },
    [setCandidateChartFormValues],
  );

  const setDepotIdOrExternal = useCallback(
    (value: string) =>
      setCandidateChartFormValues((prev) => ({
        ...prev,
        depotIdOrExternal: value,
        infrastructureProductInstanceId: '',
        includeChargingDurationInValidate: true,
        runValidateVehicleChargingEvents: true,
      })),
    [setCandidateChartFormValues],
  );

  const onChangeResultingSoc = useCallback(
    (value: string) => {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        resultingSoc: Number(value),
        includeChargingDurationInValidate: false,
        runValidateVehicleChargingEvents: true,
      }));
    },
    [setCandidateChartFormValues],
  );

  const completeLongestDistancePercentage: number | undefined = useMemo(
    () => validateChargingEventsHandler.data?.CompleteLongestDistancePercentage,
    [validateChargingEventsHandler.data],
  );

  useEffect(() => {
    if (
      (validateChargingEventsHandler.isSuccess || validateChargingEventsHandler.isError) &&
      !validateChargingEventsHandler.isLoading
    ) {
      setInputNotTouched();
    }
  }, [
    validateChargingEventsHandler.isSuccess,
    validateChargingEventsHandler.isError,
    validateChargingEventsHandler.isLoading,
    setInputNotTouched,
  ]);

  const onCompleteLongestDistance = useCallback(() => {
    if (completeLongestDistancePercentage) {
      setCandidateChartFormValues((prev) => ({
        ...prev,
        resultingSoc: completeLongestDistancePercentage,
        includeChargingDurationInValidate: false,
        runValidateVehicleChargingEvents: true,
      }));
    }
  }, [completeLongestDistancePercentage, setCandidateChartFormValues]);

  const locationOptions = useMemo(() => {
    const options: DropdownOption[] = [];

    if (depots) {
      for (const depot of depots) {
        options.push({
          name: `${depot.DepotName}${depot.DepotId === homeDepotId ? ' (home)' : ''}`,
          value: depot.DepotId,
        });
      }
    }

    options.push({ name: `External`, value: 'external' });

    return options;
  }, [homeDepotId, depots]);

  const currentChartChargingEventIsInvalid = useMemo(
    () => Boolean(currentChartChargingEvent && !currentChartChargingEvent.isValid),
    [currentChartChargingEvent],
  );

  return (
    <>
      {modal === 'delete-charging-event' && (
        <Modal
          size="xs"
          header="Delete charging event"
          variant="danger"
          ctaConfirmText="Delete"
          ctaConfirmSubmit={onDelete}
          onClose={closeModal}
          disabled={updateVehicleChargingEventsLoading}
        >
          Are you sure you want to delete this charging event?
        </Modal>
      )}
      <div className={classes['charging-power-inputs']}>
        <div className={classes.header}>
          <div>
            <div className="tds-detail-04">Setup charging event</div>
            <p className="tds-detail-05 mt-spacing-4">Define a charging event to complete the daily operation</p>
          </div>
          <ButtonAlt tdsIcon="cross" onClick={onCancel} />
        </div>
        <div className={classes.body}>
          <Dropdown
            label="Location"
            labelPosition="outside"
            value={depotIdOrExternal}
            disabled={updateVehicleChargingEventsLoading || currentChartChargingEventIsInvalid}
            onChange={setDepotIdOrExternal}
            options={locationOptions}
          />
          <DebouncedTimeField
            label="Event start time"
            value={eventStartTime}
            disabled={updateVehicleChargingEventsLoading || currentChartChargingEventIsInvalid}
            onChange={onChangeEventStartTime}
            onChangeImmediately={setInputIsTouched}
            state={messages?.eventStartTimeErrorMessage ? 'error' : undefined}
            helper={messages?.eventStartTimeErrorMessage?.header}
          />
          <DebouncedTextField
            label="Charging duration"
            type="number"
            suffixText="mins"
            disabled={updateVehicleChargingEventsLoading || currentChartChargingEventIsInvalid}
            value={chargingDuration ? chargingDuration.toString() : ''}
            onChange={onChangeDuration}
            onChangeImmediately={setInputIsTouched}
            min={0}
            max={360}
          />
          <div>
            <Slider
              label="Resulting SoC [%]"
              min="0"
              max="100"
              className="mt-negative-spacing-22"
              disabled={updateVehicleChargingEventsLoading || currentChartChargingEventIsInvalid}
              value={resultingSoc}
              onChange={onChangeResultingSoc}
            />
            {completeLongestDistancePercentage && (
              <button
                type="button"
                disabled={
                  hasTouchedInput ||
                  currentChartChargingEventIsInvalid ||
                  updateVehicleChargingEventsLoading ||
                  validateChargingEventsHandler.isLoading
                }
                onClick={onCompleteLongestDistance}
                className={classNames('reset-button-styles', classes['complete-longest-distance'])}
              >
                Complete longest distance
              </button>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <div className="flex">
            <Button
              size="sm"
              variant="secondary"
              onClick={onSave}
              text={`${isSavedChargingEvent(currentChargingEvent?.Id) ? 'Save' : 'Create'}  event`}
              disabled={
                hasTouchedInput ||
                currentChartChargingEventIsInvalid ||
                updateVehicleChargingEventsLoading ||
                !Boolean(eventStartTime) ||
                validateChargingEventsHandler.isLoading ||
                (validateChargingEventsHandler.isSuccess &&
                  validateChargingEventsHandler.data &&
                  !validateChargingEventsHandler.data.ChargingEventIsValid)
              }
            />
            {isSavedChargingEvent(currentChargingEvent?.Id) && (
              <Button
                className="ml-spacing-16"
                size="sm"
                onClick={showDeleteChargingEventModal}
                text="Delete event"
                variant="secondary"
                disabled={
                  hasTouchedInput || updateVehicleChargingEventsLoading || validateChargingEventsHandler.isLoading
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargingPowerInputs;
