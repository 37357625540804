import { SolutionMetadataTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  solutions?: SolutionMetadataTransformed[];
  selectedSolutionNames: string[];
}

const useFilteredSolutions = ({ solutions, selectedSolutionNames }: Props) =>
  useMemo(() => {
    if (solutions?.length) {
      return [...solutions].filter((solution) =>
        selectedSolutionNames.length
          ? selectedSolutionNames.includes(solution.transformed.solutionNamePresentation)
          : true,
      );
    }
  }, [solutions, selectedSolutionNames]);

export default useFilteredSolutions;
