import { solutionApi } from 'app/services/solution';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useInvalidateSolutionCache = () => {
  const dispatch = useDispatch();

  return useCallback(
    (tags: any[]) => {
      dispatch(solutionApi.util.invalidateTags(tags));
    },
    [dispatch],
  );
};

export default useInvalidateSolutionCache;
