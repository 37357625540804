import { FactBasicChargingSolution } from '@optimization/sa-common';
import { KeyedStringOrNumber } from '@optimization/ssi-common';
import { useMemo } from 'react';

const useChargerVariantValues = (chargingSolutions?: FactBasicChargingSolution[]) =>
  useMemo(() => {
    let result: KeyedStringOrNumber = {};

    if (chargingSolutions) {
      for (const chargingSolution of chargingSolutions) {
        result[chargingSolution.Brand.VariantCode] = chargingSolution.Brand.VariantDescription;

        result[chargingSolution.Solution.VariantCode] = chargingSolution.Solution.VariantDescription;

        for (const numberOfOutlet of chargingSolution.NumberOfOutlets) {
          result[numberOfOutlet.VariantCode] = numberOfOutlet.VariantValue || 0;
        }

        for (const powerOutput of chargingSolution.PowerOutputs) {
          result[powerOutput.VariantCode] = powerOutput.VariantValue || 0;
        }
      }
    }

    return result;
  }, [chargingSolutions]);

export default useChargerVariantValues;
