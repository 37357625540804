import { chassisAdaptationTranslate } from '@optimization/sa-common';
import { Filter } from 'common/components/Filters/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedCabs: string[];
  selectedLaunchPeriods: string[];
  selectedBatteries: string[];
  selectedPerformanceSteps: string[];
  selectedChassisNames: string[];
  selectedAxleDistances: string[];
  selectedWheelConfigurations: string[];
  selectedChassisAdaptations: string[];
}

const useSelectedFilters = ({
  selectedCabs,
  selectedLaunchPeriods,
  selectedBatteries,
  selectedPerformanceSteps,
  selectedChassisNames,
  selectedAxleDistances,
  selectedWheelConfigurations,
  selectedChassisAdaptations,
}: Props) =>
  useMemo(() => {
    let filters: Filter[] | null = null;

    if (selectedChassisNames.length) {
      if (!filters) {
        filters = [];
      }

      filters.push({
        key: 'chassisNames',
        name: 'NAMES',
        value: '',
      });
    }

    if (selectedChassisAdaptations.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedChassisAdaptation of selectedChassisAdaptations) {
        filters.push({
          key: 'chassisAdaptation',
          name: chassisAdaptationTranslate[selectedChassisAdaptation],
          value: selectedChassisAdaptation,
        });
      }
    }

    if (selectedWheelConfigurations.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedWheelConfiguration of selectedWheelConfigurations) {
        filters.push({
          key: 'wheelConfiguration',
          name: selectedWheelConfiguration,
          value: selectedWheelConfiguration,
        });
      }
    }

    if (selectedAxleDistances.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedAxleDistance of selectedAxleDistances) {
        filters.push({
          key: 'axleDistance',
          name: `${selectedAxleDistance} mm`,
          value: selectedAxleDistance,
        });
      }
    }

    if (selectedCabs.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedCab of selectedCabs) {
        filters.push({
          key: 'cab',
          name: selectedCab,
          value: selectedCab,
        });
      }
    }

    if (selectedLaunchPeriods.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedLaunchPeriod of selectedLaunchPeriods) {
        filters.push({
          key: 'launchPeriod',
          name: selectedLaunchPeriod,
          value: selectedLaunchPeriod,
        });
      }
    }

    if (selectedBatteries.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedBattery of selectedBatteries) {
        filters.push({
          key: 'battery',
          name: `${selectedBattery} kWh`,
          value: selectedBattery,
        });
      }
    }

    if (selectedPerformanceSteps.length) {
      if (!filters) {
        filters = [];
      }

      for (const selectedPerformanceStep of selectedPerformanceSteps) {
        filters.push({
          key: 'performanceStep',
          name: selectedPerformanceStep,
          value: selectedPerformanceStep,
        });
      }
    }

    return filters;
  }, [
    selectedLaunchPeriods,
    selectedPerformanceSteps,
    selectedBatteries,
    selectedChassisNames,
    selectedCabs,
    selectedAxleDistances,
    selectedWheelConfigurations,
    selectedChassisAdaptations,
  ]);

export default useSelectedFilters;
