import classNames from 'classnames';
import DepotTeaser from 'features/infrastructure/utils/DepotTeaser';
import Grid from './Grid';
import { Link } from 'react-router-dom';
import { ListDepotTransformed } from '@optimization/sa-common';

interface Props {
  className?: string;
  depotsWithWarning: ListDepotTransformed[];
  solutionId: string;
}

const DepotsGrid = ({ className, depotsWithWarning, solutionId }: Props) => (
  <div className={className}>
    <div className="flex">
      <div className="tds-detail-04">Depot management</div>
      <Link
        to={`/solution/${solutionId}/infrastructure`}
        className={classNames('cursor-pointer', 'tds-text-white', 'tds-u-ml-auto', 'underline')}
      >
        Go to page
      </Link>
    </div>
    <Grid className="mt-spacing-8">
      {depotsWithWarning.map((depot) => (
        <DepotTeaser key={depot.DepotId} depot={depot} solutionId={solutionId} />
      ))}
    </Grid>
  </div>
);

export default DepotsGrid;
