import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

const Box = ({ className, children }: Props) => (
  <section className={classNames(classes.box, className)}>{children}</section>
);

export default Box;
