import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useChassisNumbers = (enhancedVehicles?: ListVehicleEnhanced[]) =>
  useMemo(
    () =>
      enhancedVehicles
        ?.map((vehicle) => vehicle.ScaniaChassisLight.ChassisNumber || 0)
        .filter((chassisNumber) => chassisNumber) || [],
    [enhancedVehicles],
  );

export default useChassisNumbers;
