import { getChargingEventMessage } from './useMessages';
import { useMemo } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';
import {
  chargerConnectivetyErrorMessage,
  eventStartTimeOccursToLateMessage,
  powerNegotiationMessage,
  notEnoughPowerBlocksMessage,
  notEnoughTimeToChargeMessage,
  overSpeedLimitMessage,
  slowChargerMissingMessage,
  unreachableChargingEventsMessage,
} from './useMessages';

interface Props {
  vehicle: VehicleEnhanced;
  depotYear?: string;
}

const useWarningMessage = ({ vehicle, depotYear }: Props) =>
  useMemo(() => {
    if (depotYear) {
      let performanceStep = vehicle.VehiclePerformanceSteps.find((e) => e.PerformanceStep === depotYear);

      let warningMessage;

      if (performanceStep) {
        const chargingEvents = performanceStep.SlowChargingEvent
          ? [...performanceStep.ChargingEvents, performanceStep.SlowChargingEvent]
          : performanceStep.ChargingEvents;

        const warningArray = chargingEvents.map((chargingEvent) => getChargingEventMessage({ chargingEvent }));

        const headers = [
          slowChargerMissingMessage.header,
          unreachableChargingEventsMessage.header,
          powerNegotiationMessage.header,
          eventStartTimeOccursToLateMessage.header,
          notEnoughPowerBlocksMessage.header,
          chargerConnectivetyErrorMessage.header,
          notEnoughTimeToChargeMessage.header,
          overSpeedLimitMessage.header,
        ];

        for (const header of headers) {
          warningMessage = warningArray.find((message) => message?.header === header);

          if (warningMessage) {
            break;
          }
        }
      }

      return warningMessage;
    }
  }, [depotYear, vehicle.VehiclePerformanceSteps]);

export default useWarningMessage;
