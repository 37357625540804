import classNames from 'classnames';
import PageHeader from 'common/components/PageHeader';
import { ButtonLink } from '@optimization/ssi-common';

interface Props {
  className?: string;
}

const PageNotFound = ({ className }: Props) => (
  <div className={classNames('tds-container', className)}>
    <PageHeader
      description={[`Maybe it has been removed. You could try navigate to frontpage.`]}
      descriptionAligment="left"
    >
      Page could not be found
    </PageHeader>
    <ButtonLink text="Go to frontpage" url="/" variant="secondary" icon="arrow_right" className="tds-u-mt2" />
  </div>
);

export default PageNotFound;
