import AddVehicleDrawer from './AddVehicleDrawer';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface AddVehicle {
  addVehicleDrawerIsActive: boolean;
  activateAddVehicleDrawer: () => void;
  deactivateAddVehicleDrawer: () => void;
}

const AddVehicleContext = createContext<AddVehicle | null>(null);

export const AddVehicleProvider = ({ children }: { children: ReactNode }) => {
  const [addVehicleDrawerIsActive, setAddVehicleDrawerIsActive] = useState<boolean>(false);

  const activateAddVehicleDrawer = useCallback(() => {
    setAddVehicleDrawerIsActive(true);
  }, [setAddVehicleDrawerIsActive]);

  const deactivateAddVehicleDrawer = useCallback(() => {
    setAddVehicleDrawerIsActive(false);
  }, [setAddVehicleDrawerIsActive]);

  const value = useMemo(
    () => ({
      addVehicleDrawerIsActive,
      activateAddVehicleDrawer,
      deactivateAddVehicleDrawer,
    }),
    [addVehicleDrawerIsActive, activateAddVehicleDrawer, deactivateAddVehicleDrawer],
  );

  return (
    <AddVehicleContext.Provider value={value}>
      <AuthenticatedTemplate>
        <AddVehicleDrawer
          addVehicleDrawerIsActive={addVehicleDrawerIsActive}
          deactivateAddVehicleDrawer={deactivateAddVehicleDrawer}
        />
      </AuthenticatedTemplate>
      {children}
    </AddVehicleContext.Provider>
  );
};

export const useAddVehicle = () => useContext(AddVehicleContext);
