import { GetDepotResponseV2VM } from '@optimization/sa-common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  yearParam?: string;
  depot?: GetDepotResponseV2VM;
  solutionId: string;
}

const useNavigateToDefaultWhenMissingYear = ({ yearParam, depot, solutionId }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      yearParam &&
      depot &&
      Object.keys(depot.DepotDataPerPerformanceStep).every((performanceStep) => performanceStep !== yearParam)
    ) {
      navigate(`/solution/${solutionId}/infrastructure/${depot.Id}`);
    }
  }, [yearParam, depot, solutionId, navigate]);
};

export default useNavigateToDefaultWhenMissingYear;
