interface Props {
  categoryName: string;
  subCategoryName: string;
}

const ProductManualHeader = ({ categoryName, subCategoryName }: Props) => (
  <div className="bg-blue-800 px-spacing-32 pb-spacing-32">
    <h1 className="pt-spacing-96 tds-text-grey-400 tds-headline-02">{categoryName}</h1>
    <h2 className="tds-headline-02 tds-text-white">{subCategoryName}</h2>
  </div>
);

export default ProductManualHeader;
