import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const MessageWrapper = ({ children }: Props) => (
  <div className={classNames('px-spacing-16', 'py-spacing-16', classes['message-wrapper'])}>{children}</div>
);

export default MessageWrapper;
