import classes from './style.module.scss';
import classNames from 'classnames';
import { Button, TextArea, Tooltip, usePlural } from '@optimization/ssi-common';
import { TdsIcon } from '@scania/tegel-react';
import { useCallback, useRef, useState } from 'react';
import { ValidateChassisNumbersVehicleResponseV2VM } from '@optimization/sa-common';

const INITIAL_NUMBER_OF_CHASSIS = 10;

interface Props {
  chassisNumber: string;
  chassisNumbers: string[];
  validateResponse: ValidateChassisNumbersVehicleResponseV2VM;
}

const Summary = ({ chassisNumber, chassisNumbers, validateResponse }: Props) => {
  const bevAdaptedRef = useRef<HTMLDivElement>(null);
  const nextGenRef = useRef<HTMLDivElement>(null);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = useCallback(() => {
    setShowAll((prev) => !prev);
  }, []);

  const { FoundVehicles, ExecutableVehicles, NonExecutableVehicles, MissingVehicles } = validateResponse;

  const nonExecutableVehiclesPlural = usePlural({
    count: NonExecutableVehicles.length,
  });

  return (
    <div className={classes.summary}>
      <div className="tds-headline-05">Summary</div>
      <div className={classNames('px-spacing-16', 'py-spacing-24', 'mt-spacing-8', classes.box)}>
        <TextArea rows={4} value={chassisNumber} disabled onChange={() => null} />
        {FoundVehicles.length > 0 && (
          <div className={classNames('mt-spacing-24', classes.results)}>
            <div>Candidates created</div>
            <div>
              {FoundVehicles.length} / {chassisNumbers.length} evaluated chassis
            </div>
            <div>
              <Tooltip elRef={bevAdaptedRef}>
                The system will find a suitable current or future BEV product for the chassis. If none is found, it will
                be considered for our next-generation products.
              </Tooltip>
              <div className="flex items-center">
                <div ref={bevAdaptedRef}>
                  <TdsIcon name="info" size="14px" className="mr-spacing-6" />
                </div>
                BEV adapted
              </div>
            </div>
            <div>{ExecutableVehicles.length}</div>
            <div>
              <Tooltip elRef={nextGenRef}>Chassis considered for our next-generation of products</Tooltip>
              <div className="flex items-center">
                <div ref={nextGenRef}>
                  <TdsIcon name="info" size="14px" className="mr-spacing-6" />
                </div>
                Next Generation
              </div>
            </div>
            <div>
              {NonExecutableVehicles.length} vehicle{nonExecutableVehiclesPlural}
            </div>
            {MissingVehicles.length > 0 && (
              <>
                <div>Missing data</div>
                <div>
                  <div>{MissingVehicles.length} chassis</div>
                  <div className="mt-spacing-8">
                    {MissingVehicles.slice(0, showAll ? undefined : INITIAL_NUMBER_OF_CHASSIS).map((chassisId, i) => (
                      <span className="tds-detail-05 tds-text-grey-400" key={i}>
                        {i !== 0 && ', '}
                        {chassisId}
                      </span>
                    ))}
                    {!showAll && MissingVehicles.length > INITIAL_NUMBER_OF_CHASSIS && <span>...</span>}
                    {MissingVehicles.length > INITIAL_NUMBER_OF_CHASSIS && (
                      <Button
                        className="mt-spacing-8"
                        text={showAll ? 'Show less' : 'Show all'}
                        size="xs"
                        variant="secondary"
                        onClick={toggleShowAll}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
