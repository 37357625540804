import useAxleDistances from './useAxleDistances';
import useCabs from './useCabs';
import useChassisAdaptations from './useChassisAdaptations';
import useChassisNames from 'features/candidate/Candidates/utils/useChassisNames';
import useCheckboxChange from 'common/components/Filters/utils/useCheckboxChange';
import useFilteredAndSortedVehicles from './useFilteredAndSortedVehicles';
import useFiltersHandler from 'common/components/Filters/utils/useFiltersHandler';
import useInstalledEnergies from './useInstalledEnergies';
import useLaunchPeriods from 'features/candidate/utils/useLaunchPeriods';
import useMultiselectChange from 'common/components/Filters/utils/useMultiselectChange';
import useParseMultipleValues from 'common/components/Filters/utils/useParseMultipleValues';
import usePerformanceSteps from './usePerformanceSteps';
import useRemoveFilter from 'common/components/Filters/utils/useRemoveFilter';
import useRemoveFilters from 'common/components/Filters/utils/useRemoveFilters';
import useSelectedFilters from './useSelectedFilters';
import useWheelConfigurations from './useWheelConfigurations';
import { CANDIDATES_PER_PAGE } from '..';
import { Filter } from 'common/components/Filters/utils/types';
import { useEffect, useMemo, useState } from 'react';
import { useExpandVehicleAndTab } from 'app/context/ExpandVehicleAndTabContext';
import { useGetDepotQuery, useGetFactBasicDataQuery, useGetVehiclesQuery } from 'app/services/solution';
import { useSearchParams } from 'react-router-dom';
import { useSplicedItems } from '@optimization/ssi-common';
import {
  useExecutedListVehicles,
  isListVehicleValidForYear,
  useEnhancedListVehicles,
  ListVehicleEnhanced,
  VehicleSettingsById,
} from '@optimization/sa-common';

const CLEAR_ALL_AT_ONCE_KEYS = ['chassisNames'];
const SORT_DIRECTION_DEFAULT = 'asc';

export interface FilterParams {
  axleDistance: string;
  battery: string;
  cab: string;
  candidateType: string;
  chassisAdaptation: string;
  chassisNames: string;
  enginePower: string;
  feasibility: string;
  launchPeriod: string;
  performanceStep: string;
  sort: string;
  sortDirection: string;
  wheelConfiguration: string;
}

export interface CandidateHandler {
  solutionId: string;
  filterParams: FilterParams;
  selectedFilters: Filter[] | null;
  selectedCandidates?: string[];
  filteredCandidates?: ListVehicleEnhanced[];
  splicedCandidates?: ListVehicleEnhanced[];
  installedEnergies: number[];
  launchPeriods: string[];
  performanceSteps: string[];
  chassisAdaptations: string[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  selectedAxleDistances: string[];
  selectedChassisNames: string[];
  selectedBatteries: string[];
  selectedLaunchPeriods: string[];
  selectedPerformanceSteps: string[];
  selectedChassisAdaptations: string[];
  selectedCabs: string[];
  chassisNames: string[];
  axleDistances: number[];
  cabs: string[];
  sortType: string;
  sortDirection: 'asc' | 'desc';
  wheelConfigurations: string[];
  selectedWheelConfigurations: string[];
  setVehiclesCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  onChangeAxleDistance: (checked: boolean, value: string) => void;
  onChangeBattery: (checked: boolean, value: string) => void;
  onChangeLaunchPeriod: (checked: boolean, value: string) => void;
  onChangeChassisNumber: (values: string) => void;
  onChangePerformanceStep: (values: string) => void;
  onChangeCab: (checked: boolean, value: string) => void;
  onChangeWheelConfiguration: (checked: boolean, value: string) => void;
  onChangeChassisAdaptation: (checked: boolean, value: string) => void;
  onRemoveFilter: (filter: Filter) => void;
  onRemoveFilters: () => void;
  filtersHandler: {
    onChangeSortType: (value: string) => void;
    toggleSortBy: () => void;
    toggleSortDirection: () => void;
    toggleFilters: () => void;
    hideFilters: () => void;
    activeFiltersAction: 'filter' | 'sort-by' | null;
  };
}

interface Props {
  selectedCandidates?: string[];
  showOnlySelectedCandidates?: boolean;
  vehiclesMappedToOtherDepot?: { [key: string]: boolean };
  solutionId: string;
  depotId?: string;
  depotYear?: string;
  selectedMode?: string;
  includeCandidateWithNonExecutedVehicleProduct?: boolean;
  vehicleSettingsById?: VehicleSettingsById;
  chassisType?: string;
  hasSetChargingEvents?: boolean;
  vehicleIdsWhenNoSelectedFilters?: number[];
}

const useCandidatesHandler = ({
  selectedCandidates,
  showOnlySelectedCandidates,
  vehiclesMappedToOtherDepot,
  solutionId,
  depotId,
  depotYear,
  selectedMode,
  includeCandidateWithNonExecutedVehicleProduct,
  vehicleSettingsById,
  chassisType,
  hasSetChargingEvents,
  vehicleIdsWhenNoSelectedFilters,
}: Props): CandidateHandler => {
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const depotQuery = useGetDepotQuery(
    {
      solutionId,
      depotId: depotId || '',
    },
    { skip: !Boolean(depotId) },
  );

  const [searchParams] = useSearchParams();

  const filterParams: FilterParams = useMemo(
    () => ({
      axleDistance: searchParams.get('axleDistance') || '',
      battery: searchParams.get('battery') || '',
      cab: searchParams.get('cab') || '',
      candidateType: searchParams.get('candidateType') || '',
      chassisNames: searchParams.get('chassisNames') || '',
      chassisAdaptation: searchParams.get('chassisAdaptation') || '',
      enginePower: searchParams.get('enginePower') || '',
      feasibility: searchParams.get('feasibility') || '',
      launchPeriod: searchParams.get('launchPeriod') || '',
      performanceStep: searchParams.get('performanceStep') || '',
      sort: searchParams.get('sort') || 'performanceStep',
      sortDirection: searchParams.get('sortDirection') || SORT_DIRECTION_DEFAULT,
      wheelConfiguration: searchParams.get('wheelConfiguration') || '',
    }),
    [searchParams],
  );

  const sortType = filterParams.sort;
  const sortDirection = filterParams.sortDirection;

  const [vehiclesCount, setVehiclesCount] = useState<number | undefined>(undefined);

  const filtersHandler = useFiltersHandler({
    filterParams: filterParams as any,
    sortDirectionDefault: SORT_DIRECTION_DEFAULT,
  });

  const selectedBatteries = useParseMultipleValues(filterParams.battery);
  const selectedChassisNames = useParseMultipleValues(filterParams.chassisNames);
  const selectedCabs = useParseMultipleValues(filterParams.cab);

  const selectedChassisAdaptations = useParseMultipleValues(filterParams.chassisAdaptation);

  const selectedWheelConfigurations = useParseMultipleValues(filterParams.wheelConfiguration);

  const selectedAxleDistances = useParseMultipleValues(filterParams.axleDistance);

  const selectedPerformanceSteps = useParseMultipleValues(filterParams.performanceStep);

  const selectedLaunchPeriods = useParseMultipleValues(filterParams.launchPeriod);

  const onChangeChassisNumber = useMultiselectChange({
    filterParams,
    filterKey: 'chassisNames',
  });

  const onChangeAxleDistance = useCheckboxChange({
    filterParams,
    filterKey: 'axleDistance',
    selectedValues: selectedAxleDistances,
  });

  const onChangeBattery = useCheckboxChange({
    filterParams,
    filterKey: 'battery',
    selectedValues: selectedBatteries,
  });

  const onChangeLaunchPeriod = useCheckboxChange({
    filterParams,
    filterKey: 'launchPeriod',
    selectedValues: selectedLaunchPeriods,
  });

  const onChangeCab = useCheckboxChange({
    filterParams,
    filterKey: 'cab',
    selectedValues: selectedCabs,
  });

  const onChangeWheelConfiguration = useCheckboxChange({
    filterParams,
    filterKey: 'wheelConfiguration',
    selectedValues: selectedWheelConfigurations,
  });

  const onChangeChassisAdaptation = useCheckboxChange({
    filterParams,
    filterKey: 'chassisAdaptation',
    selectedValues: selectedChassisAdaptations,
  });

  const onChangePerformanceStep = useMultiselectChange({
    filterParams,
    filterKey: 'performanceStep',
  });

  const selectedFilters = useSelectedFilters({
    selectedLaunchPeriods,
    selectedBatteries,
    selectedPerformanceSteps,
    selectedChassisNames,
    selectedCabs,
    selectedAxleDistances,
    selectedWheelConfigurations,
    selectedChassisAdaptations,
  });

  const onRemoveFilter = useRemoveFilter({
    filterParams,
    clearAllAtOnceKeys: CLEAR_ALL_AT_ONCE_KEYS,
  });

  const onRemoveFilters = useRemoveFilters();

  const enhancedVehicles = useEnhancedListVehicles({
    vehicles: vehiclesQuery.data,
    depotYear,
    factBasicDataQueryData: factBasicDataQuery.data,
    vehicleSettingsById,
  });

  const preFilteredCandidates = useMemo(
    () =>
      enhancedVehicles
        .filter((vehicle) =>
          depotId ? (depotQuery.data ? depotQuery.data.MappedVehicles.includes(vehicle.Id) : false) : true,
        )
        .filter((vehicle) => (selectedMode === 'map-vehicles' ? !vehiclesMappedToOtherDepot?.[vehicle.Id || ''] : true))
        .filter((vehicle) => isListVehicleValidForYear({ vehicle, year: depotYear }))
        .filter((vehicle) =>
          showOnlySelectedCandidates ? selectedCandidates?.includes(vehicle.Id.toString() || '') : true,
        )
        .filter((vehicle) => (chassisType ? vehicle.ChassisType === chassisType : true))
        .filter((vehicle) =>
          hasSetChargingEvents !== undefined ? vehicle.HasSetChargingEvents === hasSetChargingEvents : true,
        )
        .filter((vehicle) =>
          vehicleIdsWhenNoSelectedFilters && !selectedFilters
            ? vehicleIdsWhenNoSelectedFilters.includes(vehicle.Id)
            : true,
        ),
    [
      enhancedVehicles,
      depotId,
      depotQuery.data,
      depotYear,
      showOnlySelectedCandidates,
      selectedCandidates,
      selectedMode,
      vehiclesMappedToOtherDepot,
      chassisType,
      hasSetChargingEvents,
      vehicleIdsWhenNoSelectedFilters,
      selectedFilters,
    ],
  );

  const preFilteredOnlyExecutedCandidates = useExecutedListVehicles({
    vehicles: preFilteredCandidates,
  });

  const filteredCandidates = useFilteredAndSortedVehicles({
    sortType,
    sortDirection: sortDirection === 'asc' ? 'asc' : 'desc',
    selectedAxleDistances,
    selectedChassisNames,
    selectedBatteries,
    selectedLaunchPeriods,
    selectedPerformanceSteps,
    selectedCabs,
    selectedWheelConfigurations,
    selectedChassisAdaptations,
    enhancedVehicles: includeCandidateWithNonExecutedVehicleProduct
      ? preFilteredCandidates
      : preFilteredOnlyExecutedCandidates,
  });

  const expandVehicleAndTab = useExpandVehicleAndTab();

  const expandVehicleAndTabIndex = useMemo(
    () =>
      filteredCandidates && expandVehicleAndTab?.vehicleId
        ? filteredCandidates.findIndex((candidate) => candidate.Id === expandVehicleAndTab.vehicleId)
        : -1,
    [filteredCandidates, expandVehicleAndTab?.vehicleId],
  );

  const splicedCandidates = useSplicedItems<ListVehicleEnhanced>({
    items: filteredCandidates,
    count:
      expandVehicleAndTabIndex < CANDIDATES_PER_PAGE
        ? Math.max(vehiclesCount || 0, CANDIDATES_PER_PAGE)
        : vehiclesCount || expandVehicleAndTabIndex + 1,
  });

  useEffect(() => {
    if (splicedCandidates) {
      setVehiclesCount(splicedCandidates.length);
    }
  }, [splicedCandidates, setVehiclesCount]);

  const launchPeriods = useLaunchPeriods(preFilteredCandidates);
  const chassisNames = useChassisNames({ vehicles: preFilteredCandidates });
  const installedEnergies = useInstalledEnergies(preFilteredCandidates);
  const performanceSteps = usePerformanceSteps(preFilteredCandidates);
  const cabs = useCabs({ vehicles: preFilteredCandidates });
  const axleDistances = useAxleDistances({ vehicles: preFilteredCandidates });

  const chassisAdaptations = useChassisAdaptations({
    vehicles: preFilteredCandidates,
  });

  const wheelConfigurations = useWheelConfigurations({
    vehicles: preFilteredCandidates,
  });

  const isLoading = vehiclesQuery.isFetching || factBasicDataQuery.isFetching;
  const isError = vehiclesQuery.isError || factBasicDataQuery.isError;
  const isSuccess = vehiclesQuery.isSuccess && factBasicDataQuery.isSuccess;

  return useMemo(
    () => ({
      cabs,
      chassisNames,
      filterParams,
      filtersHandler,
      filteredCandidates,
      installedEnergies,
      performanceSteps,
      chassisAdaptations,
      isLoading,
      isError,
      isSuccess,
      launchPeriods,
      selectedFilters,
      selectedAxleDistances,
      selectedChassisNames,
      selectedLaunchPeriods,
      selectedBatteries,
      selectedPerformanceSteps,
      selectedCandidates,
      selectedCabs,
      selectedWheelConfigurations,
      selectedChassisAdaptations,
      axleDistances,
      solutionId,
      splicedCandidates,
      sortType,
      sortDirection: sortDirection === 'asc' ? 'asc' : 'desc',
      wheelConfigurations,
      onChangeAxleDistance,
      onChangeBattery,
      onChangeCab,
      onChangeLaunchPeriod,
      onChangeChassisNumber,
      onChangePerformanceStep,
      onChangeWheelConfiguration,
      onChangeChassisAdaptation,
      onRemoveFilter,
      onRemoveFilters,
      setVehiclesCount,
    }),
    [
      solutionId,
      filterParams,
      filtersHandler,
      filteredCandidates,
      splicedCandidates,
      installedEnergies,
      launchPeriods,
      performanceSteps,
      chassisAdaptations,
      isLoading,
      isError,
      isSuccess,
      selectedFilters,
      selectedAxleDistances,
      selectedChassisNames,
      selectedLaunchPeriods,
      selectedBatteries,
      selectedPerformanceSteps,
      selectedCandidates,
      selectedCabs,
      selectedWheelConfigurations,
      selectedChassisAdaptations,
      axleDistances,
      chassisNames,
      cabs,
      sortType,
      sortDirection,
      wheelConfigurations,
      onChangeAxleDistance,
      onChangeCab,
      onChangeBattery,
      onChangeLaunchPeriod,
      onChangeChassisNumber,
      onChangePerformanceStep,
      onChangeWheelConfiguration,
      onChangeChassisAdaptation,
      onRemoveFilter,
      onRemoveFilters,
    ],
  );
};

export default useCandidatesHandler;
