import { ReactNode, useEffect } from 'react';
import { THEME_COLOR } from 'app/config';

const DARK_MODE = 'tds-mode-dark';
const LIGHT_MODE = 'tds-mode-light';

const TDS_COLOR_BLUE_800 = '#041e42';

const LightMode = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    document.documentElement.classList.remove(DARK_MODE);
    document.documentElement.classList.add(LIGHT_MODE);

    document.body.classList.remove(DARK_MODE);
    document.body.classList.add(LIGHT_MODE);

    return () => {
      document.documentElement.classList.remove(LIGHT_MODE);
      document.documentElement.classList.add(DARK_MODE);

      document.body.classList.remove(LIGHT_MODE);
      document.body.classList.add(DARK_MODE);
    };
  }, []);

  useEffect(() => {
    const metaTagThemeColor = document.querySelector('meta[name=theme-color]');

    if (metaTagThemeColor) {
      metaTagThemeColor.setAttribute('content', TDS_COLOR_BLUE_800);
    }

    return () => {
      if (metaTagThemeColor) {
        metaTagThemeColor.setAttribute('content', THEME_COLOR);
      }
    };
  }, []);

  return children;
};

export default LightMode;
