import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedSoc = ({ vehicles, socList }: { vehicles: ListVehicleTransformed[]; socList: number[] }) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const socWindowCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const socWindow = vehicle.Soc?.SocWindow;

        return increaseValue({ acc, key: socWindow, list: socList });
      }, {});

      result = getMaxKey({ countByName: socWindowCount });
    }

    return result;
  }, [vehicles, socList]);

export default useSuggestedSoc;
