import classes from './style.module.scss';
import { Button, ButtonAlt, Chip, Dropdown } from '@optimization/ssi-common';
import { MapLocationName, MapLocationV2VM } from '@optimization/sa-common';
import { TopRightAction } from '..';
import { useMemo } from 'react';

interface Props {
  isLoading: boolean;
  mapLocation?: MapLocationV2VM;
  mapLocationName: MapLocationName;
  activeTopRightAction?: TopRightAction;
  togglePlaceLocation: () => void;
  saveLocation: () => void;
  deleteLocation: (id: number) => void;
}

const PlaceLocation = ({
  isLoading,
  mapLocation,
  mapLocationName,
  activeTopRightAction,
  togglePlaceLocation,
  saveLocation,
  deleteLocation,
}: Props) => {
  const mapLocationNameReadable = useMemo(() => {
    let result = mapLocationName.replaceAll('-', ' ');
    return `${result[0].toUpperCase()}${result.slice(1)}`;
  }, [mapLocationName]);

  const locationOptions = useMemo(
    () => [{ name: mapLocationNameReadable, value: mapLocationName }],
    [mapLocationNameReadable, mapLocationName],
  );

  return (
    <>
      <ButtonAlt hoverBorder customIcon="camera" onClick={togglePlaceLocation} />
      {activeTopRightAction === 'place-location' && (
        <div className={classes.popover}>
          <p className="tds-detail-02">Where in the presentation do you want to place the image?</p>
          <Dropdown
            className="tds-u-mt2"
            value={mapLocationName}
            label="Select location"
            labelPosition="outside"
            disabled={isLoading}
            options={locationOptions}
            onChange={() => null}
          />
          <Button
            text="Place image"
            variant={mapLocation ? 'secondary' : 'primary'}
            size="sm"
            className="tds-u-mt3"
            fullbleed
            disabled={isLoading}
            onClick={saveLocation}
          />
          {mapLocation && (
            <div className="tds-u-mt3">
              <Chip onClick={() => deleteLocation(mapLocation.Id)} text={mapLocationNameReadable} icon="cross" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PlaceLocation;
