interface Props {
  header: string;
  description: string;
  className?: string;
}

const InfraPerformanceStepInfo = ({ header, description, className }: Props) => (
  <div className={className}>
    <h4 className="tds-headline-04">{header}</h4>
    <p className="tds-body-02 mt-spacing-4">{description}</p>
  </div>
);

export default InfraPerformanceStepInfo;
