import { useEffect, useState } from 'react';
import { useGetStopsStatusQuery } from 'app/services/solution';

interface Props {
  solutionId: string;
  queryId: string;
  queryIdRef: React.MutableRefObject<{
    queryId: string;
  }>;
  setReadyToGetStops: (readyToGetStops: boolean) => void;
  setStopsStatusState: React.Dispatch<
    React.SetStateAction<{
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    }>
  >;
}

const GetStopsStatus = ({ solutionId, queryId, queryIdRef, setReadyToGetStops, setStopsStatusState }: Props) => {
  const [skip, setSkip] = useState(false);

  const getStopsStatusQuery = useGetStopsStatusQuery(
    {
      solutionId,
      queryId,
    },
    { skip, pollingInterval: 2000 },
  );

  useEffect(() => {
    if (getStopsStatusQuery.data) {
      const executionStatus = getStopsStatusQuery.data.ExecutionStatus;

      if (executionStatus === 'SUCCESS' && queryId === queryIdRef.current.queryId) {
        setReadyToGetStops(true);
      }

      if (
        ['ABORTED', 'DISCONNECTED', 'FAILED_WITH_ERROR', 'FAILED_WITH_INCIDENT', 'NO_DATA', 'SUCCESS'].includes(
          executionStatus,
        )
      ) {
        setSkip(true);
      }
    }
  }, [getStopsStatusQuery, queryId, queryIdRef, setReadyToGetStops]);

  useEffect(() => {
    setStopsStatusState({
      isError: getStopsStatusQuery.isError,
      isLoading: getStopsStatusQuery.isLoading,
      isSuccess: getStopsStatusQuery.isSuccess,
    });

    return () => {
      setStopsStatusState({
        isError: false,
        isLoading: false,
        isSuccess: false,
      });
    };
  }, [setStopsStatusState, getStopsStatusQuery.isError, getStopsStatusQuery.isLoading, getStopsStatusQuery.isSuccess]);

  return null;
};

export default GetStopsStatus;
