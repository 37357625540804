import { Filter } from 'common/components/Filters/utils/types';
import { MULTIPLE_VALUES_SEPARATOR } from 'app/config';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  filterParams: any;
  clearAllAtOnceKeys?: string[];
}

const useRemoveFilter = ({ filterParams, clearAllAtOnceKeys = [] }: Props) => {
  const [, setSearchParams] = useSearchParams();

  return useCallback(
    (filter: Filter) => {
      if (!clearAllAtOnceKeys.includes(filter.key) && filterParams[filter.key]?.includes(MULTIPLE_VALUES_SEPARATOR)) {
        const values = filterParams[filter.key]
          ? filterParams[filter.key].split(MULTIPLE_VALUES_SEPARATOR).filter((value: string) => value !== filter.value)
          : [];

        setSearchParams({
          ...filterParams,
          [filter.key]: values.join(MULTIPLE_VALUES_SEPARATOR),
        });
      } else {
        setSearchParams({
          ...filterParams,
          [filter.key]: '',
        });
      }
    },
    [setSearchParams, filterParams, clearAllAtOnceKeys],
  );
};

export default useRemoveFilter;
