import useChargerVariantValues from 'features/infrastructure/utils/useChargerVariantValues';
import { getChargersFromInstructureProducts, getStepIsComplete } from './helper';
import { InfraPerformanceStep } from './types';
import { useMemo } from 'react';
import {
  DepotDataPerPerformanceStepV2VM,
  InfrastructureProductV2VM,
  FactBasicChargingSolution,
} from '@optimization/sa-common';

interface Props {
  chargingSolutions?: FactBasicChargingSolution[];
  infrastructureProducts?: InfrastructureProductV2VM[];
  years: string[];
  depotDataPerPerformanceStep?: {
    [key: string]: DepotDataPerPerformanceStepV2VM;
  };
}

const useInfraPerformanceSteps = ({
  chargingSolutions,
  infrastructureProducts,
  years,
  depotDataPerPerformanceStep,
}: Props): InfraPerformanceStep[] => {
  const variantValues = useChargerVariantValues(chargingSolutions);

  return useMemo(
    () =>
      years.map(Number).map((year) => {
        const chargers = getChargersFromInstructureProducts({
          infrastructureProducts,
          variantValues,
          chargingSolutions,
          year,
        });

        chargers.sort(function (a, b) {
          if (a.year > b.year) return 1;
          if (a.year < b.year) return -1;
          return 0;
        });

        const totalMaxChargingPower = depotDataPerPerformanceStep?.[year]?.ChargingPower || 0;
        const peakConnectedVehicles = depotDataPerPerformanceStep?.[year]?.PeakConnectedVehicles || 0;
        const satellitesCount = depotDataPerPerformanceStep?.[year]?.NumberOfSatellites || 0;
        const peakChargingPowerDemand = depotDataPerPerformanceStep?.[year]?.PowerDemand || 0;
        const energyConsumptionPerDayKwh = depotDataPerPerformanceStep?.[year]?.EnergyConsumptionPerDayKwh || 0;
        const maxUsedPowerBlocks = depotDataPerPerformanceStep?.[year]?.MaxUsedPowerBlocks || 0;
        const totalNumberOfPowerBlocks = depotDataPerPerformanceStep?.[year]?.TotalNumberOfPowerBlocks || 0;
        const notEnoughPower = depotDataPerPerformanceStep?.[year]?.ErrorStates.NotEnoughPower || false;
        const notEnoughPowerBlocks = depotDataPerPerformanceStep?.[year]?.ErrorStates.NotEnoughPowerBlocks || false;
        const notEnoughSatellites = depotDataPerPerformanceStep?.[year]?.ErrorStates.NotEnoughSatellites || false;

        return {
          year,
          totalMaxChargingPower,
          peakChargingPowerDemand,
          peakConnectedVehicles,
          satellitesCount,
          energyConsumptionPerDayKwh,
          maxUsedPowerBlocks,
          totalNumberOfPowerBlocks,
          notEnoughPower,
          notEnoughPowerBlocks,
          notEnoughSatellites,
          chargers,
          stepIsComplete: getStepIsComplete({
            notEnoughPower,
            notEnoughPowerBlocks,
            notEnoughSatellites,
          }),
        };
      }),
    [depotDataPerPerformanceStep, infrastructureProducts, chargingSolutions, variantValues, years],
  );
};

export default useInfraPerformanceSteps;
