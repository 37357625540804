import { Dropdown } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  vehicleOptionsHandler: VehicleOptionsHandler;
  disabled: boolean;
  className?: string;
  dataTestidBase: string;
}

const TruckFields = ({ vehicleOptionsHandler, disabled, className, dataTestidBase }: Props) => {
  const {
    truckPortfolioOptions,
    truckBatteryOptions,
    truckSocOptions,
    truckEngineGearBoxOptions,
    truckChassisAdaptationOptions,
    truckWheelConfigurationOptions,
    truckAxleDistanceOptions,
    truckChassisHeightOptions,
    truckCabTypeOptions,
    selections,
    truckBatteryDisabled,
    truckSocDisabled,
    truckEngineGearBoxDisabled,
    truckChassisAdaptationDisabled,
    truckWheelConfigurationDisabled,
    truckAxleDistanceDisabled,
    truckChassisHeightDisabled,
    truckCabTypeDisabled,
    handleSelection,
  } = vehicleOptionsHandler;

  const onChangePortfolio = useCallback(
    (value: string) => {
      handleSelection('truckPortfolio', value);
    },
    [handleSelection],
  );

  const onChangeTruckBattery = useCallback(
    (value: string) => {
      handleSelection('truckBattery', value);
    },
    [handleSelection],
  );

  const onChangeTruckSocWindow = useCallback(
    (value: string) => {
      handleSelection('truckSocWindow', value);
    },
    [handleSelection],
  );

  const onChangeTruckElectricMotor = useCallback(
    (value: string) => {
      handleSelection('truckElectricMotor', value);
    },
    [handleSelection],
  );

  const onChangeChassis = useCallback(
    (value: string) => {
      handleSelection('truckChassisAdaptation', value);
    },
    [handleSelection],
  );

  const onChangeWheelConfiguration = useCallback(
    (value: string) => {
      handleSelection('truckWheelConfiguration', value);
    },
    [handleSelection],
  );

  const onChangeAxleDistance = useCallback(
    (value: string) => {
      handleSelection('truckAxleDistance', value);
    },
    [handleSelection],
  );

  const onChangeChassisHeight = useCallback(
    (value: string) => {
      handleSelection('truckChassisHeight', value);
    },
    [handleSelection],
  );

  const onChangeCabType = useCallback(
    (value: string) => {
      handleSelection('truckCabType', value);
    },
    [handleSelection],
  );

  return (
    <>
      <div className={className} data-testid="candidate-detail-bottom-default">
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.truckPortfolio}
            label="Product portfolio"
            labelPosition="outside"
            placeholder="Product portfolio"
            disabled={disabled}
            options={truckPortfolioOptions}
            dataTestid={`${dataTestidBase}-portfolio`}
            onChange={onChangePortfolio}
          />
          <Dropdown
            value={selections.truckBattery}
            label="Battery"
            labelPosition="outside"
            placeholder="Battery"
            options={truckBatteryOptions}
            dataTestid={`${dataTestidBase}-battery`}
            disabled={truckBatteryDisabled || disabled}
            onChange={onChangeTruckBattery}
          />
          <Dropdown
            value={selections.truckSocWindow}
            label="SoC window"
            labelPosition="outside"
            placeholder="SoC window"
            options={truckSocOptions}
            dataTestid={`${dataTestidBase}-soc-window`}
            disabled={truckSocDisabled || disabled}
            onChange={onChangeTruckSocWindow}
          />
        </div>
      </div>
      <div className={className}>
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.truckElectricMotor}
            label="Electric motor"
            labelPosition="outside"
            placeholder="Electric motor"
            options={truckEngineGearBoxOptions}
            dataTestid={`${dataTestidBase}-electric-motor`}
            disabled={truckEngineGearBoxDisabled || disabled}
            onChange={onChangeTruckElectricMotor}
          />
          <Dropdown
            value={selections.truckChassisAdaptation}
            label="Chassis adaptation"
            labelPosition="outside"
            placeholder="Chassis adaptation"
            options={truckChassisAdaptationOptions}
            disabled={truckChassisAdaptationDisabled || disabled}
            dataTestid={`${dataTestidBase}-chassis-adaptation`}
            onChange={onChangeChassis}
          />
          <Dropdown
            value={selections.truckWheelConfiguration}
            label="Wheel configuration"
            labelPosition="outside"
            placeholder="Wheel configuration"
            options={truckWheelConfigurationOptions}
            disabled={truckWheelConfigurationDisabled || disabled}
            dataTestid={`${dataTestidBase}-wheel-configuration`}
            onChange={onChangeWheelConfiguration}
          />
        </div>
      </div>
      <div className={className}>
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.truckAxleDistance}
            label="Axle distance"
            labelPosition="outside"
            placeholder="Axle distance"
            options={truckAxleDistanceOptions}
            dataTestid={`${dataTestidBase}-axle-distance`}
            disabled={truckAxleDistanceDisabled || disabled}
            onChange={onChangeAxleDistance}
          />
          <Dropdown
            value={selections.truckChassisHeight}
            label="Chassis height"
            labelPosition="outside"
            placeholder="Chassis height"
            options={truckChassisHeightOptions}
            dataTestid={`${dataTestidBase}-chassis-height`}
            disabled={truckChassisHeightDisabled || disabled}
            onChange={onChangeChassisHeight}
          />
          <Dropdown
            value={selections.truckCabType}
            label="Cab type"
            labelPosition="outside"
            placeholder="Cab type"
            options={truckCabTypeOptions}
            dataTestid={`${dataTestidBase}-cab-type`}
            disabled={truckCabTypeDisabled || disabled}
            onChange={onChangeCabType}
          />
        </div>
      </div>
    </>
  );
};

export default TruckFields;
