import classes from './style.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { TdsIcon } from '@scania/tegel-react';

interface Props {
  borderTop?: boolean;
  showIconTitle: boolean;
}

const ProductManualLink = ({ borderTop = true, showIconTitle }: Props) => (
  <NavLink
    className={classNames({ [classes['border-top']]: borderTop })}
    data-testid="button-product-manual-menu-item"
    target="_blank"
    to={`/product-manual/solution-management`}
  >
    <TdsIcon name="document" size="24px" title={showIconTitle ? 'Documentation' : ''} />
    Documentation
  </NavLink>
);

export default ProductManualLink;
