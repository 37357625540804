import { useEffect, useMemo, useState } from 'react';

const MINUTES_PER_COLUMN = 120;

const usePixelsPerMinute = (columnRef: React.MutableRefObject<HTMLTableCellElement | null>) => {
  const [columnWidth, setColumnWidth] = useState(0);

  useEffect(() => {
    if (columnRef.current) {
      setColumnWidth(columnRef.current.offsetWidth);
    }

    let interval: number = 0;

    const resizeViewPort = () => {
      if (columnRef.current) {
        interval = window.setTimeout(() => {
          if (columnRef.current) {
            setColumnWidth(columnRef.current.offsetWidth);
          }
        }, 300);
      }
    };

    window.addEventListener('resize', resizeViewPort);

    return () => {
      window.removeEventListener('resize', resizeViewPort);

      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [columnRef]);

  return useMemo(() => (columnWidth ? columnWidth / MINUTES_PER_COLUMN : 0), [columnWidth]);
};

export default usePixelsPerMinute;
