import socsCheck from './socsCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles: (FactBasicTruck | FactBasicBus)[];
  selectedBattery: string;
  selectedPortfolio: string;
}

const useSoc = ({ basicVehicles, selectedBattery, selectedPortfolio }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicVehicles
          .filter((basicVehicle) => socsCheck({ basicVehicle, selectedBattery, selectedPortfolio }))
          .map((basicTruck) => basicTruck.SocSpecifications.map((socSpecification) => socSpecification.SocWindow))
          .flat()
          .sort(),
      ),
    ],
    [basicVehicles, selectedBattery, selectedPortfolio],
  );

export default useSoc;
