import { Component, ErrorInfo, ReactNode } from 'react';
import { Error } from '@optimization/ssi-common';
import { getErrorMessage, logError } from 'common/utils/helper';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const message = getErrorMessage(error);

    if (message) {
      logError(message);
    }
  }

  public render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
