import classes from './style.module.scss';
import classNames from 'classnames';
import useLoadingText from 'common/hooks/useLoadingText';
import { Loading } from '@optimization/ssi-common';
import { TdsIcon } from '@scania/tegel-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  children: string;
  valueWhenEditing?: string;
  className?: string;
  isLoading: boolean;
  isError: boolean;
  isIcon?: boolean;
  dataActionName?: string;
  iconSize?: number;
  handleEdit: (name: string) => void;
}

const InlineEdit = ({
  children,
  valueWhenEditing,
  className,
  isLoading,
  isError,
  isIcon,
  dataActionName,
  iconSize,
  handleEdit,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(children);

  useEffect(() => {
    // Trying to avoid flickering of "text" when saving
    const timer = setTimeout(() => {
      if (!isLoading && !isEditing) {
        setText(children);
      }
    }, 600);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isEditing, isLoading, children]);

  useEffect(() => {
    if (isEditing && typeof valueWhenEditing === 'string') {
      setText(valueWhenEditing);
    }
  }, [isEditing, valueWhenEditing]);

  const activateEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  }, []);

  const skipEdit = useMemo(() => children === text, [children, text]);

  const onBlur = useCallback(() => {
    setIsEditing(false);

    if (skipEdit) {
      return;
    }

    handleEdit(text);
  }, [text, skipEdit, handleEdit]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setIsEditing(false);

        if (skipEdit) {
          return;
        }

        handleEdit(text);
      }
    },
    [skipEdit, handleEdit, text],
  );

  const loadingText = useLoadingText({ isSaving: true });

  return (
    <div data-action-name={dataActionName} className={classNames(className, classes['editable-heading'])}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {isEditing ? (
        <input
          type="text"
          className="tds-u-w-100"
          value={text}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus
        />
      ) : (
        <button onClick={activateEditing} type="button">
          <span>{text || <>&nbsp;</>}</span>
          {isIcon && (
            <span className={classes.icon}>
              <TdsIcon name="edit" size={iconSize ? `${iconSize}px` : '32px'} />
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default InlineEdit;
