import classes from './style.module.scss';
import classNames from 'classnames';
import InlineEdit from '../InlineEdit';
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
  cta?: ReactNode;
  description?: ReactNode[];
  descriptionAligment?: 'left' | 'right';
  dataTestid?: string;
  editIsLoading?: boolean;
  editIsError?: boolean;
  headerClassName?: string;
  intro?: string;
  valueWhenEditing?: string;
  handleEdit?: (Name: string) => void;
}

const PageHeader = ({
  className,
  children,
  cta,
  description,
  descriptionAligment = 'right',
  dataTestid,
  editIsLoading,
  editIsError,
  headerClassName,
  intro,
  valueWhenEditing,
  handleEdit,
}: Props) => (
  <div className={classNames(classes['page-header'], className)} data-testid={dataTestid}>
    {intro && (
      <div className={classNames(classes['intro'], 'tds-detail-06')}>
        {handleEdit ? (
          <InlineEdit
            isIcon
            handleEdit={handleEdit}
            iconSize={16}
            valueWhenEditing={valueWhenEditing}
            isLoading={Boolean(editIsLoading)}
            isError={Boolean(editIsError)}
          >
            {intro}
          </InlineEdit>
        ) : (
          intro
        )}
      </div>
    )}
    <div className={classNames(classes.body, classes[descriptionAligment])}>
      <div className={classes['body-top']}>
        <h2 className={classNames('tds-headline-02', classes.header, headerClassName)}>{children}</h2>
        {cta && <div className={classNames(classes.cta)}>{cta}</div>}
      </div>
      {description && (
        <div className={classes.description}>
          {description?.map((p, index) => (
            <p key={index} className={classNames('tds-body-02', { 'tds-u-mt2': index })}>
              {p}
            </p>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default PageHeader;
