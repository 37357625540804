import { KeyedString } from '@optimization/ssi-common';
import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depots?: ListDepotTransformed[];
}

const useDepotNamesById = ({ depots }: Props): KeyedString =>
  useMemo(() => {
    if (depots) {
      return depots.reduce((acc, depot) => ({ ...acc, [depot.DepotId]: depot.DepotName }), {});
    }

    return {};
  }, [depots]);

export default useDepotNamesById;
