import chargingSpecificationCheck from './chargingSpecificationCheck';
import engineGearboxCheck from './engineGearboxCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

const useBusEngineGearBox = ({
  basicVehicles,
  selectedBattery,
  selectedSocWindow,
  selectedPortfolio,
  selectedChargingType,
}: {
  basicVehicles: (FactBasicTruck | FactBasicBus)[];
  selectedBattery: string;
  selectedSocWindow: string;
  selectedPortfolio: string;
  selectedChargingType: string;
}) =>
  useMemo(
    () => [
      ...new Set(
        basicVehicles
          .filter(
            (basicVehicle) =>
              engineGearboxCheck({
                basicVehicle,
                selectedBattery,
                selectedSocWindow,
                selectedPortfolio,
              }) &&
              chargingSpecificationCheck({
                chargingSpecifications: basicVehicle.ChargingSpecifications,
                selectedChargingType,
              }),
          )
          .map((basicVehicle) => basicVehicle.EngineGearboxes.map((engineGearbox) => engineGearbox.Name))
          .flat(),
      ),
    ],
    [basicVehicles, selectedBattery, selectedSocWindow, selectedPortfolio, selectedChargingType],
  );

export default useBusEngineGearBox;
