import { Checkbox } from '@optimization/ssi-common';
import { EcItem } from './types';
import { PtoDevices, PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  ecItems: EcItem[];
  ptoDevices: PtoDevices;
  disabled?: boolean;
  onCheckboxChange: (checked: boolean, value: string, type: PtoType) => void;
}

const PtoRefuseCollectorField = ({ ecItems, ptoDevices, disabled, onCheckboxChange }: Props) => {
  const onChange = useCallback(
    (checked: boolean, value: string) => onCheckboxChange(checked, value, PtoType.RefuseCollector),
    [onCheckboxChange],
  );

  return (
    <div>
      <div className="tds-detail-05 tds-u-mb1">No. of collector(s)</div>
      {Object.keys(ptoDevices.RefuseCollector.PtoAlternatives).map((numberOfCollectors) => (
        <Checkbox
          key={numberOfCollectors}
          disabled={disabled}
          value={numberOfCollectors}
          label={numberOfCollectors}
          onChange={onChange}
          checked={ecItems.find((ecItem) => ecItem.Type === PtoType.RefuseCollector)?.Value === numberOfCollectors}
        />
      ))}
    </div>
  );
};

export default PtoRefuseCollectorField;
