import classes from './style.module.scss';

interface Props {
  body: {
    type: string;
    text?: string;
    url?: string;
  };
}

const ProductManualBlock = ({ body }: Props) => {
  switch (body.type) {
    case 'description':
      return <p className="tds-body-01 max-w-800 mb-spacing-24">{body.text}</p>;
    case 'image':
      return (
        <div className={classes['image-container']}>
          <img src={body.url} alt="" className={classes.image} />
        </div>
      );
  }
};

export default ProductManualBlock;
