import engineGearboxCheck from './engineGearboxCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';

const chassisAdaptationCheck = ({
  basicVehicle,
  selectedBattery,
  selectedSocWindow,
  selectedElectricMotor,
  selectedPortfolio,
}: {
  basicVehicle: FactBasicTruck | FactBasicBus;
  selectedBattery: string;
  selectedSocWindow: string;
  selectedElectricMotor?: string;
  selectedPortfolio?: string;
}) =>
  engineGearboxCheck({
    basicVehicle,
    selectedBattery,
    selectedSocWindow,
    selectedPortfolio,
  }) &&
  ((selectedElectricMotor &&
    basicVehicle.EngineGearboxes.map((engineGearbox) => engineGearbox.Name).includes(selectedElectricMotor)) ||
    !selectedElectricMotor);

export default chassisAdaptationCheck;
