import chassisAdaptationCheck from './chassisAdaptationCheck';
import { FactBasicTruck } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicTrucks: FactBasicTruck[];
  selections: SelectionExecutions;
}

const useTruckChassisAdaptation = ({ basicTrucks, selections }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicTrucks
          .filter((basicTruck) =>
            chassisAdaptationCheck({
              basicVehicle: basicTruck,
              selectedBattery: selections.truckBattery,
              selectedSocWindow: selections.truckSocWindow,
              selectedElectricMotor: selections.truckElectricMotor,
              selectedPortfolio: selections.truckPortfolio,
            }),
          )
          .map((bev) => bev.ChassisAdaptation),
      ),
    ],
    [basicTrucks, selections],
  );

export default useTruckChassisAdaptation;
