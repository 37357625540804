import { useEffect } from 'react';
import { useGetTripsQuery } from 'app/services/solution';
import { useToast } from '@optimization/ssi-common';
import { Trips } from '@optimization/sa-common';

interface Props {
  solutionId: string;
  queryId: string;
  setTripsData: (data: Trips) => void;
  setTripsState: React.Dispatch<
    React.SetStateAction<{
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    }>
  >;
}

const GetTrips = ({ solutionId, queryId, setTripsData, setTripsState }: Props) => {
  const showToast = useToast();

  const getTripsQuery = useGetTripsQuery({
    solutionId,
    queryId,
  });

  useEffect(() => {
    if (getTripsQuery.isSuccess) {
      setTripsData(getTripsQuery.data);

      if (getTripsQuery.data.TripAggregates.length === 0) {
        showToast({
          header: 'No trips found within the specified filter range',
          variant: 'warning',
        });
      }
    } else if (getTripsQuery.isError) {
      showToast({
        header: 'Too many trips',
        subheader: 'There were too many trips to retrieve. Please reduce the timespan or resolution',
        variant: 'error',
      });
    }
  }, [getTripsQuery, queryId, setTripsData, showToast]);

  useEffect(() => {
    setTripsState({
      isError: getTripsQuery.isError,
      isLoading: getTripsQuery.isLoading,
      isSuccess: getTripsQuery.isSuccess,
    });

    return () => {
      setTripsState({
        isError: false,
        isLoading: false,
        isSuccess: false,
      });
    };
  }, [getTripsQuery.isError, getTripsQuery.isLoading, getTripsQuery.isSuccess, setTripsState]);

  return null;
};

export default GetTrips;
