import chassisAdaptationCheck from '../defaultOptions/chassisAdaptationCheck';
import sortByPortfoliosAsc from './sortByPortfoliosAsc';
import { FactBasicTruck } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicTrucks: FactBasicTruck[];
  selections: SelectionExecutions;
}

const useSelectedBasicTruckEarliest = ({ basicTrucks, selections }: Props) =>
  useMemo(() => {
    const trucks = basicTrucks.filter(
      (basicTruck) =>
        chassisAdaptationCheck({
          basicVehicle: basicTruck,
          selectedBattery: selections.truckBattery,
          selectedSocWindow: selections.truckSocWindow,
          selectedElectricMotor: selections.truckElectricMotor,
        }) &&
        basicTruck.ChassisAdaptation === selections.truckChassisAdaptation &&
        basicTruck.WheelConfiguration === selections.truckWheelConfiguration.toLocaleLowerCase() &&
        basicTruck.AxleDistancesMm.map(String).includes(selections.truckAxleDistance.toString()) &&
        basicTruck.ChassisHeights.includes(selections.truckChassisHeight) &&
        basicTruck.Cabs.includes(selections.truckCabType),
    );

    if (trucks.length) {
      trucks.sort(sortByPortfoliosAsc);
      return trucks[0];
    }

    return null;
  }, [basicTrucks, selections]);

export default useSelectedBasicTruckEarliest;
