import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  vehicles?: ListVehicleEnhanced[];
}

const useCabs = ({ vehicles }: Props) =>
  useMemo(
    () =>
      vehicles
        ? [
            ...new Set(
              vehicles
                .map((vehicle) => vehicle.Cab || '')
                .filter((value) => value)
                .flat(),
            ),
          ].sort()
        : [],
    [vehicles],
  );

export default useCabs;
