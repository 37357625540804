import chassisAdaptationCheck from './chassisAdaptationCheck';
import { FactBasicTruck } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicTrucks: FactBasicTruck[];
  selections: SelectionExecutions;
}

const useTruckAxleDistance = ({ basicTrucks, selections }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicTrucks
          .filter(
            (basicTruck) =>
              chassisAdaptationCheck({
                basicVehicle: basicTruck,
                selectedBattery: selections.truckBattery,
                selectedSocWindow: selections.truckSocWindow,
                selectedElectricMotor: selections.truckElectricMotor,
                selectedPortfolio: selections.truckPortfolio,
              }) &&
              basicTruck.ChassisAdaptation === selections.truckChassisAdaptation &&
              basicTruck.WheelConfiguration === selections.truckWheelConfiguration.toLocaleLowerCase(),
          )
          .map((basicTruck) => basicTruck.AxleDistancesMm.map(String))
          .flat(),
      ),
    ],
    [basicTrucks, selections],
  );

export default useTruckAxleDistance;
