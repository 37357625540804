import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedCabType = ({
  vehicles,
  truckCabTypeList,
}: {
  vehicles: ListVehicleTransformed[];
  truckCabTypeList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const truckCabTypeCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const truckCabType = vehicle.Cab;

        return increaseValue({
          acc,
          key: truckCabType?.toString(),
          list: truckCabTypeList,
        });
      }, {});

      result = getMaxKey({ countByName: truckCabTypeCount });
    }

    return result;
  }, [vehicles, truckCabTypeList]);

export default useSuggestedCabType;
