import { FormState } from '../../Main';
import { useCallback } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';

const useOperationalFactorHandlers = ({
  vehicle,
  setFormState,
}: {
  vehicle: VehicleEnhanced;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}) => {
  const onChangeDailyDistance = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        dailyOdometerMedian: value,
        dailyOdometerLongest:
          Number(value) > Number(vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm)
            ? value
            : vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm?.toString() || '',
      }));
    },
    [setFormState, vehicle],
  );

  const onChangeLongestDailyDistance = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        dailyOdometerLongest: value,
      }));
    },
    [setFormState],
  );

  const onChangeAvgGtw = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        avgGtw: value,
      }));
    },
    [setFormState],
  );

  const onChangeAvgSpeed = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        avgSpeed: value,
        cruisingSpeed:
          Number(value) > Number(vehicle.ExecutedVehicleProduct?.CruiseSpeedKmh)
            ? value
            : vehicle.ExecutedVehicleProduct?.CruiseSpeedKmh?.toString(),
      }));
    },
    [vehicle, setFormState],
  );

  const onChangeCruisingSpeed = useCallback(
    (value: string) => {
      setFormState((prev) => ({
        ...prev,
        runUpdateVehicle: true,
        cruisingSpeed: value,
      }));
    },
    [setFormState],
  );

  return {
    onChangeDailyDistance,
    onChangeLongestDailyDistance,
    onChangeAvgGtw,
    onChangeAvgSpeed,
    onChangeCruisingSpeed,
  };
};

export default useOperationalFactorHandlers;
