import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  depots?: ListDepotTransformed[];
}

const useDepotNames = ({ depots }: Props) =>
  useMemo(() => {
    if (depots) {
      return [...new Set(depots.map((depot) => depot.DepotName))].splice(0, 100).sort();
    }

    return [];
  }, [depots]);

export default useDepotNames;
