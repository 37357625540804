import { useMemo } from 'react';

interface Props {
  depotDefaultYear?: string;
  yearParam?: string;
}

const useDepotYear = ({ depotDefaultYear, yearParam }: Props): string =>
  useMemo(() => (yearParam ? yearParam : depotDefaultYear || ''), [depotDefaultYear, yearParam]);

export default useDepotYear;
