import { useMemo, useEffect, useState } from 'react';
import { InfraPerformanceStepHelper } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';

import { Mode } from '..';

export const MAP_VEHICLES_PROGRESS_PERCENT = 33;
export const DEPOT_INFRA_PROGRESS_PERCENT = 66;

interface Props {
  mode: Mode;
  selectedCandidates: string[];
  infraPerformanceStepHandler: InfraPerformanceStepHelper;
}

const useProgressPercent = ({ mode, selectedCandidates, infraPerformanceStepHandler }: Props) => {
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    if (mode === 'map-vehicles') {
      setProgressPercent(selectedCandidates.length ? MAP_VEHICLES_PROGRESS_PERCENT : 0);
    } else if (mode === 'depot-infrastructure') {
      const completeSteps =
        infraPerformanceStepHandler.infraPerformanceSteps?.filter((item) => item.stepIsComplete).length || 0;

      const totalSteps = infraPerformanceStepHandler.infraPerformanceSteps?.length || 0;

      if (completeSteps < totalSteps) {
        setProgressPercent(
          DEPOT_INFRA_PROGRESS_PERCENT + (100 - DEPOT_INFRA_PROGRESS_PERCENT) * (completeSteps / totalSteps),
        );
      } else {
        setProgressPercent(100);
      }
    }
  }, [mode, selectedCandidates, infraPerformanceStepHandler]);

  return useMemo(() => ({ progressPercent, setProgressPercent }), [progressPercent, setProgressPercent]);
};

export default useProgressPercent;
