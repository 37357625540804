import CandidateGraph from './CandidateGraph';
import ChargingDetails from './ChargingDetails';
import classes from './style.module.scss';
import classNames from 'classnames';
import DepartureAndSlowChargeFields from './DepartureAndSlowChargeFields';
import ensureClickableSecondary, { LOWEST_CLICKABLE_SECONDARY_IN_CHART } from './utils/ensureClickableSecondary';
import getResultingSoc from './utils/getResultingSoc';
import isChargingEventNearDistance from './utils/isChargingEventNearDistance';
import MessageWrapper from './utils/MessageWrapper';
import SetupChargingEvent from './SetupChargingEvent';
import useDepotNamesById from './utils/useDepotNamesById';
import useKmAtEvent from './utils/useKmAtEvent';
import useLoadingText from 'common/hooks/useLoadingText';
import useMessages from './utils/useMessages';
import useSetupChargingEventHandler from './utils/useSetupChargingEventHandler';
import useUpdateChargingEventsHandler from './utils/useUpdateChargingEventsHandler';
import useValidateChargingEventsHandler from './utils/useValidateChargingEventsHandler';
import useWarningMessage from './utils/useWarningMessage';
import { CandidateChartFormValues } from './utils/types';
import { ContentSwitcherButtons, Loading } from '@optimization/ssi-common';
import { Datum } from 'react-charts';
import { EXTERNAL_DEPOT_VALUE } from './SetupChargingEvent/ChargingPowerInputs';
import { TdsMessage } from '@scania/tegel-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetDepotQuery, useGetDepotsQuery } from 'app/services/solution';
import {
  isSavedChargingEvent,
  useCandidateChargingDistance,
  useCandidateChargingEvents,
  useGetCandidateChargingTime,
} from '@optimization/sa-common';
import {
  getInfrastructureProducts,
  useMedianTick,
  useMedianTickIsOver,
  VehicleEnhanced,
  ChargingEventsList,
  DEPARTURE_TIME_DEFAULT,
} from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
  solutionId: string;
  baseRange: number;
  depotYear?: string;
  enableYearSelectorForChargingEvents: boolean;
  setDepotYear: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CandidateCharging = ({
  vehicle,
  solutionId,
  baseRange,
  depotYear,
  enableYearSelectorForChargingEvents,
  setDepotYear,
}: Props) => {
  const nowTime = useMemo(() => new Date().getTime(), []);

  const [currentDatum, setCurrentDatum] = useState<Datum<unknown> | null>(null);
  const [currentDatumTimeAtEvent, setCurrentDatumTimeAtEvent] = useState('');
  const [chartHasBeenClicked, setChartHasBeenClicked] = useState(false);
  const [loadCandidateChartFormValues, setLoadCandidateChartFormValues] = useState(true);
  const [showCurrentChargingErrorInSetup, setShowCurrentChargingErrorInSetup] = useState(true);
  const [, setCurrentDatumIsAdjustedByCurrentChargingEvent] = useState(false);
  const [, setOnlyCompleteLongestDistancePercentageFromValidate] = useState(false);

  const dailyOdometerMedianKm = vehicle.ExecutedVehicleProduct?.DailyOdometerMedianKm;
  const dailyOdometerLongestKm = vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm || 0;
  const usableEnergy = vehicle.ExecutedVehicleProduct?.Soc?.UsableEnergyKWh || 0;

  const depotQuery = useGetDepotQuery(
    {
      solutionId,
      depotId: vehicle.HomeDepotId?.toString() || '',
    },
    { skip: !Boolean(vehicle.HomeDepotId) },
  );

  const depotsQuery = useGetDepotsQuery(solutionId);

  const depotNames = useDepotNamesById({ depots: depotsQuery.data });

  const infrastructureProducts = useMemo(
    () =>
      getInfrastructureProducts({
        depotYear: depotYear || '',
        infrastructureProducts: depotQuery.data?.InfrastructureProducts,
      }),
    [depotQuery.data, depotYear],
  );

  const findChargingEventKmOffset = useMemo(() => dailyOdometerLongestKm / 58, [dailyOdometerLongestKm]);

  const findSavedChargingEvent = useCallback(
    (distance: number) =>
      vehicle.enhanced.vehiclePerformanceStep?.ChargingEvents.find((chargingEvent) =>
        isChargingEventNearDistance({
          chargingEventKmAtEvent: chargingEvent.KmAtEvent,
          findChargingEventKmOffset,
          distance,
        }),
      ),
    [vehicle, findChargingEventKmOffset],
  );

  const candidateChartFormValuesDefault = useMemo(
    () => ({
      depotIdOrExternal: vehicle.HomeDepotId?.toString() || EXTERNAL_DEPOT_VALUE,
      chargingDuration: 0,
      infrastructureProductInstanceId: '',
      eventStartTime: '',
      departureTime: '',
      slowChargeStartTime: '',
      resultingSoc: 0,
      valuesIsLoaded: false,
      chargingEventsFromValidate: undefined,
      slowChargingEventFromValidate: undefined,
      includeChargingDurationInValidate: false,
      runValidateVehicleChargingEvents: false,
      runSaveSlowChargingStartTimeAndDepartureTime: false,
    }),
    [vehicle.HomeDepotId],
  );

  const [candidateChartFormValues, setCandidateChartFormValues] = useState<CandidateChartFormValues>({
    ...candidateChartFormValuesDefault,
  });

  const resetCandidateChartFormValues = useCallback(() => {
    setCandidateChartFormValues((prev) => ({
      ...candidateChartFormValuesDefault,
      // To not lose these values when closing setup charging event panel
      departureTime: prev.departureTime,
      slowChargeStartTime: prev.slowChargeStartTime,
    }));
  }, [candidateChartFormValuesDefault]);

  const chargingEvents = useMemo(
    () =>
      candidateChartFormValues.chargingEventsFromValidate || vehicle.enhanced.vehiclePerformanceStep?.ChargingEvents,
    [candidateChartFormValues.chargingEventsFromValidate, vehicle.enhanced.vehiclePerformanceStep?.ChargingEvents],
  );

  const slowChargingEvent = useMemo(
    () =>
      candidateChartFormValues.slowChargingEventFromValidate ||
      vehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent,
    [
      candidateChartFormValues.slowChargingEventFromValidate,
      vehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent,
    ],
  );

  const currentChargingEvent = useMemo(
    () =>
      currentDatum
        ? chargingEvents?.find((chargingEvent) =>
            isChargingEventNearDistance({
              chargingEventKmAtEvent: chargingEvent.KmAtEvent,
              findChargingEventKmOffset,
              distance: currentDatum.primaryValue,
            }),
          )
        : undefined,
    [currentDatum, chargingEvents, findChargingEventKmOffset],
  );

  const { chartChargingEvents, savedChartChargingEvents } = useCandidateChargingEvents({
    hasCurrentDatum: Boolean(currentDatum),
    currentChargingEvent,
    chargingEvents,
  });

  const currentChartChargingEvent = useMemo(
    () =>
      currentChargingEvent
        ? chartChargingEvents.find((chartChargingEvent) => chartChargingEvent.id === currentChargingEvent.Id)
        : undefined,
    [currentChargingEvent, chartChargingEvents],
  );

  const clickableChartChargingEvents = useMemo(
    () =>
      chartChargingEvents.map((chartChargingEvent) => ({
        ...chartChargingEvent,
        secondary:
          chartChargingEvent.secondary === 0 ? LOWEST_CLICKABLE_SECONDARY_IN_CHART : chartChargingEvent.secondary,
      })),
    [chartChargingEvents],
  );

  const kmAtEvent = useKmAtEvent({
    currentDatum,
    currentChargingEvent,
  });

  const validateChargingEventsHandler = useValidateChargingEventsHandler({
    solutionId,
    vehicle,
    currentDatum,
    candidateChartFormValues,
    kmAtEvent,
    setLoadCandidateChartFormValues,
    setOnlyCompleteLongestDistancePercentageFromValidate,
    setCandidateChartFormValues,
  });

  const { distanceIncludingPreview, distanceFromSavedChargingEvents } = useCandidateChargingDistance({
    chartChargingEvents,
    savedChartChargingEvents,
    dailyOdometerLongestKm,
    totEc: vehicle.enhanced.totEc,
    usableEnergy,
    nowTime,
    slowChargingEvent,
    departureTime: vehicle.enhanced.vehiclePerformanceStep?.DepartureTime,
  });

  const getCandidateChargingTime = useGetCandidateChargingTime({
    distanceFromSavedChargingEvents,
    distanceIncludingPreview,
  });

  const findChartChargingEvent = useCallback(
    (distance: number) =>
      chartChargingEvents.find((chargingEvent) =>
        isChargingEventNearDistance({
          chargingEventKmAtEvent: chargingEvent.kmAtEvent,
          findChargingEventKmOffset,
          distance,
        }),
      ),
    [chartChargingEvents, findChargingEventKmOffset],
  );

  useEffect(() => {
    if (currentDatum && currentChargingEvent) {
      const currentChargingEventSecondary = ensureClickableSecondary({
        batteryLevelBeforeCharging: currentChargingEvent.BatteryLevelBeforeCharging,
      });

      if (
        currentChargingEvent.KmAtEvent !== currentDatum.primaryValue ||
        currentChargingEventSecondary !==
          ensureClickableSecondary({ batteryLevelBeforeCharging: currentDatum.secondaryValue })
      ) {
        setCurrentDatum({
          ...currentDatum,
          primaryValue: currentChargingEvent.KmAtEvent,
          secondaryValue: currentChargingEventSecondary,
        });

        setCurrentDatumIsAdjustedByCurrentChargingEvent(true);
      }
    }
  }, [currentDatum, currentChargingEvent, vehicle]);

  useEffect(() => {
    setCurrentDatumIsAdjustedByCurrentChargingEvent((prevCurrentDatumIsAdjustedByCurrentChargingEvent) => {
      if (!prevCurrentDatumIsAdjustedByCurrentChargingEvent) {
        setLoadCandidateChartFormValues(true);
      }

      return prevCurrentDatumIsAdjustedByCurrentChargingEvent;
    });
  }, [currentDatum, vehicle]);

  useEffect(() => {
    if (loadCandidateChartFormValues) {
      let eventStartTime = '';

      if (currentChargingEvent) {
        eventStartTime = currentChargingEvent.TimeAtEvent;
      } else if (currentDatumTimeAtEvent) {
        eventStartTime = currentDatumTimeAtEvent;
      }

      let depotIdOrExternal = EXTERNAL_DEPOT_VALUE;

      if (infrastructureProducts.length) {
        if (currentChargingEvent) {
          if (currentChargingEvent.DepotId) {
            depotIdOrExternal = currentChargingEvent.DepotId.toString();
          }
        } else if (vehicle.HomeDepotId) {
          depotIdOrExternal = vehicle.HomeDepotId.toString();
        }
      }

      setCandidateChartFormValues((prev) => ({
        ...prev,
        depotIdOrExternal,
        eventStartTime,
        chargingDuration: currentChargingEvent?.ChargingDuration || 0,
        departureTime: vehicle.enhanced.vehiclePerformanceStep?.DepartureTime || DEPARTURE_TIME_DEFAULT,
        slowChargeStartTime: vehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent?.TimeAtEvent || '',
        resultingSoc: getResultingSoc(currentChargingEvent),
        infrastructureProductInstanceId: currentChargingEvent?.InfrastructureProductInstanceId?.toString() || '',
        runValidateVehicleChargingEvents: Boolean(eventStartTime),
        includeChargingDurationInValidate: currentChargingEvent ? true : prev.includeChargingDurationInValidate,
      }));
    }
  }, [
    currentDatumTimeAtEvent,
    currentChargingEvent,
    loadCandidateChartFormValues,
    infrastructureProducts.length,
    vehicle.HomeDepotId,
    vehicle.enhanced.vehiclePerformanceStep,
  ]);

  useEffect(() => {
    if (!currentDatum && chartHasBeenClicked) {
      resetCandidateChartFormValues();
      validateChargingEventsHandler.reset();
    }
  }, [currentDatum, chartHasBeenClicked, validateChargingEventsHandler, resetCandidateChartFormValues]);

  const resetCurrentDatum = useCallback(() => {
    setCurrentDatum(null);
    setCurrentDatumTimeAtEvent('');
    setCurrentDatumIsAdjustedByCurrentChargingEvent(false);
  }, []);

  useEffect(() => {
    if (depotYear) {
      resetCurrentDatum();
    }
  }, [depotYear, resetCurrentDatum]);

  useEffect(() => {
    if (validateChargingEventsHandler.isLoading) {
      setShowCurrentChargingErrorInSetup(false);
    }
  }, [validateChargingEventsHandler.isLoading]);

  useEffect(() => {
    setShowCurrentChargingErrorInSetup(true);
  }, [currentDatum]);

  useEffect(() => {
    setOnlyCompleteLongestDistancePercentageFromValidate(isSavedChargingEvent(currentChargingEvent?.Id));
  }, [currentChargingEvent?.Id]);

  const updateChargingEventsHandler = useUpdateChargingEventsHandler({
    solutionId,
    vehicle,
    candidateChartFormValues,
    setCandidateChartFormValues,
  });

  const messages = useMessages({
    currentDatum,
    currentChargingEvent,
    departureTime: candidateChartFormValues.departureTime,
    eventStartTime: candidateChartFormValues.eventStartTime,
    showCurrentChargingErrorInSetup,
    slowChargeStartTime: candidateChartFormValues.slowChargeStartTime,
    validateChargingEventsHandler,
    valuesIsLoaded: candidateChartFormValues.valuesIsLoaded,
  });

  const years = useMemo(
    () =>
      vehicle.VehiclePerformanceSteps.map((item) => item.PerformanceStep || '').filter(
        (performanceStep) => performanceStep,
      ),
    [vehicle],
  );

  const warningMessage = useWarningMessage({ vehicle, depotYear });

  const contentSwitcherItems = useMemo(
    () =>
      enableYearSelectorForChargingEvents
        ? years.map((year) => ({
            id: year,
            name: year,
            active: vehicle.enhanced.vehicleDepotYear === year,
            onClick: () => {
              setDepotYear(year);
            },
          }))
        : [],
    [years, vehicle, enableYearSelectorForChargingEvents, setDepotYear],
  );

  const selectedDepot = useMemo(
    () =>
      depotsQuery.data
        ? depotsQuery.data.find((depot) => depot.DepotId.toString() === candidateChartFormValues.depotIdOrExternal)
        : undefined,
    [depotsQuery.data, candidateChartFormValues],
  );

  const setupChargingEventHandler = useSetupChargingEventHandler({
    currentDatum,
    currentChargingEvent,
    vehicle,
    solutionId,
    chargingEvents,
    slowChargingEvent,
    resetCurrentDatum,
  });

  const medianTick = useMedianTick({ dailyOdometerLongestKm, dailyOdometerMedianKm });
  const medianTickIsOver = useMedianTickIsOver({ medianTick });

  const loadingText = useLoadingText({
    isLoadingDepot: depotQuery.isLoading,
    isLoadingDepots: depotsQuery.isLoading,
    isUpdatingChargingEvent: updateChargingEventsHandler.isLoading,
    isValidatingChargingEvent: validateChargingEventsHandler.isLoading,
  });

  const isLoading =
    updateChargingEventsHandler.isLoading ||
    depotsQuery.isLoading ||
    depotQuery.isLoading ||
    validateChargingEventsHandler.isLoading ||
    setupChargingEventHandler.isLoading;

  const isError =
    updateChargingEventsHandler.isError ||
    depotsQuery.isError ||
    depotQuery.isError ||
    validateChargingEventsHandler.isError ||
    setupChargingEventHandler.isError;

  const depot = depotQuery.data;
  const depots = depotsQuery.data;

  if (!vehicle.enhanced.vehiclePerformanceStep || !depot || !depots) {
    return null;
  }

  return (
    <>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <div className={classes['candidate-charging']}>
        <div className={classes.top}>
          <div>
            <div className={classes.header}>
              <DepartureAndSlowChargeFields
                currentDatum={currentDatum}
                departureTime={candidateChartFormValues.departureTime}
                messages={messages}
                setCandidateChartFormValues={setCandidateChartFormValues}
                slowChargeStartTime={candidateChartFormValues.slowChargeStartTime}
              />
              {contentSwitcherItems.length > 1 && (
                <div className={classes['content-switcher']}>
                  <ContentSwitcherButtons items={contentSwitcherItems} />
                </div>
              )}
            </div>
            <CandidateGraph
              clickableChartChargingEvents={clickableChartChargingEvents}
              distanceFromSavedChargingEvents={distanceFromSavedChargingEvents}
              distanceIncludingPreview={distanceIncludingPreview}
              vehicle={vehicle}
              dailyRange={baseRange}
              currentDatum={currentDatum}
              candidateChartFormValues={candidateChartFormValues}
              dailyOdometerMedianKm={dailyOdometerMedianKm}
              dailyOdometerLongestKm={dailyOdometerLongestKm}
              medianTick={medianTick}
              medianTickIsOver={medianTickIsOver}
              setCurrentDatumIsAdjustedByCurrentChargingEvent={setCurrentDatumIsAdjustedByCurrentChargingEvent}
              resetCandidateChartFormValues={resetCandidateChartFormValues}
              findSavedChargingEvent={findSavedChargingEvent}
              getCandidateChargingTime={getCandidateChargingTime}
              setCurrentDatum={setCurrentDatum}
              findChartChargingEvent={findChartChargingEvent}
              setLoadCandidateChartFormValues={setLoadCandidateChartFormValues}
              setChartHasBeenClicked={setChartHasBeenClicked}
              setCurrentDatumTimeAtEvent={setCurrentDatumTimeAtEvent}
              validateChargingEventsHandler={validateChargingEventsHandler}
            />
            <SetupChargingEvent
              depots={depots}
              resultingSoc={candidateChartFormValues.resultingSoc}
              messages={messages}
              candidateChartFormValues={candidateChartFormValues}
              currentChargingEvent={currentChargingEvent}
              currentChartChargingEvent={currentChartChargingEvent}
              medianTickIsOver={medianTickIsOver}
              setupChargingEventHandler={setupChargingEventHandler}
              validateChargingEventsHandler={validateChargingEventsHandler}
              setCandidateChartFormValues={setCandidateChartFormValues}
            />
          </div>
          {currentDatum ? (
            <ChargingDetails
              depotYear={depotYear}
              messages={messages}
              selectedDepot={selectedDepot}
              infrastructureProductInstanceId={candidateChartFormValues.infrastructureProductInstanceId}
              footer={
                messages?.mainErrorMessage && (
                  <MessageWrapper>
                    <TdsMessage header={messages.mainErrorMessage.header} variant={messages.mainErrorMessage.variant}>
                      {messages.mainErrorMessage.body}
                    </TdsMessage>
                  </MessageWrapper>
                )
              }
            />
          ) : (
            <ChargingEventsList
              departureTime={candidateChartFormValues.departureTime}
              depotName={depot.Name}
              depotNames={depotNames}
              chargingEvents={vehicle.enhanced.vehiclePerformanceStep.ChargingEvents}
              energyConsumptionPerDayKwh={vehicle.enhanced.vehiclePerformanceStep.EnergyConsumptionPerDayKwh}
              slowChargingEvent={vehicle.enhanced.vehiclePerformanceStep.SlowChargingEvent}
              classNameInner={classNames(classes['charging-events-list-inner'], {
                [classes['charging-events-list-inner-when-warning']]: Boolean(warningMessage),
              })}
              footer={
                warningMessage && (
                  <MessageWrapper>
                    <TdsMessage variant={warningMessage.variant} header={warningMessage.header}>
                      {warningMessage.body}
                    </TdsMessage>
                  </MessageWrapper>
                )
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateCharging;
