import { Slider } from '@optimization/ssi-common';

interface Props {
  onChangeDailyDistance: (value: string) => void;
  onChangeLongestDailyDistance: (value: string) => void;
  onChangeAvgGtw: (value: string) => void;
  onChangeAvgSpeed: (value: string) => void;
  onChangeCruisingSpeed: (value: string) => void;
  dailyDistance: string | number;
  longestDailyDistance: string | number;
  longestDailyDistanceMin: string;
  avgGtw: string | number;
  avgSpeed: string | number;
  cruisingSpeed: string | number;
  cruisingSpeedMin: string;
  disabled: boolean;
}

const OperationalFactors = ({
  onChangeDailyDistance,
  onChangeLongestDailyDistance,
  onChangeAvgGtw,
  onChangeAvgSpeed,
  onChangeCruisingSpeed,
  dailyDistance,
  longestDailyDistance,
  longestDailyDistanceMin,
  avgGtw,
  avgSpeed,
  cruisingSpeed,
  cruisingSpeedMin,
  disabled,
}: Props) => (
  <>
    <Slider
      className="mt-negative-spacing-30"
      onChange={onChangeDailyDistance}
      label="Daily Distance (median day)"
      min="1"
      max="1000"
      disabled={disabled}
      value={dailyDistance}
    />
    <Slider
      className="mt-negative-spacing-38"
      onChange={onChangeLongestDailyDistance}
      label="Daily Distance (longest day)"
      min={longestDailyDistanceMin}
      max="1000"
      disabled={disabled}
      value={longestDailyDistance}
      dataTestid="slider-longest-daily-distance"
    />
    <Slider
      className="mt-negative-spacing-38"
      onChange={onChangeAvgGtw}
      label="Average GTW (kg)"
      min="10000"
      max="64000"
      disabled={disabled}
      value={avgGtw}
    />
    <Slider
      className="mt-negative-spacing-36"
      onChange={onChangeAvgSpeed}
      label="Average speed (km/h)"
      min="0"
      max="90"
      disabled={disabled}
      value={avgSpeed}
      dataTestid="slider-avg-speed"
    />
    <Slider
      className="mt-negative-spacing-40 h-80"
      onChange={onChangeCruisingSpeed}
      label="Cruising speed (km/h)"
      min={cruisingSpeedMin}
      max="100"
      disabled={disabled}
      value={cruisingSpeed}
      dataTestid="slider-cruising-speed"
    />
  </>
);

export default OperationalFactors;
