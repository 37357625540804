import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { matchRoutes, useLocation } from 'react-router-dom';
import { routes } from 'src/index';
import { useAppSelector } from 'app/hooks';
import { useEffect, useMemo } from 'react';
import { useGetUserQuery } from 'app/services/solution';
import { useMsal } from '@azure/msal-react';
import { v4 as uuidv4 } from 'uuid';

const shouldDatadogInit = import.meta.env.MODE === 'production';

shouldDatadogInit &&
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'scania-analytic',
    env: 'prod',
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    // allowedTracingUrls: [import.meta.env.VITE_SOLUTION_ENDPOINT],
    actionNameAttribute: 'data-action-name',
    trackViewsManually: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

shouldDatadogInit &&
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    service: 'scania-analytic-logs',
    sessionSampleRate: 100,
  });

const computeViewName = (routeMatches: any) => {
  let viewName = '';
  for (let index = 0; index < routeMatches.length; index++) {
    const routeMatch = routeMatches[index];
    const path = routeMatch.route.path;

    // Skip pathless routes
    if (!path) {
      continue;
    }

    if (path.startsWith('/')) {
      // Handle absolute child route paths
      viewName = path;
    } else {
      // Handle route paths ending with "/"
      viewName += viewName.endsWith('/') ? path : `/${path}`;
    }
  }

  return viewName || '/';
};

const Datadog = ({ children }: { children: React.ReactNode }) => {
  let location = useLocation();

  const { accounts } = useMsal();

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const userQuery = useGetUserQuery(undefined, { skip: Boolean(!accessToken) });

  const hashedGlobal = useMemo(() => userQuery.data?.HashedGlobal, [userQuery]);

  const isDataRumInit = datadogRum.getInternalContext()?.session_id;

  useEffect(() => {
    const xSessionId = sessionStorage.getItem('x_session_id');

    if (!xSessionId && accounts.length > 0 && isDataRumInit && hashedGlobal) {
      const uuid = uuidv4();
      datadogLogs.logger.info('Session created', {
        x_session_id: uuid,
        global: hashedGlobal,
        country: accounts[0].idTokenClaims?.ctry,
      });
      sessionStorage.setItem('x_session_id', uuid);
    }
  }, [accounts, hashedGlobal, isDataRumInit]);

  useEffect(() => {
    if (hashedGlobal) {
      datadogRum.setUser({
        name: hashedGlobal,
      });
    }
  }, [hashedGlobal]);

  useEffect(() => {
    const routeMatches = matchRoutes(routes.children, location.pathname);
    const viewName = routeMatches && computeViewName(routeMatches);

    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);
  return children;
};

export default Datadog;
