import { SelectionExecutions } from '../useVehicleOptionsHandler';

const allBusSelectionsCompleted = (selections: SelectionExecutions) =>
  selections.busPortfolio &&
  selections.busBattery &&
  selections.busSocWindow &&
  selections.busChargingType &&
  selections.busElectricMotor &&
  selections.busWheelConfiguration &&
  selections.busBodyLength &&
  selections.busChassisHeight &&
  selections.busFloorHeight;

export default allBusSelectionsCompleted;
