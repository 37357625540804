import timeToDayMinute from '../../utils/timeToDayMinute';
import { ChargingEventV2VM } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  slowChargingEvent: ChargingEventV2VM;
}

const usePreparedSlowChargingEvent = ({ slowChargingEvent }: Props) =>
  useMemo(
    () => ({
      timeAtEvent: timeToDayMinute(slowChargingEvent.TimeAtEvent),
      eventDuration: slowChargingEvent.ChargingDuration,
      power: slowChargingEvent.Power,
    }),
    [slowChargingEvent],
  );

export default usePreparedSlowChargingEvent;
