import { useCallback, useEffect, useMemo, useState } from 'react';
import { ValidateChassisNumbersVehicleResponseV2VM } from '@optimization/sa-common';
import { VehiclesByPerformanceStep } from '..';

export const currentYear = new Date().getFullYear() + 1;

export type SetVehiclesByPerformanceStepByNumberOfYears = (numberOfYears: number) => void;

interface Props {
  validateChassisData?: ValidateChassisNumbersVehicleResponseV2VM;
}

const useVehiclesByPerformanceStep = ({ validateChassisData }: Props) => {
  const [vehiclesByPerformanceStep, setVehiclesByPerformanceStep] = useState<VehiclesByPerformanceStep>();

  const setVehiclesByPerformanceStepByNumberOfYears: SetVehiclesByPerformanceStepByNumberOfYears = useCallback(
    (numberOfYears: number) => {
      if (validateChassisData?.ExecutableVehicles.length) {
        const result: VehiclesByPerformanceStep = {};
        const years = Array.from({ length: numberOfYears }, (_, index) => currentYear + index);

        validateChassisData.ExecutableVehicles.forEach((vehicleId, index) => {
          const year = years[index % years.length];

          // Todo: Add check that vehicle is valid for this year
          if (result[year]) {
            result[year].push(vehicleId);
          } else {
            result[year] = [vehicleId];
          }
        });

        setVehiclesByPerformanceStep(result);
      } else {
        setVehiclesByPerformanceStep(undefined);
      }
    },
    [validateChassisData],
  );

  useEffect(() => {
    setVehiclesByPerformanceStepByNumberOfYears(5);
  }, [setVehiclesByPerformanceStepByNumberOfYears]);

  return useMemo(
    () => ({ vehiclesByPerformanceStep, setVehiclesByPerformanceStepByNumberOfYears }),
    [vehiclesByPerformanceStep, setVehiclesByPerformanceStepByNumberOfYears],
  );
};

export default useVehiclesByPerformanceStep;
