import { ExecutedVehicleProductV2VM, FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { getEngineGearbox, getVehicleSoc } from 'common/utils/helper';
import { IOperationalFactors, IUserFactors } from '../types';
import { parseChargingTypeValue } from 'features/candidate/utils/vehicleOptionsHandler/defaultOptions/useBusChargingTypeOptions';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useCallback, useEffect, useMemo } from 'react';
import { useCreateManualVehicleMutation } from 'app/services/solution';
import { useToast } from '@optimization/ssi-common';
import { valueToTemperature } from 'common/utils/translate';
import {
  getBusWheelConfigurationWithoutChassisAdaptation,
  getChassisAdaptationFromBusWheelConfiguration,
} from 'features/candidate/utils/vehicleOptionsHandler/defaultOptions/useBusWheelConfiguration';

interface Props {
  candidateName: string;
  solutionId: string;
  selectedBasicTruck: FactBasicTruck | null;
  selectedBasicBus: FactBasicBus | null;
  selections: SelectionExecutions;
  userFactors: IUserFactors;
  operationalFactors: IOperationalFactors;
  deactivateAddVehicleDrawer: () => void;
}

const useAddVehicleHandler = ({
  candidateName,
  solutionId,
  selectedBasicTruck,
  selectedBasicBus,
  selections,
  userFactors,
  operationalFactors,
  deactivateAddVehicleDrawer,
}: Props): { isLoading: boolean; isError: boolean; addVehicle: () => void } => {
  const showToast = useToast();

  const [createManualVehicle, createManualVehicleState] = useCreateManualVehicleMutation();

  const addVehicle = useCallback(() => {
    const selectedBasicVehicle = selections.chassisType === 'Truck' ? selectedBasicTruck : selectedBasicBus;

    if (!selectedBasicVehicle) {
      return;
    }

    let runCreateManualVehicle = false;

    let ExecutedVehicleProduct: ExecutedVehicleProductV2VM = {
      AvgGtwKg: operationalFactors.avgGtw,
      AvgSpeedKmh: operationalFactors.avgSpeed,
      ChargingSpecification: selectedBasicVehicle.ChargingSpecifications[0],
      CompositeId: selectedBasicVehicle.CompositeId,
      CruiseSpeedKmh: operationalFactors.cruisingSpeed,
      DailyOdometerLongestKm: operationalFactors.longestDailyDistance,
      DailyOdometerMedianKm: operationalFactors.dailyDistance,
      Discontinued: selectedBasicVehicle.Discontinued || '',
      ExecutedVehicleProductPTOs: [],
      InstalledEnergyKWh: selectedBasicVehicle.InstalledEnergyKWh,
      LaunchPeriod: selectedBasicVehicle.Period,
      MaxGtwKg: operationalFactors.avgGtw,
      Name: candidateName,
      Status: selectedBasicVehicle.Status,
      TemperatureSummerC: valueToTemperature(userFactors.climate, 'summer'),
      TemperatureWinterC: valueToTemperature(userFactors.climate, 'winter'),
      Topography: userFactors.topography,
      Traffic: userFactors.traffic,
    };

    if (selections.chassisType === 'Truck') {
      if (!selectedBasicTruck) {
        return;
      }

      ExecutedVehicleProduct = {
        ...ExecutedVehicleProduct,
        AxleDistanceMm: Number(selections.truckAxleDistance),
        Cab: selections.truckCabType,
        ChassisAdaptation: selections.truckChassisAdaptation,
        ChassisHeight: selections.truckChassisHeight,
        EngineGearbox: getEngineGearbox(selectedBasicTruck, selections.truckElectricMotor),
        Soc: getVehicleSoc(selectedBasicTruck, selections.truckSocWindow),
        WheelConfiguration: selections.truckWheelConfiguration,
      };

      runCreateManualVehicle = true;
    } else if (selections.chassisType === 'Bus') {
      if (!selectedBasicBus) {
        return;
      }

      const chargingTypeParts = parseChargingTypeValue(selections.busChargingType);

      if (chargingTypeParts.length !== 2) {
        return;
      }

      const [chargingType, chargingPowerKW] = chargingTypeParts;

      ExecutedVehicleProduct = {
        ...ExecutedVehicleProduct,
        BodyLengthM: selections.busBodyLength,
        BusFloorHeight: selections.busFloorHeight,
        ChassisHeight: selections.busChassisHeight,
        EngineGearbox: getEngineGearbox(selectedBasicBus, selections.busElectricMotor),
        Soc: getVehicleSoc(selectedBasicBus, selections.busSocWindow),
        ChassisAdaptation: getChassisAdaptationFromBusWheelConfiguration(selections.busWheelConfiguration),
        WheelConfiguration: getBusWheelConfigurationWithoutChassisAdaptation(selections.busWheelConfiguration),
        ChargingSpecification: {
          ChargingPowerKW: Number(chargingPowerKW),
          ChargingType: chargingType,
        },
      };

      runCreateManualVehicle = true;
    }

    if (runCreateManualVehicle) {
      createManualVehicle({
        solutionId,
        ExecutedVehicleProduct,
      });
    }
  }, [
    candidateName,
    operationalFactors,
    selectedBasicTruck,
    selectedBasicBus,
    solutionId,
    userFactors,
    selections,
    createManualVehicle,
  ]);

  useEffect(() => {
    if (!createManualVehicleState.isUninitialized && !createManualVehicleState.isLoading) {
      if (createManualVehicleState.isSuccess) {
        showToast({
          header: '1 vehicle candidate added',
          dataTestid: 'toast-manually-added-vehicle',
        });
        deactivateAddVehicleDrawer();
      } else {
        showToast({ header: "Couldn't add candidate", variant: 'error' });
      }
    }
  }, [createManualVehicleState, deactivateAddVehicleDrawer, showToast]);

  return useMemo(
    () => ({ isLoading: createManualVehicleState.isLoading, isError: createManualVehicleState.isError, addVehicle }),
    [createManualVehicleState, addVehicle],
  );
};

export default useAddVehicleHandler;
