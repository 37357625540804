import useDatadogLogInfo from 'features/datadog/useDatadogLogInfo';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  filterParams: any;
  filterKey: string;
}

const useMultiselectChange = ({ filterParams, filterKey }: Props) => {
  const [, setSearchParams] = useSearchParams();

  const onChangeLogInfo = useDatadogLogInfo();

  return useCallback(
    (values: string) => {
      const newFilterParams = {
        ...filterParams,
        [filterKey]: values,
      };
      setSearchParams(newFilterParams);
      onChangeLogInfo({
        logText: 'Filter updated',
        logObject: newFilterParams,
      });
    },
    [setSearchParams, filterKey, filterParams, onChangeLogInfo],
  );
};

export default useMultiselectChange;
