import { useEffect } from 'react';

interface Props {
  ref: React.RefObject<HTMLDivElement>;
  enabled: boolean;
}

const useScrollToView = ({ ref, enabled }: Props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (enabled && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [ref, enabled]);
};

export default useScrollToView;
