import classes from './style.module.scss';
import classNames from 'classnames';
import { DepotInfrastructureErrors } from '@optimization/sa-common';
import { TdsMessage } from '@scania/tegel-react';
import { useMemo } from 'react';

interface Props {
  className?: string;
  errorsStates?: DepotInfrastructureErrors;
  peakConnectedVehicles: number;
}

const DepotMessages = ({ className, errorsStates, peakConnectedVehicles }: Props) => {
  const hasErrorMessage = useMemo(
    () =>
      errorsStates
        ? errorsStates.NotEnoughPower || errorsStates.NotEnoughPowerBlocks || errorsStates.NotEnoughSatellites
        : false,
    [errorsStates],
  );

  const showSuccessMessage = useMemo(
    () => !hasErrorMessage && peakConnectedVehicles > 0,
    [hasErrorMessage, peakConnectedVehicles],
  );

  const hasMessage = useMemo(() => hasErrorMessage || showSuccessMessage, [hasErrorMessage, showSuccessMessage]);

  return hasMessage ? (
    <div className={classNames(classes['depot-messages'], className)}>
      {showSuccessMessage && (
        <TdsMessage variant="success" mode-variant="inherit from parent" header="Sufficient power and connectivity">
          Charging power and connectivity are sufficient for all mapped vehicles
        </TdsMessage>
      )}
      {errorsStates && (
        <>
          {errorsStates.NotEnoughPower && errorsStates.NotEnoughPowerBlocks && (
            <TdsMessage
              variant="warning"
              mode-variant="inherit from parent"
              header="Not enough power and power channels"
            >
              Vehicle demand exceeds available power, and power channels. Add or adjust chargers to cover the demand
            </TdsMessage>
          )}
          {errorsStates.NotEnoughPower && !errorsStates.NotEnoughPowerBlocks && (
            <TdsMessage variant="warning" mode-variant="inherit from parent" header="Not enough power">
              Vehicle demand exceeds available power. Add or adjust chargers to cover the demand
            </TdsMessage>
          )}
          {errorsStates.NotEnoughPowerBlocks && !errorsStates.NotEnoughPower && (
            <TdsMessage variant="warning" mode-variant="inherit from parent" header="Not enough power channels">
              Vehicle demand exceeds available power blocks. Add or adjust chargers to cover the demand
            </TdsMessage>
          )}
          {errorsStates.NotEnoughSatellites && (
            <TdsMessage variant="warning" mode-variant="inherit from parent" header="Not enough connectivity">
              Vehicle demand exceeds number of connectors. Add or adjust chargers to cover the demand
            </TdsMessage>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default DepotMessages;
