import PageHeader from 'common/components/PageHeader';
import { invariant } from '@optimization/ssi-common';
import { ReactNode, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateSolutionMutation } from 'app/services/solution';

interface Props {
  children: string;
  solutionName?: string | null;
  intro?: ReactNode;
  description?: string[];
  cta?: ReactNode;
}

const SolutionPageHeader = ({ children, solutionName, intro = 'Vehicle candidates', description, cta }: Props) => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const [updateSolution, updateSolutionState] = useUpdateSolutionMutation();

  const handleEdit = useCallback(
    (Name: string) => {
      updateSolution({ Id: Number(solutionId), Name });
    },
    [solutionId, updateSolution],
  );

  return (
    <PageHeader
      valueWhenEditing={solutionName ? solutionName : ''}
      editIsLoading={updateSolutionState.isLoading}
      editIsError={updateSolutionState.isError}
      intro={children}
      description={description}
      handleEdit={handleEdit}
      cta={cta}
    >
      {intro}
    </PageHeader>
  );
};

export default SolutionPageHeader;
