import Main from './Main';
import useLoadingText from 'common/hooks/useLoadingText';
import { Loading } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery, useGetPtoDevicesQuery, useGetVehicleQuery } from 'app/services/solution';
import {
  vehicleSettingsDefault,
  ListVehicleTransformed,
  SetIsSoH80,
  SetIsWinter,
  SetShowTopUp,
  VehicleSettings,
} from '@optimization/sa-common';

export type CandidateTabs =
  | 'Charging events'
  | 'Historical stops'
  | 'Environmental impact'
  | 'Financial evaluation'
  | 'Vehicle configuration';

interface Props {
  className?: string;
  depotYear?: string;
  candidateTabDefault?: CandidateTabs;
  enableYearSelectorForChargingEvents: boolean;
  solutionId: string;
  tooltipKey: number;
  expanded?: boolean;
  scrollToView: boolean;
  vehicle: ListVehicleTransformed;
  vehicleSettings?: VehicleSettings;
  setIsWinter: SetIsWinter;
  setIsSoH80: SetIsSoH80;
  setShowTopUp: SetShowTopUp;
}

const CandidateDetail = ({
  className,
  depotYear,
  candidateTabDefault = 'Vehicle configuration',
  enableYearSelectorForChargingEvents,
  solutionId,
  tooltipKey,
  vehicle,
  expanded,
  scrollToView,
  vehicleSettings = vehicleSettingsDefault,
  setIsWinter,
  setIsSoH80,
  setShowTopUp,
}: Props) => {
  const vehicleQuery = useGetVehicleQuery(
    {
      solutionId,
      vehicleId: vehicle.Id,
    },
    { skip: !expanded },
  );

  const getPtoDevicesQuery = useGetPtoDevicesQuery();
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const isLoading = vehicleQuery.isLoading || getPtoDevicesQuery.isLoading || factBasicDataQuery.isLoading;

  const isError = vehicleQuery.isError || getPtoDevicesQuery.isError || factBasicDataQuery.isError;

  const loadingText = useLoadingText({
    isLoadingCandidate: vehicleQuery.isLoading || factBasicDataQuery.isLoading,
    isLoadingPtoDevices: getPtoDevicesQuery.isLoading,
  });

  return (
    <>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {vehicleQuery.data && getPtoDevicesQuery.data && factBasicDataQuery.data && (
        <Main
          className={className}
          depotYear={depotYear}
          factBasicDataQueryData={factBasicDataQuery.data}
          ptoDevices={getPtoDevicesQuery.data}
          candidateTabDefault={candidateTabDefault}
          solutionId={solutionId}
          tooltipKey={tooltipKey}
          scrollToView={scrollToView}
          vehicle={vehicleQuery.data}
          vehicleSettings={vehicleSettings}
          setIsWinter={setIsWinter}
          setIsSoH80={setIsSoH80}
          setShowTopUp={setShowTopUp}
          enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
        />
      )}
    </>
  );
};

export default CandidateDetail;
