import { useCallback, useEffect, useMemo } from 'react';
import { useDeleteMapLocationMutation } from 'app/services/solution';
import { useToast } from '@optimization/ssi-common';

interface Props {
  solutionId: string;
  vehicleIds: number[];
}

const useDeleteLocation = ({ solutionId, vehicleIds }: Props) => {
  const showToast = useToast();

  const [deleteMapLocationV2, deleteLocationV2State] = useDeleteMapLocationMutation();

  const deleteLocationState = useMemo(() => deleteLocationV2State, [deleteLocationV2State]);

  const deleteLocation = useCallback(
    (mapLocationId: number) => {
      deleteMapLocationV2({
        solutionId,
        mapLocationId,
      });
    },
    [deleteMapLocationV2, solutionId],
  );

  useEffect(() => {
    if (!deleteLocationState.isUninitialized && !deleteLocationState.isLoading) {
      if (deleteLocationState.isSuccess) {
        showToast({ header: 'Image was deleted' });
      } else {
        showToast({ header: "Couldn't delete image", variant: 'error' });
      }
    }
  }, [deleteLocationState, showToast]);

  return useMemo(() => ({ deleteLocation, deleteLocationState }), [deleteLocation, deleteLocationState]);
};

export default useDeleteLocation;
