import { manageDepotInfrastructureInfoWizard, mapVehiclesToDepotInfo } from 'app/config/infrastructure';
import { Mode } from '..';
import { useMemo } from 'react';

interface Props {
  mode: Mode;
}

const useStepInfo = ({ mode }: Props) =>
  useMemo(() => {
    let stepInfo = null;

    if (mode === 'map-vehicles') {
      stepInfo = mapVehiclesToDepotInfo;
    } else if (mode === 'depot-infrastructure') {
      stepInfo = manageDepotInfrastructureInfoWizard;
    }

    return stepInfo;
  }, [mode]);

export default useStepInfo;
