import BusFields from './BusFields';
import TruckFields from './TruckFields';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  vehicleOptionsHandler: VehicleOptionsHandler;
  disabled: boolean;
  className?: string;
  dataTestidBase?: string;
}

const VehicleConfiguration = ({
  vehicleOptionsHandler,
  disabled,
  className,
  dataTestidBase = 'vehicle-configuration',
}: Props) => {
  if (vehicleOptionsHandler.selections.chassisType === 'Truck') {
    return (
      <TruckFields
        vehicleOptionsHandler={vehicleOptionsHandler}
        disabled={disabled}
        className={className}
        dataTestidBase={dataTestidBase}
      />
    );
  } else if (vehicleOptionsHandler.selections.chassisType === 'Bus') {
    return (
      <BusFields
        vehicleOptionsHandler={vehicleOptionsHandler}
        disabled={disabled}
        className={className}
        dataTestidBase={dataTestidBase}
      />
    );
  }

  return null;
};

export default VehicleConfiguration;
