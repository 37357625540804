export const LOWEST_CLICKABLE_SECONDARY_IN_CHART = 6;

interface Props {
  batteryLevelBeforeCharging: number;
}

const ensureClickableSecondary = ({ batteryLevelBeforeCharging }: Props): number =>
  Math.max(batteryLevelBeforeCharging, LOWEST_CLICKABLE_SECONDARY_IN_CHART);

export default ensureClickableSecondary;
