import useGetPtoField from 'features/candidate/Pto/useGetPtoField';
import useOnChangePtoDevices from 'features/candidate/Pto/useOnChangePtoDevices';
import useOnCheckboxChange from 'features/candidate/Pto/useOnCheckboxChange';
import useSetEcItem from 'features/candidate/Pto/useSetEcItem';
import { EcItem } from 'features/candidate/Pto/types';
import { PtoDevices, PtoType, VehicleEnhanced } from '@optimization/sa-common';
import { useCallback, useMemo, useState } from 'react';
import { useEffect, useRef } from 'react';
import { useEnterKeyInTextField } from '@optimization/ssi-common';
import { useUpdateVehicleMutation } from 'app/services/solution';

const checkboxEcItemTypes = ['Crane', 'RefuseCollector'];

export interface PtoHandler {
  isLoading: boolean;
  isError: boolean;
  ecItems: EcItem[];
  checkboxEcItems: EcItem[];
  dropdownEcItems: EcItem[];
  ptoDevices: PtoDevices;
  getPtoField: (type: PtoType) => JSX.Element | null;
  onChangePtoDevices: (value: string) => void;
  onCheckboxChange: (checked: boolean, value: string, type: PtoType) => void;
}

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  disabled: boolean;
  ptoDevices?: PtoDevices;
}

const usePtoHandler = ({ solutionId, vehicle, disabled, ptoDevices = {} }: Props): PtoHandler => {
  const ecItemsRef = useRef<EcItem[] | null>(null);
  const vehicleRef = useRef<VehicleEnhanced | null>(null);

  const [calculateCount, setCalculateCount] = useState(0);
  const [calculateDelay, setCalculateDelay] = useState(0);

  const [ecItems, setEcItems] = useState<EcItem[]>([]);

  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const calculatePtoInstant = useCallback(() => {
    setCalculateCount((prev) => prev + 1);
    setCalculateDelay(0);
  }, []);

  const calculatePtoDelayed = useCallback(() => {
    setCalculateCount((prev) => prev + 1);
    setCalculateDelay(1000);
  }, []);

  const onChangePtoDevices = useOnChangePtoDevices({
    ecItems,
    setEcItems,
    calculatePtoInstant,
  });

  const setEcItem = useSetEcItem({
    ecItems,
    setEcItems,
    calculatePtoInstant,
    calculatePtoDelayed,
  });

  const onCheckboxChange = useOnCheckboxChange({
    setEcItem,
    setEcItems,
    calculatePtoInstant,
  });

  const submitOnEnterKey = useEnterKeyInTextField(calculatePtoInstant);

  const getPtoField = useGetPtoField({
    ecItems,
    ptoDevices,
    disabled,
    onCheckboxChange,
    setEcItem,
    submitOnEnterKey,
  });

  useEffect(() => {
    setEcItems((prevEcItems) => {
      const mergedEcItems = prevEcItems.filter((prevEcItem) => {
        const existingVehiclePto = vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs.find(
          (ExecutedVehicleProductPTO) => ExecutedVehicleProductPTO.Type === prevEcItem.Type,
        );

        if (existingVehiclePto) {
          return { ...existingVehiclePto };
        } else {
          return prevEcItem;
        }
      });

      for (const vehiclePto of vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs) {
        const vehiclePtoExistsInPrevEcItems = prevEcItems.some((prevEcItem) => prevEcItem.Type === vehiclePto.Type);

        if (!vehiclePtoExistsInPrevEcItems) {
          mergedEcItems.push({ ...vehiclePto, Value: vehiclePto.Value || '' });
        }
      }

      return mergedEcItems;
    });
  }, [vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs]);

  useEffect(() => {
    ecItemsRef.current = ecItems;
  }, [ecItems]);

  useEffect(() => {
    vehicleRef.current = vehicle;
  }, [vehicle]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ecItemsRef.current && vehicleRef.current && calculateCount) {
        updateVehicle({
          solutionId,
          vehicle: {
            ...vehicleRef.current,
            ExecutedVehicleProduct: {
              ...vehicleRef.current.ExecutedVehicleProduct,
              ExecutedVehicleProductPTOs: ecItemsRef.current
                .filter((ecItem) => ecItem.Value || ecItem.Type === PtoType.RefrigerationUnit)
                .map((ecItem) => ({
                  ...ecItem,
                })),
            },
          },
        });
      }
    }, calculateDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [solutionId, updateVehicle, calculateDelay, calculateCount]);

  const filteredEcItems = useMemo(
    () => ecItems.filter((ecItem) => ecItem.Type !== PtoType.RefrigerationUnit),
    [ecItems],
  );

  const checkboxEcItems = useMemo(
    () => filteredEcItems.filter((ecItem) => checkboxEcItemTypes.includes(ecItem.Type)),
    [filteredEcItems],
  );

  const dropdownEcItems = useMemo(
    () => filteredEcItems.filter((ecItem) => !checkboxEcItemTypes.includes(ecItem.Type)),
    [filteredEcItems],
  );

  const isLoading = updateVehicleState.isLoading;
  const isError = updateVehicleState.isError;

  return useMemo(
    () => ({
      isLoading,
      isError,
      ecItems,
      checkboxEcItems,
      dropdownEcItems,
      ptoDevices,
      getPtoField,
      onChangePtoDevices,
      onCheckboxChange,
    }),
    [
      isLoading,
      isError,
      ecItems,
      checkboxEcItems,
      dropdownEcItems,
      ptoDevices,
      getPtoField,
      onChangePtoDevices,
      onCheckboxChange,
    ],
  );
};

export default usePtoHandler;
