import { Charger, InfraProductChangeAttribute } from './types';
import { KeyedStringOrNumber } from '@optimization/ssi-common';
import {
  FactBasicChargingSolution,
  InfrastructureProductV2VM,
  VariantCodeDescription,
  VariantCodeDescriptionWithDependency,
} from '@optimization/sa-common';

const getChargingSolutionIndex = (
  infrastructureProduct: InfrastructureProductV2VM,
  chargingSolutions?: FactBasicChargingSolution[],
) => {
  if (chargingSolutions) {
    const index = chargingSolutions?.findIndex(
      (chargingSolution) =>
        chargingSolution.Brand.VariantCode === infrastructureProduct.Brand &&
        chargingSolution.Solution.VariantCode === infrastructureProduct.Solution,
    );

    if (index > -1) {
      return index;
    }
  }

  return 0;
};

export const mutateInfrastructureProduct = ({
  product,
  chargingSolution,
  depotYear,
  attribute,
  selectedValue,
}: {
  product: InfrastructureProductV2VM;
  chargingSolution?: FactBasicChargingSolution;
  depotYear: string;
  attribute: InfraProductChangeAttribute;
  selectedValue: string;
}) => {
  const brand = chargingSolution?.Brand?.VariantCode;
  const solution = chargingSolution?.Solution?.VariantCode;

  const powerOutputs = chargingSolution?.PowerOutputs.filter((powerOutput) =>
    powerOutputIsAvailable(powerOutput, depotYear),
  );

  const numberOfOutlets = chargingSolution?.NumberOfOutlets.filter((outlet) =>
    outletIsValidForPower(outlet, attribute === 'Power' ? selectedValue : product.Power || ''),
  );

  const power = powerOutputs ? powerOutputs[powerOutputs.length - 1].VariantCode : null;

  const outlets = numberOfOutlets ? numberOfOutlets[numberOfOutlets.length - 1].VariantCode : null;

  if (brand && solution && power && outlets) {
    if (attribute === 'Brand') {
      product.Brand = brand;
      product.Solution = solution;
      product.Power = power;
      product.Outlets = outlets;
    } else if (attribute === 'Power') {
      product.Power = selectedValue;
      product.Outlets = outlets;
    } else if (attribute === 'Outlets') {
      product.Outlets = selectedValue;
    } else if (attribute === 'Quantity') {
      product.Quantity = Number(selectedValue);
    }
  }
};

export const powerOutputIsAvailable = (powerOutput: VariantCodeDescription, depotYear: string) =>
  powerOutput.AvailableFrom ? Number(depotYear) >= Number(powerOutput.AvailableFrom) : true;

export const outletIsValidForPower = (outlet: VariantCodeDescriptionWithDependency, power: string) =>
  outlet?.RequiresOneOf ? outlet.RequiresOneOf.includes(power) : outlet;

const getChargerIndex = ({
  infrastructureProduct,
  infrastructureProducts,
}: {
  infrastructureProduct: InfrastructureProductV2VM;
  infrastructureProducts: InfrastructureProductV2VM[];
}) => infrastructureProducts.findIndex((item) => item === infrastructureProduct);

export const getChargersFromInstructureProducts = ({
  infrastructureProducts,
  variantValues,
  chargingSolutions,
  year,
}: {
  infrastructureProducts?: InfrastructureProductV2VM[];
  variantValues: KeyedStringOrNumber;
  chargingSolutions?: FactBasicChargingSolution[];
  year: number;
}): Charger[] =>
  infrastructureProducts
    ? infrastructureProducts
        .filter((infrastructureProduct) => parseInt(infrastructureProduct.RequiredAt || '') <= year)
        .map((infrastructureProduct) => ({
          chargerIndex: getChargerIndex({
            infrastructureProduct,
            infrastructureProducts,
          }),
          brand: {
            code: infrastructureProduct.Brand || '',
            value: (variantValues[infrastructureProduct.Brand || ''] as string) || '',
          },
          solution: {
            code: infrastructureProduct.Solution || '',
            value: (variantValues[infrastructureProduct.Solution || ''] as string) || '',
          },
          power: {
            code: infrastructureProduct.Power || '',
            value: (variantValues[infrastructureProduct.Power || ''] as number) || 0,
          },
          satellites: {
            code: infrastructureProduct.Outlets || '',
            value: (variantValues[infrastructureProduct.Outlets || ''] as number) || 0,
          },
          quantity: infrastructureProduct.Quantity,
          year: parseInt(infrastructureProduct.RequiredAt || ''),
          disabled: parseInt(infrastructureProduct.RequiredAt || '') < year,
          chargingSolutionIndex: getChargingSolutionIndex(infrastructureProduct, chargingSolutions),
        }))
    : [];

export const getStepIsComplete = ({
  notEnoughPower,
  notEnoughPowerBlocks,
  notEnoughSatellites,
}: {
  notEnoughPower: boolean;
  notEnoughPowerBlocks: boolean;
  notEnoughSatellites: boolean;
}) => !notEnoughPower && !notEnoughPowerBlocks && !notEnoughSatellites;
