import { PickingInfo } from 'deck.gl/typed';
import { useEffect, useState } from 'react';

interface Props {
  pickingInfo?: PickingInfo;
}

// To avoid add new depot popover menu flickering before the other popovers
const usePickingInfoDelayed = ({ pickingInfo }: Props): PickingInfo | undefined => {
  const [pickingInfoDelayed, setPickingInfoDelayed] = useState<PickingInfo>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setPickingInfoDelayed(pickingInfo);
    }, 50);

    return () => {
      clearTimeout(timer);
    };
  }, [pickingInfo]);

  return pickingInfoDelayed;
};

export default usePickingInfoDelayed;
