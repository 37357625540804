import Main from './Main';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useGetVehicleQuery } from 'app/services/solution';

interface Props {
  depotId: number;
  depotYear: string;
  tdIndex: number;
  pixelsPerMinute: number;
  listVehicle: ListVehicleTransformed;
  solutionId: string;
}

const ChargingEvents = ({ depotId, depotYear, tdIndex, pixelsPerMinute, listVehicle, solutionId }: Props) => {
  const vehicleQuery = useGetVehicleQuery({
    solutionId,
    vehicleId: listVehicle.Id,
  });

  return vehicleQuery.data ? (
    <Main
      depotId={depotId}
      depotYear={depotYear}
      tdIndex={tdIndex}
      pixelsPerMinute={pixelsPerMinute}
      vehicle={vehicleQuery.data}
    />
  ) : null;
};

export default ChargingEvents;
