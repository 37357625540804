import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';

interface Props {
  logText: string;
  logObject: {};
}

const useDatadogLogInfo = () => {
  const { solutionId } = useParams();

  const { accounts } = useMsal();
  return useCallback(
    ({ logText, logObject }: Props) => {
      const xSessionId = sessionStorage.getItem('x_session_id');
      if (accounts.length > 0) {
        datadogLogs.logger.info(logText, {
          x_session_id: xSessionId,
          extra_log: logObject,
          global: accounts[0].username,
          country: accounts[0].idTokenClaims?.ctry,
          solution_id: solutionId || '',
        });
      }
    },
    [accounts, solutionId],
  );
};

export default useDatadogLogInfo;
