import { EcItem } from './types';
import { PtoType } from '@optimization/sa-common';
import { useCallback } from 'react';

interface Props {
  ecItems: EcItem[];
  calculatePtoInstant?: () => void;
  setEcItems: (value: React.SetStateAction<EcItem[]>) => void;
}

const useOnChangePtoDevices = ({ ecItems, calculatePtoInstant, setEcItems }: Props) =>
  useCallback(
    (value: string) => {
      let result: EcItem[] = [];

      if (value) {
        const selectedPtos = value.split(',');

        if (selectedPtos) {
          result = ecItems.filter((ecItem) => selectedPtos.includes(ecItem.Type));

          const missingPtos = selectedPtos.filter(
            (selectedPto) => !ecItems.some((ecItem) => ecItem.Type === selectedPto),
          );

          for (const missingPto of missingPtos) {
            result.push({
              ElectricalConsumption: 0,
              Type: missingPto as PtoType,
              Value: '',
            });
          }
        }
      }

      setEcItems(result);

      if (calculatePtoInstant) {
        calculatePtoInstant();
      }
    },
    [ecItems, calculatePtoInstant, setEcItems],
  );

export default useOnChangePtoDevices;
