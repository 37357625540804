import Card from 'common/components/Card';
import classes from './style.module.scss';
import classNames from 'classnames';
import DepotInfoList from '../DepotInfoList';
import { LightningAltIcon } from '@optimization/ssi-common';
import { Link } from 'react-router-dom';
import { ListDepotTransformed } from '@optimization/sa-common';

interface Props {
  depot: ListDepotTransformed;
  solutionId: string;
}

const DepotTeaser = ({ depot, solutionId }: Props) => (
  <Link className={classes['depot-teaser-link']} to={`/solution/${solutionId}/infrastructure/${depot.DepotId}`}>
    <Card innerClassName="px-spacing-16 pt-spacing-16 pb-spacing-24">
      <div className="flex">
        <LightningAltIcon className="tds-u-ml-auto" sizeClassName="w-24 h-24" />
      </div>
      <p className={classNames('tds-u-mt2', 'tds-detail-05', 'tds-text-grey-300')}>Depot</p>
      <h3
        className={classNames(
          'tds-paragraph-02',
          'mt-spacing-4',
          'mb-spacing-16',
          'overflow-hidden',
          'text-ellipsis',
          'whitespace-nowrap',
        )}
      >
        {depot.DepotName}
      </h3>
      <DepotInfoList depot={depot} />
    </Card>
  </Link>
);

export default DepotTeaser;
