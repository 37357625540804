const increaseValue = ({
  acc,
  key,
  list,
}: {
  acc: {
    [key: string]: number;
  };
  key?: string | number;
  list: (string | number)[];
}) => {
  if (key) {
    const prevCount = acc[key] || 0;
    const isIncludedInList = Boolean(key && list.includes(key));

    return {
      ...acc,
      [key]: prevCount + (isIncludedInList ? 1 : 0),
    };
  }

  return acc;
};

export default increaseValue;
