import useDatadogLogInfo from 'features/datadog/useDatadogLogInfo';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  filterParams: any;
}

const useChangeSortType = ({ filterParams }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const onChangeLogInfo = useDatadogLogInfo();
  return useCallback(
    (value: string) => {
      const newFilterParams = {
        ...filterParams,
        sort: value,
      };
      setSearchParams(newFilterParams);
      onChangeLogInfo({
        logText: 'Filter updated',
        logObject: newFilterParams,
      });
    },
    [setSearchParams, filterParams, onChangeLogInfo],
  );
};

export default useChangeSortType;
