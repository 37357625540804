import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedPortfolio = ({
  vehicles,
  portfolioList,
}: {
  vehicles: ListVehicleTransformed[];
  portfolioList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const launchPeriodCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const launchPeriod = vehicle.Period;

        return increaseValue({ acc, key: launchPeriod, list: portfolioList });
      }, {});

      result = getMaxKey({ countByName: launchPeriodCount });
    }

    return result;
  }, [vehicles, portfolioList]);

export default useSuggestedPortfolio;
