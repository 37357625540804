import { Dropdown } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  vehicleOptionsHandler: VehicleOptionsHandler;
  disabled: boolean;
  className?: string;
  dataTestidBase: string;
}

const BusFields = ({ vehicleOptionsHandler, disabled, className, dataTestidBase }: Props) => {
  const {
    busPortfolioOptions,
    busBatteryOptions,
    busBatteryDisabled,
    busSocOptions,
    busSocDisabled,
    busEngineGearBoxDisabled,
    busEngineGearBoxOptions,
    busWheelConfigurationDisabled,
    busWheelConfigurationOptions,
    busBodyLengthOptions,
    busBodyLengthDisabled,
    busChassisHeightOptions,
    busChassisHeightDisabled,
    busFloorHeightDisabled,
    busFloorHeightOptions,
    busChargingTypeOptions,
    busChargingTypeDisabled,
    selections,
    handleSelection,
  } = vehicleOptionsHandler;

  const onChangePortfolio = useCallback(
    (value: string) => {
      handleSelection('busPortfolio', value);
    },
    [handleSelection],
  );

  const onChangeBusBattery = useCallback(
    (value: string) => {
      handleSelection('busBattery', value);
    },
    [handleSelection],
  );

  const onChangeBusSocWindow = useCallback(
    (value: string) => {
      handleSelection('busSocWindow', value);
    },
    [handleSelection],
  );

  const onChangeBusElectricMotor = useCallback(
    (value: string) => {
      handleSelection('busElectricMotor', value);
    },
    [handleSelection],
  );

  const onChangeWheelConfiguration = useCallback(
    (value: string) => {
      handleSelection('busWheelConfiguration', value);
    },
    [handleSelection],
  );

  const onChangeBodyLength = useCallback(
    (value: string) => {
      handleSelection('busBodyLength', value);
    },
    [handleSelection],
  );

  const onChangeChassisHeight = useCallback(
    (value: string) => {
      handleSelection('busChassisHeight', value);
    },
    [handleSelection],
  );

  const onChangeFloorHeight = useCallback(
    (value: string) => {
      handleSelection('busFloorHeight', value);
    },
    [handleSelection],
  );

  const onChangeChargingType = useCallback(
    (value: string) => {
      handleSelection('busChargingType', value);
    },
    [handleSelection],
  );

  return (
    <>
      <div className={className} data-testid="candidate-detail-bottom-default">
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.busPortfolio}
            label="Product portfolio"
            labelPosition="outside"
            placeholder="Product portfolio"
            disabled={disabled}
            options={busPortfolioOptions}
            dataTestid={`${dataTestidBase}-portfolio`}
            onChange={onChangePortfolio}
          />
          <Dropdown
            value={selections.busBattery}
            label="Battery"
            labelPosition="outside"
            placeholder="Battery"
            options={busBatteryOptions}
            dataTestid={`${dataTestidBase}-battery`}
            disabled={busBatteryDisabled || disabled}
            onChange={onChangeBusBattery}
          />
          <Dropdown
            value={selections.busSocWindow}
            label="SoC window"
            labelPosition="outside"
            placeholder="SoC window"
            options={busSocOptions}
            dataTestid={`${dataTestidBase}-soc-window`}
            disabled={busSocDisabled || disabled}
            onChange={onChangeBusSocWindow}
          />
        </div>
      </div>
      <div className={className}>
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.busChargingType}
            label="Charging type"
            labelPosition="outside"
            placeholder="Charging type"
            options={busChargingTypeOptions}
            dataTestid={`${dataTestidBase}-charging-type`}
            disabled={busChargingTypeDisabled || disabled}
            onChange={onChangeChargingType}
          />
          <Dropdown
            value={selections.busElectricMotor}
            label="Electric motor"
            labelPosition="outside"
            placeholder="Electric motor"
            options={busEngineGearBoxOptions}
            dataTestid={`${dataTestidBase}-electric-motor`}
            disabled={busEngineGearBoxDisabled || disabled}
            onChange={onChangeBusElectricMotor}
          />
          <Dropdown
            value={selections.busWheelConfiguration}
            label="Wheel configuration"
            labelPosition="outside"
            placeholder="Wheel configuration"
            options={busWheelConfigurationOptions}
            disabled={busWheelConfigurationDisabled || disabled}
            dataTestid={`${dataTestidBase}-wheel-configuration`}
            onChange={onChangeWheelConfiguration}
          />
        </div>
      </div>
      <div className={className}>
        <div className="flex flex-col gap-24">
          <Dropdown
            value={selections.busBodyLength}
            label="Body length"
            labelPosition="outside"
            placeholder="Body length"
            options={busBodyLengthOptions}
            dataTestid={`${dataTestidBase}-body-length`}
            disabled={busBodyLengthDisabled || disabled}
            onChange={onChangeBodyLength}
          />
          <Dropdown
            value={selections.busChassisHeight}
            label="Chassis height"
            labelPosition="outside"
            placeholder="Chassis height"
            options={busChassisHeightOptions}
            dataTestid={`${dataTestidBase}-chassis-height`}
            disabled={busChassisHeightDisabled || disabled}
            onChange={onChangeChassisHeight}
          />
          <Dropdown
            value={selections.busFloorHeight}
            label="Floor height"
            labelPosition="outside"
            placeholder="Floor height"
            options={busFloorHeightOptions}
            dataTestid={`${dataTestidBase}-floor-height`}
            disabled={busFloorHeightDisabled || disabled}
            onChange={onChangeFloorHeight}
          />
        </div>
      </div>
    </>
  );
};

export default BusFields;
