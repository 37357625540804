import { useMemo } from 'react';
import { ListDepotResponseV2VM, ListVehicleTransformed } from '@optimization/sa-common';

interface Props {
  depots?: ListDepotResponseV2VM[];
  depotId: string;
  vehicles?: ListVehicleTransformed[];
}

const useVehiclesMappedToOtherDepot = ({ depots, depotId, vehicles }: Props): { [key: string]: boolean } | undefined =>
  useMemo(
    () =>
      vehicles?.reduce(
        (acc, vehicle) => ({
          ...acc,
          [vehicle.Id]: depots
            ?.filter((depot) => depot.DepotId.toString() !== depotId)
            .some((depot) => depot.MappedVehicles.includes(vehicle.Id)),
        }),
        {},
      ),
    [depots, depotId, vehicles],
  );

export default useVehiclesMappedToOtherDepot;
