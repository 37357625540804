import PageHeader from 'common/components/PageHeader';
import useLoadingText from 'common/hooks/useLoadingText';
import { Loading } from '@optimization/ssi-common';
import { ReactNode } from 'react';
import { useGetSolutionQuery } from 'app/services/solution';
import { useParams } from 'react-router-dom';

const SolutionExists = ({ children }: { children: ReactNode }) => {
  const { solutionId } = useParams();

  const solutionQuery = useGetSolutionQuery(solutionId || '', {
    skip: !solutionId,
  });

  const { isLoading, isError } = solutionQuery;

  const loadingText = useLoadingText({
    isLoadingSolution: isLoading,
  });

  if (isLoading || isError) {
    return <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />;
  }

  if (solutionQuery.data?.DeletedAt) {
    return (
      <div className="tds-container mt-spacing-144">
        <PageHeader description={['Create an issue report to restore it.']} descriptionAligment="left">
          SOLUTION DELETED
        </PageHeader>
      </div>
    );
  }

  return children;
};

export default SolutionExists;
