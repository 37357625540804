import classNames from 'classnames';
import PageHeader from 'common/components/PageHeader';
import { APP_TITLE, SUPPORT_EMAIL, SUPPORT_NAME } from 'app/config';
import { TruckDetailGallery } from '@optimization/sa-common';

interface Props {
  className?: string;
}

const PageAccessDenied = ({ className }: Props) => (
  <div className={classNames('tds-container', className)}>
    <PageHeader
      intro="ACCESS DENIED"
      description={[
        <>
          Your global user does not have access to {APP_TITLE}.
          <br />
          Please contact {SUPPORT_NAME} (
          <a
            className="tds-text-grey-100 no-underline"
            href={`mailto:${SUPPORT_EMAIL}?subject=Need access to ${APP_TITLE}`}
          >
            {SUPPORT_EMAIL}
          </a>
          ) in order to request access.
        </>,
      ]}
      descriptionAligment="left"
      dataTestid="page-header-access-denied"
    >
      NOT AUTHORIZED
    </PageHeader>
    <TruckDetailGallery className="mt-spacing-98" />
  </div>
);

export default PageAccessDenied;
