import Checkboxes from 'common/components/Filters/utils/Checkboxes';
import classes from './style.module.scss';
import classNames from 'classnames';
import FiltersSelector from 'common/components/Filters/Selector';
import sortOptions from '../utils/sortOptions';
import { CandidateHandler } from 'features/candidate/Candidates/utils/useCandidatesHandler';
import { chassisAdaptationTranslate } from '@optimization/sa-common';
import { Checkbox, Dropdown, useNamesToDropdownOptions } from '@optimization/ssi-common';
import { MULTIPLE_VALUES_SEPARATOR } from 'app/config';

interface Props {
  candidatesHandler: CandidateHandler;
}

const CandidatesFilterSelector = ({ candidatesHandler }: Props) => {
  const chassisNumberOptions = useNamesToDropdownOptions({
    names: candidatesHandler.chassisNames,
  });

  const performanceStepOptions = useNamesToDropdownOptions({
    names: candidatesHandler.performanceSteps,
  });

  return candidatesHandler.isSuccess ? (
    <FiltersSelector
      sortType={candidatesHandler.sortType}
      sortOptions={sortOptions}
      onChangeSortType={candidatesHandler.filtersHandler.onChangeSortType}
      activeAction={candidatesHandler.filtersHandler.activeFiltersAction}
      hideFilters={candidatesHandler.filtersHandler.hideFilters}
    >
      <div className="min-w-250">
        <div className="tds-detail-04">Filter by name</div>
        <Dropdown
          placeholder="Select names"
          className="mt-spacing-12"
          multiselect
          filter
          options={chassisNumberOptions}
          onChange={candidatesHandler.onChangeChassisNumber}
          value={candidatesHandler.selectedChassisNames.join(MULTIPLE_VALUES_SEPARATOR)}
        />
      </div>
      {candidatesHandler.chassisAdaptations.length > 0 && (
        <Checkboxes
          className={classNames('min-w-100', classes['overflow-individually'])}
          dataTestid="adaptation-checkboxes"
          header="Adaptation"
        >
          {candidatesHandler.chassisAdaptations.map((chassisAdaptation) => (
            <Checkbox
              key={chassisAdaptation}
              value={chassisAdaptation}
              label={chassisAdaptationTranslate[chassisAdaptation]}
              onChange={candidatesHandler.onChangeChassisAdaptation}
              checked={candidatesHandler.selectedChassisAdaptations.includes(chassisAdaptation)}
            />
          ))}
        </Checkboxes>
      )}
      {candidatesHandler.wheelConfigurations.length > 0 && (
        <Checkboxes header="Wheel config" className={classNames('min-w-100', classes['overflow-individually'])}>
          {candidatesHandler.wheelConfigurations.map((wheelConfiguration) => (
            <Checkbox
              key={wheelConfiguration}
              value={wheelConfiguration}
              label={wheelConfiguration}
              onChange={candidatesHandler.onChangeWheelConfiguration}
              checked={candidatesHandler.selectedWheelConfigurations.includes(wheelConfiguration.toString())}
            />
          ))}
        </Checkboxes>
      )}
      {candidatesHandler.axleDistances.length > 0 && (
        <Checkboxes header="Axle distance" className={classNames('min-w-100', classes['overflow-individually'])}>
          {candidatesHandler.axleDistances.map((axleDistance) => (
            <Checkbox
              key={axleDistance}
              value={axleDistance.toString()}
              label={`${axleDistance} mm`}
              onChange={candidatesHandler.onChangeAxleDistance}
              checked={candidatesHandler.selectedAxleDistances.includes(axleDistance.toString())}
            />
          ))}
        </Checkboxes>
      )}
      {candidatesHandler.cabs.length > 0 && (
        <Checkboxes header="Cab type" className={classNames('min-w-100', classes['overflow-individually'])}>
          {candidatesHandler.cabs.map((cab) => (
            <Checkbox
              key={cab}
              value={cab}
              label={cab}
              checked={candidatesHandler.selectedCabs.includes(cab)}
              onChange={candidatesHandler.onChangeCab}
            />
          ))}
        </Checkboxes>
      )}
      {candidatesHandler.launchPeriods.length > 0 && (
        <Checkboxes header="Launch period" className={classNames('min-w-100', classes['overflow-individually'])}>
          {candidatesHandler.launchPeriods.map((launchPeriod) => (
            <Checkbox
              key={launchPeriod}
              value={launchPeriod}
              label={launchPeriod}
              onChange={candidatesHandler.onChangeLaunchPeriod}
              checked={candidatesHandler.selectedLaunchPeriods.includes(launchPeriod)}
            />
          ))}
        </Checkboxes>
      )}
      {candidatesHandler.installedEnergies.length > 0 && (
        <Checkboxes header="Energy" className={classNames('min-w-100', classes['overflow-individually'])}>
          {candidatesHandler.installedEnergies.map((installedEnergy) => (
            <Checkbox
              key={installedEnergy}
              value={installedEnergy.toString()}
              label={`${installedEnergy} kWh`}
              onChange={candidatesHandler.onChangeBattery}
              checked={candidatesHandler.selectedBatteries.includes(installedEnergy.toString())}
            />
          ))}
        </Checkboxes>
      )}
      {performanceStepOptions.length > 0 && (
        <div style={{ width: '182px' }}>
          <div className="tds-detail-04">Performance step</div>
          <Dropdown
            placeholder="Select years"
            className="mt-spacing-12"
            multiselect
            filter
            options={performanceStepOptions}
            onChange={candidatesHandler.onChangePerformanceStep}
            value={candidatesHandler.selectedPerformanceSteps.join(MULTIPLE_VALUES_SEPARATOR)}
          />
        </div>
      )}
    </FiltersSelector>
  ) : null;
};

export default CandidatesFilterSelector;
