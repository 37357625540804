import {
  getBusWheelConfigurationWithoutChassisAdaptation,
  getChassisAdaptationFromBusWheelConfiguration,
} from './useBusWheelConfiguration';

const busWheelConfigurationCheck = ({
  chassisAdaptation,
  wheelConfiguration,
  selectedWheelConfiguration,
}: {
  chassisAdaptation: string;
  wheelConfiguration: string;
  selectedWheelConfiguration: string;
}) =>
  wheelConfiguration === getBusWheelConfigurationWithoutChassisAdaptation(selectedWheelConfiguration) &&
  chassisAdaptation === getChassisAdaptationFromBusWheelConfiguration(selectedWheelConfiguration);

export default busWheelConfigurationCheck;
