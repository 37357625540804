import { useCallback, useEffect } from 'react';
import { useExpandVehicleAndTab } from 'app/context/ExpandVehicleAndTabContext';

interface Props {
  vehicleId: number;
}

const useResetExpandVehicleAndTabContext = ({ vehicleId }: Props) => {
  const expandVehicleAndTab = useExpandVehicleAndTab();

  const resetExpandVehicleAndTab = useCallback(() => {
    if (expandVehicleAndTab?.vehicleId && vehicleId === expandVehicleAndTab.vehicleId) {
      expandVehicleAndTab.setExpandVehicleAndTab({ vehicleId: undefined, candidateTab: undefined });
    }
  }, [expandVehicleAndTab, vehicleId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetExpandVehicleAndTab();
    }, 100);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [resetExpandVehicleAndTab]);
};

export default useResetExpandVehicleAndTabContext;
