import socsCheck from './socsCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';

const engineGearboxCheck = ({
  basicVehicle,
  selectedBattery,
  selectedSocWindow,
  selectedPortfolio,
}: {
  basicVehicle: FactBasicTruck | FactBasicBus;
  selectedBattery: string;
  selectedSocWindow: string;
  selectedPortfolio?: string;
}) =>
  socsCheck({
    basicVehicle,
    selectedBattery,
    selectedPortfolio,
  }) &&
  basicVehicle.SocSpecifications.map((socSpecification) => socSpecification.SocWindow.toString()).includes(
    selectedSocWindow,
  );

export default engineGearboxCheck;
