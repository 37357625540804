import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  enhancedListVehicles: ListVehicleEnhanced[];
}

const useNotFeasableVehicles = ({ enhancedListVehicles }: Props) =>
  useMemo(() => {
    if (enhancedListVehicles.length) {
      const notFeasableVehicles = enhancedListVehicles.filter(
        (vehicle) => vehicle.enhanced.vehicleColor !== 'positive',
      );

      if (notFeasableVehicles.length) {
        return notFeasableVehicles;
      }
    }

    return undefined;
  }, [enhancedListVehicles]);

export default useNotFeasableVehicles;
