import classes from './style.module.scss';
import ProductManualLink from './utils/ProductManualLink';
import usePersistentCollapsedHandler from './utils/usePersistentCollapsedHandler';
import { TdsSideMenu, TdsSideMenuItem } from '@scania/tegel-react';
import { useElementController } from 'app/context/ElementControllerContext';

const SideNavbarMinimal = ({ children }: { children: React.ReactNode }) => {
  const elementController = useElementController();

  const { persistent, collapsed, sideMenuKey, showIconTitle } = usePersistentCollapsedHandler();

  return (
    <div className="side-menu-wrapper">
      {elementController?.enableSidebar && (
        <>
          <TdsSideMenu
            id="side-menu"
            aria-label="Side menu"
            ref={elementController.sideMenuRef}
            persistent={persistent}
            collapsed={collapsed}
            key={sideMenuKey}
          >
            <TdsSideMenuItem class={classes['visible-mobile']}>
              <ProductManualLink borderTop={false} showIconTitle={showIconTitle} />
            </TdsSideMenuItem>
            <TdsSideMenuItem class={classes['visible-desktop']} slot="sticky-end">
              <ProductManualLink showIconTitle={showIconTitle} />
            </TdsSideMenuItem>
          </TdsSideMenu>
        </>
      )}
      <div className={classes['container-margin']}>{children}</div>
    </div>
  );
};

export default SideNavbarMinimal;
