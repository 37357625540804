import { useCallback } from 'react';

interface Props {
  avgGtwMin: number;
  avgGtwMax: number;
}

const useEnsureInAvgGtwRange = ({ avgGtwMin, avgGtwMax }: Props) =>
  useCallback(
    (value: number) => {
      let avgGtw = value;

      if (avgGtw < avgGtwMin) {
        avgGtw = avgGtwMin;
      } else if (avgGtw > avgGtwMax) {
        avgGtw = avgGtwMax;
      }

      return avgGtw;
    },
    [avgGtwMin, avgGtwMax],
  );

export default useEnsureInAvgGtwRange;
