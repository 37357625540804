import DepotInfoList from 'features/infrastructure/utils/DepotInfoList';
import { ButtonLink, PopoverCanvas } from '@optimization/ssi-common';
import { ExistingDepotMarker } from '../types';

interface Props {
  existingDepotMarker: ExistingDepotMarker;
  solutionId: string;
  onHideMarkerPopover: () => void;
}

const ExistingDepotPopover = ({ existingDepotMarker, solutionId, onHideMarkerPopover }: Props) => {
  return (
    <PopoverCanvas
      show
      hidePopoverCanvas={onHideMarkerPopover}
      style={{
        top: `${existingDepotMarker.position.y}px`,
        left: `${existingDepotMarker.position.x}px`,
        width: '275px',
      }}
    >
      <div className="flex justify-between">
        <div className="tds-detail-06">Depot</div>
        <ButtonLink
          variant="secondary"
          size="xs"
          text="Enter depot"
          url={`/solution/${solutionId}/infrastructure/${existingDepotMarker.existingDepot.DepotId}`}
        />
      </div>
      <div className="tds-headline-07 my-spacing-16">{existingDepotMarker.existingDepot.DepotName}</div>
      <DepotInfoList depot={existingDepotMarker.existingDepot} />
    </PopoverCanvas>
  );
};

export default ExistingDepotPopover;
