import { KeyedString } from '@optimization/ssi-common';
import { TElectrificationPlan, VehiclesByPerformanceStep } from '..';

interface Props {
  vehiclesByPerformanceStep: VehiclesByPerformanceStep;
  minimumPerformanceStepByVehicle: KeyedString;
}

const getInitialElectrificationPlan = ({
  vehiclesByPerformanceStep,
  minimumPerformanceStepByVehicle,
}: Props): TElectrificationPlan =>
  Object.keys(vehiclesByPerformanceStep).map((performanceStep) => ({
    performanceStep,
    modified: false,
    vehicles: vehiclesByPerformanceStep[performanceStep].reduce(
      (acc, vehicleId) => ({
        ...acc,
        [vehicleId]: { minimumPerformanceStep: minimumPerformanceStepByVehicle[vehicleId] },
      }),
      {},
    ),
  }));

export default getInitialElectrificationPlan;
