import { CHARGER_VALUES_SEPARATOR, RowItem } from '../../';
import { InfraPerformanceStepHelper } from '../types';
import { TextField } from '@optimization/ssi-common';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  disabled: boolean;
  row: RowItem;
  infraPerformanceStepHandler: InfraPerformanceStepHelper;
}

const QuantityTextField = ({ disabled, row, infraPerformanceStepHandler }: Props) => {
  const [value, setValue] = useState(row.charger.quantity.toString());
  const [changedByUser, setChangedByUser] = useState(false);

  const onChange = useCallback((quantity: string) => {
    setValue(quantity);
    setChangedByUser(true);
  }, []);

  const { onChangeChargerValue } = infraPerformanceStepHandler;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (changedByUser && value && Number(value) > 0) {
        onChangeChargerValue(
          `${row.charger.chargingSolutionIndex}${CHARGER_VALUES_SEPARATOR}${value}`,
          'Quantity',
          row.charger.chargerIndex,
        );
        setChangedByUser(false);
      }
    }, 1500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [changedByUser, value, row.charger.chargingSolutionIndex, row.charger.chargerIndex, onChangeChargerValue]);

  useEffect(() => {
    setChangedByUser(false);
    setValue(row.charger.quantity.toString());
  }, [row.charger.quantity]);

  return <TextField size="md" type="number" value={value} disabled={disabled} onChange={onChange} />;
};

export default QuantityTextField;
