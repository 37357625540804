import { SolutionTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  solutions?: SolutionTransformed[];
}

const useSolutionNames = ({ solutions }: Props) =>
  useMemo(() => {
    if (solutions) {
      return [...new Set(solutions.map((solution) => solution.transformed.solutionNamePresentation))]
        .splice(0, 100)
        .sort();
    }

    return [];
  }, [solutions]);

export default useSolutionNames;
