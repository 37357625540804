import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedEngineGearBox = ({
  vehicles,
  engineGearBoxList,
}: {
  vehicles: ListVehicleTransformed[];
  engineGearBoxList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const engineGearboxCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const engineGearbox = vehicle.EngineGearbox?.Name;

        return increaseValue({
          acc,
          key: engineGearbox,
          list: engineGearBoxList,
        });
      }, {});

      result = getMaxKey({ countByName: engineGearboxCount });
    }

    return result;
  }, [vehicles, engineGearBoxList]);

export default useSuggestedEngineGearBox;
