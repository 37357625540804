import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePersistentCollapsedHandler = () => {
  const location = useLocation();

  const [values, setValues] = useState({
    persistent: true,
    collapsed: true,
    showIconTitle: true,
    sideMenuKey: 0,
  });

  const handleValues = useCallback(() => {
    const timer = setTimeout(() => {
      if (window.innerWidth < 992) {
        setValues((prev) => ({
          collapsed: false,
          persistent: false,
          showIconTitle: false,
          sideMenuKey: prev.sideMenuKey + 1,
        }));
      } else {
        setValues((prev) => ({
          collapsed: true,
          persistent: true,
          showIconTitle: true,
          sideMenuKey: prev.sideMenuKey + 1,
        }));
      }
    }, 100);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    setValues((prev) => ({ ...prev, sideMenuKey: prev.sideMenuKey + 1 }));
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleValues);

    return () => {
      window.removeEventListener('resize', handleValues);
    };
  }, [handleValues]);

  return { ...values };
};

export default usePersistentCollapsedHandler;
