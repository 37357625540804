import ActionLayout from 'common/components/ActionLayout';
import CandidatesFilterSelector from 'features/candidate/Candidates/Filter/Selector';
import CandidatesFilterToggle from 'features/candidate/Candidates/Filter/Toggle';
import HelmetTitle from 'common/components/HelmetTitle';
import HistoricalStops from 'features/candidate/HistoricalStops';
import TotalCount from 'common/components/TotalCount';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useChassisNumbers from 'features/candidate/Candidates/utils/useChassisNumbers';
import useSolutionHasVehicles from 'features/solution/utils/useSolutionHasVehicles';
import { MapLocationType, SolutionTransformed, useVehicleSettingsHandler } from '@optimization/sa-common';
import { useMemo } from 'react';

const MAP_LOCATION_NAME = 'overview';

interface Props {
  solution: SolutionTransformed;
}

const HistoricalStopsWrapper = ({ solution }: Props) => {
  const solutionId = useMemo(() => solution.Id.toString(), [solution]);

  const solutionHasVehicles = useSolutionHasVehicles({ solutionId });

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const mapLocation = useMemo(
    () => solution.MapLocationsV2?.filter((item) => item.Name === MAP_LOCATION_NAME)?.[0],
    [solution],
  );

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    includeCandidateWithNonExecutedVehicleProduct: true,
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
    vehicleIdsWhenNoSelectedFilters: mapLocation?.VehicleIds,
  });

  const chassisNumbers = useChassisNumbers(candidatesHandler.filteredCandidates);

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Historical stops']} />
      {candidatesHandler.isSuccess && (
        <>
          {solutionHasVehicles && (
            <ActionLayout
              left={
                <>
                  {candidatesHandler.isSuccess && !candidatesHandler.isLoading && !chassisNumbers.length && (
                    <TotalCount
                      count={chassisNumbers.length}
                      entityName="VEHICLE CANDIDATE"
                      extraText="AVAILABLE FOR HISTORICAL STOPS"
                    />
                  )}
                </>
              }
              right={<CandidatesFilterToggle candidatesHandler={candidatesHandler} enabledSorting={false} />}
              below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
            />
          )}
          <HistoricalStops
            solutionId={solutionId}
            enhancedVehicles={candidatesHandler.filteredCandidates}
            mapLocationName={MAP_LOCATION_NAME}
            mapLocation={mapLocation}
            mapLocationType={MapLocationType.Solution}
            mapHeight="65vh"
          />
        </>
      )}
    </>
  );
};

export default HistoricalStopsWrapper;
