import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  selections: SelectionExecutions;
}

const useTruckDisabled = ({ selections }: Props) => {
  const truckBatteryDisabled = useMemo(() => !selections.truckPortfolio, [selections]);

  const truckSocDisabled = useMemo(() => !selections.truckPortfolio || !selections.truckBattery, [selections]);

  const truckEngineGearBoxDisabled = useMemo(
    () => !selections.truckPortfolio || !selections.truckBattery || !selections.truckSocWindow,
    [selections],
  );

  const truckChassisAdaptationDisabled = useMemo(
    () =>
      !selections.truckPortfolio ||
      !selections.truckBattery ||
      !selections.truckSocWindow ||
      !selections.truckElectricMotor,
    [selections],
  );

  const truckWheelConfigurationDisabled = useMemo(
    () =>
      !selections.truckPortfolio ||
      !selections.truckBattery ||
      !selections.truckSocWindow ||
      !selections.truckElectricMotor ||
      !selections.truckChassisAdaptation,
    [selections],
  );

  const truckAxleDistanceDisabled = useMemo(
    () =>
      !selections.truckPortfolio ||
      !selections.truckBattery ||
      !selections.truckSocWindow ||
      !selections.truckElectricMotor ||
      !selections.truckChassisAdaptation ||
      !selections.truckWheelConfiguration,
    [selections],
  );

  const truckChassisHeightDisabled = useMemo(
    () =>
      !selections.truckPortfolio ||
      !selections.truckBattery ||
      !selections.truckSocWindow ||
      !selections.truckElectricMotor ||
      !selections.truckChassisAdaptation ||
      !selections.truckWheelConfiguration ||
      !selections.truckAxleDistance,
    [selections],
  );

  const truckCabTypeDisabled = useMemo(
    () =>
      !selections.truckPortfolio ||
      !selections.truckBattery ||
      !selections.truckSocWindow ||
      !selections.truckElectricMotor ||
      !selections.truckChassisAdaptation ||
      !selections.truckWheelConfiguration ||
      !selections.truckAxleDistance ||
      !selections.truckChassisHeight,
    [selections],
  );

  return useMemo(
    () => ({
      truckBatteryDisabled,
      truckSocDisabled,
      truckEngineGearBoxDisabled,
      truckChassisAdaptationDisabled,
      truckWheelConfigurationDisabled,
      truckAxleDistanceDisabled,
      truckChassisHeightDisabled,
      truckCabTypeDisabled,
    }),
    [
      truckBatteryDisabled,
      truckSocDisabled,
      truckEngineGearBoxDisabled,
      truckChassisAdaptationDisabled,
      truckWheelConfigurationDisabled,
      truckAxleDistanceDisabled,
      truckChassisHeightDisabled,
      truckCabTypeDisabled,
    ],
  );
};

export default useTruckDisabled;
