import classNames from 'classnames';
import { usePlural } from '@optimization/ssi-common';

interface Props {
  count?: number;
  entityName: string;
  autoPlural?: boolean;
  extraText?: string;
  className?: string;
}

const TotalCount = ({ count, entityName, autoPlural = true, extraText, className }: Props) => {
  const plural = usePlural({ count, uppercase: true });

  return typeof count === 'number' ? (
    <p className={classNames('tds-detail-03', 'tds-text-grey-600', className)}>
      {count} {entityName}
      {autoPlural && plural}
      {extraText && ` ${extraText}`}
    </p>
  ) : null;
};

export default TotalCount;
