import { ChargingSpecification } from '@optimization/sa-common';
import { getChargingTypeValue } from './useBusChargingTypeOptions';

const chargingSpecificationCheck = ({
  chargingSpecifications,
  selectedChargingType,
}: {
  chargingSpecifications: ChargingSpecification[];
  selectedChargingType: string;
}) =>
  chargingSpecifications.some(
    (chargingSpecification) =>
      getChargingTypeValue({
        chargingType: chargingSpecification.ChargingType,
        chargingPowerKW: chargingSpecification.ChargingPowerKW,
      }) === selectedChargingType,
  );

export default chargingSpecificationCheck;
