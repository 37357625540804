import Datadog from 'features/datadog';
import DepotDetail from 'features/infrastructure/DepotDetail';
import DepotManager from 'features/infrastructure/DepotManager';
import EditDepot from 'features/infrastructure/EditDepot';
import Header from 'features/navigation/Header';
import Layout from 'common/components/Layout';
import LightMode from 'common/components/LightMode';
import LoadUser from 'features/auth/LoadUser';
import PageNotFound from 'common/components/PageNotFound';
import ProductManualOverview from 'features/productManual/ProductManualOverview';
import ProductManualSubCategory from 'features/productManual/ProductManualSubCategory';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteError from 'common/components/RouteError';
import SideNavbarDefault from 'features/navigation/SideNavbar/Default';
import SideNavbarMinimal from 'features/navigation/SideNavbar/Minimal';
import SideNavbarSolutionProductManual from 'features/navigation/SideNavbar/ProductManual';
import SolutionDetail from 'features/solution/SolutionDetail';
import SolutionHome from 'features/solution/SolutionHome';
import SolutionManager from 'features/solution/SolutionManager';
import SolutionPresentationConfigurator from 'features/solution/SolutionPresentationConfigurator';
import { AddVehicleProvider } from 'app/context/AddVehicleContext';
import { APP_TITLE, APP_USP } from 'app/config';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { defineCustomElements } from '@scania/tegel-react';
import { ElementControllerProvider } from 'app/context/ElementControllerContext';
import { enableMocking } from './mocks/setup';
import { ExpandVehicleProvider } from 'app/context/ExpandVehicleAndTabContext';
import { FinancialEvaluationProvider } from 'app/context/FinancialEvaluationContext';
import { Helmet } from 'react-helmet';
import { msalConfig } from 'app/config/auth';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { StickyHandlerProvider } from 'app/context/StickyHandlerContext';
import { store } from 'app/store';
import { ToastProvider, UpdateApp } from '@optimization/ssi-common';
import { TourProvider } from 'app/context/TourContext';
import './index.scss';

defineCustomElements();

export const msalInstance = new PublicClientApplication(msalConfig);

export const routes = {
  errorElement: <RouteError />,
  element: (
    <ElementControllerProvider>
      <Helmet>
        <title>
          {APP_TITLE} - {APP_USP}
        </title>
      </Helmet>
      <LoadUser>
        <TourProvider>
          <Datadog>
            <UpdateApp appTitle={APP_TITLE}>
              <ExpandVehicleProvider>
                <StickyHandlerProvider>
                  <Header />
                  <AddVehicleProvider>
                    <FinancialEvaluationProvider>
                      <Outlet />
                    </FinancialEvaluationProvider>
                  </AddVehicleProvider>
                </StickyHandlerProvider>
              </ExpandVehicleProvider>
            </UpdateApp>
          </Datadog>
        </TourProvider>
      </LoadUser>
    </ElementControllerProvider>
  ),
  children: [
    {
      element: (
        <Layout>
          <Outlet />
        </Layout>
      ),
      children: [
        {
          element: (
            <SideNavbarMinimal>
              <SolutionManager />
            </SideNavbarMinimal>
          ),
          path: '/',
        },
        {
          element: (
            <SideNavbarDefault>
              <Outlet />
            </SideNavbarDefault>
          ),
          children: [
            {
              element: <SolutionHome />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/home',
            },
            {
              element: <SolutionDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/candidate',
            },
            {
              element: <DepotManager />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure',
            },
            {
              element: <DepotDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId',
            },
            {
              element: <DepotDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId/year/:yearParam',
            },
            {
              element: <EditDepot />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId/edit',
            },
            {
              element: <SolutionPresentationConfigurator />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/presentation-configurator',
            },
          ],
        },
        {
          path: 'callback',
        },
      ],
    },
    {
      element: (
        <LightMode>
          <SideNavbarSolutionProductManual>
            <Outlet />
          </SideNavbarSolutionProductManual>
        </LightMode>
      ),
      children: [
        {
          element: <ProductManualOverview />,
          errorElement: <RouteError />,
          path: '/product-manual/:categorySlug',
        },
        {
          element: <ProductManualSubCategory />,
          errorElement: <RouteError />,
          path: '/product-manual/:categorySlug/:subCategorySlug',
        },
      ],
    },
    {
      path: '/*',
      element: <PageNotFound className="tds-u-mt3 pt-spacing-64" />,
    },
  ],
};

const router = createBrowserRouter([routes]);

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ToastProvider>
            <RouterProvider router={router} />
          </ToastProvider>
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
