import { formatDateInYearMonthDay, isNumeric, isValidDate, useToast } from '@optimization/ssi-common';
import { GetStopsParams } from '..';
import { MapLocationName, MapLocationType, MapLocationV2VM } from '@optimization/sa-common';
import { MapRef } from 'react-map-gl';
import { useAddMapLocationMutation, useUpdateMapLocationMutation } from 'app/services/solution';
import { useCallback, useEffect, useMemo } from 'react';

interface Props {
  mapRef: React.MutableRefObject<MapRef | null>;
  solutionId: string;
  vehicleIds: number[];
  getStopsParamsRef: React.MutableRefObject<GetStopsParams>;
  showTrips: boolean;
  showStops: boolean;
  mapLocation?: MapLocationV2VM;
  mapLocationName: MapLocationName;
  mapLocationType: MapLocationType;
  mapProvider: 'MapBox' | 'Here';
}

const useSaveLocation = ({
  mapRef,
  solutionId,
  vehicleIds,
  getStopsParamsRef,
  showTrips,
  showStops,
  mapLocation,
  mapLocationName,
  mapLocationType,
  mapProvider,
}: Props) => {
  const showToast = useToast();

  const [addLocationV2Mutation, addLocationV2State] = useAddMapLocationMutation();

  const [updateLocationV2Mutation, updateLocationV2State] = useUpdateMapLocationMutation();

  const addLocationState = useMemo(() => addLocationV2State, [addLocationV2State]);

  const updateLocationState = useMemo(() => updateLocationV2State, [updateLocationV2State]);

  const isAdd = useMemo(() => !mapLocation, [mapLocation]);

  const saveLocationMutationV2 = useMemo(
    () => (isAdd ? addLocationV2Mutation : updateLocationV2Mutation),
    [isAdd, addLocationV2Mutation, updateLocationV2Mutation],
  );

  const saveLocation = useCallback(() => {
    const startDate = new Date(getStopsParamsRef.current.startDate);
    const endDate = new Date(getStopsParamsRef.current.endDate);
    const minDuration = getStopsParamsRef.current.minDuration;
    const maxDuration = getStopsParamsRef.current.maxDuration;
    const resolution = getStopsParamsRef.current.resolution;

    if (
      mapRef.current &&
      isValidDate(startDate) &&
      isValidDate(endDate) &&
      isNumeric(minDuration) &&
      isNumeric(maxDuration) &&
      vehicleIds.length &&
      resolution
    ) {
      const mapCenter = mapRef.current.getCenter();
      const bearing = mapRef.current.getBearing();
      const pitch = mapRef.current.getPitch();

      saveLocationMutationV2({
        solutionId: parseInt(solutionId),
        mapLocation: {
          Bearing: bearing,
          EndDate: formatDateInYearMonthDay(endDate),
          Id: mapLocation ? mapLocation.Id : undefined,
          Latitude: mapCenter.lat,
          Longitude: mapCenter.lng,
          MapProvider: mapProvider,
          MinDuration: parseInt(minDuration) * 60,
          MaxDuration: parseInt(maxDuration) * 60,
          Name: mapLocationName,
          Pitch: pitch,
          Resolution: resolution,
          ShowTrips: showTrips,
          ShowStops: showStops,
          StartDate: formatDateInYearMonthDay(startDate),
          Type: mapLocationType,
          VehicleIds: vehicleIds,
          Zoom: mapRef.current.getZoom(),
        },
      });
    }
  }, [
    solutionId,
    getStopsParamsRef,
    showTrips,
    showStops,
    vehicleIds,
    mapLocation,
    mapLocationName,
    mapLocationType,
    mapProvider,
    mapRef,
    saveLocationMutationV2,
  ]);

  const saveLocationState = useMemo(
    () => (isAdd ? addLocationState : updateLocationState),
    [addLocationState, updateLocationState, isAdd],
  );

  useEffect(() => {
    if (!saveLocationState.isUninitialized && !saveLocationState.isLoading) {
      if (saveLocationState.isSuccess) {
        showToast({ header: 'Image was saved' });
      } else {
        showToast({ header: "Couldn't save image", variant: 'error' });
      }
    }
  }, [saveLocationState, showToast]);

  return useMemo(
    () => ({
      saveLocation,
      saveLocationState,
    }),
    [saveLocation, saveLocationState],
  );
};

export default useSaveLocation;
