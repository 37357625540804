import useGetPtoField from 'features/candidate/Pto/useGetPtoField';
import useOnChangePtoDevices from 'features/candidate/Pto/useOnChangePtoDevices';
import useOnCheckboxChange from 'features/candidate/Pto/useOnCheckboxChange';
import useSetEcItem from 'features/candidate/Pto/useSetEcItem';
import { EcItem } from 'features/candidate/Pto/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateMultipleVehiclesMutation } from 'app/services/solution';
import { ListVehicleEnhanced, PtoDevices, PtoType } from '@optimization/sa-common';
import { useToast, useEnterKeyInTextField, usePlural } from '@optimization/ssi-common';

const checkboxEcItemTypes = ['Crane', 'RefuseCollector'];

export interface PtoHandler {
  isLoading: boolean;
  isError: boolean;
  ecItems: EcItem[];
  checkboxEcItems: EcItem[];
  dropdownEcItems: EcItem[];
  ptoDevices: PtoDevices;
  onClose: () => void;
  getPtoField: (type: PtoType) => JSX.Element | null;
  setupPto: () => void;
  onChangePtoDevices: (value: string) => void;
  onCheckboxChange: (checked: boolean, value: string, type: PtoType) => void;
}

interface Props {
  solutionId: string;
  selectedVehicles: ListVehicleEnhanced[];
  ptoDevices?: PtoDevices;
  onClose: () => void;
}

const useSetupPtoHandler = ({ solutionId, selectedVehicles, ptoDevices = {}, onClose }: Props): PtoHandler => {
  const showToast = useToast();

  const [updateMultipleVehicles, updateMultipleVehiclesState] = useUpdateMultipleVehiclesMutation();

  const [ecItems, setEcItems] = useState<EcItem[]>([]);
  const [updatedVehiclesCount, setUpdatedVehiclesCount] = useState(0);

  const onChangePtoDevices = useOnChangePtoDevices({
    ecItems,
    setEcItems,
  });

  const setEcItem = useSetEcItem({
    ecItems,
    setEcItems,
  });

  const onCheckboxChange = useOnCheckboxChange({
    setEcItem,
    setEcItems,
  });

  const selectedVehiclesPlural = usePlural({
    count: updatedVehiclesCount,
  });

  const setupPto = useCallback(() => {
    updateMultipleVehicles({
      solutionId,
      Ids: selectedVehicles.map((selectedVehicle) => selectedVehicle.Id),
      Ptos: ecItems
        .filter((ecItem) => ecItem.Value || ecItem.Type === PtoType.RefrigerationUnit)
        .map((ecItem) => ({
          ElectricalConsumption: 0,
          Type: ecItem.Type,
          Value: ecItem.Value,
        })),
    });

    setUpdatedVehiclesCount(selectedVehicles.length);
  }, [solutionId, ecItems, selectedVehicles, updateMultipleVehicles]);

  useEffect(() => {
    if (updateMultipleVehiclesState.isSuccess && updatedVehiclesCount) {
      showToast({
        header: `PTO has been added to ${updatedVehiclesCount} vehicle${selectedVehiclesPlural}`,
        dataTestid: 'toast-setup-pto-success',
      });

      onClose();
      setUpdatedVehiclesCount(0);
    }
  }, [
    updateMultipleVehiclesState,
    selectedVehicles,
    selectedVehiclesPlural,
    updatedVehiclesCount,
    onClose,
    showToast,
    setUpdatedVehiclesCount,
  ]);

  const submitOnEnterKey = useEnterKeyInTextField(setupPto);

  const getPtoField = useGetPtoField({
    disabled: updateMultipleVehiclesState.isLoading,
    ecItems,
    ptoDevices,
    onCheckboxChange,
    setEcItem,
    submitOnEnterKey,
  });

  const filteredEcItems = useMemo(
    () => ecItems.filter((ecItem) => ecItem.Type !== PtoType.RefrigerationUnit),
    [ecItems],
  );

  const checkboxEcItems = useMemo(
    () => filteredEcItems.filter((ecItem) => checkboxEcItemTypes.includes(ecItem.Type)),
    [filteredEcItems],
  );

  const dropdownEcItems = useMemo(
    () => filteredEcItems.filter((ecItem) => !checkboxEcItemTypes.includes(ecItem.Type)),
    [filteredEcItems],
  );

  const isLoading = updateMultipleVehiclesState.isLoading;
  const isError = updateMultipleVehiclesState.isError;

  return useMemo(
    () => ({
      isLoading,
      isError,
      ecItems,
      checkboxEcItems,
      dropdownEcItems,
      ptoDevices,
      onClose,
      getPtoField,
      setupPto,
      onChangePtoDevices,
      onCheckboxChange,
    }),
    [
      isLoading,
      isError,
      ecItems,
      checkboxEcItems,
      dropdownEcItems,
      ptoDevices,
      onClose,
      getPtoField,
      setupPto,
      onChangePtoDevices,
      onCheckboxChange,
    ],
  );
};

export default useSetupPtoHandler;
