import { VehicleEnhanced, financialEvaluationVehicleTypeOptions } from '@optimization/sa-common';
import { TcoVehicleType } from '@optimization/sa-common/dist/types/backendModels';
import { FormValues } from '..';

export const convertResponseToFormValues = (vehicle: VehicleEnhanced) => {
  let comparisonConsumption;
  switch (true) {
    case vehicle.ExecutedVehicleProduct?.ComparisonConsumptionLPer100Km !== undefined:
      comparisonConsumption = vehicle.ExecutedVehicleProduct.ComparisonConsumptionLPer100Km!.toString();
      break;
    case vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm !== undefined:
      comparisonConsumption = (100 * vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm!).toString();
      break;
    default:
      comparisonConsumption = '25';
  }

  const defaultFormValues: FormValues = {
    currency: 'EUR',
    bevInsuranceCost: '0',
    bevTaxesCost: '0',
    bevRoadToll: '0',
    bevMaintenanceCost: '0',
    bevEngineType: 'BEV',
    bevEnergyCost: '0',
    bevFinanceType: 'Cash',
    bevVehiclePrice: '0',
    bevInterestRate: '0',
    bevResidual: '0',
    bevGovernmentSubsidies: '0',
    comparisonInsuranceCost: '0',
    comparisonTaxesCost: '0',
    comparisonRoadtoll: '0',
    comparisonMaintenanceCost: '0',
    comparisonAdBlueCost: '0',
    comparisonAdBlueConsumption: '0.1',
    comparisonEngineType: vehicle.ScaniaChassis?.Consumption?.FuelType ?? TcoVehicleType[TcoVehicleType.Diesel],
    comparisonEnergyCost: '0',
    comparisonEnergyConsumption: comparisonConsumption,
    comparisonFinanceType: 'Cash',
    comparisonVehiclePrice: '0',
    comparisonInterestRate: '0',
    comparisonResidual: '0',
    comparisonGovernmentSubsidies: '0',
  };

  if (vehicle.TcoCalculations !== undefined) {
    const formValues: FormValues = {
      currency: vehicle.TcoCalculations.Currency,
      bevEngineType: 'BEV',
      bevFinanceType: vehicle.TcoCalculations!.BevVehicle!.FinancingType ?? 'Leasing',
      bevVehiclePrice: vehicle.TcoCalculations.BevVehicle!.VehiclePrice.toString(),
      bevInterestRate: vehicle.TcoCalculations.BevVehicle!.InterestRate.toString(),
      bevResidual: vehicle.TcoCalculations.BevVehicle!.ResidualValue.toString(),
      bevGovernmentSubsidies: vehicle.TcoCalculations.BevVehicle!.GovernmentSubsidies.toString(),
      bevEnergyCost: vehicle.TcoCalculations.BevVehicle!.EnergyCost.toString(),
      bevInsuranceCost: vehicle.TcoCalculations.BevVehicle!.InsurancePerMonth.toString(),
      bevRoadToll: vehicle.TcoCalculations.BevVehicle!.RoadTollPerMonth.toString(),
      bevTaxesCost: vehicle.TcoCalculations.BevVehicle!.TaxPerMonth.toString(),
      bevMaintenanceCost: vehicle.TcoCalculations.BevVehicle!.MonthlyMaintenance.toString(),
      comparisonEngineType:
        financialEvaluationVehicleTypeOptions.find(
          (x) => x.value === vehicle.TcoCalculations?.ComparisonVehicle?.EngineType,
        )?.value ?? 'Diesel',
      comparisonFinanceType: vehicle.TcoCalculations?.ComparisonVehicle?.FinancingType ?? 'Leasing',
      comparisonVehiclePrice: vehicle.TcoCalculations?.ComparisonVehicle?.VehiclePrice.toString() ?? '10000',
      comparisonInterestRate: vehicle.TcoCalculations?.ComparisonVehicle?.InterestRate.toString() ?? '0',
      comparisonResidual: vehicle.TcoCalculations?.ComparisonVehicle?.ResidualValue.toString() ?? '0',
      comparisonGovernmentSubsidies: vehicle.TcoCalculations?.ComparisonVehicle?.GovernmentSubsidies.toString() ?? '0',
      comparisonEnergyCost: vehicle.TcoCalculations?.ComparisonVehicle?.EnergyCost.toString() ?? '0',
      comparisonEnergyConsumption: vehicle.TcoCalculations?.ComparisonVehicle?.EnergyConsumption.toString() ?? '25',
      comparisonAdBlueCost: vehicle.TcoCalculations?.ComparisonVehicle?.AdBlueCost?.toString() ?? '0',
      comparisonAdBlueConsumption: vehicle.TcoCalculations?.ComparisonVehicle?.AdBlueConsumption?.toString() ?? '0',
      comparisonInsuranceCost: vehicle.TcoCalculations?.ComparisonVehicle?.InsurancePerMonth.toString() ?? '0',
      comparisonRoadtoll: vehicle.TcoCalculations?.ComparisonVehicle?.RoadTollPerMonth.toString() ?? '0',
      comparisonTaxesCost: vehicle.TcoCalculations?.ComparisonVehicle?.TaxPerMonth.toString() ?? '0',
      comparisonMaintenanceCost: vehicle.TcoCalculations?.ComparisonVehicle?.MonthlyMaintenance.toString() ?? '0',
    };
    return formValues;
  }
  return defaultFormValues;
};
