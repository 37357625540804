import { Dropdown } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils//vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  disabled?: boolean;
  vehicleOptionsHandler: VehicleOptionsHandler;
}

const BusFields = ({ disabled, vehicleOptionsHandler }: Props) => {
  const {
    busPortfolioOptions,
    busBatteryOptions,
    busBatteryDisabled,
    busSocOptions,
    busSocDisabled,
    busChargingTypeDisabled,
    busChargingTypeOptions,
    busEngineGearBoxOptions,
    busEngineGearBoxDisabled,
    busWheelConfigurationOptions,
    busWheelConfigurationDisabled,
    busBodyLengthOptions,
    busBodyLengthDisabled,
    busChassisHeightOptions,
    busChassisHeightDisabled,
    busFloorHeightOptions,
    busFloorHeightDisabled,
    selections,
    handleSelection,
  } = vehicleOptionsHandler;

  const handleBusPortfolioChange = useCallback(
    (value: string) => handleSelection('busPortfolio', value),
    [handleSelection],
  );

  const handleBusBatteryChange = useCallback(
    (value: string) => handleSelection('busBattery', value),
    [handleSelection],
  );

  const handleBusSocChange = useCallback((value: string) => handleSelection('busSocWindow', value), [handleSelection]);

  const handleBusChargingTypeChange = useCallback(
    (value: string) => handleSelection('busChargingType', value),
    [handleSelection],
  );

  const handleBusElectricMotorChange = useCallback(
    (value: string) => handleSelection('busElectricMotor', value),
    [handleSelection],
  );

  const handleBusWheelConfigurationChange = useCallback(
    (value: string) => handleSelection('busWheelConfiguration', value),
    [handleSelection],
  );

  const handleBusBodyLengthChange = useCallback(
    (value: string) => handleSelection('busBodyLength', value),
    [handleSelection],
  );

  const handleBusChassisHeightChange = useCallback(
    (value: string) => handleSelection('busChassisHeight', value),
    [handleSelection],
  );

  const handleBusFloorHeightChange = useCallback(
    (value: string) => handleSelection('busFloorHeight', value),
    [handleSelection],
  );

  return (
    <>
      <div>
        <Dropdown
          value={selections.busPortfolio}
          label="Product portfolio"
          labelPosition="outside"
          placeholder="Product portfolio"
          modeVariant="secondary"
          disabled={disabled}
          options={busPortfolioOptions}
          dataTestid="dropdown-configure-vehicle-product-portfolio"
          onChange={handleBusPortfolioChange}
        />
        <Dropdown
          value={selections.busBattery}
          label="Battery"
          placeholder="Battery"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-battery"
          modeVariant="secondary"
          options={busBatteryOptions}
          disabled={busBatteryDisabled || disabled}
          onChange={handleBusBatteryChange}
        />
        <Dropdown
          value={selections.busSocWindow}
          label="SoC window"
          labelPosition="outside"
          placeholder="SoC window"
          dataTestid="dropdown-configure-vehicle-product-soc-window"
          modeVariant="secondary"
          options={busSocOptions}
          disabled={busSocDisabled || disabled}
          onChange={handleBusSocChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.busChargingType}
          label="Charging type"
          placeholder="Charging type"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-charging-type"
          modeVariant="secondary"
          options={busChargingTypeOptions}
          disabled={busChargingTypeDisabled || disabled}
          onChange={handleBusChargingTypeChange}
        />
        <Dropdown
          value={selections.busElectricMotor}
          label="Electric motor"
          labelPosition="outside"
          placeholder="Electric motor"
          dataTestid="dropdown-configure-vehicle-product-electric-motor"
          modeVariant="secondary"
          options={busEngineGearBoxOptions}
          disabled={busEngineGearBoxDisabled || disabled}
          onChange={handleBusElectricMotorChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.busWheelConfiguration}
          label="Wheel configuration"
          placeholder="Wheel configuration"
          labelPosition="outside"
          disabled={busWheelConfigurationDisabled || disabled}
          options={busWheelConfigurationOptions}
          dataTestid="dropdown-configure-vehicle-product-wheel-configuration"
          modeVariant="secondary"
          onChange={handleBusWheelConfigurationChange}
        />
        <Dropdown
          value={selections.busBodyLength}
          label="Body length"
          placeholder="Body length"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-body-length"
          modeVariant="secondary"
          options={busBodyLengthOptions}
          disabled={busBodyLengthDisabled || disabled}
          onChange={handleBusBodyLengthChange}
        />
      </div>
      <div>
        <Dropdown
          value={selections.busChassisHeight}
          label="Chassis height"
          placeholder="Chassis height"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-product-chassis-height"
          modeVariant="secondary"
          options={busChassisHeightOptions}
          disabled={busChassisHeightDisabled || disabled}
          onChange={handleBusChassisHeightChange}
        />
        <Dropdown
          value={selections.busFloorHeight}
          label="Floor height"
          placeholder="Floor height"
          labelPosition="outside"
          dataTestid="dropdown-configure-vehicle-floor-height"
          modeVariant="secondary"
          options={busFloorHeightOptions}
          disabled={busFloorHeightDisabled || disabled}
          onChange={handleBusFloorHeightChange}
        />
      </div>
    </>
  );
};

export default BusFields;
