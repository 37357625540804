import classNames from 'classnames';
import OperationalFactors from 'features/candidate/utils/OperationalFactors';
import Pto from './Pto';
import useOperationalFactorHandlers from './utils/useOperationalFactorHandlers';
import UserFactors from './UserFactors';
import VehicleConfiguration from './VehicleConfiguration';
import { AVG_SPEED_DEFAULT, PtoDevices, VehicleEnhanced } from '@optimization/sa-common';
import { FormState } from '../Main';
import { MenuItemName } from '../utils/Menu';
import { useMemo } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  activeMenuItemTitleName: MenuItemName;
  vehicle: VehicleEnhanced;
  vehicleOptionsHandler: VehicleOptionsHandler;
  ptoDevices?: PtoDevices;
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const BottomDetail = ({
  activeMenuItemTitleName,
  vehicle,
  vehicleOptionsHandler,
  ptoDevices,
  formState,
  setFormState,
}: Props) => {
  const disabled = useMemo(() => vehicle.enhanced.hasChargingEvents, [vehicle]);

  const {
    onChangeDailyDistance,
    onChangeLongestDailyDistance,
    onChangeAvgGtw,
    onChangeAvgSpeed,
    onChangeCruisingSpeed,
  } = useOperationalFactorHandlers({ vehicle, setFormState });

  return (
    <>
      {activeMenuItemTitleName === 'Vehicle configuration' && (
        <VehicleConfiguration
          disabled={vehicle.enhanced.hasChargingEvents}
          vehicleOptionsHandler={vehicleOptionsHandler}
        />
      )}
      {activeMenuItemTitleName === 'Operational factors' && (
        <>
          <div>
            <h5 className={classNames('tds-u-mb3', 'font-bold', 'tds-detail-04')}>OPERATIONAL FACTORS</h5>
            <OperationalFactors
              avgGtw={formState.avgGtw}
              avgSpeed={formState.avgSpeed}
              cruisingSpeed={formState.cruisingSpeed}
              cruisingSpeedMin={vehicle.ExecutedVehicleProduct?.AvgSpeedKmh?.toString() || AVG_SPEED_DEFAULT.toString()}
              dailyDistance={formState.dailyOdometerMedian}
              disabled={disabled}
              longestDailyDistance={formState.dailyOdometerLongest}
              longestDailyDistanceMin={vehicle.ExecutedVehicleProduct.DailyOdometerMedianKm.toString()}
              onChangeDailyDistance={onChangeDailyDistance}
              onChangeLongestDailyDistance={onChangeLongestDailyDistance}
              onChangeAvgGtw={onChangeAvgGtw}
              onChangeAvgSpeed={onChangeAvgSpeed}
              onChangeCruisingSpeed={onChangeCruisingSpeed}
            />
          </div>
          <div>
            <h5 className={classNames('font-bold', 'tds-detail-04')}>User factors</h5>
            <div className="flex flex-col gap-16 tds-u-mt2">
              <UserFactors disabled={disabled} formState={formState} setFormState={setFormState} />
            </div>
          </div>
          <div />
        </>
      )}
      {activeMenuItemTitleName === 'PTO device' && (
        <>
          {ptoDevices ? (
            <Pto vehicle={vehicle} ptoDevices={ptoDevices} />
          ) : (
            <>
              <div />
              <div />
            </>
          )}
          <div />
        </>
      )}
    </>
  );
};

export default BottomDetail;
