import portfolioCheck from './portfolioCheck';
import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { sortNumbers } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles: (FactBasicTruck | FactBasicBus)[];
  selectedPortfolio: string;
}

const useBattery = ({ basicVehicles, selectedPortfolio }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicVehicles
          .filter((basicVehicle) => portfolioCheck({ basicVehicle, selectedPortfolio }))
          .map((basicVehicle) => basicVehicle.InstalledEnergyKWh)
          .sort(sortNumbers),
      ),
    ],
    [basicVehicles, selectedPortfolio],
  );

export default useBattery;
