import { EcItem, SetEcItem, SubmitTextFieldByKeyboardEvent } from './types';
import { PtoDevices, PtoType } from '@optimization/sa-common';
import { TextField } from '@optimization/ssi-common';
import { useCallback, useMemo } from 'react';

interface Props {
  ecItems: EcItem[];
  ptoDevices: PtoDevices;
  type: PtoType;
  disabled?: boolean;
  setEcItem: SetEcItem;
  submitOnEnterKey: SubmitTextFieldByKeyboardEvent;
}

const PtoTextField = ({ ecItems, ptoDevices, type, disabled, setEcItem, submitOnEnterKey }: Props) => {
  const onChange = useCallback((value: string) => setEcItem({ type, value, intensity: 'delayed' }), [setEcItem, type]);

  const value = useMemo(() => ecItems.find((ecItem) => ecItem.Type === type)?.Value || '', [ecItems, type]);

  return (
    <TextField
      value={value}
      label={ptoDevices[type]?.Name}
      helper={ptoDevices[type]?.HelperText}
      suffixText={ptoDevices[type]?.Unit}
      disabled={disabled}
      labelPosition="outside"
      variant="secondary"
      type="number"
      min={0}
      onKeyUp={submitOnEnterKey}
      onChange={onChange}
    />
  );
};

export default PtoTextField;
